<template>
  <div>
    <div class="invite-form" v-if="message == 1">
      <div class="invite-form-category">
        <div class="invite-form-title">Контактные данные</div>
        <div class="invite-form-category-inputs">
          <div class="invite-form-input-container">
            <div class="label">
              <label>Фамилия</label>
            </div>
            <input-layout
              :input-value="invite.last_name"
              placeholder="Фамилия"
              :complete="invite.last_name"
              @update:input-value="invite.last_name = $event"
            />
          </div>
          <div class="invite-form-input-container">
            <div class="label">
              <label>Имя</label>
            </div>

            <input-layout
              :input-value="invite.first_name"
              placeholder="Имя"
              :complete="invite.first_name"
              @update:input-value="invite.first_name = $event"
            />
          </div>
          <div class="invite-form-input-container">
            <div class="label">
              <label>Отчество</label>
            </div>
            <input-layout
              :input-value="invite.father_name"
              placeholder="Отчество"
              :complete="invite.father_name"
              @update:input-value="invite.father_name = $event"
            />
          </div>
          <div class="invite-form-input-container">
            <div class="label">
              <label>Специальность</label>
            </div>
            <input-layout
              :input-value="invite.spec"
              placeholder="Специальность / специальности"
              :complete="invite.spec"
              @update:input-value="invite.spec = $event"
            />
          </div>
          <div class="invite-form-input-container">
            <div class="label">
              <label for="phone">Телефон</label>
            </div>
            <input-layout-phone
              :input-value="invite.phone"
              placeholder="Укажите ваш телефон"
              :complete="invite.phone"
              @update:input-value="invite.phone = $event"
            />
          </div>
        </div>
      </div>

      <!-- <div class="invite-form-category">
      <div class="invite-form-title">Социальные сети</div>

      <div class="invite-form-category-inputs">
        <div class="invite-form-input-container">
          <div class="label">
            <label>Whatsapp</label>
          </div>
          <input-layout-phone
            icon-name="whatsapp"
            :input-value="invite.social"
            placeholder="Whatsapp"
            :complete="invite.social"
            @update:input-value="invite.social = $event"
          />
        </div>
        <div class="invite-form-input-container">
          <div class="label">
            <label>Telegram</label>
          </div>

          <input-layout
            icon-name="telegram"
            :input-value="invite.telegram"
            placeholder="Telegram"
            :complete="invite.telegram"
            @update:input-value="invite.telegram = $event"
          />
        </div>
        <div class="invite-form-input-container">
          <div class="label">
            <label>Instagram</label>
          </div>
          <input-layout
            icon-name="instagram"
            :input-value="invite.instagram"
            placeholder="Instagram"
            :complete="invite.instagram"
            @update:input-value="invite.instagram = $event"
          />
        </div>
      </div>

      <div class="invite-form-category-inputs">
        <div class="invite-form-input-container">
          <div class="label">
            <label for="whatsapp">{{ invite.social }}</label>
          </div>
          <div class="input">
            <select
              class="invite-input"
              v-model="invite.social"
              type="text"
              id="whatsapp"
              placeholder="Whatsapp"
            >
              <option value="Whatsapp" selected="selected">Whatsapp</option>
              <option value="Telegram">Telegram</option>
            </select>
          </div>
        </div>
      </div>
    </div> -->

      <default-button class="invite-btn" color="#ffffff" @click="inviteDoctor">
        Отправить
      </default-button>
    </div>
    <div class="invite-end" v-if="message == 3">
      <div class="invite-end-top">
        <circle-check-icon />
        <div class="invite-end-title">Вы отправили приглашение</div>
      </div>
      <div class="invite-end-items">
        <div v-if="invite.name" class="invite-end-item">
          {{ invite.name }} <br />
          <span class="invite-spec">{{ invite.spec }}</span>
        </div>
        <div v-if="invite.phone" class="invite-end-item">
          {{ invite.phone }} <br />
          <span class="invite-spec">Номер телефона</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from '@/components/helppers/DefaultButton'
import InputLayout from '@/components/layout/inputs/InputLayout.vue'
import InputLayoutPhone from '@/components/layout/inputs/InputLayoutPhone.vue'

export default {
  name: 'AdminSendInvite',
  components: {
    InputLayoutPhone,
    DefaultButton,
    InputLayout
  },
  data() {
    return {
      invite: {
        name: '',
        spec: '',
        phone: '',
        whatsapp: '',
        instagram: '',
        telegram: '',
        social: 'Whatsapp'
      },
      message: 1
    }
  },
  computed: {
    loginUser() {
      return this.$store.getters.getUser
    }
  },
  methods: {
    async inviteDoctor() {
      let message = `${this.invite.last_name} ${this.invite.first_name} ${this.invite.father_name}, \n${this.loginUser.full_name} отметил вас, как доверенного ${this.invite.spec} в Системе Связи врачей. \nУзнать больше о системе и пройти регистрацию вы можете по ссылке. \nhttps://doctorsconnection.nomadicdemo.com/`
      let phone = this.invite.phone
      phone = phone.replace(/[\s()-]/g, '')

      let params = {
        user_id: this.loginUser.id,
        first_name: this.invite.first_name,
        last_name: this.invite.last_name,
        father_name: this.invite.father_name,
        special: this.invite.spec,
        phone: this.invite.phone,
        send_to: this.invite.social,
      }

      await this.$store.dispatch('inviteDoctor', params);
      await this.$store.dispatch('getInvitations', this.loginUser.id);

      if (this.invite.social === 'Whatsapp') {
        window.open(`https://wa.me/${phone}?text=${encodeURIComponent(message)}`)
      } else {
        window.open(`https://t.me/share/url?url=${encodeURIComponent(message)}`)
      }
      this.message = 3
    }
  }
}
</script>

<style scoped>
.invite-end-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #373a43;
}

.invite-end-items {
  display: flex;
  column-gap: 100px;
  flex-wrap: nowrap;
}

.invite-end-item {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  color: #373a43;
}

.invite-end {
  margin-top: 30px;
  background: #edf3f1;
  border-radius: 20px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.invite-end-top {
  display: flex;
  align-items: center;
  column-gap: 10px;
  flex-wrap: nowrap;
}

.invite-spec {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #7f818a;
}
.input {
  position: relative;
}

.invite-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 100px;
}

.invite-form-category {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 40px;
}

.invite-form-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #373a43;
}

.invite-form-category-inputs {
  display: grid;
  flex-wrap: nowrap;
  column-gap: 80px;
  row-gap: 50px;
  grid-template-columns: auto auto auto;

  @media screen and (max-width: 1200px) {
    column-gap: 50px;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: auto auto;
    column-gap: 50px;
  }

  @media screen and (max-width: 769px) {
    grid-template-columns: auto;
  }
}

.invite-form-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  row-gap: 10px;
  width: 100%;
}

.invite-btn {
  max-width: 130px;
  padding: 17px 29px;
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #373a43;
}

.invite-input {
  width: 100%;
  padding: 10px 21px;
  height: 40px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #7f818a;
  background: #edeff3;
  border-radius: 10px;
}

.invite-input::placeholder {
  color: #7f818a;
  font-size: 14px;
}

.social {
  padding-left: 50px;
}

.social-icon {
  position: absolute;
  top: 20px;
  left: 20px;
}
</style>

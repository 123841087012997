<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <close-icon @click="$store.dispatch('changeConnectPopup', false)" />
      </div>
      <div class="reg-item">
        <h3>Зарегистрироваться как пациент</h3>
        <p>Осуществляйте поиск среди рекомендованных врачей</p>
        <default-button :outline="true" @click="openPopUp('user')"> Регистрация </default-button>
      </div>
      <div class="reg-item">
        <h3>Зарегистрироваться как врач</h3>
        <p>
          Создайте свою анкету, расскажите о себе и своих услугах, делитесь рекомендациями о других
          врачах
        </p>
        <default-button :outline="true" @click="openPopUp('doctor')"> Регистрация </default-button>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from '@/components/helppers/DefaultButton'

export default {
  name: 'ConnectPopup',
  components: { DefaultButton },
  methods: {
    openPopUp(type) {
      this.$store.dispatch('changeConnectPopup', false)
      this.$store.dispatch('changeRegisterPopup', true)
      this.$store.dispatch('changeRegisterType', type)
    }
  }
}
</script>

<style scoped lang="scss">
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 497px;
  height: max-content;
  min-height: 434px;
  background: #ffffff;
  box-shadow: 0 4px 50px rgba(189, 189, 189, 0.7);
  border-radius: 20px;
  right: 0;
  bottom: 0;
  padding: 6px 10px 14px;
}

.close-parent {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 16px;
  right: 12px;
  cursor: pointer;
  svg {
    width: inherit;
    height: inherit;
    top: 0;
    position: absolute;
  }
}

.reg-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 57px;
  &:not(:last-child) {
    border-bottom: 1px solid #dbe0eb;
  }

  h3 {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #373a43;
    margin-bottom: 12px;
    margin-top: 38px;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #656873;
    margin-bottom: 23px;
    text-align: center;
  }

  button {
    margin-bottom: 34px;
    padding: 15px 20px;
  }
}

@media screen and (max-width: 520px) {
  .form {
    width: auto;
  }
  .reg-item {
    padding: 0 20px;
  }
}
</style>

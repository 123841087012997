<template>
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.27619 14.2503L5.21009 9.05107C5.1005 8.77529 5.10495 8.46727 5.22247 8.19476C5.33998 7.92226 5.56093 7.7076 5.83672 7.59801C6.1125 7.48842 6.42052 7.49287 6.69302 7.61038C6.96552 7.7279 7.18018 7.94885 7.28978 8.22463L9.08039 12.7306"
            :stroke="color" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M7.27668 8.22903L6.72572 6.84257C6.67145 6.70602 6.64462 6.56012 6.64674 6.41319C6.64886 6.26627 6.6799 6.1212 6.73809 5.98627C6.79628 5.85134 6.88047 5.72919 6.98587 5.6268C7.09126 5.52441 7.21579 5.44378 7.35234 5.38951C7.48889 5.33525 7.6348 5.30841 7.78172 5.31054C7.92865 5.31266 8.07372 5.3437 8.20865 5.40189C8.34358 5.46007 8.46572 5.54427 8.56812 5.64966C8.67051 5.75505 8.75114 5.87958 8.8054 6.01614L11.147 11.9086"
            :stroke="color" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M9.36634 7.40019C9.25675 7.12441 9.2612 6.81639 9.37872 6.54388C9.49623 6.27138 9.71718 6.05672 9.99297 5.94713C10.2687 5.83754 10.5768 5.84199 10.8493 5.9595C11.1218 6.07702 11.3364 6.29797 11.446 6.57375L13.2366 11.0797"
            :stroke="color" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M11.9973 7.96345C11.8877 7.68767 11.8921 7.37964 12.0096 7.10714C12.1271 6.83464 12.3481 6.61998 12.6239 6.51039C12.8997 6.4008 13.2077 6.40525 13.4802 6.52276C13.7527 6.64028 13.9673 6.86123 14.0769 7.13701L16.4185 13.0294C16.8569 14.1326 16.8391 15.3647 16.369 16.4547C15.899 17.5447 15.0152 18.4033 13.912 18.8417L12.5256 19.3926L12.6698 19.3353C11.981 19.6092 11.235 19.7077 10.4987 19.622C9.76244 19.5363 9.05899 19.2691 8.45152 18.8443C8.37854 18.7932 8.30571 18.7419 8.233 18.6904C7.88476 18.4443 6.59978 17.4225 4.37711 15.6248C4.15053 15.4415 4.00438 15.1771 3.96975 14.8878C3.93511 14.5984 4.01473 14.307 4.19166 14.0754C4.38006 13.8287 4.64552 13.6519 4.94586 13.5733C5.2462 13.4947 5.5642 13.5187 5.84935 13.6414L7.27335 14.2555"
            :stroke="color" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.48598 4.19971C2.73158 4.19971 1.41534 6.39387 2.29315 8.1492" :stroke="color"
              stroke-linecap="round"/>
    </svg>

</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: false,
            default: '#02B061'
        }
    },

    name: 'HelloIcon'
}
</script>

<style scoped></style>

<template>
    <div class="doctor-dashboard">
                <div class="doctor-dashboard-content">
                    <doctor-subscribes
                        v-if="pageId == 1"
                        type="1"
                        :page="true"
                        :subscribes="getDoctorDetalies.recommendations"
                    />
                    <doctor-subscribes
                        v-if="pageId == 2"
                        type="2"
                        :page="false"
                        :subscribes="getDoctorDetalies.un_recommendations"
                    />
                    <doctor-subscribes
                        v-if="pageId == 3"
                        type="3"
                        :page="false"
                        :subscribes="getDoctorDetalies.my_recommendations"
                    />
                    <doctor-notification v-if="pageId == 4"/>
                    <doctor-dertalis v-if="pageId == 5"/>
                    <doctor-settings v-if="pageId == 6"/>
                </div>

                <div class="info" v-if="pageId == 1">
                    <div class="info-text">
                        <div class="title-info">
                            {{doctorDashboardContent.banner_recommend?.title}}
                        </div>
                        <div class="text-info">
                            {{doctorDashboardContent.banner_recommend?.annotation}}
                        </div>
                    </div>
                    <div class="info-btn">
                        <button-helper color="#24AD6F" background="#EDF3F1" @click="this.$store.commit('setRecommendationNotAuthPopup', true)">
                            {{doctorDashboardContent.banner_recommend?.btn}}
                        </button-helper>
                    </div>
                </div>
    </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import DoctorSubscribes from '@/components/doctor/DoctorSubscribes.vue'
import DoctorNotification from '@/components/doctor/DoctorNotification.vue'
import DoctorDertalis from '@/components/doctor/DoctorDertalis.vue'
import DoctorSettings from '@/components/doctor/DoctorSettings.vue'

export default {
    name: 'DoctorDashboard',

    components: {
        DoctorSettings,
        DoctorDertalis,
        DoctorNotification,
        DoctorSubscribes,
        ButtonHelper,
    },

    computed: {
        pageId() {
            return this.$route.params.id
        },
        user() {
            return this.$store.getters.getUser
        },
        showTopChangeContent() {
            return this.$store.getters.getShowTopChangeContent
        },
        getDoctorDetalies() {
            return this.$store.getters.getDoctorDetalies
        },
        subs() {
            return this.$store.getters.getdocSubscribes
        },
        subscribeDoctors() {
            return this.$store.getters.getSubDoctors
        },
        mobile() {
            return this.$store.getters.getMobile
        },
        doctorDashboardContent() {
            return this.$store.getters.getDoctorDashboardContent
        },
        getDoctorSubscribe(){
            if (this.user.my_recommendations.length) {
                let ids = this.user.my_recommendations.map((item) => item.doctor_id)
                this.$store.dispatch('getDoctorsSubscribe', {page: ids})
            }
            if (this.user.recommendations.length) {
                let ids = this.user.recommendations.map((item) => item.recommendation_id)
                this.$store.dispatch('getSubscribeDoctors', {page: ids})
            }
            if (this.user.un_recommendations.length) {
                let ids = this.user.un_recommendations.map((item) => item.unrecommendation_id)
                this.$store.dispatch('getUnSubscribeDoctors', {page: ids})
            }
            return true
        }
    },

    async mounted() {
        await this.$store.dispatch('getUserByToken')
        await this.$store.dispatch('getDoctorDetalies', this.user.id)
        await this.$store.dispatch('getNotifications')

        if (!this.showTopChangeContent) {
            this.$store.dispatch('getDoctorDashboardContent')
        }

        this.getDoctorSubscribe;
    },
    watch:{
        async '$store.getters.getUser'() {
            await this.$store.dispatch('getDoctorDetalies', this.user.id)
            this.getDoctorSubscribe;
        }
    }
}
</script>

<style scoped>
.row {
    margin-top: 20px;
}

.menu-way {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #9195a4;
}

.page-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #373a43;
}

.small {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #9195a4;
}

.title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #373a43;
    margin-left: 5px;
    cursor: pointer;
}

.info {
    margin-top: 100px;
    background: #edf3f1;
    border-radius: 20px;
    padding: 45px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.info-text {

}

.title-info {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #373a43;
}

.text-info {
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #7f818a !important;
}

@media screen and (max-width: 769px) {
    .container {
        text-align: center;
    }

    .menu-way {

    }

    .info {
        margin-top: 50px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        padding: 35px 25px;
    }
}
</style>

<template>
    <div class="admin-details-top desktop-only">
        <div class="left-admin">
            <button-helper
                color="#24AD6F"
                background="#ffffff"
                class="subscribe-button"
                @click="this.$store.dispatch('changeCreateSub', this.doctor);"
            >
                <create-sub-icon-doc style="margin-right: 17px"/>
                Образовать связь
            </button-helper>
            <button-helper
                class="subscribe-button"
                v-if="user.role_id == 1"
                @click="subscribe"
                color="#24AD6F"
                background="#ffffff"
            >
                <thamb-up-doc-icon style="margin-right: 17px"/>
                Рекомендовать врача
            </button-helper>
        </div>
        <div class="right-admin">

            <button-helper color="#7F818A" background="#ffffff" class="subscribe-button" v-if="type == 1" @click="changeType">
                <doctor-edit-mobile-icon style="margin-right: 17px"/>
                Редактировать
            </button-helper>
            <button-helper color="#7F818A" background="#ffffff" class="subscribe-button" v-else @click="updateUser" >
                <doctor-edit-mobile-icon style="margin-right: 17px"/>
                Сохранить
            </button-helper>

            <button-helper
                color="#7F818A"
                background="#ffffff"
                @click="this.$store.dispatch('changeBlockUser', {
              doctor:this.doctor,
              text:'Вы хотите заблокировать данную анкету?',
              buttonText:'Заблокировать',
              block:true

            });"
                class="subscribe-button">
                <doctor-block-mobile-icon style="margin-right: 17px"/>
                Блокировать
            </button-helper>
            <button-helper
                color="#7F818A"
                background="#ffffff"
                class="subscribe-button"
                @click="this.$store.dispatch('changeDelete', true);this.$store.dispatch('changeDeleteUser', this.doctor);"
            >
                <doctor-trash-mobile-icon style="margin-right: 17px"/>
                Удалить
            </button-helper>
        </div>
    </div>

    <div class="admin-details-top-mobile mobile-only">

        <div class='buttons-block-item border-right'
            v-if="type == 1"
            @click="changeType">
            <doctor-edit-mobile-icon/>
            <div style="margin-top: 7px; width: 100%;float: left;">Редактировать</div>
        </div>
        <div class='buttons-block-item border-right'
            v-else
            @click="updateUser">
            <doctor-edit-mobile-icon/>
            <div style="margin-top: 7px; width: 100%;float: left;">Сохранить</div>
        </div>

        <div class='buttons-block-item border-right'
             @click="this.$store.dispatch('changeBlockUser', {
              doctor:this.doctor,
              text:'Вы хотите заблокировать данную анкету?',
              buttonText:'Заблокировать',
              block:true

            });">
            <doctor-block-mobile-icon/>
            <div style="margin-top: 7px; width: 100%;float: left;">Блокировать</div>
        </div>

        <div class='buttons-block-item'
             @click="this.$store.dispatch('changeDelete', true);this.$store.dispatch('changeDeleteUser', this.doctor);"
        >
            <doctor-trash-mobile-icon/>
            <div style="margin-top: 7px; width: 100%;float: left;">Удалить</div>
        </div>
    </div>
</template>

<script>
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";
import CreateSubIconDoc from "@/components/icons/CreateSubIconDoc.vue";
import ThambUpDocIcon from "@/components/icons/ThambUpDocIcon.vue";
import DoctorEditMobileIcon from "@/components/icons/DoctorEditMobileIcon.vue";
import DoctorBlockMobileIcon from "@/components/icons/DoctorBlockMobileIcon.vue";
import DoctorTrashMobileIcon from "@/components/icons/DoctorTrashMobileIcon.vue";

export default {

  name: 'AdminDetailsTop',

    components: {
        DoctorTrashMobileIcon,
        DoctorBlockMobileIcon,
        DoctorEditMobileIcon,
        ThambUpDocIcon,
      CreateSubIconDoc,
      ButtonHelper
    },
    props: {
        type: {
            type: Number,
            default: 0
        }
    },

    computed: {
        doctor() {
            return this.$store.getters.getDoctorDetalies
        },
        user() {
            return this.$store.getters.getUser
        },
    },

    methods: {
        subscribe() {
            this.$store.dispatch('changeSubscribPopup', true)
            this.$store.dispatch('changeSubscribType', 'Вы рекомендуете данного врача?')
            this.$store.dispatch('changeSubscribInfo', {
                user_id: this.user.id,
                doctor_id: this.doctor.id,
                subscribeText: 'Да, рекомендую',
                subscribeType: true
            })
        },
        deleteDoctor() {
            this.$store.dispatch('changeSubscribInfo', {
                user_id: this.doctor.id,
            })
        },
        changeType(){
            return this.$emit('changeType', 2);
        },
        updateUser() {
            return this.$emit('updateUser', 1);
        }
    }

}
</script>
<style  lang="scss">

.admin-details-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    gap: 20px;
    flex-wrap: wrap;

    @media screen and (max-width: 1080px) {
        justify-content: center;
        margin-bottom: 40px;
    }
}

.admin-details-top-mobile {
    box-sizing: border-box;
    width: 281px;
    height: 56px;
    border: 1px solid #dbe0eb;
    border-radius: 10px;
    display: flex;
    margin-bottom: 40px;
}

.left-admin {
    display: flex;
    align-items: center;
    column-gap: 22px;
}

.right-admin {
    display: flex;
    align-items: center;
    column-gap: 22px;
}

.subscribe-button {
    white-space: nowrap;
}

.content-buttons-block {
    box-sizing: border-box;
    width: 281px;
    height: 56px;
    border: 1px solid #DBE0EB;
    border-radius: 10px;
    margin: 40px auto;
    display: flex;
}

.buttons-block-item {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    color: #656873;
    text-align: center;
    margin: auto;
    padding-right: 7px;
    height: 100%;
    padding-top: 12px;
}

.border-right {
    border-right: 1px solid #DBE0EB;
}


</style>

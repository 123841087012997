export default {
    state: {
        cities:[],
        states:[],
        popularCities:[]
    },
    mutations: {
        setCities(state,cities){
            state.cities = cities
        },
        setStates(state,states){
            state.states = states
        },
        setPopularCities(state,popularCities){
            state.popularCities = popularCities
        }
    },
    getters: {
        getCities(state){
            return state.cities
        },
        getStates(state){
            return state.states
        },
        getPopularCities(state){
            return state.popularCities
        }
    },
    actions: {
        async getCities({commit}){
            await this.$axios.get(`/api/get-city`).then(res => {
                commit('setStates',res.data.states)
                commit('setCities',res.data.cities)
            })
        },
        async getPopularCities({commit}){
            await this.$axios.get(`/api/get-popular-city`).then(res => {
                commit('setPopularCities',res.data)
            })
        },
        async setLocation({commit},params){
            await this.$axios.post(`/api/set-user-city`,params).then(res =>{
                commit('setUser',res.data)
            })
        }
    }
}

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.06667 1H11.9333C12.2 1 12.3778 1.08889 12.5556 1.26667L16.7333 5.44444C16.9111 5.62222 17 5.8 17 6.06667V11.9333C17 12.2 16.9111 12.3778 16.7333 12.5556L12.5556 16.7333C12.3778 16.9111 12.2 17 11.9333 17H6.06667C5.8 17 5.62222 16.9111 5.44444 16.7333L1.26667 12.5556C1.08889 12.3778 1 12.2 1 11.9333V6.06667C1 5.8 1.08889 5.62222 1.26667 5.44444L5.44444 1.26667C5.62222 1.08889 5.8 1 6.06667 1V1Z"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 5.44446V9.00001"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 12.5555H9.00889"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'CircleInfoDocIcon'
}
</script>

<style scoped></style>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00016 15.1666C4.04683 15.1666 0.833496 11.9533 0.833496 7.99992C0.833496 4.04659 4.04683 0.833252 8.00016 0.833252C11.9535 0.833252 15.1668 4.04659 15.1668 7.99992C15.1668 11.9533 11.9535 15.1666 8.00016 15.1666ZM8.00016 1.83325C4.60016 1.83325 1.8335 4.59992 1.8335 7.99992C1.8335 11.3999 4.60016 14.1666 8.00016 14.1666C11.4002 14.1666 14.1668 11.3999 14.1668 7.99992C14.1668 4.59992 11.4002 1.83325 8.00016 1.83325Z"
      fill="#02B061"
    />
    <path
      d="M7.05346 10.3867C6.92012 10.3867 6.79346 10.3334 6.70012 10.2401L4.81346 8.3534C4.62012 8.16007 4.62012 7.84007 4.81346 7.64673C5.00679 7.4534 5.32679 7.4534 5.52012 7.64673L7.05346 9.18007L10.4801 5.7534C10.6735 5.56007 10.9935 5.56007 11.1868 5.7534C11.3801 5.94673 11.3801 6.26673 11.1868 6.46006L7.40679 10.2401C7.31346 10.3334 7.18679 10.3867 7.05346 10.3867Z"
      fill="#02B061"
    />
  </svg>
</template>

<script>
export default {
  name: 'TickIcon'
}
</script>

<style scoped></style>

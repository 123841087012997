<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.95898 4.95801V8.06912L9.51454 9.62467" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 7.29063C1.17429 5.57956 1.97243 3.99255 3.24212 2.83238C4.51182 1.67222 6.1642 1.0201 7.88401 1.00046C9.60382 0.980814 11.2707 1.59502 12.5665 2.72588C13.8624 3.85675 14.6966 5.42511 14.9099 7.13175C15.1232 8.83839 14.7008 10.5638 13.7232 11.9789C12.7455 13.3939 11.2812 14.3995 9.60943 14.8038C7.93771 15.2081 6.17565 14.9828 4.65944 14.1709C3.14323 13.359 1.979 12.0173 1.38889 10.4017M1 14.2906V10.4017H4.88889" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMobileStory"
}
</script>

<style scoped>

</style>

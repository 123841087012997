<template>
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V11L8.9375 5.5L0 0Z" :fill="color"/>
    </svg>
</template>

<script>
export default {

  props: {
    color: {
      type: String,
      required: false,
      default: '#02B061'
    }
  },

  name: 'PlayIcon'
}
</script>

<style scoped></style>

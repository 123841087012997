<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.33171 8.11068C8.29527 8.11068 9.88705 6.5189 9.88705 4.55534C9.88705 2.59178 8.29527 1 6.33171 1C4.36815 1 2.77637 2.59178 2.77637 4.55534C2.77637 6.5189 4.36815 8.11068 6.33171 8.11068Z" stroke="#9FD6BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 16.9981V15.2204C1 14.2774 1.37458 13.3731 2.04134 12.7064C2.70809 12.0396 3.61241 11.665 4.55534 11.665H8.11068C9.05362 11.665 9.95793 12.0396 10.6247 12.7064C11.2914 13.3731 11.666 14.2774 11.666 15.2204V16.9981" stroke="#9FD6BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5537 1.11621C13.3185 1.31202 13.9963 1.7568 14.4804 2.38041C14.9644 3.00403 15.2272 3.77101 15.2272 4.56045C15.2272 5.34989 14.9644 6.11687 14.4804 6.74049C13.9963 7.3641 13.3185 7.80887 12.5537 8.00469" stroke="#9FD6BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.9976 16.9995V15.2218C16.993 14.4371 16.7291 13.676 16.2467 13.057C15.7644 12.438 15.0909 11.996 14.3311 11.7998" stroke="#9FD6BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMenuPepole"
}
</script>

<style scoped>

</style>

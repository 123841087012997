<template>
  <div v-if="subscribes" class="doctor-subscribes-container">
    <div class="top-buttons" v-if="message == 1">
        <div class="top-filters">
            <FilterPersonalCabinet
              :sort-value="this.sortSpecia"
              :show-sort="this.showSortSpecia"
              :sort-by="'Специальность'"
              :drop-down-list="this.specialities"
              @changeShowSort="this.showSortSpecia = $event"
              @changeSortValue="sortingShowSpecia($event)"
            />
            <!-- <FilterPersonalCabinet
              :sort-value="this.sortCity"
              :show-sort="this.showSortCity"
              :drop-down-list="this.specialities"
              :sort-by="'Город'"
              @changeShowSort="this.showSortCity = $event"
              @changeSortValue="sortingShowCity($event)"
            /> -->

            <button-helper
                color="#656873"
                style="border-color: #D3D8E2; background: #fff; font-weight: 300"
                @click="showSortCity = true;"
            >
                Город: <span style="font-weight: 400">{{ sortCity ? sortCity : 'Все' }}</span>
                <arrow-down style="margin-left: 10px; transition: .2s" :class="{'arrow-icon-active': hidden_city}"/>
            </button-helper>
        </div>
      <div class="top-button-right desktop-only">
        <button-helper style="background-color: #FFFFFF; border: 1px solid #02B061; color: #02B061"
          v-if="message == 1"
          @click="message = 2">
          Отправить приглашение
        </button-helper>
      </div>
    </div>

    <div v-if="message == 1">
      <div class="doctor-items-container" v-for="(city, i) in cities" :key="city">
      <div class="doctor-items-city" v-if="subscribes && subscribes.length">
        {{ city }} <span class="count"> {{ docByCities[i].length }} </span>
      </div>
      <div
        class="doctor-items"
        v-for="(item, index) in docByCities[i]"
        :key="item.id"
      >
        <sub-doctor-item :user="item" :id="subscribes[index]?.id"/>
      </div>
    </div>
    </div>

    <div class="invite-form" v-if="message == 2">
      <div class="invite-form-category">
          <div class="invite-form-title">Контактные данные</div>
          <div class="invite-form-category-inputs">
              <div class="invite-form-input-container">
                  <div class="label">
                      <label for="name">ФИО</label>
                  </div>
                  <div class="input">
                    <input-layout
                      :input-value="invate.last_name"
                      placeholder="Фамилия"
                      :complete="invate.last_name"
                      @update:input-value="invate.last_name = $event"
                  />
                    </div>
                    <div class="input">
                      <input-layout
                      :input-value="invate.first_name"
                      placeholder="Имя"
                      :complete="invate.first_name"
                      @update:input-value="invate.first_name = $event"
                  />
                    </div>
                    <div class="input">
                      <input-layout
                      :input-value="invate.father_name"
                      placeholder="Отчество"
                      :complete="invate.father_name"
                      @update:input-value="invate.father_name = $event"
                    />
                    </div>
              </div>
              <div class="invite-form-input-container">
                  <div class="label">
                      <label for="spec">Специальность</label>
                  </div>
                  <div class="input">
                    <input-layout
                      :input-value="invate.spec"
                      placeholder="Специальность / специальности"
                      :complete="invate.spec"
                      @update:input-value="invate.spec = $event"
                    />
                  </div>
              </div>
              <div class="invite-form-input-container">
                  <div class="label">
                      <label for="phone">Телефон</label>
                  </div>
                  <div class="input">
                    <input-layout-phone
                      :input-value="invate.phone"
                      placeholder="Укажите ваш телефон"
                      :complete="invate.phone"
                      @update:input-value="invate.phone = $event"
                  />
                  </div>
              </div>
          </div>
      </div>
      <!-- <div class="invite-form-category">
          <div class="invite-form-title">Социальные сети</div>
          <div class="invite-form-category-inputs">
              <div class="invite-form-input-container">
                  <div class="label">
                      <label for="whatsapp">{{ invate.social }}</label>
                  </div>
                  <div class="input">
                      <select
                          class="invite-input"
                          v-model="invate.social"
                          type="text"
                          id="whatsapp"
                          placeholder="Whatsapp"
                      >
                          <option value="Whatsapp" selected="selected">Whatsapp</option>
                          <option value="Telegram">Telegram</option>
                      </select>
                  </div>
              </div>
          </div>
      </div> -->
      <div style="display: flex;">
        <button-helper class="invite-btn" @click="invateDoctor" color="#ffffff" background="#02B061">
          Отправить
        </button-helper>
      </div>

    </div>
    <div class="invite-end" v-if="message == 3">
      <div class="invite-end-top">
          <circle-check-icon/>
          <div class="invite-end-title">Вы отправили приглашение</div>
      </div>
      <div class="invite-end-items">
          <div v-if="invate.name" class="invite-end-item">
              {{ invate.name }} <br/>
              <span class="invite-spec">{{ invate.spec }}</span>
          </div>
          <div v-if="invate.phone" class="invite-end-item">
              {{ invate.phone }} <br/>
              <span class="invite-spec">Номер телефона</span>
          </div>
      </div>
      <button-helper class="invite-btn" color="#656873" background="#FFFFFF" @click="message = 1">
          Назад
      </button-helper>
    </div>
    <SelectCity v-if="showSortCity" @changeSortCity="filterByCity($event)" @closeModal="showSortCity = false"/>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/ButtonHelper.vue';
import SubDoctorItem from '@/components/doctor/SubDoctorItem.vue';
import ArrowDown from "@/components/icons/HeaderNameArrowDown.vue";
import FilterPersonalCabinet from "@/components/layout/FilterPersonalCabinet.vue";
import SelectCity from "@/components/layout/SelectCity.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import InputLayoutPhone from "@/components/layout/inputs/InputLayoutPhone.vue";


export default {
  name: 'DoctorSubscribes',
  components: {
    SubDoctorItem,
    ButtonHelper,
    ArrowDown,
    FilterPersonalCabinet,
    SelectCity,
    InputLayout,
    InputLayoutPhone
  },
  props: {
    subscribes: {
      type: Array,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    page: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
        hidden_speciality: false,
        hidden_city: false,
        hidden_share: false,
        message: 1,
        invate: {
                name: '',
                spec: '',
                social: 'Whatsapp'
        },
        sortSpecia: null,
        sortCity: null,
        showSortSpecia: false,
        showSortCity: false,
        specialities: [],
        // cities: []
    }
  },
  computed: {
    subs() {
      return this.$store.getters.getdocSubscribes
    },
    subscribeDoctors() {
      return this.$store.getters.getSubDoctors
    },
    unsubs(){
      return this.$store.getters.getunsubscribesDoc
    },
    doctors() {
      let sortedDoctors = [];
      if (this.type == 1) {
        sortedDoctors = this.subscribeDoctors;
      }else if(this.type == 3){
        sortedDoctors =  this.subs;
      }else if(this.type == 2){
        sortedDoctors =  this.unsubs;
      }
      if (this.sortSpecia && this.sortSpecia !== null) {
        sortedDoctors = sortedDoctors.filter(doctor => {
          return doctor.specialities.some(specie => specie.specie_name === this.sortSpecia)
        });
      }

      if (this.sortCity && this.sortCity !== null) {
        sortedDoctors = sortedDoctors.filter(doctor => {
          return doctor.user_detales.city === this.sortCity
        });
      }
      return sortedDoctors;
    },
    cities() {
      let cities = []
      this.doctors.forEach((item) => {
        if (item.user_detales.city && cities.indexOf(item.user_detales.city) == -1) {
          cities.push(item.user_detales.city)
        }
      })

      return cities
    },
    docByCities() {
      let docByCities = this.cities.map((item) => {
        return this.doctors.filter((i) => {
          if (item == i.user_detales.city) {
            return i
          }
        })
      })
      return docByCities
    },
    loginUser() {
      return this.$store.getters.getUser
    }
  },
  methods:{
    async invateDoctor() {
      let message = `${this.invate.last_name} ${this.invate.first_name} ${this.invate.father_name}, \n${this.loginUser.full_name} отметил вас, как доверенного ${this.invate.spec} в Системе Связи врачей. \nУзнать больше о системе и пройти регистрацию вы можете по ссылке. \nhttps://doctorsconnection.nomadicdemo.com/`;
      let phone = this.invate.phone
      phone= phone.replace(/[\s()-]/g, '');

      let params = {
        user_id: this.loginUser.id,
        first_name: this.invate.first_name,
        last_name: this.invate.last_name,
        father_name: this.invate.father_name,
        special: this.invate.spec,
        phone: this.invate.phone,
        send_to: this.invate.social,
      }

      await this.$store.dispatch('inviteDoctor', params);

      if (this.invate.social === 'Whatsapp') {
          window.open(`https://wa.me/${phone}?text=${encodeURIComponent(message)}`)
      } else {
          window.open(`https://t.me/share/url?url=${encodeURIComponent(message)}`)
      }
      this.message = 3
    },
    sortingShowSpecia($event){
      this.sortSpecia = $event;
    },
    sortingShowCity($event){
      this.sortCity = $event;
    },
    filterByCity($event){
      this.sortCity = $event;
      this.showSortCity = false;
    }
  },
  async mounted(){
    await this.$store.dispatch('getOnlySpecialities');
    this.specialities = this.$store.getters.getOnlySpecialities;
  }
}
</script>

<style scoped lang="scss">

.doctor-subscribes-container {
    display: flex;
    flex-direction: column;
}

.doctor-items-container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.doctor-items {
    display: flex;
    flex-direction: column;
}

.arrow-icon-active {
    transition: .2s;
    transform: rotateX(-180deg);
}

.top-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.top-filters {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    align-items: center;

    @media screen and (max-width: 400px) {
        flex-direction: column;
    }
}

.doctor-items-city {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #373a43;
}
.count {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}

@media screen and (max-width: 869px) {
    .top-button-right {
        display: none;
    }
}

@media screen and (max-width: 769px){
  .top-buttons {
      justify-content: center;
  }
  .share-btn {
      margin-top: 50px;
  }
}

.invate-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #373a43;
    margin: 10px;
}

.invate-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    margin: 10px;
    color: #656873;
}

.invate-button {
    margin-top: 20px;
}

.invate {
    text-align: center;
}

.invite-form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 100px;
}

.invite-form-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 40px;
}

.invite-form-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #373a43;
}

.invite-form-category-inputs {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 80px;
    row-gap: 20px;
}

.invite-form-input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    row-gap: 10px;
    max-width: 350px;
    width: 100%;
}

.invite-btn {
    max-width: 130px;
}

.label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #373a43;
}


.invite-input {
    width: 100%;
    padding: 10px;
    height: 40px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #7f818a;
    background: #edeff3;
    border-radius: 10px;
}

.invite-end-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #373a43;
}

.invite-end-items {
    display: flex;
    column-gap: 100px;
    flex-wrap: nowrap;
}

.invite-end-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #373a43;
}

.invite-end {
    margin-top: 30px;
    background: #edf3f1;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.invite-end-top {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: nowrap;
}

.invite-spec {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #7f818a;
}

@media screen and (max-width: 1080px) {

    .invite-form-category-inputs {
        flex-wrap: wrap;
    }

}

@media screen and (max-width: 769px) {

    .invate-text {
        font-size: 13px;
    }

    .invate-title {
        font-size: 16px;
    }


    .invite-form-category-inputs {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .invite-form-input-container {
        max-width: 100%;
    }

    .invite-btn {
        margin: 0 auto;
    }

    .invite-form {
        row-gap: 60px;
    }

    .invite-end {
        align-items: center;
        padding: 30px 20px;
    }

    .invite-end-items {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }

    .invite-end-title {
        white-space: nowrap;
        font-size: 15px;
    }
}
</style>

<template>
  <div class="user-dashboard-sub">
    <div class="user-dashboard-sub__top-btns">
        <FilterPersonalCabinet
            style="margin-top: 10px;"
            :sort-value="this.sortSpecia"
            :show-sort="this.showSortSpecia"
            :sort-by="'Специальность'"
            :drop-down-list="this.specialities"
            @changeShowSort="this.showSortSpecia = $event"
            @changeSortValue="this.sortSpecia = $event;"
        />

      <div style="margin-top: 10px;">
        <button-helper color="#656873" style="height: 46px; border-color: #D3D8E2; background: #fff; font-weight: 300;"
        @click="isCopied = !isCopied">
          <shared-icon class="shared" :class="{'arrow-icon-active': isCopied}"/>
          Поделиться коллекцией &#9662;
        </button-helper>
        <div class="copy-link" v-if="isCopied">{{ copyLink }}</div>
      </div>
    </div>

    <div class="sub-doctor-items">
        <sub-doctor-item
            v-for="item in favoriteDoctors"
            :key="item.doctor.id"
            :user="item.doctor"
            :id="item.id"
        />
    </div>
  </div>
</template>

<script>
import SubDoctorItem from '@/components/doctor/SubDoctorItem.vue'
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";
import SharedIcon from "@/components/icons/SharedIcon.vue";
import FilterPersonalCabinet from "@/components/layout/FilterPersonalCabinet.vue";

export default {
  name: 'UserDashboardSubscrib',

  components: {
    FilterPersonalCabinet,
    SharedIcon,
    ButtonHelper,
    SubDoctorItem,
  },

    data() {
        return {
            sortSpecia: '',
            showSortSpecia: false,
            specialities: [],
            isCopied: false,
        }
    },

  computed: {
    favoriteDoctors() {
      let favorites = this.$store.getters.getFavorites

      if (this.sortSpecia && this.sortSpecia !== null) {
        favorites = favorites.filter(favorite => {
        return favorite.doctor.specialities.some(specie => specie.specie_name === this.sortSpecia)
        });
      }
      
      return favorites
    },
    user() {
      return this.$store.getters.getUser
    },
    mobile() {
        return this.$store.getters.getMobile //mobile breakpoint
    },
    copyLink(){
      let link = `${window.location.origin}/user-collections/${this.user.id}`;
      return link
    }

  },
    async mounted(){
        await this.$store.dispatch('getOnlySpecialities');
        await this.$store.dispatch('getUserFavorites', localStorage.userToken);
        this.specialities = this.$store.getters.getOnlySpecialities;

    }
}
</script>

<style scoped lang="scss">
.arrow-icon-active {
    transition: .2s;
    transform: rotateX(-180deg);
}
.copy-link {
  background: rgb(255, 255, 255);
  padding: 10px 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  border-bottom: 1px solid rgb(211, 216, 226);
}
.sub-doctor-items {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}


.user-dashboard-sub {

    &__top-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        @media screen and (max-width: 769px) {
            flex-direction: column;
        }
    }
}

.shared {
  margin-right: 16px;
}

@media screen and (max-width: 768px) {

}
</style>

<template>
  <div class="user-dashboard-notification">
    <div class="row" v-for="notification in notifications" :key="notification.id">
      <div class="notification" :class="{'first-not':!notification.read}">
        <div class="date">{{ new Date(notification.created_at).toISOString() }}</div>
        <div class="title">{{ notification.title }}</div>
        <div class="context">{{ notification.description }}</div>
      </div>
    </div>

    <div class="instruction">

      <div class="instruction_text">
        <div class="date">21 декабря 2022 12:05</div>
        <div class="title">Спасибо за регистрацию на нашем сайте!</div>
        <div class="context">
          Мы предлагаем вам посмотреть краткую инструкцию о том, как пользоваться нашим сервисом
        </div>
      </div>

        <div class="video-block" :style="backImage">
            <div class="play">
                <PlayIcon/>
            </div>
            <div class="info">
                <div class="info-title">Инструкция</div>
                <div class="info-text">Как пользоваться сервисом</div>
                <div class="info-time">01:06</div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import config from '@/config/config'
import PlayIcon from "@/components/icons/PalyIcon.vue";
export default {
  name: 'UserDashboardNotification',
    components: {PlayIcon},
  computed: {
    backImage() {
      return `background-image: url(${config.URL}/img/back/video-background.png);`
    },
    notifications() {
      return this.$store.getters.getAllNotifications
    }
  }
}
</script>

<style scoped lang="scss">
.user-dashboard-notification {
    display: flex;
    flex-direction: column;
    row-gap: 70px;
    text-align: left;
}
.notification {
    text-align: left;
    padding: 30px;
}

.instruction {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    text-align: left;

    @media screen and (max-width: 769px) {
        align-items: center;
    }
}

.first-not {
  background: #edf3f1;
  border-radius: 20px;
  padding: 30px;
  height: 160px;
}
.date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #7f818a;
  margin-bottom: 14px;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #373a43;
  margin-bottom: 14px;
}

.context {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #656873;
}

.video-block {
  position: relative;
  border-radius: 9px;
  height: 335px;
  background-repeat: no-repeat;
    width: 100%;
    max-width: 594px;


}
.play {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 43%;
  transform: translate(-50%);
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  background: #ffffff;
}
.info {
  position: absolute;
  left: 30px;
  top: 200px;
  color: #ffffff;
}
.info-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
}
.info-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
}
.info-time {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
</style>

<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3333 1L13.4444 4.11114L10.3333 7.22228" stroke="#02B061" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.66675 4.11133H13.4446" stroke="#02B061" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.11114 8.77734L1 11.8885L4.11114 14.9996" stroke="#02B061" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 11.8887H8.00007" stroke="#02B061" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "CreateSubIconDoc"
}
</script>

<style scoped>

</style>

<template>
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18.5991" cy="18" r="18" fill="#0078FF" />
    <path
      d="M19.1424 23.4C13.7216 23.4 10.6298 19.6838 10.501 13.5H13.2163C13.3055 18.0387 15.3072 19.9613 16.8928 20.3577V13.5H19.4497V17.4144C21.0154 17.2459 22.6603 15.4622 23.2152 13.5H25.772C25.3459 15.918 23.5621 17.7018 22.2937 18.4351C23.5621 19.0297 25.5938 20.5856 26.3667 23.4H23.5522C22.9477 21.5171 21.4416 20.0604 19.4497 19.8622V23.4H19.1424Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'VkCircleIcon'
}
</script>

<style scoped></style>

<template>
  <div class="doctor-settings">
      <div class="img-parent">
        <div class="img-div">
          <img alt="img" :src="img" class="img" :class="{'no-img': doctor.user_detales.image === 'no_doctor_photo.svg' && !url}" v-if="img || img !== ''"/>
          <photo-icon class="photo-icon" v-else/>
        </div>
        <div class="upload">
          <label style="cursor: pointer" for="file" class="upload">Сменить фото</label>
          <input type="file" @change="changePhoto" id="file" hidden="" />
        </div>
      </div>

      <doctor-dashboard-settings/>
  </div>
</template>

<script>
import config from '@/config/config'
import DoctorDashboardSettings from '@/components/doctor/DoctorDashboardSettings.vue'
import PhotoIcon from "@/components/icons/PhotoIcon.vue";

export default {
  name: 'DoctorSettings',
  components: {DoctorDashboardSettings, PhotoIcon},
  data() {
    return {
      url: false
    }
  },
  computed: {
    doctor() {
      return this.$store.getters.getDoctorDetalies
    },
    user() {
      return this.$store.getters.getUser
    },
    mobile() {
      return this.$store.getters.getMobile
    },
    img() {
      if (this.url) {
        return this.url
      } else {
          if (this.doctor?.user_detales){
              return this.doctor?.user_detales?.image
                  ? `${config.URL}/img/doctors/${this.doctor.user_detales.image}`
                  : ''
          }
          return '';
      }
    }
  },
  methods: {
    async changePhoto(e) {
      this.url = URL.createObjectURL(e.target.files[0])
      let formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('doc_id', this.user.id)

        this.$store.dispatch('changePhoto',formData)
    }
  }
}
</script>

<style scoped>

.doctor-settings {
    display: flex;
    gap: 115px;
}

@media screen and (max-width: 1460px) {
    .doctor-settings {
        flex-direction: column;
    }
}

@media screen and (max-width: 969px) {
    .doctor-settings {
        align-items: center;
        justify-content: center;
    }
}

.img-parent {
  width: 150px;
  height: 150px;
}

.img-div {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #e9ebf0;
}

.photo-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.no-img {
    width: 16px !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.upload {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  text-decoration-line: underline;
  color: #656873;
  text-align: center;
}

@media screen and (max-width: 769px){
  .img-parent {
      margin-right: 0;
      padding: 0;
  }
  .upload {
      margin-top: 5px;
  }
}
</style>

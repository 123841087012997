<template>
  <user-layout>
    <div class="header-collection">
      <div class="header-collection__img"><img src="/img/image_3.png" alt="" /></div>
      <div class="header-collection__title">
        <span>
          Коллекция
          {{
            userCollections.user_detales?.first_name
              ? userCollections.full_name
              : userCollections.email
          }}</span
        >
      </div>
    </div>
    <div class="inner-collection">
      <div class="inner-collection__filter">
        <FilterPersonalCabinet
          :sort-value="this.sortSpecia"
          :show-sort="this.showSortSpecia"
          :sort-by="'Специальность'"
          :drop-down-list="this.specialities"
          @changeShowSort="this.showSortSpecia = $event"
          @changeSortValue="sortingShowSpecia($event)"
        />
      </div>
      <doctors-reg-list-layout
        style="margin-top: 0"
        title="Он рекомендует"
        :doctors="doctors"
        :screen-type="screenType"
        :list-type="false"
        @changeScreenType="screenType = $event"
      />
    </div>

    <div class="img-content" :style="imgBack">
      <div class="text-cont">
        <div class="text-cont-title">{{ doctorInviteContent.banner?.title }}</div>
        <div class="text-cont-text">
          {{ doctorInviteContent.banner?.annotation }}
        </div>
        <div class="text-cont-button">
          <button-helper
            color="#02B061"
            class="m-h-40"
            style="width: 149px; height: 46px"
            background="rgb(255 255 255 0)"
            @click="openLogin"
          >
            {{ doctorInviteContent.banner?.btn }}
          </button-helper>
        </div>
      </div>

      <img class="img-cont-img" :src="imgPhone" />
    </div>
  </user-layout>
</template>

<script>
import UserLayout from '@/components/layout/UserLayout.vue'
import DoctorsRegListLayout from '@/components/doctor/DoctorRegListLayout.vue'
import FilterPersonalCabinet from '@/components/layout/FilterPersonalCabinet.vue'
import buttonHelper from '@/components/helppers/DefaultButton.vue'
import config from '@/config/config'

export default {
  name: 'UserCollections',
  components: { UserLayout, DoctorsRegListLayout, FilterPersonalCabinet, buttonHelper },
  data() {
    return {
      sortSpecia: null,
      showSortSpecia: false,
      showSortCity: false,
      specialities: []
    }
  },
  computed: {
    userCollections() {
      return this.$store.getters.getUserCollections
    },
    doctors() {
      let doctors = this.userCollections.favorites?.map((items) => items.doctor)

      if (this.sortSpecia && this.sortSpecia !== null) {
        doctors = doctors.filter((doctor) => {
          return doctor.specialities.some((specie) => specie.specie_name === this.sortSpecia)
        })
      }
      return doctors
    },
    imgPhone() {
      return `${config.URL}/img/back/phone.png`
    },
    imgBack() {
      return `background-image: url(${config.URL}/img/back/back.png);`
    },
    doctorInviteContent() {
      return this.$store.getters.getDoctorInviteContent
    }
  },
  methods: {
    sortingShowSpecia($event) {
      this.sortSpecia = $event
    }
  },
  async mounted() {
    await this.$store.dispatch('getUserCollections', this.$route.params.id)
    await this.$store.dispatch('getOnlySpecialities')
    await this.$store.dispatch('getDoctorInviteContent')
    this.specialities = this.$store.getters.getOnlySpecialities
  }
}
</script>

<style scoped lang="scss">
.img-content {
  margin-top: 99px;
  width: 100%;
  background-position: center;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
}

.text-cont {
  margin-top: 86px;
  margin-left: 83px;

  @media screen and (max-width: 1200px) {
    margin-top: 50px;
    margin-left: 50px;
  }

  @media screen and (max-width: 769px) {
    margin: 0;
    padding: 35px 20px 0 20px;
  }
}

.text-cont-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  color: #373a43;
}

.text-cont-text {
  margin-top: 14px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #373a43;
}

.text-cont-button {
  margin-top: 22px;
}

.img-cont-img {
  margin-bottom: -10px;
  margin-right: 250px;
  height: 100%;
  background-position-x: center;
  position: relative;
  background-repeat: no-repeat;

  @media screen and (max-width: 1200px) {
    margin-right: 150px;
  }

  @media screen and (max-width: 969px) {
    margin-right: 50px;
  }

  @media screen and (max-width: 769px) {
    margin: 0 auto -10px auto;
  }

  @media screen and (max-width: 360px) {
    width: 260px;
  }
}
.inner-collection {
  &__filter {
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
    
    @media screen and (max-width: 768.98px) {
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 50px;
    }
  }
}

.header-collection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__img {
    border-radius: 50%;
    border: 1px solid #d3d8e2;
    img {
      border-radius: 50%;
      padding: 10px;
      width: 180px;
      height: 180px;
      vertical-align: top;
    }
  }
  &__title {
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 35px;
      color: #373a43;
    }
  }
}
</style>

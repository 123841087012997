export default {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async registerUser(_,params) {
            return await this.$axios.post('/api/register', params)
        },
        async registerDoctor(_,params) {
            return await this.$axios.post('/api/registerDoctor', params)
        }
    }
}

<template>
    <user-layout>

        <top-change-content @save-content="saveDoctorDashboardContent" type="dashboard"/>

        <admin-preview-top-dashboard-route/>

        <div class="dashboard-page-container">

            <div v-if="!mobile" class="dashboard-left-menu desktop-only">
                <div v-for="item in menu" :key="item.id">
                    <div
                        class="dashboard-left-menu__item"
                    >
                        <component :is="item.icon"/>
                        <span class="title">{{ item.title }}</span>
                    </div>
                </div>
            </div>

            <div class="dashboard-page-content">
                <div class="doctor-dashboard-content">
                    <admin-preview-doctor-subscribes
                        v-if="pageId == 1"
                        type="1"
                        :page="true"
                    />
                    <doctor-notification v-if="pageId == 4"/>
                    <admin-preview-doctor-dertalis v-if="pageId == 5"/>
                    <doctor-settings v-if="pageId == 6"/>
                </div>

                <div class="info" v-if="pageId == 1">
                    <div class="info-text">
                        <div class="title-info">
                            {{doctorDashboardContent.banner_recommend?.title}}
                        </div>
                        <div class="text-info">
                            {{doctorDashboardContent.banner_recommend?.annotation}}
                        </div>
                    </div>
                    <div class="info-btn">
                        <button-helper color="#24AD6F" background="#EDF3F1">
                            {{doctorDashboardContent.banner_recommend?.btn}}
                        </button-helper>
                    </div>
                </div>
            </div>
        </div>

    </user-layout>
</template>

<script>

import UserLayout from "@/components/layout/UserLayout.vue";
import UserDashboard from "@/components/user/UserDashboard.vue";
import DoctorDashboard from "@/components/doctor/DoctorDashboard.vue";
import TopDashboardRoute from "@/components/layout/TopDashboardRoute.vue";
import TopChangeContent from "@/components/layout/TopChangeContent.vue";
import config from "@/config/config";
import AdminPreviewTopDashboardRoute from "@/components/admin/AdminPreviewTopDashboardRoute.vue";
import DoctorNotification from "@/components/doctor/DoctorNotification.vue";
import DoctorDertalis from "@/components/doctor/DoctorDertalis.vue";
import DoctorSubscribes from "@/components/doctor/DoctorSubscribes.vue";
import DoctorSettings from "@/components/doctor/DoctorSettings.vue";
import ButtonHelper from "@/components/helppers/DefaultButton.vue";
import AdminPreviewDoctorSubscribes from "@/components/admin/AdminPreviewDoctorSubscribes.vue";
import AdminPreviewDoctorDertalis from "@/components/admin/AdminPreviewDoctorDertalis.vue";

export default {
    name: 'AdminDoctorDashboardPreview',

    components: {
        AdminPreviewDoctorDertalis,
        AdminPreviewDoctorSubscribes,
        ButtonHelper, DoctorSettings, DoctorSubscribes, DoctorDertalis, DoctorNotification,
        AdminPreviewTopDashboardRoute,
        TopChangeContent,
        TopDashboardRoute,
        DoctorDashboard,
        UserDashboard,
        UserLayout,
    },

    data() {
        return {
            showChangeContent: false
        }
    },

    computed: {
        pageId() {
            return this.$route.params.id
        },
        doctorDashboardContent() {
            return this.$store.getters.getDoctorDashboardContent
        },
        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        },
        menu() {
            return config.DOCTOR_MANU_ITEM
        },

    },

    methods: {
        async saveDoctorDashboardContent() {
            await this.$store.dispatch('updateDoctorDashboardContent', this.doctorDashboardContent)
        },
    },

}
</script>

<style scoped lang="scss">

.dashboard-page-container {
    display: flex;
    column-gap: 25px;
    width: 100%;
}

.dashboard-page-menu {
    flex-basis: calc(13%);
    max-width: calc(13%);
}

.dashboard-page-content {
    flex-basis: calc(87% - 20px);
    max-width: calc(87% - 20px);
}

@media screen and (max-width: 1450px) {
    .dashboard-page-menu {
        flex-basis: calc(18%);
        max-width: calc(18%);
    }

    .dashboard-page-content {
        flex-basis: calc(82% - 20px);
        max-width: calc(82% - 20px);
    }
}

@media screen and (max-width: 969px) {
    .dashboard-page-menu {
        flex-basis: calc(22%);
        max-width: calc(22%);
    }

    .dashboard-page-content {
        flex-basis: calc(78% - 20px);
        max-width: calc(78% - 20px);
    }
}

@media screen and (max-width: 769px) {
    .dashboard-page-content {
        flex-basis: calc(100%);
        max-width: calc(100%);
    }
}

.dashboard-left-menu {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    min-width: 180px;

    &__item {
        display: flex;
        column-gap: 10px;
        flex-wrap: nowrap;
        white-space: nowrap;
    }
}

.info {
    margin-top: 100px;
    background: #edf3f1;
    border-radius: 20px;
    padding: 45px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.info-text {

}

.title-info {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #373a43;
}

.text-info {
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #7f818a !important;
}

@media screen and (max-width: 769px) {
    .container {
        text-align: center;
    }

    .menu-way {

    }

    .info {
        margin-top: 50px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        padding: 35px 25px;
    }
}

.title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #373a43;
    cursor: pointer;
}

.rotate-icon {
    transition: .2s;
    transform: rotateX(-180deg);
}

</style>

<template>
    <div class="input-layout__container">
        <div class="input-layout"
             :class="{
                'input-layout__focus': focus,
                'input-layout__complete': complete && !error,
                'input-layout__error': error,
             }"
             @focusin="focus = true" @focusout="focus = false"
        >

            <div class="input-layout__left">

                <!--PasswordInput-->
                <input :placeholder="placeholder" :type="passType"
                       class="input-layout__field"
                       :value="inputValue"
                       @change="changeInputValue" style="">
            </div>

            <div class="input-layout__icons">
                <eye-icon v-if="!showPass" @click="showPass = true" class="input-layout__check-icon"/>
                <open-eye-icon v-else @click="showPass = false" class="input-layout__check-icon"/>
            </div>

        </div>

        <div class="input-layout__password" v-if="showDifficult && inputValue.length > 0">
            <div class="input-layout__password-progress">
                <div v-if="passwordDifficult === 1" class="input-layout__password-progress-red"/>
                <div v-else-if="passwordDifficult === 2" class="input-layout__password-progress-yellow"/>
                <div v-else-if="passwordDifficult === 3" class="input-layout__password-progress-green"/>
            </div>
            <div class="input-layout__password-status">
                {{ passwordStatus }}
            </div>
        </div>
    </div>


</template>

<script>

import OpenEyeIcon from "@/components/icons/OpenEyeIcon.vue";
import EyeIcon from "@/components/icons/EyeIcon.vue";
export default {
    name: 'InputLayoutPassword',
    components: {EyeIcon, OpenEyeIcon},

    emits: ['update:inputValue'],

    props: {
        inputValue: {
            required: true,
            default: ''
        },
        showDifficult: {
            required: false,
            default: false
        },
        placeholder: {
            required: true,
            type: String,
            default: '',
        },
        complete: {
            required: false,
        },
        error: {
            required: false,
        }
    },

    data() {
        return {
            focus: false,
            showPass: false,
        }
    },

    computed: {
        passType() {
            return this.showPass ? 'text' : 'password'
        },
        passwordDifficult() {
            if (this.inputValue.length < 7) {
                return 1
            } else if (this.inputValue.length < 9) {
                return 2
            } else {
                return 3
            }
        },
        passwordStatus() {
            switch (this.passwordDifficult) {
                case 1:
                    return 'Слабый пароль'
                case 2:
                    return 'Средний пароль'
                default:
                    return 'Хороший пароль'
            }
        }
    },

    methods: {
        changeInputValue(event) {
            this.$emit('update:inputValue', event.target.value)
        },
    },

}
</script>

<style scoped lang="scss">

.input-layout {
    background: #EDEFF3;
    border-radius: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #9195a4;
    padding: 0 15px 0 18px;
    border: 1px solid #EDEFF3;
    cursor: text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    column-gap: 5px;
    width: 100%;
    height: 46px;

    &__container {
        display: flex;
        flex-direction: column;
        row-gap: 17px;
        width: 100%;
    }

    &__password {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    &__password-progress {
        background: #DBE0EB;
        border-radius: 50px;
        height: 3px;
        width: 100%;

        &-red {
            height: 3px;
            border-radius: 50px;
            background: #F93232;
            width: 33%;
        }

        &-yellow {
            height: 3px;
            border-radius: 50px;
            background: #FF912B;
            width: 66%;
        }

        &-green {
            height: 3px;
            border-radius: 50px;
            background: #02B061;
            width: 100%;
        }
    }

    &__password-status {
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #373A43;
    }

    &__icons {
        cursor: pointer;
    }

    &__field {
        outline: none;
        border: none;
        background: transparent !important;
        padding: 0;
        color: #373A43;
        width: 100%;
        height: 46px;

        &:focus {
            outline: none;
            border: none;
        }
    }

    &:hover {
        border: 1px solid #DBE0EB;
    }

    &::placeholder {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #A4ACBD;
    }

    &__focus {
        border: 1px solid #02B061 !important;
    }

    &__complete {
        border: 1px solid #EAF2EF;
        background: #EAF2EF;
    }

    &__error {
        border: 1px solid #F93232 !important;
        background: #F9EDED;
    }

    &__left {
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
    }

    &__check-icon {

    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

</style>

<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66632 2.55544C5.66632 2.14291 5.8302 1.74728 6.1219 1.45558C6.4136 1.16388 6.80923 1 7.22176 1C7.63429 1 8.02992 1.16388 8.32162 1.45558C8.61332 1.74728 8.7772 2.14291 8.7772 2.55544C9.67034 2.97776 10.4317 3.63517 10.9797 4.4572C11.5278 5.27923 11.8418 6.23489 11.8881 7.22176V9.55492C11.9466 10.0384 12.1178 10.5014 12.388 10.9067C12.6582 11.3119 13.0197 11.6481 13.4435 11.8881H1C1.42381 11.6481 1.78535 11.3119 2.05551 10.9067C2.32567 10.5014 2.49691 10.0384 2.55544 9.55492V7.22176C2.60176 6.23489 2.91576 5.27923 3.46378 4.4572C4.0118 3.63517 4.77318 2.97776 5.66632 2.55544" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.89062 11.8896V12.6674C4.89062 13.2862 5.13644 13.8796 5.57399 14.3172C6.01154 14.7547 6.60499 15.0005 7.22378 15.0005C7.84258 15.0005 8.43602 14.7547 8.87358 14.3172C9.31113 13.8796 9.55694 13.2862 9.55694 12.6674V11.8896" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMobileBell"
}
</script>

<style scoped>

</style>

<template>
    <user-layout>
        <top-category-route :specialities="specialities"/>

        <div class="category-page-container">

            <category-left-menu class="category-page-menu" v-if="!mobile" :specialities="specialities"/>

            <unregistred-user-content class="category-page-content"
                                      v-if="!login || (user && (user.role_id === 1 || user.role_id === 2))"/>
            <registred-user-content v-else/>

        </div>

        <div class="category-page-bottom" v-if="!login">

            <div class="category-page-bottom__explanation desktop-only">


                <div class="req">Поиск специалиста по рекомендациям</div>


                <div class="sale">
                    На нашем портале вы можете выбрать врача из лучших клиник Москвы и получить скидку до 20%
                    на приём.
                </div>


                <div class="category-page-bottom__items">

                    <div class="category-page-bottom__item">
                        <info-blok-helper icon="comment-dots">
                            Специалисты с рекомендациями <br/>
                            и отзывами
                        </info-blok-helper>
                    </div>

                    <div class="category-page-bottom__item">
                        <info-blok-helper icon="stethoscope">
                            Подробный каталог с детальной <br/>
                            информацией
                        </info-blok-helper>
                    </div>
                    <div class="category-page-bottom__item">
                        <info-blok-helper icon="map-location-dot">
                            Быстрый поиск специалистов <br/>
                            рядом с вами
                        </info-blok-helper>
                    </div>
                    <div class="category-page-bottom__item">
                        <info-blok-helper icon="shield">
                            Врачи c проверенными <br/>
                            отзывами и фото
                        </info-blok-helper>
                    </div>

                </div>


                <div class="category-page-bottom__text">
                    <div class="text-middle">
                        Если Вам нужен хороший врач, но у вас нет времени самостоятельно сравнивать цены и
                        обзванивать клиники, лучшие платные врачи города Москвы всегда к вашим услугам на нашем
                        сервисе.
                    </div>
                    <div class="text-middle">
                        На нашем сайте собраны врачи Москвы с отзывами и полной информацией. Если вы ищете
                        хорошего доктора в Москве, воспользуйтесь нашим списком врачей и фильтром. Вы можете найти
                        доктора по адресу, фамилии врача, <br/>
                        местоположению. Или выбрать нужного специалиста по рейтингу или стажу работы. Выберите
                        необходимого врача и воспользуйтесь кнопкой "Запись на прием". Вы можете отправить форму
                        или записаться на прием к доктору по телефону.
                    </div>
                </div>
            </div>

            <div class="img-content" :style="imgBack">

                <div class="text-cont">
                    <div class="text-cont-title">{{doctorInviteContent.banner?.title}}</div>
                    <div class="text-cont-text">
                        {{doctorInviteContent.banner?.annotation}}
                    </div>
                    <div class="text-cont-button">
                        <button-helper
                            color="#02B061"
                            class="m-h-40"
                            style="width: 149px; height: 46px"
                            background="rgb(255 255 255 0)"
                            @click="openLogin"
                        >
                            {{doctorInviteContent.banner?.btn}}
                        </button-helper>
                    </div>
                </div>


                <img class="img-cont-img" :src="imgPhone"/>

            </div>
        </div>
    </user-layout>
</template>

<script>
import UnregistredUserContent from '@/components/user/UnregistredUserContent.vue'
import RegistredUserContent from '@/components/user/RegistredUserContent.vue'
import UserLayout from "@/components/layout/UserLayout.vue";
import TopCategoryRoute from "@/components/layout/TopCategoryRoute.vue";
import CategoryLeftMenu from "@/components/layout/CategoryLeftMenu.vue";
import buttonHelper from "@/components/helppers/DefaultButton.vue";
import config from "@/config/config";
import InfoBlokHelper from "@/components/helppers/InfoBlokHelper.vue";

export default {

    name: 'UnregistredUser',

    components: {
        InfoBlokHelper,
        buttonHelper,
        CategoryLeftMenu,
        TopCategoryRoute,
        UserLayout,
        UnregistredUserContent,
        RegistredUserContent,
    },

    methods: {
        openLogin() {
            this.$store.dispatch('changeLoginPopup', true)
            this.$store.dispatch('changeRegisterType', 'user')
            this.closePopup()
        },
        async saveDoctorInviteContent() {
            await this.$store.dispatch('updateDoctorInviteContent', this.doctorInviteContent)
        }
    },

    computed: {
        login() {
            return localStorage.userToken ? true : false
        },
        user() {
            return this.$store.getters.getUser
        },
        specialities() {
            return this.$store.getters.getAllSpecialities
        },
        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        },
        imgBack() {
            return `background-image: url(${config.URL}/img/back/back.png);`
        },
        imgPhone() {
            return `${config.URL}/img/back/phone.png`
        },
        doctorInviteContent() {
            return this.$store.getters.getDoctorInviteContent
        },
    },
    async mounted() {
        if (this.login) {
            await this.$store.dispatch('getUserByToken')
        }

        this.$store.dispatch('getDoctorInviteContent')
    },
}
</script>

<style scoped lang="scss">

.category-page-container {
    display: flex;
    column-gap: 25px;
    width: 100%;
}

.category-page-bottom__items {
    margin-top: 40px;
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.category-page-bottom__text {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.category-page-menu {
    flex-basis: calc(10%);
    max-width: calc(10%);
}

.category-page-content {
    flex-basis: calc(90% - 20px);
    max-width: calc(90% - 20px);
}

.category-page-bottom {
    margin-top: 100px;
    row-gap: 50px;
    display: flex;
    flex-direction: column;
}

.req {
    margin-top: 20px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #373a43;
}

.sale {
    margin-top: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #373a43;
}

.text-middle {
    margin-top: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #656873;
}

.img-content {
    width: 100%;
    background-position: center;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    @media screen and (max-width: 769px) {
        flex-direction: column;
    }
}

.img-cont-img {
    margin-bottom: -10px;
    margin-right: 250px;
    height: 100%;
    background-position-x: center;
    position: relative;
    background-repeat: no-repeat;

    @media screen and (max-width: 1200px) {
        margin-right: 150px;
    }

    @media screen and (max-width: 969px) {
        margin-right: 50px;
    }

    @media screen and (max-width: 769px) {
        margin: 0 auto -10px auto;
    }

    @media screen and (max-width: 400px) {
        width: 260px;
    }
}

.text-cont {
    margin-top: 86px;
    margin-left: 83px;

    @media screen and (max-width: 1200px) {
        margin-top: 50px;
        margin-left: 50px;
    }

    @media screen and (max-width: 769px) {
        margin: 0;
        padding: 35px 20px 0 20px;
    }
}

.text-cont-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #373a43;
}

.text-cont-text {
    margin-top: 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #373a43;
}

.text-cont-button {
    margin-top: 22px;
}

@media screen and (max-width: 1350px) {
    .category-page-menu {
        flex-basis: calc(15%);
        max-width: calc(15%);
    }

    .category-page-content {
        flex-basis: calc(85% - 20px);
        max-width: calc(85% - 20px);
    }
}


@media screen and (max-width: 769px) {
    .category-page-content {
        flex-basis: calc(100%);
        max-width: calc(100%);
    }
}

</style>


<template>
    <div class="reg-user">
        <SearchPageTopPanel
                    :sort-value="this.sort"
                    :list-type="this.listType"
                    :switch="this.switch"
                    :show-sort="this.showSort"
                    @changeShowSort="this.showSort = $event"
                    @changeSwitch="this.switch = $event"
                    @changeListType="this.listType = $event"
                    @changeSortValue="sortingShow($event)"
                />
        <div class="doctors-list-content" v-if="doctors.length">

                <doctors-reg-list-layout
                    title="Самые подходящие"
                    :doctors="subscribesDoctores"
                    :screen-type="screenType"
                    :list-type="listType"
                    @changeScreenType="screenType = $event"
                />

                <doctors-reg-list-layout
                    title="Возможно, вас заинтересуют"
                    :doctors="subscribesDoctores"
                    :screen-type="maybeList"
                    :list-type="listType"
                    @changeScreenType="maybeList = $event"
                />

                <doctors-reg-list-layout
                    title="Остальные врачи"
                    :doctors="doctors"
                    :screen-type="allList"
                    :list-type="listType"
                    @changeScreenType="allList = $event"
                />
        </div>
        <div class="invite-offer" v-if="!doctors.length && loginUser?.role_id !== 4">
            <div class="invate" v-if="message == 1">
                <div class="invate-title">
                    Такой специалист не найден. Вы можете отправить ему приглашение в систему
                </div>
                <div class="invate-text">
                    Врач сразу будет отображаться у вас как тот, кому вы доверяете
                </div>
                <div class="invate-button">
                    <button-helper style="background-color: #FFFFFF; border: 1px solid #02B061; color: #02B061"
                                   @click="message = 2">
                        <hello-icon/>
                        Отправить приглашение
                    </button-helper>
                </div>
            </div>

            <div class="invite-form" v-if="message == 2">
                <div class="invite-form-category">
                    <div class="invite-form-title">Контактные данные</div>
                    <div class="invite-form-category-inputs">
                        <div class="invite-form-input-container">
                            <div class="label">
                                <label>Фамилия</label>
                            </div>
                            <input-layout
                                :input-value="invate.last_name"
                                placeholder="Фамилия"
                                :complete="invate.last_name"
                                @update:input-value="invate.last_name = $event"
                            />
                        </div>
                        <div class="invite-form-input-container">
                            <div class="label">
                                <label>Имя</label>
                            </div>
                            <input-layout
                                :input-value="invate.father_name"
                                placeholder="Отчество"
                                :complete="invate.father_name"
                                @update:input-value="invate.father_name = $event"
                            />
                        </div>
                        <div class="invite-form-input-container">
                            <div class="label">
                                <label>Отчество</label>
                            </div>
                            <input-layout
                                :input-value="invate.last_name"
                                placeholder="Фамилия"
                                :complete="invate.last_name"
                                @update:input-value="invate.last_name = $event"
                            />
                        </div>
              <div class="invite-form-input-container">
                  <div class="label">
                      <label for="spec">Специальность</label>
                  </div>
                  <div class="input">
                    <input-layout
                      :input-value="invate.spec"
                      placeholder="Специальность / специальности"
                      :complete="invate.spec"
                      @update:input-value="invate.spec = $event"
                    />
                  </div>
              </div>
              <div class="invite-form-input-container">
                  <div class="label">
                      <label for="phone">Телефон</label>
                  </div>
                  <div class="input">
                    <input-layout-phone
                    :input-value="invate.phone"
                    placeholder="Укажите ваш телефон"
                    :complete="invate.phone"
                    @update:input-value="invate.phone = $event"
                    />
                  </div>
              </div>
                    </div>
                </div>

                <div class="invite-form-category">

                    <!-- <div class="invite-form-title">Социальные сети</div> -->

                    <!-- <div class="invite-form-category-inputs">
                        <div class="invite-form-input-container">
                            <div class="label">
                                <label>Whatsapp</label>
                            </div>
                            <input-layout-phone
                                icon-name="whatsapp"
                                :input-value="invate.social"
                                placeholder="Whatsapp"
                                :complete="invate.social"
                                @update:input-value="invite.social = $event"
                            />
                        </div>
                        <div class="invite-form-input-container">
                            <div class="label">
                                <label>Telegram</label>
                            </div>

                            <input-layout
                                icon-name="telegram"
                                :input-value="invate.telegram"
                                placeholder="Telegram"
                                :complete="invate.telegram"
                                @update:input-value="invite.telegram = $event"
                            />

                        </div>
                        <div class="invite-form-input-container">
                            <div class="label">
                                <label>Instagram</label>
                            </div>
                            <input-layout
                                icon-name="instagram"
                                :input-value="invate.instagram"
                                placeholder="Instagram"
                                :complete="invate.instagram"
                                @update:input-value="invite.instagram = $event"
                            />
                        </div>
                    </div> -->

<!--                    <div class="invite-form-category-inputs">-->
<!--                        <div class="invite-form-input-container">-->
<!--                            <div class="label">-->
<!--                                <label for="whatsapp">{{ invate.social }}</label>-->
<!--                            </div>-->
<!--                            <div class="input">-->
<!--                                <select-->
<!--                                    class="invite-input"-->
<!--                                    v-model="invate.social"-->
<!--                                    type="text"-->
<!--                                    id="whatsapp"-->
<!--                                    placeholder="Whatsapp"-->
<!--                                >-->
<!--                                    <option value="Whatsapp" selected="selected">Whatsapp</option>-->
<!--                                    <option value="Telegram">Telegram</option>-->
<!--                                </select>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>


                <button-helper class="invite-btn" @click="invateDoctor" color="#ffffff" background="#02B061">
                    Отправить
                </button-helper>

            </div>

            <div class="invite-end" v-if="message == 3">
                <div class="invite-end-top">
                    <circle-check-icon/>
                    <div class="invite-end-title">Вы отправили приглашение</div>
                </div>
                <div class="invite-end-items">
                    <div v-if="invate.name" class="invite-end-item">
                        {{ invate.name }} <br/>
                        <span class="invite-spec">{{ invate.spec }}</span>
                    </div>
                    <div v-if="invate.phone" class="invite-end-item">
                        {{ invate.phone }} <br/>
                        <span class="invite-spec">Номер телефона</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="doctors-list-no-items" v-if="!doctors.length && loginUser.role_id === 4">Такой специалист не найден.</div>
    </div>
</template>

<script>
import buttonHelper from '@/components/helppers/DefaultButton.vue'
import config from '@/config/config'
import sortingShow from '@/mixins/sortingShow'
import HelloIcon from "@/components/icons/HelloIcon.vue";
import CircleCheckIcon from "@/components/icons/CircleCheckIcon.vue";
import DoctorsRegListLayout from "@/components/doctor/DoctorRegListLayout.vue";
import SearchPageTopPanel from "@/components/layout/SearchPageTopPanel.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import InputLayoutPhone from "@/components/layout/inputs/InputLayoutPhone.vue";

export default {
    name: 'RegistredUserContent',
    components: {
        InputLayoutPhone,
        SearchPageTopPanel,
        DoctorsRegListLayout,
        CircleCheckIcon,
        HelloIcon,
        buttonHelper,
        InputLayout
    },
    data() {
        return {
            specList: false,
            listType: false,
            screenType: false,
            maybeList: false,
            allList: false,
            message: 1,
            switch: false,
            showSort: false,
            invate: {
                name: '',
                spec: '',
                social: 'Whatsapp'
            },
            sort: 'По популярности',
        }
    },
    computed: {
        subscribesDoctores() {
            if (this.$store.getters.getdocSubscribes.length > 0) {
                return this.$store.getters.getdocSubscribes
            } else {
                return this.doctors
            }
        },
        specialities() {
            return this.$store.getters.getAllSpecialities
        },
        spacListName() {
            return this.specList ? 'Скрыть' : 'Развернуть все'
        },
        imgBack() {
            return `background-image: url(${config.URL}/img/back/back.png);`
        },
        imgPhone() {
            return `background-image: url(${config.URL}/img/back/phone.png);`
        },
        colorLarge() {
            return this.listType ? '#DBE0EB' : '#02B061'
        },
        colorList() {
            return this.listType ? '#02B061' : '#DBE0EB'
        },
        loginUser() {
            return this.$store.getters.getUser
        },
        doctors(){
            let doctors = this.$store.getters.getAllDoctores;
            if(this.switch === true){
                doctors = doctors.filter(doctor => {
                    return doctor.specialities.some(specie => specie.specie_name === "Детский врач")
                });
                doctors = this.filterDoctors(doctors);
            }else{
                doctors = this.filterDoctors(doctors);
            }

            return doctors
        }
    },
    watch:{
        $route() {
            this.switch === false;
        }
    },
    mixins: [sortingShow],
    methods: {
        filterDoctors(doctors){
            if(this.sort == 'По дате регистрации'){
                doctors = doctors.sort((a, b) => a.id - b.id);
            }
            if(this.sort == 'По количеству рекомендаций'){
                doctors = doctors.sort((a, b) => b.my_recommendations.length - a.my_recommendations.length);
                console.log(doctors);
            }
            if(this.sort == 'По популярности'){
                doctors = doctors.sort((a, b) => b.me_favorites_count - a.me_favorites_count);
            }
            return doctors
        },
        invateDoctor() {
            let message = `${this.invate.last_name} ${this.invate.first_name} ${this.invate.father_name}, \n${this.loginUser.full_name} отметил вас, как доверенного ${this.invate.spec} в Системе Связи врачей. \nУзнать больше о системе и пройти регистрацию вы можете по ссылке. \nhttps://doctorsconnection.nomadicdemo.com/`;
            let phone = this.invate.phone
            phone = phone.replace(/[\s()-]/g, '');

            if (this.invate.social === 'Whatsapp') {
                window.open(`https://wa.me/${phone}?text=${encodeURIComponent(message)}`)
            } else {
                window.open(`https://t.me/share/url?url=${encodeURIComponent(message)}`)
            }
            this.message = 3
        },
        async newUsers(id) {
            await this.$store.dispatch('getAllDoctors', {page: 1, spec: id})
            await this.$store.dispatch('getPageCount', {spec_id: id})
            this.$router.push(`category?spec=${id}`)
        }
    },
    async mounted() {
        await this.$store.dispatch('getUserByToken')
        await this.$store.dispatch('getAllDoctors', {
            page: 1,
            spec: this.$route.query.spec,
            name: this.$route.query.name
        })
        let ids = this.loginUser.recommendations.map((item) => item.id)
        await this.$store.dispatch('getDoctorsSubscribe', {page: ids})
        await this.$store.dispatch('getPageCount', {
            spec_id: this.$route.query.spec,
            name: this.$route.query.name
        })
    }
}
</script>

<style scoped lang="scss">
.doctors-list-no-items {
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    color: #373a43;
}
.reg-user {
    width: 100%;
}

.name-title {
    margin-bottom: 5px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #373a43;
}

.spec-list-content {
    max-width: 184px;
}

.name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
    color: #373a43;
    margin-top: 27px;
}

.name-footer {
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #656873;
    margin-top: 33px;
}

.doctors-list-content-sort {
    position: relative
}

.form-switch {
    padding: 0 !important;
}

.form-check-label {
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #656873;
}

#flexSwitchCheckDefault {
    width: 48px;
    height: 26px;
}

.search-div {
    display: flex;
    cursor: pointer;
    padding: 14px 16px;
    flex-wrap: nowrap;
    border: 1px solid #dbe0eb;
    border-radius: 50px;
    width: fit-content;
}

.search-input {
    white-space: nowrap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #373a43;
}

.arrow-down {
    margin-top: 10px;
    margin-left: 20px;
    float: right;
    border: 5px solid rgba(164, 172, 189, 0.01);
    border-top-color: #a4acbd;
    width: 0;
}

.sort {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #7f818a;
}

.sort-popup {
    z-index: 99999;
    position: absolute;
    width: 235px;
    background: #ffffff;
    border: 1px solid #dbe0eb;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.3);
    border-radius: 10px;
    top: 60px;
}

.sort-popup-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 40px;
    color: #373a43;
    cursor: pointer;
    text-align: left;
    padding-left: 20px;
}

.sort-popup-item:hover {
    background: #edeff3;
    border-radius: 5px;
}

.invate-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #373a43;
    margin-bottom: 10px;
}

.invate-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 20px;
    color: #656873;
}

.invate-button {

}

.invate {
    text-align: center;
}

.invite-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 100px;
}

.invite-form-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 40px;
}

.invite-form-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #373a43;
}

.invite-form-category-inputs {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 80px;
    row-gap: 50px;

    @media screen and (max-width: 1200px) {
        grid-template-columns: auto auto;
        gap: 50px;
    }

    @media screen and (max-width: 769px) {
        grid-template-columns: auto;
        align-items: center;
        justify-content: normal !important;
    }
}

.invite-form-input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    row-gap: 10px;
    width: 100%;
}

.invite-btn {
    width: 130px;
    height: 50px;
}

.label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #373a43;
}

.invite-input {
    width: 100%;
    padding: 10px;
    height: 40px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #7f818a;
    background: #edeff3;
    border-radius: 10px;
}

.invite-end-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #373a43;
}

.invite-end-items {
    display: flex;
    column-gap: 100px;
    flex-wrap: nowrap;
}

.invite-end-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #373a43;
}

.invite-end {
    margin-top: 30px;
    background: #edf3f1;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.invite-end-top {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: nowrap;
}

.invite-spec {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #7f818a;
}

@media screen and (max-width: 1080px) {

    .invite-form-category-inputs {
        flex-wrap: wrap;
    }

}

@media screen and (max-width: 769px) {

    .invate-text {
        font-size: 13px;
    }

    .invate-title {
        font-size: 16px;
    }


    .invite-form-category-inputs {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .invite-form-input-container {
        max-width: 100%;
    }

    .invite-btn {
        margin: 0 auto;
    }

    .invite-form {
        row-gap: 60px;
    }

    .invite-end {
        align-items: center;
        padding: 30px 20px;
    }

    .invite-end-items {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }

    .invite-end-title {
        white-space: nowrap;
        font-size: 15px;
    }
}
</style>

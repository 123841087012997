<template>
  <svg width="18" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99895 7.12316V14.1211C4.99895 14.353 4.90679 14.5755 4.74274 14.7396C4.5787 14.9036 4.3562 14.9958 4.12421 14.9958H2.37474C2.14274 14.9958 1.92025 14.9036 1.7562 14.7396C1.59216 14.5755 1.5 14.353 1.5 14.1211V7.99789C1.5 7.7659 1.59216 7.54341 1.7562 7.37936C1.92025 7.21532 2.14274 7.12316 2.37474 7.12316H4.99895ZM4.99895 7.12316C5.92693 7.12316 6.8169 6.75452 7.47308 6.09834C8.12926 5.44216 8.49789 4.55219 8.49789 3.62421V2.74947C8.49789 2.28548 8.68221 1.8405 9.0103 1.51241C9.33839 1.18432 9.78338 1 10.2474 1C10.7114 1 11.1563 1.18432 11.4844 1.51241C11.8125 1.8405 11.9968 2.28548 11.9968 2.74947V7.12316H14.6211C15.085 7.12316 15.53 7.30748 15.8581 7.63557C16.1862 7.96366 16.3705 8.40864 16.3705 8.87263L15.4958 13.2463C15.37 13.7829 15.1313 14.2437 14.8158 14.5593C14.5003 14.8748 14.1249 15.028 13.7463 14.9958H7.62316C6.92717 14.9958 6.25969 14.7193 5.76756 14.2272C5.27543 13.735 4.99895 13.0676 4.99895 12.3716"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ThambUpDocIcon',

  props: {
    color: {
      type: String,
      required: false,
      default: '#02B061'
    }
  }
}
</script>

<style scoped></style>

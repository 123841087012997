<template>
    <user-layout v-if="doctor.user_detales">

        <admin-details-top :type="type" @updateUser="updateUser" @changeType="type = value" v-if="user && (user.role_id == 1 || user.role_id == 2)"/>

        <div class="buttons-content mobile-only"
             v-if="doctor.user_detales.blocked == 1 && mobile && user && (user.role_id == 1 || user.role_id == 2)">

            <button-helper style="width: 100%;max-width:420px;height: 40px;position: relative;" color="#FFFFFF"
                           background="#02B061"
                           @click="this.$store.dispatch('changeCreateSub', this.doctor);"
            >

                <create-sub-icon class="button-icon"/>
                Образовать связь
            </button-helper>
            <button-helper style="width: 100%;max-width:420px;height: 40px;position: relative;" color="#FFFFFF"
                           background="#02B061">
                <admin-doctor-mobile-thumb class="button-icon"/>
                Рекомендовать врача
            </button-helper>
        </div>

        <div class="block-content" v-if="doctor.user_detales.blocked == 1 && (user.role_id == 1 || user.role_id == 2)">
            <div class="block-content-icon">
                <admin-block-icon-mobile/>
            </div>
            <div class="block-content-text">Вы заблокировали данную анкету</div>
            <div class="block-content-button">
                <button-helper color="#F93232" background='#FBEFEF'
                               @click="this.$store.dispatch('changeBlockUser', {
              doctor:this.doctor,
              text:'Вы хотите восстановить данную анкету?',
              buttonText:'Восстановить',
              block:false

            });">Отменить блокировку</button-helper>
            </div>
        </div>

        <div class="doctor-details-content" v-if="type == 1">

            <div v-if="doctor.user_detales.blocked == 1" class="block-content-close-all"/>

            <div class="doctor-details-top">
                <div class="doctor-details-top-left">
                    <div class="doctor-details-top-img">
                        <img :src="image" class="user-image"
                             :class="{'no-img': doctor?.user_detales?.image === 'no_doctor_photo.svg'}"
                             v-if="doctor.user_detales"  alt="фото профиля"/>
                        <div class="heart">
                            <heart-icon/>
                        </div>
                    </div>
                    <div class="doctor-details-top-content" v-if="doctor.user_detales">

                        <div class="doctor-details-top-page">
                            <router-link
                                style="text-decoration: none; color: #9195a4"
                                :to="{ name: 'home'}"
                            >
                            Главная
                            </router-link>
                            <ellipse-icon style="margin: 0 10px"/>
                            <router-link
                                style="text-decoration: none; color: #9195a4"
                                :to="{ name: 'category-page', query: {spec: this.$route.query.spec}}"
                            >
                            {{specName}}
                            </router-link>
                            <ellipse-icon style="margin: 0 10px"/>
                            {{ doctor.full_name }}
                        </div>
                        <div class="doctor-details-top-name">
                            {{ doctor.full_name }}
                        </div>
                        <div class="doctor-details-top-spec">
                            {{ doctor.user_detales.description }}
                        </div>

                        <div class="doctor-details-top-buttons">
                            <button-helper color="#24AD6F" background="#ffffff" v-if="doctor.user_detales.verified == 1" >
                                <shield-doctor-page-icon></shield-doctor-page-icon>
                                Документы проверены
                            </button-helper>
                            <div class="doctor-details-top-shared">
                                <a v-if="doctor.user_detales.whatsapp" :href="`https://wa.me/${doctor.user_detales.whatsapp}`" target="_blank" class="circle green">
                                    <whatsapp-doctor-page-icon/>
                                </a>
                                <a v-if="doctor.user_detales.telegram" :href="`https://t.me/${doctor.user_detales.telegram}`" target="_blank" class="circle">
                                    <telegram-doctor-page-icon/>
                                </a>
                                <a v-if="doctor.user_detales.instagram" :href="`https://www.instagram.com/${doctor.user_detales.instagram}`" target="_blank" class="circle">
                                    <instagram-doctor-page-icon/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="doctor-details-top-right" v-if="loginUser && user.role_id == 4 || user.role_id == 3">
                    <div v-if="loginUser && user.role_id == 4">
                        <button-helper
                        class="subscribe-button"
                        v-if="isFavorite"
                        color="#ffffff" background="#02B061"
                        >
                        <heart-icon color="#ffffff"/>
                            Врач добавлен в избранное
                        </button-helper>
                        <button-helper
                        class="subscribe-button"
                        v-else
                        @click="addFavorite"
                        color="#24AD6F" background="#ffffff"
                        >
                        <heart-icon color="#24AD6F"/>
                            Добавить врача в избранное
                        </button-helper>
                    </div>

                    <button-helper
                        class="subscribe-button"
                        v-if="loginUser && user.role_id == 3"
                        @click="subscribe"
                        color="#24AD6F"
                        background="#ffffff"
                    >
                        <thamb-up-doc-icon/>
                        Рекомендовать врача
                    </button-helper>
                    <button-helper
                        class="subscribe-button"
                        v-if="loginUser && user.role_id == 3"
                        color="#656873"
                        background="#ffffff"
                        @click="unSubscribe"
                    >
                        <thamb-down-doc-icon/>
                        Не рекомендовать врача
                    </button-helper>
                </div>
            </div>

            <div class="buttons-content mobile-only"
                 v-if="mobile && doctor.user_detales.blocked != 1 && user && (user.role_id == 1 || user.role_id == 2)">

                <button-helper style="width: 100%;max-width:420px;height: 40px;position: relative;" color="#FFFFFF"
                               background="#02B061"
                               @click="this.$store.dispatch('changeCreateSub', this.doctor);"
                >

                    <create-sub-icon class="button-icon"/>
                    Образовать связь
                </button-helper>
                <button-helper style="width: 100%;max-width:420px;height: 40px;position: relative;" color="#FFFFFF"
                               background="#02B061">
                    <admin-doctor-mobile-thumb class="button-icon"/>
                    Рекомендовать врача
                </button-helper>
            </div>

            <div class="massage-box" v-if="!loginUser">
                <div class="text-node">
                    <circle-info-doc-icon style="min-width: 18px; min-height: 18px"/>
                    Зарегистрируйтесь, чтобы посмотреть всю информацию о враче
                </div>
                <button-helper
                    style="height: 50px"
                    color="#24AD6F"
                    @click="loginChange"
                    background="#EDF3F1"
                >
                    Зарегистрироваться
                </button-helper>
            </div>

            <doctors-reg-list-layout
                title="Его рекомендуют"
                :doctors="subs"
                :screen-type="screenType"
                :list-type="false"
                :recommendation-type="2"
                @changeScreenType="screenType = $event"
            />

            <doctors-reg-list-layout
                title="Он рекомендует"
                :doctors="subscribeDoctors"
                :screen-type="screenTypeAll"
                :list-type="false"
                :recommendation-type="1"
                @changeScreenType="screenTypeAll = $event"
            />

            <div class="problems" v-if="user">
                <div class="problems-title">Какие проблемы решает специалист?</div>

                <div class="problems-items">
                    <button-helper class="problems-item" color="#656873" background="#ffffff" v-for="(special, index) in doctor.specialities" :key="index"
                    >{{ special.specie_name }}
                    </button-helper>
                </div>
            </div>

            <div class="education" v-if="loginUser && user.role_id != 4">
                <div class="education-title">
                    <div class="col lern">Образование и опыт</div>
                </div>

                <div class="education-items">
                    <div class="education-item">
                        <div class="education-item-title">
                            Высшее образование
                        </div>
                        <div class="education-item-desc">
                            <div class="education-item-desc-item" v-for="item in doctor.education" :key="item.id">
                                Диплом по специальности "{{ item.speciality }}",
                            {{ item.school }}, {{ item.faculty }}
                            </div>
                        </div>
                    </div>

                    <div class="education-item">
                        <div class="education-item-title">
                            Курсы повышения квалификации
                        </div>
                        <div class="education-item-desc">
                            <div class="education-item-desc-item" v-for="item in doctor.courses" :key="item.id">
                                {{ item.city  }}, {{ item.nanme  }} {{ item.year  }}
                            </div>
                        </div>
                    </div>

                    <div class="education-item">
                        <div class="education-item-title">
                            Опыт работы
                        </div>
                        <div class="education-item-desc">
                            <div class="education-item-desc-item" v-for="item in doctor.jobs" :key="item.id">
                                Должность-{{ item.title }}, Город-{{ item.city }}, Компания-{{ item.company }}
                                {{ item.start_year }}.{{ item.start_month }} - {{ item.current_date ? 'По ностоящие время' : item.end_year + '.' + item.end_month }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  v-else>
            <div class="doctor-settings"  v-if="user && (user.role_id == 1 || user.role_id == 2)">
                <div class="doctor-details-top-img">
                    <img :src="image" class="user-image"
                         :class="{'no-img': doctor?.user_detales?.image === 'no_doctor_photo.svg'}"
                         v-if="doctor.user_detales"  alt="фото профиля"/>
                    <div class="heart">
                        <heart-icon/>
                    </div>
                </div>
                <div class="doctor-dashboard-settings">
                <div class="doctor-settings-items">
                        <div class="form-group">
                            <label for="exampleInputEmail1" class="label">Имя</label>
                            <div class="form-input">
                                <input-layout
                                    :input-value="doctor.first_name"
                                    placeholder="Имя"
                                    :complete="doctor.first_name"
                                    @update:input-value="doctor.first_name = $event"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1" class="label">Фамилия</label>
                            <div class="form-input">
                                <input-layout
                                    :input-value="doctor.last_name"
                                    placeholder="Фамилия"
                                    :complete="doctor.last_name"
                                    @update:input-value="doctor.last_name = $event"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1" class="label">Отчество</label>
                            <div class="form-input">
                                <input-layout
                                    :input-value="doctor.father_name"
                                    placeholder="Отчество"
                                    :complete="doctor.father_name"
                                    @update:input-value="doctor.father_name = $event"
                                />
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="" class="label">Пол</label>
                            <div class="form-input">
                                <input-layout
                                    :input-value="doctor.user_detales.gender"
                                    placeholder="Пол"
                                    :complete="doctor.user_detales.gender"
                                    type="dropdown"
                                    @click="chooseGender = !chooseGender"
                                />
                            </div>
                            <drop-down style="height: 100px;" v-if="chooseGender" :items="['Мужской', 'Женский']" @closeDrop="chooseGender = false" @dropChosen="doctor.user_detales.gender = $event; chooseGender = !chooseGender"/>
                        </div>
                        <div class="form-group">
                            <label for="" class="label">Возраст</label>
                            <div class="form-input">
                                <input-layout
                                    :input-value="doctor.user_detales.age"
                                    placeholder="Укажите возраст"
                                    :complete="doctor.user_detales.age"
                                    type="number"
                                    @update:input-value="doctor.user_detales.age = $event"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="" class="label">Город</label>
                            <div class="form-input">
                                <input-layout
                                    :input-value="doctor.user_detales.city"
                                    :placeholder="doctor.user_detales.city ? doctor.user_detales.city : 'Укажите город'"
                                    :complete="doctor.user_detales.city"
                                    type="dropdown"
                                    @click="showSortCity = true;"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="" class="label">Телефон</label>
                            <div class="form-input">
                                <input-layout-phone
                                    :input-value="doctor.phone"
                                    placeholder="Укажите ваш телефон"
                                    :complete="doctor.phone"
                                    @update:input-value="doctor.phone = $event"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 30px;" v-if="user && (user.role_id == 1 || user.role_id == 2)">
                <div class="form-card">
                <div class="speciality"><span class="small-title">Специальность</span></div>
                <div class="col-md-2 right-plus" @click="this.$store.dispatch('changeSpecial', true)">
                    <font-awesome-icon icon="plus" color="#24AD6F" />
                    <span class="add-btn">Добавить <span v-if="doctor?.user_detales?.instagram || doctor?.user_detales?.whatsapp || doctor?.user_detales?.telegram">ещё</span></span>
                </div>
            </div>
            <div v-if="doctor.specialities.length" style="margin-top: 30px;">
                <div>
                  <button-helper
                  style="margin: 0 20px 20px 0; border: 1px solid #DBE0EB; width: auto;"
                  v-for="spicial in doctor.specialities"
                  :key="spicial.species_id"
                  class="doctor-details-btn"
                  color="#656873"
                  background="#ffff"
                  @click="deleteSpecial(spicial.species_id)"
                  >{{ spicial.specie_name }}
                  <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
                </button-helper>
                </div>
            </div>
            </div>

            <div style="margin-top: 30px;" v-if="user && (user.role_id == 1 || user.role_id == 2)">
                <div class="form-card">
                <div class="speciality">
                    <span class="small-title">Социальные сети</span>
                </div>
                <div class="col-md-2 right-plus" @click="this.$store.dispatch('changeSocial', true)">
                    <font-awesome-icon icon="plus" color="#24AD6F" />
                    <span class="add-btn">Добавить <span v-if="doctor?.user_detales?.instagram || doctor?.user_detales?.whatsapp || doctor?.user_detales?.telegram">ещё</span></span>
                </div>
                </div>
                <div style="margin-top: 30px;">
                      <button-helper
                      style="margin: 0 20px 20px 0; border: 1px solid #DBE0EB; width: auto;"
                      class="doctor-details-btn"
                      color="#656873"
                      background="#ffff"
                      v-if="doctor?.user_detales?.instagram"
                      @click="deleteSocial('instagram')"
                      >
                      <font-awesome-icon icon="fa-brands fa-instagram" :color="left_instagram_color" class="icon-left"></font-awesome-icon>
                      {{ doctor?.user_detales?.instagram }}
                      <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
                    </button-helper>

                    <button-helper
                      style="margin: 0 20px 20px 0; border: 1px solid #DBE0EB; width: auto;"
                      class="doctor-details-btn"
                      color="#656873"
                      background="#ffff"
                      v-if="doctor?.user_detales?.telegram"
                      @click="deleteSocial('telegram')"
                      >
                      <font-awesome-icon icon="fa-brands fa-telegram" :color="left_telegram_color" class="icon-left"></font-awesome-icon>
                      {{ doctor?.user_detales?.telegram }}
                      <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
                    </button-helper>

                    <button-helper
                      style="margin: 0 20px 20px 0; border: 1px solid #DBE0EB; width: auto;"
                      class="doctor-details-btn"
                      color="#656873"
                      background="#ffff"
                      v-if="doctor?.user_detales?.whatsapp"
                      @click="deleteSocial('whatsapp')"
                      >
                      <font-awesome-icon icon="fa-brands fa-whatsapp" :color="left_whatsapp_color" class="icon-left"></font-awesome-icon>
                      {{ doctor?.user_detales?.whatsapp }}
                      <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
                    </button-helper>
                </div>
            </div>

            <doctors-reg-list-layout
                v-if="user && (user.role_id == 1 || user.role_id == 2)"
                title="Его рекомендуют"
                :doctors="subs"
                :screen-type="screenType"
                :list-type="false"
                :recommendation-type="2"
                @changeScreenType="screenType = $event"
            />

            <doctors-reg-list-layout
                v-if="user && (user.role_id == 1 || user.role_id == 2)"
                title="Он рекомендует"
                :doctors="subscribeDoctors"
                :screen-type="screenTypeAll"
                :list-type="false"
                :recommendation-type="1"
                @changeScreenType="screenTypeAll = $event"
            />


            <div class="form-card" style="margin-top: 30px;" v-if="user && (user.role_id == 1 || user.role_id == 2)">
                <div>
                    <div class="form-card-title">
                      <div class="speciality"><span class="small-title">Навыки</span>
                      </div>
                    </div>
                    <div v-if="doctor.talantes.length" style="margin-top: 30px;">
                        <div>
                          <button-helper
                          style="margin: 0 20px 20px 0; border: 1px solid #DBE0EB; width: auto;"
                          v-for="talent in doctor.talantes"
                          :key="talent.id"
                          class="doctor-details-btn"
                          color="#656873"
                          background="#ffff"
                          @click="deleteTalent(talent.id)"
                          >{{ talent.name }}
                          <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
                        </button-helper>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 right-plus" @click="this.$store.dispatch('changeTalant', true)">
                  <font-awesome-icon icon="plus" color="#24AD6F" />
                  <span class="add-btn">Добавить <span v-if="doctor.talantes.length">ещё</span></span>
                </div>
            </div>


            <div class="form-cards" style="margin-top: 30px;">
                <div class="col-md-12">
                  <div class="speciality">
                    <span class="small-title">Образование и опыт</span>
                  </div>
                  <div style="margin: 23px 0; padding: 0">
                    <div class="row-main">
                        <div class="row-top"
                        :style="{'border-bottom': !doctor.courses.length ? 'none' : '', 'margin-bottom': styleEducation ? '15px' : '0'}"
                        :class="{'row-bord': !doctor.education.length, 'row-bord-active': doctor.education.length, 'row-bottom': doctor.courses.length}">
                            <div class="form-card-title">
                                <div class="small-title">Высшее образование</div>
                                <div class="text" v-if="!doctor.education.length">Вы не добавили информации о своем высшем образовании</div>
                            </div>
                            <div class="col-md-2 right-plus" @click="this.$store.dispatch('showEducation', true)">
                                <font-awesome-icon icon="plus" color="#24AD6F" />

                                <span class="add-btn">Добавить <span v-if="doctor.education.length">ещё</span></span>
                            </div>
                        </div>
                        <div class="row-info" v-if="doctor.education.length">
                            <div class="row-info-inner" :key="educ.id" v-for="educ in doctor.education">
                                <div>
                                    <span class="row-info-text">
                                      Диплом по специальности "{{ educ.speciality }}",
                                      {{ educ.school }}, {{ educ.faculty }} <span class="row-info-date">{{ educ.start_year }}-{{ educ.end_year }}</span></span>
                                </div>
                                <div class="row-info-icons">
                                    <span @click="editEducation({...educ})" class="row-info-icon-1"><edit-icon/></span>
                                    <span @click="deleteEducation(educ.id)" class="row-info-icon-2"><green-trash-icon :fill="'none'" :stroke="'#C0C4CD'"/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-main">
                        <div :class="{'row-top': doctor.education.length, 'row-bord': !doctor.courses.length, 'row-bord-active': doctor.courses.length
                        , 'row-bottom' : doctor.jobs.length}"
                        :style="{'border-bottom': !doctor.jobs.length ? 'none' : '', 'margin-bottom': styleCourse ? '15px' : '0'}">
                            <div class="form-card-title">
                                <div class="small-title">Курсы повышения квалификации</div>
                                <div class="text" v-if="!doctor.courses.length">Вы не добавили информации о своем высшем образовании</div>
                            </div>
                            <div class="col-md-2 right-plus" @click="this.$store.dispatch('showCourse', true)">
                                <font-awesome-icon icon="plus" color="#24AD6F" />
                                <span class="add-btn">
                                    Добавить <span v-if="doctor.courses.length">ещё</span>
                                </span>
                            </div>
                        </div>
                        <div class="row-info" v-if="doctor.courses.length">
                            <div class="row-info-inner" :key="course.id" v-for="course in doctor.courses">
                                <div>
                                    <span class="row-info-text">{{ course.city  }}, {{ course.nanme  }} {{ course.year  }}</span>
                                </div>
                                <div class="row-info-icons">
                                    <span @click="editCourse({...course})" class="row-info-icon-1"><edit-icon/></span>
                                    <span @click="deleteCourse(course.id)" class="row-info-icon-2"><green-trash-icon :fill="'none'" :stroke="'#C0C4CD'"/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-main">
                        <div class="row-bottom" :class="{'row-top': doctor.courses.length, 'row-bord': !doctor.jobs.length, 'row-bord-active': doctor.jobs.length}">
                            <div class="form-card-title">
                                <div class="small-title">Опыт работы</div>
                                <div class="text" v-if="!doctor.jobs.length">Вы не добавили информации о своем высшем образовании</div>
                            </div>
                            <div class="col-md-2 right-plus" @click="this.$store.dispatch('showJob', true)">
                                <font-awesome-icon icon="plus" color="#24AD6F" />

                                <span class="add-btn">
                                    Добавить <span v-if="doctor.jobs.length">ещё</span>
                                </span>
                            </div>
                        </div>
                        <div class="row-info" v-if="doctor.jobs.length">
                            <div class="row-info-inner" :key="work.id" v-for="work in doctor.jobs">
                                <div>
                                    <span class="row-info-text">
                                      Должность-{{ work.title }}, Город-{{ work.city }}, Компания-{{ work.company }}
                                      <span class="row-info-date">
                                        {{ work.start_year }}.{{ work.start_month }} - {{ work.current_date ? 'По ностоящие время' : work.end_year + '.' + work.end_month }}
                                      </span>
                                    </span>
                                </div>
                                <div class="row-info-icons">
                                    <span @click="editJob({...work})" class="row-info-icon-1"><edit-icon/></span>
                                    <span @click="deleteJob(work.id)" class="row-info-icon-2"><green-trash-icon :fill="'none'" :stroke="'#C0C4CD'"/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>



        </div>
        <SelectCity v-if="showSortCity" @changeSortCity="doctor.user_detales.city = $event; showSortCity = false;" @closeModal="showSortCity = false"/>

    </user-layout>
</template>

<script>
import config from '@/config/config'
import ButtonHelper from '@/components/helppers/ButtonHelper.vue'
import HeartIcon from '@/components/icons/HeartIcon.vue'
import ShieldDoctorPageIcon from '@/components/icons/ShieldDoctorPageIcon.vue'
import WhatsappDoctorPageIcon from '@/components/icons/WhatsappDoctorPageIcon.vue'
import TelegramDoctorPageIcon from '@/components/icons/TelegramDoctorPageIcon.vue'
import InstagramDoctorPageIcon from '@/components/icons/InstagramDoctorPageIcon.vue'
import ThambDownDocIcon from '@/components/icons/ThambDownDocIcon.vue'
import ThambUpDocIcon from '@/components/icons/ThambUpDocIcon.vue'
import CircleInfoDocIcon from '@/components/icons/CircleInfoDocIcon.vue'
import DoctorsRegListLayout from "@/components/doctor/DoctorRegListLayout.vue";
import UserLayout from "@/components/layout/UserLayout.vue";
import AdminDetailsTop from "@/components/layout/AdminDetailsTop.vue";
import CreateSubIcon from "@/components/icons/CreateSubIcon.vue";
import AdminDoctorMobileThumb from "@/components/icons/AdminDoctorMobileThumb.vue";
import AdminBlockIconMobile from "@/components/icons/AdminBlockIconMobile.vue";
import EllipseIcon from "@/components/icons/EllipseIcon.vue";
import DropDown from "@/components/layout/Dropdown.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import SelectCity from "@/components/layout/SelectCity.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import GreenTrashIcon from "@/components/icons/GreenTrashIcon.vue";
import InputLayoutPhone from "@/components/layout/inputs/InputLayoutPhone.vue";

export default {
    name: 'DoctorDetaliesPage',
    components: {
        AdminBlockIconMobile,
        AdminDoctorMobileThumb, CreateSubIcon,
        AdminDetailsTop,
        UserLayout,
        DoctorsRegListLayout,
        CircleInfoDocIcon,
        ThambUpDocIcon,
        ThambDownDocIcon,
        InstagramDoctorPageIcon,
        TelegramDoctorPageIcon,
        WhatsappDoctorPageIcon,
        ShieldDoctorPageIcon,
        HeartIcon,
        ButtonHelper,
        EllipseIcon,
        DropDown,
        InputLayout,
        SelectCity,
        EditIcon,
        GreenTrashIcon,
        InputLayoutPhone
    },
    data() {
        return {
            numberArr: 0,
            numArr: 0,
            screenType: false,
            screenTypeAll: false,
            currentPage: 0,
            currentPageMy: 0,
            type: 1,
            showSortCity: false,
            chooseGender: false
        }
    },

    methods: {
        async deleteTalent(talentId) {
            this.$store.dispatch('deleteTalentDoctor', talentId);
            await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        },
        async editJob(job){
            this.$store.dispatch('showJob', true);
            this.$store.dispatch('changeJob', job);
        },
        async deleteJob(jobId){
            this.$store.dispatch('deleteJobDoctor', jobId);
            await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        },
        async editCourse(course){
            this.$store.dispatch('showCourse', true);
            this.$store.dispatch('changeCourse', course);
        },
        async deleteCourse(courseId) {
            this.$store.dispatch('deleteCourseDoctor', courseId);
            await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        },
        async deleteEducation(educId) {
            this.$store.dispatch('deleteEducationDoctor', educId);
            await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        },
        async editEducation(educ) {
            this.$store.dispatch('showEducation', true);
            this.$store.dispatch('changeEducation', educ);
        },
        async deleteSocial(name){
            let params = {
                doctor_id: this.doctor.id,
                name: name
            }
            await this.$store.dispatch('deleteSocialDoctor', params)
            await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        },
        async deleteSpecial(specId){
            let params = {
                doctor_id: this.doctor.id,
                special_id: specId
            };
            await this.$store.dispatch('deleteSpecialDoctor', params);
            await this.$store.dispatch('getDoctorDetalies', this.doctor.id);
        },
        async updateUser(value){
            let doctor = {
                id: this.doctor.id,
                first_name: this.doctor.first_name,
                last_name: this.doctor.last_name,
                father_name: this.doctor.father_name,
                age: this.doctor.user_detales.age,
                gender: this.doctor.user_detales.gender,
                phone: this.doctor.phone,
                city: this.doctor.user_detales.city,
            };
            await this.$store.dispatch('editDoctor', doctor);
            await this.$store.dispatch('getDoctorDetalies', doctor.id);
            this.type = value;
        },
        async addFavorite() {
            this.$store.dispatch('addFavorite',{id:this.doctor.id});
            this.$store.dispatch('getUserFavorites');
        },
        subscribe() {
            this.$store.dispatch('changeSubscribPopup', true)
            this.$store.dispatch('changeSubscribType', 'Вы рекомендуете данного врача?')
            this.$store.dispatch('changeSubscribInfo', {
                user_id: this.user.id,
                doctor_id: this.doctor.id,
                subscribeText: 'Да, рекомендую',
                subscribeType: true
            })
        },
        unSubscribe(){
            this.$store.dispatch('changeSubscribPopup', true)
            this.$store.dispatch('changeSubscribType', 'Вы не рекомендуете данного врача?')
            this.$store.dispatch('changeSubscribInfo', {
                user_id: this.user.id,
                doctor_id: this.doctor.id,
                subscribeText: 'Да, я не рекомендую',
                subscribeType: 'unsubscribe'
            })
        },
        loginChange() {
            this.$store.dispatch('changeRegisterType', 'user')
            this.$store.dispatch('changeLoginPopup', false)
            this.$store.dispatch('changeRegisterPopup', true)
        },
    },

    computed: {
        specId() {
            return this.$route.query.spec
        },
        specialities() {
            return this.$store.getters.getAllSpecialities
        },
        specName() {
            let arr = this.specialities.filter((item) => item.id == this.specId)
            if(arr[0]){
                let name = arr[0].name;
                if (name.charAt(name.length - 1).toLowerCase() === "т") {
                    return name + "ы";
                }else if(name.charAt(name.length - 1).toLowerCase() === "и"){
                    return name;
                } else {
                    return name + "и";
                }
            }
            return null;
            // return arr[0] ? arr[0].name : null
        },
        styleEducation() {
            if(this.doctor.courses.length && !this.doctor.education.length){
              return true
            }
            return false;
        },
        styleCourse() {
            if(this.doctor.jobs.length && !this.doctor.courses.length){
              return true
            }
            return false;
        },
        isFavorite() {
            const favoriteIds = this.$store.getters.getFavorites;

            const isDoctorIdFound = favoriteIds.some(
              (appointment) => appointment.doctor_id === this.doctor.id
            );

            return isDoctorIdFound;

        },
        doctor() {
            return this.$store.getters.getDoctorDetalies
        },
        image() {
            return `${config.URL}/img/doctors/${this.doctor?.user_detales?.image}`
        },
        user() {
            return this.$store.getters.getUser
        },
        loginUser() {
            return !!localStorage.userToken
        },
        left() {
            return this.numArr > 0 ? true : false
        },
        leftSub() {
            return this.numberArr > 0 ? true : false
        },
        right() {
            return this.numArr + 4 == this.doctor.subscribes.length ? false : true
        },
        rightSub() {
            return this.numberArr + 4 == this.doctor.my_recommendations.length ? false : true
        },
        subs() {
            return this.$store.getters.getdocSubscribes
        },
        subscribeDoctors() {
            return this.$store.getters.getSubDoctors
        },
        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        },
         getDoctorSubscribe(){
            if (this.doctor.my_recommendations.length) {
                let ids = this.doctor.my_recommendations.map((item) => item.doctor_id)
                this.$store.dispatch('getDoctorsSubscribe', {page: ids})
            }else{
                this.$store.dispatch('resetDoctorsSubscribe')
            }
            if (this.doctor.recommendations.length) {
                let ids = this.doctor.recommendations.map((item) => item.recommendation_id)
                this.$store.dispatch('getSubscribeDoctors', {page: ids})
            }else{
                this.$store.dispatch('resetSubscribeDoctors')
            }
            return true
        }
    },
    async mounted() {
        this.screenTypeAll = this.mobile ? false : this.screenTypeAll

        await this.$store.dispatch('getDoctorDetalies', this.$route.params.id)
        if (localStorage.userToken) {
            await this.$store.dispatch('getUserByToken')
        }

        this.getDoctorSubscribe;
    },
    watch: {
        $route() {
            this.$store.dispatch('getDoctorDetalies', this.$route.params.id);
        },
        '$store.getters.getDoctorDetalies'() {
            this.getDoctorSubscribe;
        }
    }
}
</script>

<style scoped lang="scss">
.speciality {
    text-align: left;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    column-gap: 10px;
}

.info-icon {
  font-size: 9px;
  cursor: pointer;
}

.text-delete {
    margin-top: 15px;
    margin-left: 0;
  }
.small-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #373a43;
}
.row-main {
    display: flex;
    flex-direction: column;
}

.row-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.row-bottom {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.row-bord {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border: 1px solid #dbe0eb;
  margin: 0;
  padding: 30px 45px;
}

.row-info-inner {
    display: flex;
    justify-content: space-between;
    padding: 40px 29px 40px 42px;
    border: 1px solid #DBE0EB;
    border-radius: 20px;
    margin-top: 24px;
}
.row-info:last-child {
    margin-bottom: 84px;
}
.row-info-text {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #373A43;
}
.row-info-date {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;

  color: #656873;
}
.row-bord-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}
.row-info-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-info-icon-1 {
    padding: 8px 11px 8px 15px;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    cursor: pointer;
}

.row-info-icon-2 {
    padding: 8px 15px 8px 10px;
    border-left: none !important;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    cursor: pointer;
}

.row-info-icons span {
    border: 1px solid #DBE0EB;
    line-height: 0;
    cursor: pointer;
}

.right-plus {
  text-align: right;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #24ad6f;
  cursor: pointer;
}
.form-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-card-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.doctor-settings {
    display: flex;
    gap: 115px;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    @media screen and (max-width: 769px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}
.doctor-dashboard-settings {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    width: 100%;

    @media screen and (max-width: 969px) {
        align-items: center;
    }
}
.doctor-settings-items {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 60px;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 1260px) {
        flex-direction: column;
    }
}
.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 30%;

  @media screen and (max-width: 1260px) {
        width: 100%;
    }
}

.form-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;

    @media screen and (max-width: 1260px) {
        width: 100%;
    }
}
.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 9px;
  color: #373a43;
}
.grid-container {
    position: relative;
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.row {
    position: relative;
    margin-top: 20px;
}

.doctor-details-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 75px;
}

.doctor-details-top-left {
    display: flex;
    column-gap: 50px;
    align-items: flex-start;
}

.doctor-details-top-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    gap: 25px;
}

.doctor-details-top-img {
    position: relative;
    width: 180px;
    height: 180px;

    border-radius: 50%;
    background: #e9ebf0;
}

.no-img {
    width: 20px !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.doctor-details-top-content {
    display: flex;
    flex-direction: column;
}

.doctor-details-top-buttons {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 20px;
}

.doctor-details-top-shared {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 20px;
}

.buttons-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 18px;
    margin-bottom: 60px;
}

.button-icon {
    position: absolute;
    top: 10px;
    left: 20px
}

.user-image {
    width: 180px;
    height: 180px;
    border-radius: 50%;
}

.col-md-2 {
    padding: 0;
}

.heart {
    text-align: center;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background: #ffffff;
    top: 140px;
    right: 10px;
}

.doctor-details-top-name {
    margin-top: 16px;
    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    color: #373a43;
}

.doctor-details-top-spec {
    margin-top: 11px;
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #656873;
}

.doctor-details-top-page {
    margin-top: 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #9195a4;
}

.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: auto;
    line-height: 36px;
    text-align: center;
    border: #c0c4cd 1px solid;
}

.green {
    background-color: #38d04a;
}

.massage-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    background-color: #edf3f1;
    border-radius: 20px;
    padding: 33px 40px;
    margin-top: 64px;
}

.text-node {
    display: flex;
    column-gap: 15px;
    align-items: baseline;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    color: #373a43;
}

.right-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #373a43;
}

.small {
    font-family: 'Museo Sans Cyrl';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #9195a4;
}

.block-parent {
    position: relative;
}

.block {
    background: #ffffffa6;
    width: 100%;
    min-height: 700px;
    z-index: 999;
    position: absolute;
    top: -55px;
}

.left-text {
    text-align: right;
}

.arrows {
    display: flex;
    float: right;
}

.left-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border: 1px solid #d3d8e2;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.right-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border: 1px solid #d3d8e2;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.right-arrow-all {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(132, 132, 151, 0.5);
    line-height: 40px;
    text-align: center;
    top: 125px;
    right: 45px;
    cursor: pointer;
}

.left-arrow-all {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(132, 132, 151, 0.5);
    top: 125px;
    left: -30px;
}

.block-content {
    width: 100%;
    height: 161px;
    left: 20px;
    top: 308px;
    margin-bottom: 60px;
    background: #FBEFEF;
    border-radius: 20px;
}

.block-content-icon {
    text-align: center;
    margin-top: 24px;
}

.block-content-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #373A43;
    text-align: center;
    margin-top: 16px;
}

.block-content-button {
    margin-top: 24px;
    text-align: center;
}

.block-content-all {
    margin: auto;
    position: relative;
}

.block-content-close-all {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFFFFF;
    opacity: 0.7;
    position: absolute;
    z-index: 101;
}

.doctor-details-content {
    position: relative;
}

.see-all {
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-decoration-line: underline;
    color: #24ad6f;
    float: right;
}


.problems {
    margin-top: 108px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #373a43;
}

.problems-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #373a43;
}

.problems-items {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: 8px;
}

.problems-item {
    padding: 8px 14px;
}

.education {
    margin-top: 89px;
    display: flex;
    flex-direction: column;
    row-gap: 60px;
}

.education-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #373a43;
}

.education-items {
    display: flex;
    flex-direction: column;
    row-gap: 95px;
}

.education-item {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.education-item-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #373a43;
}

.education-item-desc {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.education-item-desc-item {
    display: flex;
    column-gap: 15px;
    align-items: baseline;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #373a43;
}

.education-item-desc-item:before {
    content: "";
    display: block;
    width: 14px;
    min-width: 14px;
    height: 1px;
    background-color: #D3D8E2;
}

.subscribe-button {
    white-space: nowrap;
}

@media screen and (max-width: 1600px) {
    .header-3 {
        max-width: 1400px !important;
    }
}

@media screen and (max-width: 1536px) {
    .header-3 {
        max-width: 1370px !important;
    }
}

@media screen and (max-width: 1440px) {
    .header-3 {
        max-width: 1280px !important;
    }

    .right-arrow-all {
        top: 110px;
        right: 25px;
    }

    .left-arrow-all {
        top: 110px;
        left: -30px;
    }
}

@media screen and (max-width: 1366px) {
    .header-3 {
        max-width: 1150px !important;
    }

    .doctor-details-top-img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: #e9ebf0;
    }

    .user-image {
        width: 160px;
        height: 160px;
    }

    .heart {
        width: 36px;
        height: 36px;
        line-height: 36px;
        top: 120px;
    }

    .doctor-details-top-name {
        font-size: 25px;
    }

    .right-arrow-all {
        top: 110px;
        right: 15px;
    }

    .left-arrow-all {
        top: 110px;
        left: -30px;
    }
}

@media screen and (max-width: 1280px) {
    .header-3 {
        max-width: 1100px !important;
    }

    .doctor-details-top-img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        background: #e9ebf0;
    }

    .user-image {
        width: 160px;
        height: 160px;
    }

    .heart {
        width: 36px;
        height: 36px;
        line-height: 36px;
        top: 120px;
    }

    .doctor-details-top-name {
        font-size: 20px;
    }

    .right-arrow-all {
        top: 110px;
        right: 0;
    }

    .left-arrow-all {
        top: 110px;
        left: -30px;
    }

}

@media screen and (max-width: 1080px) {
    .header-3 {
        max-width: 1000px !important;
    }

    .doctor-details-top {
        flex-direction: column;
        align-items: center;
    }

    .doctor-details-top-left {
        flex-direction: column;
        align-items: center;
        text-align-all: center;
        row-gap: 30px;
    }

    .doctor-details-top-right {
        flex-direction: column;
        width: 100%;
    }

    .doctor-details-top-content {
        justify-content: center;
        align-items: center;
    }

    .doctor-details-top-spec {
        text-align: center;
    }

    .doctor-details-top-name {
        text-align: center;
    }
}

@media screen and (max-width: 769px){
    .problems-title {
        text-align: center;
    }

    .problems-items {
        justify-content: center;
    }

    .doctor-details-top {
        margin-bottom: 40px;
    }

    .doctor-details-top-buttons {
        flex-direction: column;
        row-gap: 30px;
    }

    .doctor-details-top-page {
        white-space: nowrap;
        width: 85vw;
        text-align: center;
        overflow: auto;
    }

    .doctor-details-top-page::-webkit-scrollbar {
        display: none;
    }

    .education-title {
        text-align: center;
    }

    .education-items {
        row-gap: 45px;
    }

    .education-item {
        row-gap: 15px;
    }

    .education {
        row-gap: 40px;
    }

    .massage-box {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 44px;
        padding: 25px;
    }

    .text-node {
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
    }
}
</style>

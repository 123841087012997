<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.commit('setRecommendationNotAuthPopup', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Рекомендовать врача</div>
      <div class="first-contnet" v-if="type === 1">
        <div class="inputs">
          <div class="icon-div">
            <search-doctor
              :doctor-img="doctorImg"
              @checked-doctor="checkedDoctor"
              :search="search"
              @update-search="searchDoctor"
              :doctors="doctors"
              @close-drop=";(doctors = []), (doctorId = null)"
            />
          </div>
        </div>
        <div class="buttons">
          <button-helper
            class="save-button"
            color="#ffffff"
            @click="recommendate"
            background="#02B061"
          >
            Рекомендовать
          </button-helper>
          <span @click="changeType" class="text" style="cursor: pointer"> Врача нет в списке</span>
        </div>
      </div>
      <div class="second-content" v-else>
        <div class="inputs">
          <div class="icon-div">
            <input-layout
              :input-value="doctorNotAuth.last_name"
              placeholder="Фамилия"
              :complete="doctorNotAuth.last_name"
              @update:input-value="doctorNotAuth.last_name = $event"
            />
          </div>
        </div>
        <div class="inputs">
          <div class="icon-div">
            <input-layout
              :input-value="doctorNotAuth.first_name"
              placeholder="Имя"
              :complete="doctorNotAuth.first_name"
              @update:input-value="doctorNotAuth.first_name = $event"
            />
          </div>
        </div>
        <div class="inputs">
          <div class="icon-div">
            <input-layout
              :input-value="doctorNotAuth.father_name"
              placeholder="Отчество"
              :complete="doctorNotAuth.father_name"
              @update:input-value="doctorNotAuth.father_name = $event"
            />
          </div>
        </div>
        <div class="inputs">
          <div class="icon-div">
            <input-layout
              :is-disable="true"
              placeholder="Специальность"
              @click="openSpecialistMenu"
              :input-value="doctorNotAuth.spec?.name"
              :complete="doctorNotAuth.spec?.name"
            />
            <div class="drop-menu" v-if="drop">
              <div class="drop-scroll">
                <div
                  class="drop-item"
                  v-for="spec in specialities"
                  :key="spec.id"
                  @click="dropSpec(spec)"
                >
                  {{ spec.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="inputs">
          <div class="icon-div">
            <input-layout
              :input-value="doctorNotAuth.city"
              placeholder="Город"
              :is-disable="true"
              :complete="doctorNotAuth.city"
              @click="showSortCity = true"
            />
          </div>
        </div>
        <div v-if="socials.length">
          <div class="inputs" v-for="(item, index) in socials" :key="index">
            <div class="icon-div">
              <input-layout
                :input-value="doctorNotAuth[socialName]"
                :placeholder="!socialName ? 'Выбрать' : 'Укажите ссылку'"
                :complete="doctorNotAuth[socialName]"
                @update:input-value="doctorNotAuth[socialName] = $event"
                @click="showSocial = !showSocial"
                :icon-name="socialName"
                :is-select="true"
                :show-select="showSocial"
              />
              <div class="sort-popup" v-if="showSocial">
                <div
                  class="sort-popup-item"
                  v-for="(item, index) in ['whatsapp', 'telegram', 'instagram']"
                  :key="index"
                  @click="changeSelectValue(item)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center" v-if="socials.length < 1">
          <span @click="addSocial" class="text" style="cursor: pointer"
            >+ Добавить ссылку на социальную сеть</span
          >
        </div>
        <div class="buttons">
          <button-helper
            class="save-button"
            color="#ffffff"
            @click="recommendateNotAuth"
            background="#02B061"
          >
            Рекомендовать
          </button-helper>
        </div>
      </div>
    </div>
    <SelectCity
      v-if="showSortCity"
      @changeSortCity="filterByCity($event)"
      @closeModal="showSortCity = false"
    />
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import SearchDoctor from '@/components/helppers/SearchDoctor.vue'
import InputLayout from '@/components/layout/inputs/InputLayout.vue'
import SelectCity from '@/components/layout/SelectCity.vue'
//   import SelectHelper from '@/components/helppers/SelectHelper.vue'

export default {
  name: 'RocommendationNotAuthPopup',
  components: {
    ButtonHelper,
    SearchDoctor,
    InputLayout,
    SelectCity
    //   SelectHelper
  },
  data() {
    return {
      socials: [],
      socialName: '',
      showSocial: false,
      showSortCity: false,
      sortCity: null,
      type: 1,
      spec: {},
      doctorNotAuth: {},
      doctors: [],
      search: '',
      drop: false,
      doctorId: null,
      doctorImg: '',
      selectStyles: {
        width: '300px',
        marginTop: '20px'
      }
    }
  },
  computed: {
    specialities() {
      return this.$store.getters.getAllSpecialities
    }
  },
  methods: {
    addSocial() {
      this.socials.push(1)
    },
    changeSelectValue(value) {
      this.showSocial = false
      this.socialName = value
    },
    changeType() {
      this.type = 2
    },
    async searchDoctor(value) {
      this.search = value
    },
      async recommendateNotAuth() {
          await this.$store.commit('recommendateNotAuth', this.doctorNotAuth)
          this.$store.commit('setRecommendationNotAuthPopup', false)

      },
      async recommendate() {
          const data = {
              'doctor_id': this.$store.getters.getUser.id,
              'recommendation_id': this.doctorId
          }

          await this.$store.dispatch('setSubscribe', data);
          await this.$store.dispatch('getUserByToken')
          this.$store.commit('setRecommendationNotAuthPopup', false);

      },
    checkedDoctor(doctor) {
      this.doctors = []
      this.doctorId = doctor.id
      this.doctorImg = doctor.user_detales.image
      this.search = doctor.full_name
    },
    closeSpecialistMenu(e) {
      if (!e.target.closest('.drop-menu') && this.drop) {
        this.drop = !this.drop
        setTimeout(() => {
          document.removeEventListener('click', this.closeSpecialistMenu)
        }, 100)
      }
    },
    dropSpec(spec) {
      this.doctorNotAuth.spec = spec
      setTimeout(() => {
        document.removeEventListener('click', this.closeSpecialistMenu)
      }, 100)
      this.drop = false
    },
    openSpecialistMenu() {
      if (!this.drop) {
        setTimeout(() => {
          document.addEventListener('click', this.closeSpecialistMenu)
        })
      } else {
        setTimeout(() => {
          document.removeEventListener('click', this.closeSpecialistMenu)
        }, 100)
      }
      this.drop = !this.drop
    },
    filterByCity($event) {
      this.doctorNotAuth.city = $event
      this.showSortCity = false
    }
  },
  watch: {
    search() {
      this.$axios.get(`/api/get-doctors-filter?name=${this.search}`).then((response) => {
        this.doctors = response.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
.sort-popup {
  z-index: 100;
  position: absolute;
  width: 300px;
  background: #ffffff;
  border: 1px solid #dbe0eb;
  box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.3);
  border-radius: 10px;
  top: 65px;
  max-height: 355px;
  overflow-y: auto;
}

.sort-popup::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
}

.sort-popup::-webkit-scrollbar-track {
  background: rgba(203, 208, 217, 0.5);
  border-radius: 50px;
}

.sort-popup-item {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 40px;
  color: #373a43;
  cursor: pointer;
  text-align: left;
  padding-left: 20px;
}

.sort-popup-item:hover {
  background: #edeff3;
  border-radius: 5px;
}

.down-icon.drop {
  transform: rotateX(180deg);
}
.down-icon {
  position: absolute;
  top: 36px;
  left: 395px;
  transition: 0.2s;
  @media screen and (max-width: 1330px) {
    left: 193px;
    top: 16px;
    width: 5px;
    height: 3px;
  }
}
.drop-menu {
  background: #ffffff;
  box-shadow: 0 4px 40px rgba(138, 138, 138, 0.45);
  border-radius: 20px;
  z-index: 999;
  position: absolute;
  width: 335px;
  height: 351px;
  overflow-y: hidden;
  top: 55px;
  left: 0;
  padding: 14px 10px 17px 4px;
  @media screen and (max-width: 1330px) {
    width: 210px;
    height: 351px;
    top: 71px;
    left: 0;
  }

  .drop-scroll {
    overflow: auto !important;
    width: 100%;
    height: 100%;
    margin: auto;

    .drop-item {
      height: 37px;
      width: 100%;
      cursor: pointer;
      z-index: 9999;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 40px;
      color: #373a43;
      padding-left: 23px;
      margin: auto;

      &:hover {
        background: #edeff3;
        border-radius: 5px;
      }
    }
  }

  .drop-scroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }

  .drop-scroll::-webkit-scrollbar-track {
    background: rgba(203, 208, 217, 0.5);
    border-radius: 50px;
  }

  .drop-scroll::-webkit-scrollbar-thumb {
    background-color: #d6d9e0;
    border-radius: 50px;
    border: 5px solid #d6d9e0;
  }
}

.down-icon.drop {
  transform: rotateX(180deg);
}
.down-icon {
  position: absolute;
  top: 36px;
  left: 395px;
  transition: 0.2s;
  @media screen and (max-width: 1330px) {
    left: 193px;
    top: 16px;
    width: 5px;
    height: 3px;
  }
}
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  width: 483px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}
.inputs {
  width: 330px;
  margin: 20px auto;
}

.input {
  width: 330px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}
.input-soc::-webkit-outer-spin-button,
.input-soc::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-soc[type='number'] {
  -moz-appearance: textfield;
}
.input-soc {
  width: 300px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}

.input:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.input-soc:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.icon-left {
  position: absolute;
  top: 35px;
  left: 30px;
}

.icon-right {
  position: absolute;
  top: 35px;
  right: 15px;
}

.icon-div {
  display: flex;
  position: relative;
}
.buttons {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 45px;
}
.text {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #24ad6f;
  margin-left: 20px;
}
.save-button {
  padding: 17px 24px;
}

@media screen and (max-width: 425px) {
  .form {
    width: 80%;
  }

  .inputs {
    width: 80%;
  }
}
</style>

import AdminMenuSetting from "@/components/icons/AdminMenuSetting.vue";

export default {
    components: {AdminMenuSetting},
    URL: '',
    copyLink: 'https://doctorsconnection.nomadicdemo.com',
    copyLink2: 'http://127.0.0.1',
    USER_MANU_ITEM: [
        {
            id: 1,
            title: 'Избранное',
            icon: 'heart-icon'
        },
        {
            id: 2,
            title: 'Лента уведомлений',
            icon: 'notification-icon'
        },
        {
            id: 3,
            title: 'Настройки',
            icon: 'settings-icon'
        }
    ],
    DOCTOR_MANU_ITEM: [
        {
            id: 1,
            title: 'Я рекомендую',
            icon: 'like-icon'
        },
        {
            id: 2,
            title: 'Я не рекомендую',
            icon: 'dislike-icon'
        },
        {
            id: 3,
            title: 'Меня рекомендуют',
            icon: 'rotated-arrow-icon'
        },
        {
            id: 4,
            title: 'Лента уведомлений',
            icon: 'notification-icon'
        },
        {
            id: 5,
            title: 'Анкета врача',
            icon: 'human-icon'
        },
        {
            id: 6,
            title: 'Настройки',
            icon: 'settings-icon'
        }
    ],
    ADMIN_MANU_ITEMS: [
        {
            id: 1,
            title: 'Статистика',
            icon: 'stat-icon'
        },
        {
            id: 2,
            title: 'Заявки на верификацию',
            icon: 'catalog-icon'
        },
        {
            id: 3,
            title: 'Доверенные специалисты',
            icon: 'like-icon'
        },
        {
            id: 4,
            title: 'Приглашения',
            icon: 'people-icon'
        },
        {
            id: 5,
            title: 'Лента уведомлений',
            icon: 'notification-icon'
        },
        {
            id: 6,
            title: 'История',
            icon: 'story-icon'
        },
        {
            id: 7,
            title: 'Настройки',
            icon: 'settings-icon'
        },
        {
            id: 8,
            title: 'Редактирование контента',
            icon: 'change-icon'
        },
        {
            id: 9,
            title: 'Отправить приглашение',
        }
    ]
}

<template>
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.9826 8.42105L8.45719 6.89564L9.30771 6.04511L12.2852 9.02256L9.30771 12L8.45719 11.1495L9.9826 9.62406H5.66862C4.39239 9.62406 3.16843 9.11708 2.266 8.21465C1.36357 7.31222 0.856587 6.08826 0.856587 4.81203C0.856587 3.5358 1.36357 2.31184 2.266 1.40941C3.16843 0.50698 4.39239 0 5.66862 0H11.0821V1.20301H5.66862C4.71144 1.20301 3.79348 1.58324 3.11665 2.26007C2.43983 2.93689 2.0596 3.85486 2.0596 4.81203C2.0596 5.7692 2.43983 6.68717 3.11665 7.36399C3.79348 8.04082 4.71144 8.42105 5.66862 8.42105H9.9826Z"
      fill="#02B061"
    />
  </svg>
</template>

<script>
export default {
  name: 'RecomendMeIcon'
}
</script>

<style scoped></style>

<template>
    <div class="dashboard-left-menu desktop-only">
        <div v-for="item in menu" :key="item.id">
            <router-link
                :to="{name: 'dashboard', params: { id: item.id }}"
                style="text-decoration: none"
                class="dashboard-left-menu__item"
            >
                <component :is="item.icon"/>
                <span class="title">{{ item.title }}</span>
                <span class="new-notification" v-if="((user.role_id === 3 && item.id===4) || (user.role_id === 4 && item.id===2)) && notifications" >{{notifications}}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import config from "@/config/config";

export default {

    name: 'UserDashboardLeftMenu',

    computed: {

        user() {
            return this.$store.getters.getUser
        },

        menu() {
            return this.user.role_id === 3 ? config.DOCTOR_MANU_ITEM : config.USER_MANU_ITEM
        },
        notifications() {
            return this.$store.getters.getAllNotifications.reduce((count, message) => {
                if (message.read === 0) {
                    count++;
                }
                return count;
            }, 0)
        }
    }

}
</script>

<style scoped lang="scss">

.dashboard-left-menu {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    min-width: 180px;

    &__item {
        display: flex;
        column-gap: 10px;
        flex-wrap: nowrap;
        white-space: nowrap;
    }
}

.title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #373a43;
    cursor: pointer;
}

.new-notification{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #02B061;
    color: #FFFFFF;
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 21px;
    text-align: center;
}

</style>

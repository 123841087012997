<template>
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.66357 1.09351H18.4882"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.66357 8.08875H18.4882"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.66357 15.084H18.4882"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="1" cy="1.04688" r="1" fill="#02B061" />
    <circle cx="1" cy="8.04688" r="1" fill="#02B061" />
    <circle cx="1" cy="15.0469" r="1" fill="#02B061" />
  </svg>
</template>

<script>
export default {
  name: 'ListIconGreen'
}
</script>

<style scoped></style>

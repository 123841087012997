export default {
    state: {
        histories: [],
        stats: [],
        statsDoctors: [],
        statsPageCount: 0
    },

    getters: {
        getHistories(state) {
            return state.histories
        },
        getStats(state){
            return state.stats
        },
        getStatsDoctors(state){
            return state.statsDoctors
        },
        getStatsPageCount(state){
            return state.statsPageCount
        }
    },

    mutations: {
        setHistories(state, histories) {
            state.histories = histories
        },
        restoreDoctor(state, doctorId){
            const user = state.histories.find(history => history.doctor.id === doctorId)
            if (user) {
              user.type = 2
            }
        },
        deleteDoctor(state, doctorId){
            const user = state.histories.find(history => history.doctor.id === doctorId)
            if (user) {
              user.type = 1
            }
        },
        setStats(state, data){
            state.stats = data
        },
        setStatsDoctors(state, data){
            state.statsDoctors = data
        },
        setStatsPageCount(state, count){
            state.statsPageCount = count
        }
    },

    actions: {
        async getHistories({commit}) {
            await this.$axios.get(`/api/admin/histories`).then((response) => {
                commit('setHistories', response.data)
            })
        },
        async restoreDoctor({commit}, doctorId) {
            await this.$axios.post(`/api/admin/restoreDoctor/${doctorId}`).then((response) =>{
                if(response.status == 201){
                    commit('restoreDoctor', doctorId)
                }
            });
        },
        async deleteDoctor({commit}, doctorId) {
            await this.$axios.delete(`/api/admin/deleteDoctor/${doctorId}`).then((response) =>{
                if(response.status == 201){
                    commit('deleteDoctor', doctorId)
                }
            });
        },
        async getStats({commit}) {
            await this.$axios.get(`/api/admin/stats`).then((response) => {
                commit('setStats', response.data)
            })
        },
        async getStatsDoctors({commit}, page){
            await this.$axios.get(`/api/admin/statsDoctors?page=${page}`)
                .then((response) => {
                    commit('setStatsDoctors', response.data.doctors)
                    commit('setStatsPageCount', response.data.count)
                })
        },
    }
}
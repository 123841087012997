<template>
    <div class="sub-doctor-item">
        <router-link
            class="mobile-no-padding-margin"
            style="text-decoration: none"
            :to="{ name: 'doctor-page', params: { id: user.id } }"
        >
        <div class="sub-doctor-item-img">
            <img alt="Фото профиля" :src="iamge" class="user-image"/>
            <div class="heart">
                <heart-icon/>
            </div>
        </div>
        </router-link>
        <div class="sub-doctor-item-content">
            <div class="sub-doctor-item-main">
                <router-link
                    class="mobile-no-padding-margin"
                    style="text-decoration: none"
                    :to="{ name: 'doctor-page', params: { id: user.id } }"
                >
                <div class="sub-doctor-item-text">
                    <div class="sub-doctor-item-name">{{ user.last_name }} {{ user.first_name }} {{ user.father_name }}</div>
                    <div class="sub-doctor-item-spec">
                        {{ user.user_detales.description }}
                    </div>
                </div>
                </router-link>

                <div class="sub-doctor-item-main-bottom">
                    <div class="sub-doctor-item-likes">
                        <div
                            class="sub-doctor-item-like"
                            v-on:mouseenter="hidden_likes = true"
                            v-on:mouseleave="hidden_likes = false"
                        >
                            <doctor-like-icon style="margin-right: 4px"/>
                            5
                            <div class="hidden" v-if="hidden_likes">
                                <div class="arrow-up"></div>
                                <div class="line-hidden">
                                    Его рекомендуют
                                    <span style="text-decoration: underline">5 специалистов</span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="sub-doctor-item-like"
                            v-on:mouseenter="hidden_dislikes = true"
                            v-on:mouseleave="hidden_dislikes = false"
                        >
                            <doctor-dislike-icon style="margin-right: 4px"/>
                            4
                            <div class="hidden" style="right: -65%" v-if="hidden_dislikes">
                                <div class="arrow-up"></div>
                                <div class="line-hidden">
                                    Он рекомендует
                                    <span style="text-decoration: underline">4 специалистов</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sub-doctor-item-main-delete mobile-only" @click="deleteRecommendation" v-if="loginUser.role_id === 3">
                        Удалить
                    </div>
                    <div class="sub-doctor-item-main-delete mobile-only" @click="deleteFavorite" v-else>
                        Удалить
                    </div>
                </div>
            </div>
            <delete-icon class="sub-doctor-item-button desktop-only" @click="deleteRecommendation" v-if="loginUser.role_id === 3"/>
            <delete-icon class="sub-doctor-item-button desktop-only" @click="deleteFavorite" v-else/>
        </div>
    </div>
</template>

<script>
import config from '@/config/config'
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import HeartIcon from "@/components/icons/HeartIcon.vue";
import DoctorLikeIcon from '@/components/icons/DoctorLike.vue';
import DoctorDislikeIcon from '@/components/icons/DoctorDislike.vue';

export default {
    name: 'SubDoctorItem',
    components: {
        HeartIcon,
        DeleteIcon,
        DoctorLikeIcon,
        DoctorDislikeIcon
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            hidden_likes: false,
            hidden_dislikes: false,
        }
    },
    computed: {
        iamge() {
            return `${config.URL}/img/doctors/${this.user.user_detales.image}`
        },
        loginUser(){
            return this.$store.getters.getUser
        }
    },
    methods: {
        deleteFavorite() {
            this.$store.dispatch('changeSubscribPopup', true)
            this.$store.dispatch('changeSubscribType', 'Вы хотите удалить этого врача из избранных?')
            this.$store.dispatch('changeSubscribInfo', {
                favorite_id: this.id,
                subscribeText: 'Да, удаляю',
                subscribeType: 'deleteFavorite'
            })
        },
        deleteRecommendation() {
            this.$store.dispatch('changeSubscribPopup', true)
            this.$store.dispatch('changeSubscribType', 'Вы хотите удалить этого врача из рекоммендованных?')
            this.$store.dispatch('changeSubscribInfo', {
                id: this.id,
                subscribeText: 'Да, удаляю',
                subscribeType: 'delete'
            })
        }
    }
}
</script>

<style scoped>

.sub-doctor-item {
    border: 1px solid #dbe0eb;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 30px;
    column-gap: 25px;
}

.sub-doctor-item-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sub-doctor-item-main {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.sub-doctor-item-main-bottom {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

.hidden {
    position: absolute;
    background: #373A43;
    border-radius: 10px;
    padding: 12px 20px;
    top: 48px;
    right: -9%;
    opacity: 0.9;
}

.arrow-up {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1px;
    height: 1px;
    background-color: #373A43;
    box-shadow: 0px 4px 50px rgba(132, 132, 151, 0.3);
    border: 2px solid #dbe0eb;
}
.arrow-up:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    background-color: inherit;
}
.line-hidden {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #fff;
}

.sub-doctor-item-main-delete {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #24AD6F;
}

.sub-doctor-item-text {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.sub-doctor-item-likes {
    display: flex;
    column-gap: 10px;
    position: relative;
}

.sub-doctor-item-like {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 30px;
    border: 1px solid #dbe0eb;
    border-radius: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #373a43;
    cursor: pointer;
}

.sub-doctor-item-name {
    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #373a43;
}

.sub-doctor-item-spec {
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #656873;
}

.sub-doctor-item-button {
    cursor: pointer;
}

.sub-doctor-item-img {
    position: relative;
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
    border-radius: 50%;
    background: #e9ebf0;
}

.user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.heart {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    line-height: 36px;
    border-radius: 50%;
    background: #ffffff;
    bottom: -8px;
    right: 0;
}

@media screen and (max-width: 769px){
    .sub-doctor-item {
        column-gap: 15px;
        align-items: baseline;
        padding: 20px;
    }
    .user-image {
        width: 60px;
        height: 60px;
    }
    .sub-doctor-item-img {
        width: 60px;
        height: 60px;
    }
}

</style>

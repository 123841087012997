<template>
  <user-layout :is-header="false" :is-footer="false"
               :is-menu="false" :is-container="false"
               :is-home-admin-layout="admin"
  >
      <div class="container-fluid-md container-flex-mobile" v-if="homeContent">
          <div class="row back order-mobile-1" :style="back_img" style="z-index: 99">
              <div>
                  <div class="container header">
                      <div class="row">
                          <div class="col">
                              <home-header @newLogin="updateLogin" :login-drop="login"/>
                          </div>
                      </div>
                      <div class="row text-animation">
                          <div class="animation desktop-only">
                              <!--                            <img :src="anim_img" class="anim-img" alt="">-->
                              <!--                            <animation-lines class="anim-img"/>-->
                              <animation-photo class="anim-img" />
                          </div>
                          <div class="container animation-text" style="z-index: 10">
                              <div class="row">
                                  <div class="col-md-6">
                                      <div class="title w-90" :class="{'change-content-left': admin}">
                                          <span class="color">
                                              {{homeContent.first_block?.title1}}
                                          </span>
                                          {{homeContent.first_block?.title2}}

                                          <circle-button
                                              v-if="admin"
                                              class="change-content-btn"
                                              style="left: -20px"
                                              @click="changeMainTitle = true"
                                          >
                                              <change-icon style="min-width: 16px; min-height: 16px"/>
                                          </circle-button>

                                          <change-content-popup
                                              v-if="changeMainTitle"
                                              title="Первый блок"
                                              title-input1="Заголовок зеленый цвет"
                                              title-input2="Заголовок черный цвет"
                                              :value1="homeContent.first_block?.title1"
                                              @update:value1="homeContent.first_block.title1 = $event"
                                              :value2="homeContent.first_block?.title2"
                                              @update:value2="homeContent.first_block.title2 = $event"
                                              @save="updateHomeContent"
                                              @close-modal="changeMainTitle = !changeMainTitle"
                                          />
                                      </div>

                                      <div class="first-text"  style="margin-top: 20px">
                                          <div :class="{'change-content-left': admin}">
                                              {{homeContent.first_block?.annotation_title}}

                                              <circle-button
                                                  v-if="admin"
                                                  class="change-content-btn"
                                                  style="left: -20px"
                                                  @click="changeMainInstruction = true"
                                              >
                                                  <change-icon style="min-width: 16px; min-height: 16px"/>
                                              </circle-button>
                                          </div>

                                          <change-content-popup
                                              v-if="changeMainInstruction"
                                              title="Первый блок"
                                              title-input1="Аннотация под заголовком"
                                              :value1="homeContent.first_block?.annotation_title"
                                              @update:value1="homeContent.first_block.annotation_title = $event"
                                              @save="updateHomeContent"
                                              @close-modal="changeMainInstruction = !changeMainInstruction"
                                          />
                                      </div>
                                      <search-input :specialities="specialities"/>
                                  </div>
                                  <div class="col-md-1"></div>
                                  <div class="col-md-5 mar">

                                      <div class="text text-center-mobile text-gray-mobile head-text-desktop">
                                          <div :class="{'change-content-right': admin}">
                                              {{homeContent.first_block?.desc}}

                                              <circle-button
                                                  v-if="admin"
                                                  class="change-content-btn"
                                                  style="right: -20px"
                                                  @click="changeMainDescFirst = true"
                                              >
                                                  <change-icon style="min-width: 16px; min-height: 16px"/>
                                              </circle-button>
                                          </div>

                                          <change-content-popup
                                              v-if="changeMainDescFirst"
                                              title="Первый блок"
                                              title-input1="Описание"
                                              :value1="homeContent.first_block?.desc"
                                              @update:value1="homeContent.first_block.desc = $event"
                                              @save="updateHomeContent"
                                              @close-modal="changeMainDescFirst = !changeMainDescFirst"
                                          />
                                      </div>

                                      <div class="text-small desktop-only medical-consulting head-text-desktop">
                                          <div :class="{'change-content-right': admin}">
                                              {{homeContent.first_block?.text_under_desc}}

                                              <circle-button
                                                  v-if="admin"
                                                  class="change-content-btn"
                                                  style="right: -20px"
                                                  @click="changeMainDescSecond = true"
                                              >
                                                  <change-icon style="min-width: 16px; min-height: 16px"/>
                                              </circle-button>
                                          </div>

                                          <change-content-popup
                                              v-if="changeMainDescSecond"
                                              title="Первый блок"
                                              title-input1="Текст под описанием"
                                              :value1="homeContent.first_block?.text_under_desc"
                                              @update:value1="homeContent.first_block.text_under_desc = $event"
                                              @save="updateHomeContent"
                                              @close-modal="changeMainDescSecond = !changeMainDescSecond"
                                          />
                                      </div>

                                      <div class="head-text-laptop">
                                          <div :class="{'change-content-right': admin}">
                                              {{homeContent.first_block?.desc}}

                                              <circle-button
                                                  v-if="admin"
                                                  class="change-content-btn"
                                                  style="right: -20px"
                                                  @click="changeMainDescFirst = true"
                                              >
                                                  <change-icon style="min-width: 16px; min-height: 16px"/>
                                              </circle-button>
                                          </div>

                                          <change-content-popup
                                              v-if="changeMainDescFirst"
                                              title="Первый блок"
                                              title-input1="Описание"
                                              :value1="homeContent.first_block?.desc"
                                              @update:value1="homeContent.first_block.desc = $event"
                                              @save="updateHomeContent"
                                              @close-modal="changeMainDescFirst = !changeMainDescFirst"
                                          />
                                      </div>

                                      <div class="mar mobile-center head-text-button" v-if="!user?.id">
                                          <default-button
                                              color="#ffffff"
                                              class="mobile-btn"
                                              background="#02B061"
                                              @click="connect"
                                          >Присоединиться
                                          </default-button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="row mar-mobile-0 order-mobile-2 presentation-block">
              <div>
                  <div class="container">
                      <div class="row">
                          <div class="col-md-6 order-md-1 order-2">
                              <div class="video-div">
                                  <div class="play-parent">
                                      <img :src="play_img" alt="" />
                                  </div>
                              </div>
                              <!-- из-за нестинга и другого порядка элементов в мобилке пришлось дублировать -->
                              <div class="text text-center-mobile mar-mobile-26 mobile-only">
                                  <div :class="{'change-content-right': admin}">
                                      {{homeContent.first_block?.desc}}

                                      <circle-button
                                          v-if="admin"
                                          class="change-content-btn"
                                          style="right: -20px"
                                          @click="changeMainDescFirst = true"
                                      >
                                          <change-icon style="min-width: 16px; min-height: 16px"/>
                                      </circle-button>
                                  </div>

                                  <change-content-popup
                                      v-if="changeMainDescFirst"
                                      title="Первый блок"
                                      title-input1="Описание"
                                      :value1="homeContent.first_block?.desc"
                                      @update:value1="homeContent.first_block.desc = $event"
                                      @save="updateHomeContent"
                                      @close-modal="changeMainDescFirst = !changeMainDescFirst"
                                  />
                              </div>
                          </div>
                          <div class="col-md-6 order-md-2 order-1 pad-top-0-mobile presentation">
                              <div class="title-tow" :class="{'change-content-right': admin}">
                                  <span class="color">
                                      {{homeContent.second_block?.title1}}


                                  </span>

                                  {{homeContent.second_block?.title2}}

                                  <circle-button
                                      v-if="admin"
                                      class="change-content-btn"
                                      style="right: -20px"
                                      @click="changePresentation = true"
                                  >
                                      <change-icon style="min-width: 16px; min-height: 16px"/>
                                  </circle-button>

                                  <change-content-popup
                                      v-if="changePresentation"
                                      title="Второй блок"
                                      title-input1="Заголовок зеленый цвет"
                                      title-input2="Заголовок черный цвет"
                                      :value1="homeContent.second_block?.title1"
                                      @update:value1="homeContent.second_block.title1 = $event"
                                      :value2="homeContent.second_block?.title2"
                                      @update:value2="homeContent.second_block.title2 = $event"
                                      @save="updateHomeContent"
                                      @close-modal="changePresentation = !changePresentation"
                                  />
                              </div>
                              <div class="text desktop-only">
                                  <div :class="{'change-content-right': admin}">
                                      {{homeContent.second_block?.desc}}

                                      <circle-button
                                          v-if="admin"
                                          class="change-content-btn"
                                          style="right: -20px"
                                          @click="changePresentationDesc = true"
                                      >
                                          <change-icon style="min-width: 16px; min-height: 16px"/>
                                      </circle-button>
                                  </div>

                                  <change-content-popup
                                      v-if="changePresentationDesc"
                                      title="Второй блок"
                                      title-input1="Описание"
                                      :value1="homeContent.second_block?.desc"
                                      @update:value1="homeContent.second_block.desc = $event"
                                      @save="updateHomeContent"
                                      @close-modal="changePresentationDesc = !changePresentationDesc"
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mar-mobile-26 order-mobile-4 recently">
              <div>
                  <div class="container">
                      <div class="row">
                          <div class="col-md" style="text-align: center; margin-top: 32px">
                              <div class="title">
                                  <span class="color">Недавно</span>
                                  зарегистрировались
                              </div>
                          </div>
                          <div class="col-md" style="text-align: center; margin-top: 50px">
                              <div class="new-users">
                                  <router-link
                                      style="text-decoration: none"
                                      :to="{ name: 'doctor-page', params: { id: doc.id } }"
                                      v-for="doc in doctorsList"
                                      :key="doc.id"
                                  >
                                      <doctor-home-item :user="doc"/>
                                  </router-link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row order-mobile-3">
              <div>
                  <div class="container-fluid mar-mobile-57 doctors-connect">
                      <div class="row mar-fix-mobile reset-height-mobile phone-block-back" :style="info">
                          <div class="col">
                              <div class="container">
                                  <div class="row mar-mobile-32" style="margin-top: 50px">
                                      <div class="col-md-9 pad-top-0-mobile phone-block">
                                          <div class="title-tow" :class="{'change-content-left': admin}">
                                              <span class="color">
                                                  {{homeContent.fourth_block?.title1}}
                                              </span>
                                                  {{homeContent.fourth_block?.title2}}

                                              <circle-button
                                                  v-if="admin"
                                                  class="change-content-btn"
                                                  style="left: -20px"
                                                  @click="changeExplanation = true"
                                              >
                                                  <change-icon style="min-width: 16px; min-height: 16px"/>
                                              </circle-button>

                                              <change-content-popup
                                                  v-if="changeExplanation"
                                                  title="Четвертый блок"
                                                  title-input1="Заголовок зеленый цвет"
                                                  title-input2="Заголовок черный цвет"
                                                  :value1="homeContent.fourth_block?.title1"
                                                  @update:value1="homeContent.fourth_block.title1 = $event"
                                                  :value2="homeContent.fourth_block?.title2"
                                                  @update:value2="homeContent.fourth_block.title2 = $event"
                                                  @save="updateHomeContent"
                                                  @close-modal="changeExplanation = !changeExplanation"
                                              />
                                          </div>
                                          <div class="text list-container" :class="{'change-content-left': admin}">
                                              <div class="list-item">
                                                  <div style="display: flex; align-items: center; column-gap: 25px">
                                                      <div class="circle-white">
                                                          <img alt="" :src="iconUrl + homeContent.fourth_block?.first_benefit_icon">
                                                      </div>
                                                      <span>{{homeContent.fourth_block?.first_benefit}}</span>
                                                  </div>
                                              </div>
                                              <div class="list-item">
                                                  <div style="display: flex; align-items: center; column-gap: 25px">
                                                      <div class="circle-white">
                                                          <img alt="" :src="iconUrl + homeContent.fourth_block?.second_benefit_icon">
                                                      </div>
                                                      <span>{{homeContent.fourth_block?.second_benefit}}</span>
                                                  </div>
                                              </div>
                                              <div class="list-item">
                                                  <div style="display: flex; align-items: center; column-gap: 25px">
                                                      <div class="circle-white">
                                                          <img alt="" :src="iconUrl + homeContent.fourth_block?.third_benefit_icon">
                                                      </div>
                                                      <span>{{homeContent.fourth_block?.third_benefit}}</span>
                                                  </div>
                                              </div>

                                              <circle-button
                                                  v-if="admin"
                                                  class="change-content-btn"
                                                  style="left: -20px"
                                                  @click="changeExplanationItems = true"
                                              >
                                                  <change-icon style="min-width: 16px; min-height: 16px"/>
                                              </circle-button>

                                              <change-icons-content-popup v-if="changeExplanationItems" @close-modal="changeExplanationItems = false"/>
                                          </div>
                                      </div>
                                      <div class="col-md-3" style="position: relative; text-align: right">
                                          <img :src="info_phone" alt="" class="phone-img" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mar-mobile-57 order-mobile-5 connect-us" >
              <div>
                  <div class="container">
                      <div class="row" style="margin-top: 30px; text-align: center">
                          <div class="col center-mobile">
                              <div class="title" style="width: 100%" :class="{'change-content-right': admin}">
                                  <span class="color">
                                        {{homeContent.fifth_block?.title1}}
                                  </span>
                                    {{homeContent.fifth_block?.title2}}

                                  <circle-button
                                      v-if="admin"
                                      class="change-content-btn"
                                      style="right: -20px"
                                      @click="changeBottomTitle = true"
                                  >
                                      <change-icon style="min-width: 16px; min-height: 16px"/>
                                  </circle-button>

                                  <change-content-popup
                                      v-if="changeBottomTitle"
                                      title="Пятый блок"
                                      title-input1="Заголовок зеленый цвет"
                                      title-input2="Заголовок черный цвет"
                                      :value1="homeContent.fifth_block?.title1"
                                      @update:value1="homeContent.fifth_block.title1 = $event"
                                      :value2="homeContent.fifth_block?.title2"
                                      @update:value2="homeContent.fifth_block.title2 = $event"
                                      @save="updateHomeContent"
                                      @close-modal="changeBottomTitle = !changeBottomTitle"
                                  />
                              </div>
                              <div class="first-text mar-mobile-12 fix-text-mobile">

                                  <div :class="{'change-content-right': admin}">
                                      {{homeContent.fifth_block?.annotation}}

                                      <circle-button
                                          v-if="admin"
                                          class="change-content-btn"
                                          style="right: -20px"
                                          @click="changeBottomDesc = true"
                                      >
                                          <change-icon style="min-width: 16px; min-height: 16px"/>
                                      </circle-button>
                                  </div>

                                  <change-content-popup
                                      v-if="changeBottomDesc"
                                      title="Пятый блок"
                                      title-input1="Аннотация"
                                      :value1="homeContent.fifth_block?.annotation"
                                      @update:value1="homeContent.fifth_block.annotation = $event"
                                      @save="updateHomeContent"
                                      @close-modal="changeBottomDesc = !changeBottomDesc"
                                  />
                              </div>
                              <div style="margin-top: 30px">
                                  <default-button
                                      style="width: 174px; height: 56px"
                                      class="mobile-btn mobile-width-144"
                                      @click="connect"
                                  >Присоединиться
                                  </default-button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row desktop-only user-footer">
              <div class="col footer">
                  <user-footer/>
              </div>
          </div>
          <user-footer-mobile class="mobile-only user-footer-mobile order-mobile-6" />
      </div>
  </user-layout>
</template>

<script>
import UserFooter from '@/components/layout/UserFooter.vue'
import UserFooterMobile from '@/components/layout/UserFooterMobile.vue'
import config from '@/config/config'
import DoctorHomeItem from '@/components/doctor/DoctorHomeItem.vue'
import HomeHeader from '@/components/layout/HomeHeader.vue'
import AnimationPhoto from '@/components/animation/AnimationPhoto'
import DefaultButton from '@/components/helppers/DefaultButton'
import selectSpecialist from '@/mixins/selectSpecialist'
import SearchInput from "@/components/helppers/SearchInput";
import UserLayout from "@/components/layout/UserLayout.vue";
import ChangeContentPopup from "@/components/helppers/ChangeContentPopup.vue";
import ChangeIcon from "@/components/icons/ChangeIcon.vue";
import CircleButton from "@/components/helppers/CircleButton.vue";
import ChangeIconsContentPopup from "@/components/helppers/ChangeIconsContentPopup.vue";
// import '@/assets/font/font.css'

export default {
  name: 'HomeView',
  components: {
      ChangeIconsContentPopup,
      CircleButton, ChangeIcon,
      ChangeContentPopup,
      UserLayout,
    DefaultButton,
    AnimationPhoto,
    HomeHeader,
    DoctorHomeItem,
    UserFooter,
    UserFooterMobile,
    SearchInput
  },
  data() {
    return {
      step: 1,
      search: '',
      login: false,
      specName: '',
      special: '',
      drop: false,
      changeMainTitle: false,
      changeMainInstruction: false,
      changeMainDescFirst: false,
      changeMainDescSecond: false,
      changePresentation: false,
      changePresentationDesc: false,
      changeExplanation: false,
      changeExplanationItems: false,
      changeBottomTitle: false,
      changeBottomDesc: false,
    }
  },
  computed: {
    user() {
       return this.$store.getters.getUser
    },
    iconUrl() {
        return `${config.URL}/img/icons/`
    },
    admin() {
        return this.user.role_id === 1 || this.user.role_id === 2
    },
    homeContent() {
        return this.$store.getters.getHomeContent
    },
    back_img() {
      return `background-image: url(${config.URL}/img/back/home_back.png);`
    },
    play_img() {
      return `${config.URL}/img/back/play.png`
    },
    newUsers() {
      return `${config.URL}/img/back/new_users.png`
    },
    info_phone() {
      return `${config.URL}/img/back/info-phone.png`
    },
    info() {
      return `background-image: url(${config.URL}/img/back/info.png);background-position: center;`
    },
    anim_img() {
      return `${config.URL}/img/anim/step_${this.step}.png`
    },
    specialities() {
      return this.$store.getters.getAllSpecialities
    },
    doctorsList() {
      if (screen && screen.width && screen.width < 769) {
        return this.$store.getters.getAllDoctores.slice(0, 4)
      }
      return this.$store.getters.getAllDoctores
    }
  },
  mixins: [selectSpecialist],
  methods: {
    openSpecialistMenu() {
      if (!this.drop) {
        setTimeout(() => {
          document.addEventListener('click', this.closeSpecialistMenu)
        })
      } else {
        setTimeout(() => {
          document.removeEventListener('click', this.closeSpecialistMenu)
        }, 100)
      }
      this.drop = !this.drop
    },
    closeSpecialistMenu(e) {
      if (!e.target.closest('.drop-menu') && this.drop) {
        this.drop = !this.drop
        setTimeout(() => {
          document.removeEventListener('click', this.closeSpecialistMenu)
        }, 100)
      }
    },
    dropSpec(spec) {
      this.selectSpecialist(spec)
      setTimeout(() => {
        document.removeEventListener('click', this.closeSpecialistMenu)
      }, 100)
      this.drop = false
    },
    paint(step) {
      if (step == 7) {
        this.step = 1
        step = 1
      } else {
        this.step = step
      }
      let paint = this.paint
      setTimeout(function () {
        paint(step + 1)
      }, 1000)
    },
    async searchFunc() {
      if (this.specName == '') {
        await this.$store.dispatch('getAllDoctors', { page: 1, name: this.search })
        await this.$store.dispatch('getPageCount', { name: this.search })
        this.$router.push(`/category?name=${this.search}`)
      } else {
        await this.$store.dispatch('getAllDoctors', { page: 1, spec: this.special })
        await this.$store.dispatch('getPageCount', { spec_id: this.special })
        this.$router.push(`/category?spec=${this.special}`)
      }
    },
    connect() {
      this.$store.dispatch('changeConnectPopup', true)
    },
    updateLogin(newLogin) {
      this.login = newLogin
    },
    updateHomeContent() {
      this.$store.dispatch('updateHomeContent', this.homeContent)
    }
  },
  async mounted() {

    await this.$store.dispatch('getHomeContent')
    await this.$store.dispatch('getLastDoctors')

    let paint = this.paint
    setTimeout(function () {
      paint(1)
    }, 1000)
  }
}
</script>

<style scoped lang="scss">

.change-content-left {
    position: relative;
    border: 1px dashed #C0C4CD;
    border-radius: 10px;
    padding: 5px 5px 5px 25px;
}

.change-content-right {
    position: relative;
    border: 1px dashed #C0C4CD;
    border-radius: 10px;
    padding: 5px 25px 5px 5px;
}

.footer {
    padding: 0 160px !important;
}

.connect-us{
    margin-top: 163px
}
.doctors-connect{
    padding: 0;
    margin-top: 182px
}
.recently{
margin-top: 193px
}
.presentation-block{
    margin-top: 110px
}
.phone-block {
  padding-top: 74px;
}

.phone-block-back {
  margin-top: 30px;
  height: 703px;
  margin-left: -40px;
}

.medical-consulting {
  margin-top: 12px;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 82px;
}

.w-90 {
  width: 90%;
}

.presentation {
  max-width: 658px;
  margin-left: auto;
  padding-top: 60px;
}

.presentation .title-tow {
  margin-bottom: 23px;
}

.presentation .title-tow span {
  display: block;
}

.title-tow {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 68px;
  line-height: 80px;
  color: #373a43;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: #373a43;
}

.first-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #373a43;
}

.text-two {
  font-family: 'Raleway';
  font-size: 18px;
  margin-top: 30px !important;
}

.text-tree {
  width: 80%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  color: #373a43;
}


.color {
  color: #24ad6f;
}

.phone-img {
  position: absolute;
  left: -151px;
  top: 103px;
  height: 550px;
  min-height: 245px;
  width: auto;
}

.back {
  margin-top: 0 !important;
  min-height: 800px;
  height: 820px;
  background-position: bottom;
}

.header,
.animation-text {
  max-width: 1597px !important;
}

.header {
  margin-top: 17px;
}

.text-small {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 26px;
  color: #656873;
}

.mar {
  margin-top: 20px;
}

.video-div {
  max-width: 699px;
  height: 393px;
  line-height: 393px;
  background: #edeff3;
  border-radius: 21px;
}

.play-parent {
  width: 56px;
  height: 56px;
  margin: auto;
  border-radius: 50%;
}

.animation {
  width: 100%;
  position: absolute;
  right: 0;
  top: 9vh;
  text-align: right;
}

.anim-img {
  width: 69%;
}

.new-users {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  text-align: center;
}

.circle-white {
  background: white;
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-item {
  width: 100%;
  max-width: 670px;
  border: solid 1px #dbe0eb;
  border-radius: 20px;
  display: flex;
  padding: 25px 27px;
  gap: 26px;
  align-items: center;
}

.text-animation {
  margin: 140px auto 0;
}

.list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}

.head-text-button button {
  width: 174px;
  height: 56px;
  padding: 0;
}

.head-text-laptop {
  display: none;
}

@media screen and (max-width: 1750px) {
  .w-90 {
    width: unset;
  }

  .text-animation {
    max-width: 1450px !important;
    padding-right: 27px;
  }

  .anim-img {
    width: 1137px;
  }
}
@media screen and (max-width: 1690px) {
    .text-animation {
        margin: 110px auto 0;
    }
}

@media screen and (max-width: 1600px) {
  .title {
    font-size: 64px;
    line-height: 61px;
  }

  .text {
    font-size: 15px;
    line-height: 23px;
  }

  .text-small {
    font-size: 12px;
    line-height: 22px;
  }

  .first-text {
    font-size: 13px;
    line-height: 22px;
  }

  .animation {
    top: 9vh;
  }
}

@media screen and (max-width: 1570px) {
    .text-animation {
        padding-right: 120px;
    }
}

@media screen and (max-width: 1510px) {
  .text-animation {
    max-width: 1380px !important;
    margin: 95px auto 0;
  }

  .anim-img {
    width: 1017px;
  }
}

@media screen and (max-width: 1400px) {
  .phone-img {
    left: -280px;
  }
    .text-animation {
        padding-right: 70px;
    }

  .list-item {
    max-width: 451px;
  }

  .title {
    font-size: 59px;
  }

  .text {
    font-size: 12px;
  }

  .text-small {
    font-size: 10px;
  }

  .animation {
    top: 100px;
    right: 0;
  }

  .anim-img {
    width: 67%;
  }

  .first-text {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (max-width: 1360px) {
  .anim-img {
    width: 900px;
  }
}

@media screen and (max-width: 1330px) {
  .head-text-desktop {
    display: none;
  }

  .head-text-laptop {
    display: block;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #373a43;
  }

  .head-text-button {
    margin-top: 15px;
  }

  .head-text-button button {
    width: 136px;
    height: 36px;
  }

  .w-90.title {
    font-size: 38px;
    line-height: 45px;
    max-width: 367px;
  }

  .text-animation {
    margin: 126px auto 0;
  }
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 52px;
    line-height: 50px;
  }

  .text {
    font-size: 11px;
    line-height: 18px;
  }

  .text-small {
    font-size: 9px;
    line-height: 20px;
  }

  .anim-img {
    width: 70%;
  }

  .title-tow {
    font-size: 38px;
    line-height: 45px;
  }

  .video-div {
    max-width: 400px;
    height: 225px;
    line-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .presentation {
    padding-top: 26px;
  }

  .first-text {
    font-size: 12px;
    line-height: 22px;
  }
  .animation{
      padding-right: 57px;
  }

  .phone-block-back .container {
    max-width: 850px !important;
  }
}

@media screen and (max-width: 1024px) {
    .doctors-connect{
        margin-top: 102px;
    }
    .recently{
        margin-top: 96px
    }
    .connect-us{
        margin-top: 92px
    }
    .header-3{
        padding-left: 48px;
        padding-right: 36px;
    }
    .presentation-block{
        margin-top: 59px;
    }
  .back {
    min-height: unset;
    height: 510px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .phone-block {
    padding-top: 0;
  }

  .phone-block-back {
    height: 460px;
  }

  .new-users a {
    width: 140px;
    margin: auto;
  }

  .title {
    font-size: 44px;
    line-height: 42px;
  }

  .list-container {
    margin-top: 24px;
  }

  .list-item {
    padding: 12px 27px;
    gap: 17px;
  }

  .text {
    font-size: 13px;
    line-height: 18px;
  }

  .text-small {
    font-size: 8px;
    line-height: 10px;
  }

  .input-search {
    min-width: 201px;
    font-size: 9px;
    width: 70%;
    display: flex;
    margin: 0;
    padding: 12px 5px 10px 35px;
  }

  .text-animation {
    margin-top: 116px;
  }

  .phone-img {
    width: 422px;
    height: 356px;
    left: -149px;
    top: 54px;
  }

  .animation-text .first-text {
    line-height: 18px;
  }

    .animation-text .col-md-6{
        padding-left: 36px;
    }

    .first-text {
    font-size: 13px;
    line-height: 32px;
  }
}

@media screen and (max-width: 950px) {
  .text-animation {
    margin-top: 80px;
      padding-right: 100px;
  }
    .animation-text .col-md-6{
        padding: 0;
    }
    .header-3{
        padding-right: 12px;
        padding-left: 0;
        margin-right: -12px;
    }
}

@media screen and (max-width: 890px) {
  .phone-block-back .container {
    margin-left: 50px;
  }
    .animation{
        padding-right: 12px;
    }
    .text-animation{
        padding-right: 14px;
    }
    .text-animation .col-md-5{
        padding-right: 38px;
    }

  .phone-img {
    left: -200px;
  }
}

@media screen and (max-width: 858px) {


  .head-text-button,
  .head-text-laptop {
    margin-left: -50px;
  }

  .w-90.title {
    font-size: 30px;
    line-height: 35px;
    max-width: 317px;
  }
}

@media screen and (max-width: 775px) {
    .text-animation .col-md-5{
        padding: 0;
    }

    .drop-menu{
        width: 201px;
        top: 75px;
    }
    .animation{
        display: none;
    }
  .search-btn-mobile {
    width: 67px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .presentation {
    margin: auto;
  }

  .phone-block-back .container {
    margin-left: 0;
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    width: 279px;
    margin-left: auto;
    margin-right: auto;
  }

  .head-text-button,
  .head-text-laptop {
    margin-left: 0;
  }

  .head-text-laptop {
    text-align: center;
  }

  .head-text-button {
    margin-top: 21px;
  }

  .w-90.title {
    max-width: 100%;
  }

  .text-animation {
    padding-right: 0px !important;
      margin-top: 52px!important;
  }

  .list-container {
    margin-top: 25px;
    gap: 21px;
  }

  .list-item {
    max-width: unset;
    gap: 25px;
    padding: 13px 16px 12px 15px;
  }

  .play-parent {
    width: 34px;
    height: 34px;
    margin: auto;
    border-radius: 50%;
  }

  .play-parent img {
    width: 100%;
    height: 100%;
  }

  .title {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }

  .first-text {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }

  .text {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
  }

  .text-center-mobile {
    text-align: center;
  }

  .mobile-center {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .input-search::placeholder {
    font-size: 12px;
    line-height: 14px;
  }

  .title-tow {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 0;
  }

  .title-tow .color {
    display: block;
  }

  .video-div {
    min-width: 280px;
    min-height: 156px;
    width: calc(100vw / 1.5);
    height: calc(100vw / 2.5);
    margin: auto;
  }

  .mar-mobile-57 {
    margin-top: 57px !important;
  }

  .mar-mobile-26 {
    margin-top: 26px !important;
  }

  .mar-mobile-32 {
    margin-top: 32px !important;
  }

  .mar-mobile-12 {
    margin-top: 12px !important;
  }

  .mar-mobile-0 {
    margin-top: 0px !important;
  }

  .back {
    height: 650px !important;
    min-height: 640px !important;
    background-position-y: 75%;
  }

  .search-icon {
    top: 0px;
    bottom: 0px;
    left: 15px;
    margin: auto 0;
    height: 13px;
    width: 13px;
  }

  .down-icon {
    top: 0px;
    bottom: 0px;
    margin: auto 0;
    right: 87px;
    left: unset;
  }

  .mobile-btn {
    height: 40px !important;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .w-90.title {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
  }

  .pad-top-0-mobile {
    padding-top: 0px !important;
  }

  .pad-top-38-mobile {
    padding-top: 38px !important;
  }

  .pad-bottom-24-mobile {
    padding-bottom: 24px !important;
  }

  .video-div {
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reset-height-mobile {
    height: fit-content !important;
  }

  .phone-img {
    position: static !important;
    width: 100% !important;
    height: auto !important;
  }

  .adjust-back-mobile {
    background-size: cover !important;
  }

  .fix-text-mobile {
    max-width: 280px !important;
  }

  .center-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-width-144 {
    width: 144px !important;
  }

  .text-tree {
    width: 100%;
    text-align: center;
  }

  .mar-fix-mobile {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .title {
    width: 100%;
    text-align: center;
  }

  .new-users {
    display: grid;
    grid-template-columns: auto auto;
    text-align: center;
    place-items: center;
    gap: 20px;
  }

  .text-gray-mobile {
    color: #656873;
  }

  .circle-white {
    width: 44px;
    height: 44px;
    min-width: 44px;
  }

  .container-flex-mobile {
    display: flex;
    flex-direction: column;
  }

  .order-mobile-1 {
    order: 1;
  }

  .order-mobile-2 {
    order: 2;
  }

  .order-mobile-3 {
    order: 3;
  }

  .order-mobile-4 {
    order: 4;
  }

  .order-mobile-5 {
    order: 5;
  }

  .order-mobile-6 {
    order: 6;
  }
}

.change-content-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (max-width: 792px) {
  .user-footer {
    display: none;
  }

  .user-footer-mobile {
    display: flex !important;
  }
}
</style>

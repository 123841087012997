<template>
  <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18.7974" cy="18" r="18" fill="#FC3F1D" />
    <path
      d="M20.9229 13.5261H19.8728C17.9477 13.5261 16.9351 14.5012 16.9351 15.9388C16.9351 17.5639 17.6351 18.3253 19.0728 19.3016L20.2604 20.1016L16.8476 25.2009H14.2974L17.3601 20.6392C15.5986 19.3766 14.6099 18.1503 14.6099 16.0763C14.6099 13.4761 16.4225 11.7009 19.8603 11.7009H23.2731V25.1884H20.9229V13.5261Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'YandexIcon'
}
</script>

<style scoped></style>

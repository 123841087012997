<template>
  <svg width="18" height="18" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4073 7.96905L8.31784 14L2.22843 7.96905M2.22843 7.96905C1.82678 7.5782 1.51041 7.10843 1.29923 6.5893C1.08805 6.07018 0.98665 5.51295 1.00141 4.95271C1.01617 4.39247 1.14676 3.84135 1.38498 3.33407C1.62319 2.82678 1.96386 2.37431 2.38553 2.00515C2.8072 1.63599 3.30074 1.35814 3.83507 1.18909C4.3694 1.02004 4.93295 0.963456 5.49022 1.0229C6.04749 1.08235 6.58642 1.25654 7.07307 1.5345C7.55971 1.81246 7.98353 2.18817 8.31784 2.63798C8.6536 2.19144 9.07791 1.81901 9.56422 1.544C10.0505 1.26899 10.5884 1.09731 11.1441 1.03973C11.6998 0.982136 12.2614 1.03987 12.7938 1.20931C13.3262 1.37876 13.8178 1.65626 14.238 2.02446C14.6582 2.39266 14.9979 2.84362 15.2358 3.34913C15.4736 3.85464 15.6046 4.40381 15.6205 4.96227C15.6364 5.52073 15.5368 6.07646 15.3281 6.59467C15.1193 7.11289 14.8058 7.58243 14.4073 7.97392"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#02B061'
    }
  },

  name: 'HeartIcon'
}
</script>

<style scoped></style>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.51111 2.17067C7.88978 0.609778 10.1102 0.609778 10.4889 2.17067C10.5457 2.40515 10.6571 2.62291 10.814 2.80622C10.9708 2.98953 11.1688 3.1332 11.3917 3.22555C11.6146 3.3179 11.8561 3.35631 12.0967 3.33766C12.3372 3.31901 12.57 3.24382 12.776 3.11822C14.1476 2.28267 15.7182 3.85244 14.8827 5.22489C14.7572 5.4308 14.6822 5.66341 14.6636 5.9038C14.645 6.14419 14.6833 6.38557 14.7756 6.60834C14.8678 6.8311 15.0113 7.02896 15.1944 7.18582C15.3775 7.34269 15.5951 7.45414 15.8293 7.51111C17.3902 7.88978 17.3902 10.1102 15.8293 10.4889C15.5948 10.5457 15.3771 10.6571 15.1938 10.814C15.0105 10.9708 14.8668 11.1688 14.7744 11.3917C14.6821 11.6146 14.6437 11.8561 14.6623 12.0967C14.681 12.3372 14.7562 12.57 14.8818 12.776C15.7173 14.1476 14.1476 15.7182 12.7751 14.8827C12.5692 14.7572 12.3366 14.6822 12.0962 14.6636C11.8558 14.645 11.6144 14.6833 11.3917 14.7756C11.1689 14.8678 10.971 15.0113 10.8142 15.1944C10.6573 15.3775 10.5459 15.5951 10.4889 15.8293C10.1102 17.3902 7.88978 17.3902 7.51111 15.8293C7.4543 15.5948 7.34291 15.3771 7.18604 15.1938C7.02916 15.0105 6.83121 14.8668 6.60831 14.7744C6.38541 14.6821 6.14385 14.6437 5.9033 14.6623C5.66275 14.681 5.43 14.7562 5.224 14.8818C3.85244 15.7173 2.28178 14.1476 3.11733 12.7751C3.24276 12.5692 3.31782 12.3366 3.33643 12.0962C3.35504 11.8558 3.31666 11.6144 3.22442 11.3917C3.13218 11.1689 2.98868 10.971 2.80558 10.8142C2.62248 10.6573 2.40495 10.5459 2.17067 10.4889C0.609778 10.1102 0.609778 7.88978 2.17067 7.51111C2.40515 7.4543 2.62291 7.34291 2.80622 7.18604C2.98953 7.02916 3.1332 6.83121 3.22555 6.60831C3.3179 6.38541 3.35631 6.14385 3.33766 5.9033C3.31901 5.66275 3.24382 5.43 3.11822 5.224C2.28267 3.85244 3.85244 2.28178 5.22489 3.11733C6.11378 3.65778 7.26578 3.17956 7.51111 2.17067Z"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.00016 11.6668C10.4729 11.6668 11.6668 10.4729 11.6668 9.00016C11.6668 7.5274 10.4729 6.3335 9.00016 6.3335C7.5274 6.3335 6.3335 7.5274 6.3335 9.00016C6.3335 10.4729 7.5274 11.6668 9.00016 11.6668Z"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#02B061'
    }
  },

  name: 'SettingsIcon'
}
</script>

<style scoped></style>

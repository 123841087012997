<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33225 6.77778C6.92774 6.77778 8.22114 5.48438 8.22114 3.88889C8.22114 2.2934 6.92774 1 5.33225 1C3.73676 1 2.44336 2.2934 2.44336 3.88889C2.44336 5.48438 3.73676 6.77778 5.33225 6.77778Z" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 14.0003V12.5559C1 11.7897 1.30436 11.0549 1.84614 10.5131C2.38791 9.97136 3.12271 9.66699 3.88889 9.66699H6.77778C7.54396 9.66699 8.27876 9.97136 8.82054 10.5131C9.36231 11.0549 9.66667 11.7897 9.66667 12.5559V14.0003" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.3867 1.09473C11.0081 1.25383 11.5589 1.61523 11.9522 2.12195C12.3456 2.62867 12.5591 3.25188 12.5591 3.89334C12.5591 4.5348 12.3456 5.15801 11.9522 5.66473C11.5589 6.17145 11.0081 6.53285 10.3867 6.69195" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.0007 13.9994V12.555C13.997 11.9174 13.7825 11.2989 13.3906 10.7959C12.9987 10.293 12.4514 9.93381 11.834 9.77441" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMobilePepolr"
}
</script>

<style scoped>

</style>

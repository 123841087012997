<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('changeVerified', { show: false })"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">
        {{ title }}
      </div>
      <div class="text">
        {{ text }}
      </div>
      <div class="first" v-if="!images.length && !finish">
        <label for="file">
          <div class="icon">
            <font-awesome-icon icon="plus" color="#A4ACBD"></font-awesome-icon>
          </div>
          <div class="icon-text">Прикрепить документы</div>
        </label>
      </div>

      <div class="grid" v-else-if="!finish">
        <div class="grid-item" v-for="img in images" :key="img">
          <img :src="img" class="img" alt="" />
        </div>
        <div class="grid-item newImages">
          <label for="file">
            <div class="icon">
              <font-awesome-icon icon="plus" color="#A4ACBD"></font-awesome-icon>
            </div>
          </label>
        </div>
      </div>
      <div class="button" v-if="images.length && !finish">
        <button-helper color="#ffffff" background="#02B061" @click="setDocs"
          >Отправить на проверку</button-helper
        >
      </div>
      <input type="file" @change="upload" hidden id="file" multiple />
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'

export default {
  name: 'VerifidePopup',
  components: { ButtonHelper },
  data() {
    return {
      images: [],
      files: [],
      finish: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    title() {
      return this.finish ? 'Ваши документы  отправлены на проверку' : ' Верифицировать анкету'
    },
    text() {
      return this.finish
        ? 'И мы очень скоро посмотрим их'
        : ' Загрузите свои документы об образовании и мы в скором времени проверим их'
    }
  },

  methods: {
    upload(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        let src = URL.createObjectURL(e.target.files[i])
        this.images.push(src)
        this.files.push(e.target.files[i])
      }
    },
    async setDocs() {
      let formData = new FormData()
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i]

        formData.append('files[' + i + ']', file)
      }
        formData.append('doc_id', this.user.id)

        await this.$store.dispatch('setDocs', formData);
        this.finish = true
        this.$store.dispatch('getDoctorDetalies', this.user.id);

    }
  }
}
</script>

<style scoped lang="scss">
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  max-width: 483px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #373a43;
  margin-top: 40px;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #656873;
  margin: 7px auto;
}

.text-center {
  width: 288px;
}
.first {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.icon {
  text-align: center;
  margin: auto;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: #edeff3;
  border-radius: 50%;
}
.icon-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #656873;
  margin-top: 13px;
}
.grid {
  margin-top: 40px;
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto;
  gap: 30px;

    @media screen and (max-width: 400px) {
        gap: 15px;
    }
}
.grid-item {
  width: 150px;
  height: 150px;
  border-radius: 10px;

    @media screen and (max-width: 400px) {
        width: 120px;
        height: 120px;
    }
}
.img {
  width: 150px;
  height: 150px;

    @media screen and (max-width: 400px) {
        width: 120px;
        height: 120px;
    }
}

.newImages {
  border: 1px dashed #ccd1db;
  line-height: 150px;
  text-align: center;

    @media screen and (max-width: 400px) {
        line-height: 120px;
    }
}

.button {
  margin-top: 45px;
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 425px) {

    .title {
        margin-top: 43px;
    }

}
</style>

<template>
  <div class="content">
    <div class="image">
      <img class="img" :class="{'no-img': user.user_detales.image === 'no_doctor_photo.svg'}" :src="img" alt=""/>
      <div class="heart">
        <!--      <font-awesome-icon icon="fa-sharp fa-regular fa-heart"   />-->
        <font-awesome-icon icon="fa-regular fa-heart" color="#02B061" />
      </div>
    </div>
    <div class="name">
      {{ user.full_name }}
    </div>
  </div>
</template>

<script>
import config from '@/config/config'
export default {
  name: 'DoctorHomeItem',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    img() {
      return `${config.URL}/img/doctors/${this.user.user_detales.image}`
    }
  }
}
</script>

<style scoped>
.content {
  width: 194px;
  text-align: center;
  margin: auto;
}

.image {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #ecedee;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 20px;
}
.img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.no-img {
    width: 14px !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.name {
  font-family: 'Raleway',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #373a43;
}
.heart {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 35px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  bottom: -8px;
}

.heart svg {
  width: 13px;
  height: 12px;
}

@media screen and (max-width: 1200px) {
  .content,
  .name {
    width: 140px;
    margin: auto;
  }
}

@media screen and (max-width: 760px) {
  .content {
    width: auto;
  }
  .image {
    width: 80px;
    height: 80px;
  }
  .img {
    width: 80px;
    height: 80px;
  }
  .heart {
    width: 24px;
    height: 24px;
    right: 6px;
    bottom: -5px;
  }
}

@media screen and (max-width: 330px) {
  .image {
    width: 60px;
    height: 60px;
  }
  .img {
    width: 60px;
    height: 60px;
  }
  .heart {
    width: 20px;
    height: 20px;
    right: 6px;
    bottom: -5px;
  }
}
</style>

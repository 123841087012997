<template>
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.9988 7.99789V15.9955C4.9988 16.2606 4.89347 16.5149 4.70599 16.7024C4.51851 16.8899 4.26423 16.9952 3.9991 16.9952H1.9997C1.73456 16.9952 1.48028 16.8899 1.29281 16.7024C1.10533 16.5149 1 16.2606 1 15.9955V8.99759C1 8.73246 1.10533 8.47818 1.29281 8.2907C1.48028 8.10322 1.73456 7.99789 1.9997 7.99789H4.9988ZM4.9988 7.99789C6.05934 7.99789 7.07645 7.57659 7.82637 6.82667C8.57629 6.07675 8.99759 5.05964 8.99759 3.9991V2.9994C8.99759 2.46912 9.20824 1.96057 9.5832 1.58561C9.95816 1.21065 10.4667 1 10.997 1C11.5273 1 12.0358 1.21065 12.4108 1.58561C12.7857 1.96057 12.9964 2.46912 12.9964 2.9994V7.99789H15.9955C16.5258 7.99789 17.0343 8.20854 17.4093 8.5835C17.7842 8.95846 17.9949 9.46702 17.9949 9.99729L16.9952 14.9958C16.8514 15.6091 16.5787 16.1357 16.2181 16.4963C15.8575 16.8569 15.4285 17.032 14.9958 16.9952H7.99789C7.20248 16.9952 6.43965 16.6792 5.87721 16.1168C5.31477 15.5543 4.9988 14.7915 4.9988 13.9961" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMenuThumbActiv"
}
</script>

<style scoped>

</style>

<template>
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3333 1H2.55556C1.69645 1 1 1.69645 1 2.55556V13.4444C1 14.3036 1.69645 15 2.55556 15H10.3333C11.1924 15 11.8889 14.3036 11.8889 13.4444V2.55556C11.8889 1.69645 11.1924 1 10.3333 1Z" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.10938 4.11035H8.77604" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.10938 7.21973H8.77604" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.10938 10.335H7.22049" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMobileVerified"
}
</script>

<style scoped>

</style>

<template>
  <svg width="18" height="18" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="0.6"
      y1="-0.6"
      x2="4.49117"
      y2="-0.6"
      transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 8 7.5)"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
    />
    <line
      x1="8.84853"
      y1="1.5"
      x2="11.6"
      y2="4.25147"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
    />
    <path
      d="M11.5104 14H5.51045C0.00588179 14 -0.991712 4.5 5.51043 4.5H11.5104"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#02B061'
    }
  },

  name: 'RotatedArrowIcon'
}
</script>

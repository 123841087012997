<template>
    <svg width="13" height="11" viewBox="0 0 13 11" fill="#A4ACBD" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.893682 4.73547C4.38333 3.18313 6.71032 2.15973 7.87463 1.66527C11.199 0.253502 11.8897 0.00826281 12.34 7.85078e-05C12.439 -0.00161621 12.6604 0.0234407 12.8038 0.142263C12.9249 0.242595 12.9583 0.378129 12.9742 0.473254C12.9901 0.56838 13.01 0.785077 12.9942 0.954399C12.8141 2.88701 12.0346 7.57694 11.638 9.74149C11.4702 10.6574 11.1398 10.9645 10.8199 10.9945C10.1248 11.0599 9.59692 10.5255 8.92363 10.0749C7.87007 9.36973 7.27487 8.93077 6.25221 8.24269C5.07034 7.44748 5.8365 7.01043 6.51004 6.29615C6.68631 6.10923 9.74916 3.26477 9.80844 3.00673C9.81586 2.97446 9.82274 2.85417 9.75274 2.79065C9.68275 2.72713 9.57945 2.74885 9.5049 2.76612C9.39923 2.79061 7.71615 3.92645 4.45566 6.17363C3.97792 6.50858 3.5452 6.67177 3.1575 6.66322C2.73009 6.65379 1.90793 6.41648 1.29673 6.21363C0.547079 5.96482 -0.0487324 5.83328 0.00314937 5.41073C0.0301726 5.19064 0.327017 4.96555 0.893682 4.73547Z"/>
    </svg>
</template>
<script>
export default {
    name: 'TelegramFooterIcon'
}
</script>

<style>

</style>

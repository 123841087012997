<template>
    <div class="change-content-dashboard">
        <div class="change-content-dashboard__return" @click="goBack">
            <arrow-left/>
            <div style="border-bottom: 1px solid">
                Вернуться назад
            </div>
        </div>
        <button-helper
            class="change-content-dashboard__save"
            color="#ffffff"
            background="#02B061"
            @click="saveContent"
        >
            <check-button-docs color="#ffffff" style="margin-right: 10px"/>
            Сохранить
        </button-helper>
    </div>
</template>

<script>
import CheckButtonDocs from "@/components/icons/CheckButtonDocs.vue";
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";
import ArrowLeft from "@/components/icons/ArrowLeft.vue";

export default {
  name: 'TopChangeContent',

  components: {ArrowLeft, ButtonHelper, CheckButtonDocs},

  emits: ['saveContent'],

  props: {
     type: {
         type: String,
         default: 'dashboard'
     }
  },

  methods: {
      saveContent() {
          this.$emit('saveContent')
          this.goBack()
      },
      goBack() {
          this.type === 'dashboard' ? this.$store.dispatch('getDoctorDashboardContent') : this.$store.dispatch('getDoctorInviteContent')
          this.$router.back();
      }
  },
}
</script>

<style scoped lang="scss">

.change-content-dashboard {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 50px;

    &__return {
        cursor: pointer;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        column-gap: 15px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #9195A4;
    }
}

</style>

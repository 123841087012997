<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
            icon="close"
            @click="this.$store.dispatch('editRecommendationDoctor', false)"
            color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Редактировать связь</div>
      <div class="inputes">
        <input type="text" class="input" :value="doctor.full_name" disabled="true">
        <circle-check-icon class="check-icon"/>
        <img :src="`/img/doctors/${doctor.user_detales.image}`" class="img" alt="doctor">
      </div>
      <div class="text">
        <span v-if="recommendationType == 1">Рекомендует</span>
        <span v-if="recommendationType == 2">Его рекомендует</span>
      </div>
      <div class="inputes" style="margin-top: 18px">
        <input type="text" @click="show=true" class="input" placeholder="ФИО" v-model="search">
        <circle-check-icon v-if="name" class="check-icon"/>
        <img :src="`/img/doctors/${recommendationUser.user_detales.image}`" v-if="recommendationUser.id" class="img" alt="recommendation">
        <div class="users" v-if="show && doctors.length">
          <div class="doctor-item" @click="checkRecommendation(doctor)" v-for="doctor in doctors"
               :key="doctor.id">
            <img :src="`/img/doctors/${doctor.user_detales.image}`" class="img-2" alt="">
            {{ doctor.full_name }}
          </div>
        </div>
      </div>
      <div class="buttons">
        <button-helper style="margin-left: 20px;" color="#ffffff" @click="saveRecommendation" background="#02B061">
          Сохранить
        </button-helper>
        <button-helper style="margin-left: 20px;"  color="#02B061" @click="deleteRecommendation" background="#ffff">
          Удалить связь
        </button-helper>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import CircleCheckIcon from "@/components/icons/CircleCheckIcon.vue";


export default {
  name: "EditRecommendatioinPopup",
  components: {
    CircleCheckIcon,
    ButtonHelper
  },
  data() {
    return {
      name:    "",
      user:    {},
      show:    false,
      checked: false,
      search: '',
      doctors : [],
      doctorId: null,
      recommendationType: 0,
    }
  },
  computed: {
    doctor() {
      return this.$store.getters.getDoctorDetalies
    },
    recommendationUser() {
      return this.$store.getters.getEditRecommendationInfo;
    }
  },
  methods:  {
    checkRecommendation(doctor){
      this.show = false;
      this.search = doctor.full_name
      this.doctorId = doctor.id
    },
    async saveRecommendation(){
      if(this.recommendationType == 1){
        let params = {
          doctor_id: this.doctor.id,
          before_recommendation_id: this.recommendationUser.id,
          after_recommendation_id: this.doctorId,
          type: this.recommendationType
        }
        await this.$store.dispatch('changeRecommendationDoctor', params);
      }else{
        let params = {
          doctor_id: this.recommendationUser.id,
          before_recommendation_id: this.doctor.id,
          after_recommendation_id: this.doctorId,
          type: this.recommendationType
        }
        await this.$store.dispatch('changeRecommendationDoctor', params);
      }
      
      await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
      this.$store.dispatch('closePopUp')
    },
    async deleteRecommendation(){
      if(this.recommendationType == 1){
        let params = {
          doctor_id: this.doctor.id,
          recommendation_id: this.recommendationUser.id
        }
        await this.$store.dispatch('deleteRecommendationDoctor', params);
      }else{
        let params = {
          doctor_id: this.recommendationUser.id,
          recommendation_id: this.doctor.id
        }
        await this.$store.dispatch('deleteRecommendationDoctor', params);
      }
      await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
      this.$store.dispatch('closePopUp')
    }
  },
  watch: {
    search() {
      this.$axios.get(`/api/get-doctors-filter?name=${this.search}`).then((response) => {
        this.doctors = response.data
      })
    }
  },
  mounted(){
    this.search = this.recommendationUser.full_name
    this.doctorId = this.recommendationUser.id
    this.recommendationType = this.$store.getters.getEditRecommendationType;
  }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 505px;
  height: 438px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 53px;
}
.inputes {
  margin-top: 38px;
  text-align: center;
  position: relative;
}
.check-icon {
  position: absolute;
  top: 15px;
  right: 75px;
}
.input {
  width: 385px;
  height: 46px;
  background: #EAF2EF;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373A43;
  border: none;
  padding-left: 51px;
}
.buttons {
  margin: 48px auto;
  text-align: center;
}

.img {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 8px;
  left: 70px;
}

.img-2 {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #373A43;
  text-align: center;
  margin-top: 39px;
}

.users {
  position: absolute;
  overflow: auto;
  width: 385px;
  height: 300px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  left: 55px;
  top: 60px;
  text-align: center;
}
.users::-webkit-scrollbar {
  width: 5px;
}

.users::-webkit-scrollbar-track {
  background: #CBD0D9;
}
.users::-webkit-scrollbar-thumb {
  background-color: #D6D9E0;
  border-radius: 20px;
  border: 5px solid #D6D9E0;
}
.doctor-item {
  width: 350px;
  height: 40px;
  border: 1px solid #EAF2EF;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #373A43;
  text-align: center;
  margin: 10px auto;
  line-height: 40px;
  cursor: pointer;
}

.text-cencel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #373A43;
  text-align: center;
  float: right;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (max-width: 792px) {
    .form {
        width: 100%;
    }
    .input{
        width: 90%;
    }
    .img{
        left:7%
    }
    .buttons{
        width: 90%;
    }
    .users {
        left: 5%;
        width: 90%;

    }

}
</style>

<template>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.61111 14.2222C11.2623 14.2222 14.2222 11.2623 14.2222 7.61111C14.2222 3.95989 11.2623 1 7.61111 1C3.95989 1 1 3.95989 1 7.61111C1 11.2623 3.95989 14.2222 7.61111 14.2222Z"
            :stroke="color"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M18 18L12.3333 12.3334"
            :stroke="color"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'HeaderSearchIcon',

    props: {
        color: {
            type: String,
            required: false,
            default: '#A4ACBD'
        }
    },
}
</script>

<style scoped></style>

<template>
    <div class="auth-login-bg" @click.self="closePopup">
        <div class="auth-login">
            <div class="auth-close-parent">
                <font-awesome-icon
                    icon="close"
                    @click="closePopup"
                    color="#A4ACBD"
                />
            </div>
            <div class="auth-choose choose-underline">
                <div class="auth-name">Пациент</div>
                <div class="auth-description">Осуществляйте поиск среди рекомендованных врачей</div>
                <div class="auth-log">
                    <default-button
                        :outline="true"
                        color="#24AD6F"
                        @click="openPopUp('user')"
                        background="#ffffff"
                    >Войти</default-button
                    >
                </div>
            </div>
            <div class="auth-choose">
                <div class="auth-name">Врач</div>
                <div class="auth-description">
                    Создайте свою анкету, расскажите о себе и своих услугах, делитесь рекомендациями о
                    других врачах
                </div>
                <div class="auth-log">
                    <default-button
                        :outline="true"
                        @click="openPopUp('doctor')"
                        color="#24AD6F"
                        background="#ffffff"
                    >Войти</default-button
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultButton from "@/components/helppers/DefaultButton.vue";

export default {

    name: 'ChooseLoginPopup',

    components: {DefaultButton},

    methods: {
        openPopUp(type) {
            this.$store.dispatch('changeLoginPopup', true)
            this.$store.dispatch('changeRegisterType', type)
            this.closePopup()
        },
        closePopup() {
            this.$emit('closePopup')
        }
    }
}
</script>

<style scoped lang="scss">
.auth-login-bg {
    position: absolute;
    left: -251px;
    top: 50px;
    z-index: 99999;

    @media screen and (max-width: 769px) {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(237, 239, 243, 0.9)
    }
}

.auth-login {
    width: 405px;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    padding: 20px;

    @media screen and (max-width: 769px) {
        position: absolute;
        bottom: 20px;
        width: 100%;
    }
}

.auth-close-parent {
    text-align: right;
    position: absolute;
    right: 14px;
    top: 15px;
    cursor: pointer;
}

.auth-choose {
    text-align: left;
    padding: 20px;
}

.choose-underline {
    padding-top: 0 !important;
    border-bottom: 1px solid #dbe0eb;
}

.auth-description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #656873;
    margin-top: 5px;
}

.auth-description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #656873;
    margin-top: 5px;
}

.auth-log {
    margin-top: 15px;
}

</style>

<template>
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 4.27797L9.77797 0L11 1.22203L6.72203 5.5L11 9.77797L9.77797 11L5.5 6.72203L1.22203 11L0 9.77797L4.27797 5.5L0 1.22203L1.22203 0L5.5 4.27797Z"
      fill="#C0C4CD"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon'
}
</script>

<style scoped></style>

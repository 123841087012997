export default {
  state: {
    category: {},
    categories: [],
    spelaity: {},
    specialities: [],
    onlySpecialities: []
  },

  getters: {
    getCategory(state) {
      return state.category
    },
    getAllCategories(state) {
      return state.categories
    },

    getSpelaity(state) {
      return state.spelaity
    },

    getAllSpecialities(state) {
      return state.specialities
    },

    getOnlySpecialities(state) {
      return state.onlySpecialities
    }
  },

  mutations: {
    setCategory(state, category) {
      state.category = category
    },

    setSpelaity(state, spelaity) {
      state.spelaity = spelaity
    },

    setCatefories(state, categories) {
      state.categories = categories
    },

    setSpecialities(state, specialities) {
      state.specialities = specialities
    },

    setOnlySpecialities(state, specialities) {
      state.onlySpecialities = specialities
    }
  },

  actions: {
    async getSpecialities({ commit }) {
      await this.$axios.get(`/api/specialities`).then((response) => {
        commit('setSpecialities', response.data[0])
      })
    },
    async getOnlySpecialities({ commit }) {
      await this.$axios.get(`/api/onlySpecialities`).then((response) => {
        commit('setOnlySpecialities', response.data[0])
      })
    },
  }
}

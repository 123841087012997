<template>
    <svg width="18" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.3329 8.11111C8.29658 8.11111 9.88845 6.51923 9.88845 4.55556C9.88845 2.59188 8.29658 1 6.3329 1C4.36922 1 2.77734 2.59188 2.77734 4.55556C2.77734 6.51923 4.36922 8.11111 6.3329 8.11111Z"
            :stroke="color" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M1 16.9998V15.2221C1 14.2791 1.3746 13.3747 2.0414 12.7079C2.70819 12.0411 3.61256 11.6665 4.55556 11.6665H8.11111C9.0541 11.6665 9.95847 12.0411 10.6253 12.7079C11.2921 13.3747 11.6667 14.2791 11.6667 15.2221V16.9998"
            :stroke="color" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {

    props: {
        color: {
            type: String,
            required: false,
            default: '#02B061'
        }
    },

    name: 'HumanIcon'
}
</script>

<style scoped></style>

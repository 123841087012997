<template>
    <user-layout>
        <div class="not-found">
            <h1 class="not-found__title">
                Данная страница не найдена
            </h1>
            <div class="not-found__desc">
                Возможно, вы неправильно ввели адрес или страница была удалена
            </div>
            <div class="not-found__link">
                <router-link :to="{name: 'home'}">
                    Перейти на главную
                </router-link>
            </div>
        </div>
    </user-layout>
</template>

<script>
import UserLayout from "@/components/layout/UserLayout.vue";

export default {
    name: 'NotFound',
    components: {UserLayout}
}

</script>

<style scoped lang="scss">

.not-found {
    margin: 200px auto 100px auto;
    text-align: center;

    &__title {
        font-family: 'Raleway';
        font-weight: 400;
        font-size: 30px;
        line-height: 35px;
        color: #373A43;
    }

    &__desc {
        margin-top: 10px;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #656873;
    }

    &__link {
        margin-top: 60px;

        >a {
            color: #24AD6F;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
            color: #24AD6F;
        }
    }
}

</style>

<template>
    <div class="admin-history">

        <div class="doctor-item" v-for="history in histories" :key="history.id">
            <div class="doctor-item-top">
                <div class="doctor-item-date">
                    {{ getVerifiedDate(history.updated_at) }}
                </div>
                <div class="doctor-item-title">
                    <span v-if="history.type == 1">Вы удалили анкету</span>
                    <span v-if="history.type == 2">Вы восстановили анкету</span>
                    <span v-if="history.type == 3">Вы добавили новую анкету</span>
                </div>
            </div>

            <div class="doctor-item-content">
                <div class="doctor-item-main">
                    <div class="doctor-item-img-bg">
                        <img :src="`/img/doctors/${history.doctor.user_detales.image}`" class="doctor-item-img no-img"/>
                    </div>
                    <div class="doctor-item-text">
                        <div class="doctor-item-name">{{ history.doctor.full_name }}</div>
                        <span class="doctor-item-spec">
                            <span v-for="special in history.doctor.specialities" :key="special.id">{{ special.specie_name }}, </span>
                        </span>
                    </div>
                </div>

                <div class="doctor-item-button">
                    <span v-if="history.type == 1" @click="restoreDoctor(history.doctor)">Восстановить</span>
                    <span v-if="history.type == 3 || history.type == 2" @click="deleteDoctor(history.doctor)">Удалить</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import config from '@/config/config'

export default {
  name: 'AdminHistory',
    components: {

    },
  computed: {
    main_url() {
      return config.URL
    },
    histories() {
        return this.$store.getters.getHistories;
    }
  },
  methods: {
    getVerifiedDate(verifiedDate) {
        return new Date(verifiedDate).toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        })
    },
    restoreDoctor(doctor){
        this.$store.dispatch('restoreDoctor', doctor.id);
    },
    deleteDoctor(doctor){
        this.$store.dispatch('deleteDoctor', doctor.id);
    }
  },
  mounted(){
    this.$store.dispatch('getHistories');
  }
}
</script>

<style scoped lang="scss">

.admin-history {
    display: flex;
    flex-direction: column;
    row-gap: 70px;
}

.doctor-item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

}

.doctor-item-top {
    display: flex;
    flex-direction: column;
}

.doctor-item-date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #656873;
}

.doctor-item-title {
    margin-top: 14px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #373a43;
}

.doctor-item-content {
    border: 1px solid #DBE0EB;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 18px 25px;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
}

.doctor-item-main {
    position: relative;
    display: flex;
    gap: 20px;
    text-align: left;
}

.doctor-item-deleted {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #FFFFFF;
    opacity: 0.7;
}

.doctor-item-img-bg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    background: #e9ebf0;
}

.doctor-item-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.doctor-item-text {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.doctor-item-name {
    margin-top: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #373a43;
}

.doctor-item-spec {
    margin-top: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #656873;
}

.doctor-item-button {
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #24ad6f;
}

.no-img {
    width: 14px !important;
}

</style>

export default {
    state: {
        doctorsNotVerified: [],
        doctorsArchive: [],
        invitations: [],
    },
    getters: {
        getDoctorsNotVerified: (state) => state.doctorsNotVerified,
        getDoctorsArchive: (state) => state.doctorsArchive,
        getInvitations: (state) => state.invitations,
    },
    mutations: {
        setState(state, data) {
            state[data.key] = data.value
        },
        setInvitations(state, data){
            state.invitations = data
        },
    },
    actions: {
        async actionGetDoctorsNotVerified({commit}) {
            const {data} = await this.$axios.get(`/api/getDoctorsNotVerified`)
            commit('setState', {key: 'doctorsNotVerified', value: data})
        },
        async actionGetDoctorsArchive({commit}) {
            const {data} = await this.$axios.get(`/api/getDoctorsArchive`)
            commit('setState', {key: 'doctorsArchive', value: data})
        },
        async approveDoctor(_, params) {
            const {data} = await this.$axios.post(`/api/admin/verifieDoctor/${params.id}`)
            return data
        },
        async dontApproveDoctor(_, params) {
            const {data} = await this.$axios.post(`/api/admin/notVerifieDoctor/${params.id}`, {message: params.message})
            return data
        },
        async changePhoto(_, params) {
            await this.$axios.post('/api/changePhpoto', params)
        },
        async blockDoctor(_, params) {
            await this.$axios.post('/api/admin/blockDoctor', params)
        },
        async unBlockDoctor(_, params) {
            await this.$axios.post('/api/admin/unBlockDoctor', params)
        },
        async setDocs(_, params) {
            await this.$axios.post('/api/setDocs', params)
        },
        async editDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/${params.id}`, params);
        },
        async deleteSpecialDoctor(_, params) {
            await this.$axios.delete(`/api/admin/doctor/deleteSpecial/${params.doctor_id}/${params.special_id}`);
        },
        async addSpecialDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/addSpecial/${params.doctor_id}/${params.special_id}`);
        },
        async deleteSocialDoctor(_, params) {
            await this.$axios.delete(`/api/admin/doctor/deleteSocial/${params.doctor_id}/${params.name}`);
        },
        async addSocialDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/addSocial/${params.doctor_id}`, params);
        },
        async addEducationDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/addEducation/${params.doctor_id}`, params);
        },
        async editEducationDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/editEducation/${params.id}`, params);
        },
        async deleteEducationDoctor(_, educationId) {
            await this.$axios.delete(`/api/admin/doctor/deleteEducation/${educationId}`);
        },
        async addCourseDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/addCourse/${params.doctor_id}`, params);
        },
        async editCourseDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/editCourse/${params.id}`, params);
        },
        async deleteCourseDoctor(_, courseId) {
            await this.$axios.delete(`/api/admin/doctor/deleteCourse/${courseId}`);
        },
        async addJobDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/addJob/${params.doctor_id}`, params);
        },
        async editJobDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/editJob/${params.id}`, params);
        },
        async deleteJobDoctor(_, jobId) {
            await this.$axios.delete(`/api/admin/doctor/deleteJob/${jobId}`);
        },
        async addTalentDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/addTalent/${params.doctor_id}`, params);
        },
        async deleteTalentDoctor(_, talentId) {
            await this.$axios.delete(`/api/admin/doctor/deleteTalent/${talentId}`);
        },
        async changeRecommendationDoctor(_, params) {
            await this.$axios.post(`/api/admin/doctor/changeRecommendation/${params.doctor_id}`, params);
        },
        async deleteRecommendationDoctor(_, params) {
            await this.$axios.delete(`/api/admin/doctor/deleteRecommendation/${params.doctor_id}/${params.recommendation_id}`);
        },
        async addRecommendationDoctor(_, params) {
            await this.$axios.post(`/api/admin/recommendation`, params);
        },
        async inviteDoctor(_, params) {
            await this.$axios.post(`/api/invite/`, params);
        },
        async getInvitations({ commit}, userId){
            await this.$axios.get(`/api/invite/${userId}`).then((response) => {
                commit('setInvitations', response.data)
            });
        }
    }
}

const selectSpecialist = {
  methods: {
    selectSpecialist(spec) {
      this.special = spec.id
      this.specName = spec.name
      this.search = spec.name
      this.location = false
    }
  }
}
export default selectSpecialist

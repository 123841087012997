<template>
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.68749 1H1.9375C1.41973 1 1 1.41973 1 1.9375V5.68749C1 6.20526 1.41973 6.62499 1.9375 6.62499H5.68749C6.20526 6.62499 6.62499 6.20526 6.62499 5.68749V1.9375C6.62499 1.41973 6.20526 1 5.68749 1Z"
      stroke="#A4ACBD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0625 1H11.3125C10.7947 1 10.375 1.41973 10.375 1.9375V5.68749C10.375 6.20526 10.7947 6.62499 11.3125 6.62499H15.0625C15.5803 6.62499 16 6.20526 16 5.68749V1.9375C16 1.41973 15.5803 1 15.0625 1Z"
      stroke="#A4ACBD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.68749 10.375H1.9375C1.41973 10.375 1 10.7947 1 11.3125V15.0625C1 15.5803 1.41973 16 1.9375 16H5.68749C6.20526 16 6.62499 15.5803 6.62499 15.0625V11.3125C6.62499 10.7947 6.20526 10.375 5.68749 10.375Z"
      stroke="#A4ACBD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0625 10.375H11.3125C10.7947 10.375 10.375 10.7947 10.375 11.3125V15.0625C10.375 15.5803 10.7947 16 11.3125 16H15.0625C15.5803 16 16 15.5803 16 15.0625V11.3125C16 10.7947 15.5803 10.375 15.0625 10.375Z"
      stroke="#A4ACBD"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'TileIconGrey'
}
</script>

<style scoped></style>

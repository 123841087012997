<template>
    <div class="doctors-reg-list" v-if="doctors?.length">

        <div class="left-arrow" v-if="!screenType && !listType && left" @click="numberArr--">
            <arrow-left/>
        </div>

        <div class="doctors-reg-list-grid-container" :style="{gridTemplateColumns: gridContainerOptions}" v-if="!listType && screenType">
            <div v-for="doctor in doctors" :key="doctor.id">
                <doctor-item :recommendation-type="recommendationType" :user="doctor" :list-type="listType"/>
            </div>
        </div>

        <div class="doctors-reg-list-grid-container" :style="{gridTemplateColumns: gridContainerOptions}" v-else-if="!listType">
            <div v-for="currentPage in numberShowCards" :key="currentPage">
                <doctor-item :recommendation-type="recommendationType" :style="{filter: !this.$store.getters.getUser.id ? 'blur(4px)' : 'blur(0px)'}" :user="doctors[currentPage - 1 + numberArr]" :list-type="listType"/>
            </div>
        </div>

        <div class="doctors-reg-list-container" v-else-if="screenType && !mobile">
            <div class="row" v-for="doctor in doctors" :key="doctor.id">
                <div class="col">
                    <doctor-item :recommendation-type="recommendationType" :user="doctor" :listType="listType"/>
                </div>
            </div>
        </div>

        <div class="doctors-reg-list-container" v-else-if="!mobile">
            <div class="row" v-for="currentPage in 3" :key="currentPage">
                <div class="col">
                    <doctor-item :recommendation-type="recommendationType" :user="doctors[currentPage]" :listType="listType"/>
                </div>
            </div>
        </div>

        <div class="right-arrow"
            v-if="!screenType && !listType && right && doctors.length > numberShowCards"
            @click="numberArr++"
        >
            <arrow-right/>
        </div>

    </div>
</template>

<script>
import DoctorItem from '@/components/doctor/DoctorItem.vue'
import ArrowLeft from '@/components/icons/ArrowLeft.vue'
import ArrowRight from '@/components/icons/ArrowRight.vue'

export default {
    name: 'DoctorsRegList',
    components: {
        ArrowRight,
        ArrowLeft,
        DoctorItem
    },

    props: {
        listType: {
            type: Boolean,
            required: true,
            default: false
        },
        screenType: {
            type: Boolean,
            required: true
        },
        doctors: {
            required: true
        },
        numberShowCards: {
            required: true,
        },
        title: String,
        recommendationType: {
            type: Number,
            default: 0
        },
    },

    data() {
        return {
            currentPage: 0,
            numberArr: 0,
        }
    },

    computed: {
        pageCount() {
            return Math.ceil(this.$store.getters.getPageCount / 24)
        },
        catId() {
            return this.$route.params.id
        },
        loginUser() {
            return this.$store.getters.getUser
        },
        left() {
            return this.numberArr > 0
        },
        right() {
            return this.numberArr + this.numberShowCards !== this.doctors.length
        },
        mobile() {
            return this.$store.getters.getMobile
        },
        gridContainerOptions() {
            switch (this.numberShowCards) {
                case 4:
                    return 'repeat(4, 1fr)'
                case 3:
                    return 'repeat(3, 1fr)'
                case 2:
                    return 'repeat(2, 1fr)'
                default:
                    return 'repeat(1, 1fr)'
            }
        }
    },
    watch: {
        async currentPage(newVal) {
            await this.$store.dispatch('getAllDoctors', {
                page: newVal,
                spec: this.$route.query.spec,
                name: this.$route.query.name
            })
        },
        async catId(newVal) {
            await this.$store.dispatch('getAllDoctors', {
                page: 1,
                spec: newVal,
                name: this.$route.query.name
            })
            await this.$store.dispatch('getPageCount', {spec_id: newVal})
        }
    },
    methods: {
        updateCurrentPage(newVal) {
            this.currentPage = newVal
        },
    },
    async mounted() {

        // await this.$store.dispatch('getUserByToken', localStorage.userToken)
        // await this.$store.dispatch('getAllDoctors', {
        //     page: 1,
        //     spec: this.$route.query.spec,
        //     name: this.$route.query.name
        // })
        // if (this.loginUser) {
        //     let ids = this.loginUser.recommendations.map((item) => item.id)
        //     await this.$store.dispatch('getDoctorsSubscribe', {page: ids})
        // }
        // await this.$store.dispatch('getPageCount', {
        //     spec_id: this.$route.query.spec,
        //     name: this.$route.query.name
        // })
    },
}
</script>

<style scoped>

.doctors-reg-list {
    position: relative;
    width: auto;
}

.doctors-reg-list-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.doctors-reg-list-grid-container {
    position: relative;
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(4, 1fr);
}

.right-arrow {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 4px 40px rgba(132, 132, 151, 0.5);
    line-height: 40px;
    text-align: center;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    z-index: 100;
}

.left-arrow {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 4px 40px rgba(132, 132, 151, 0.5);
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
    z-index: 100;
}

.left-arrow:hover,
.right-arrow:hover {
    transition: all 0.1s;
    box-shadow: 0 4px 40px rgba(20, 100, 45, 0.5);
}

</style>

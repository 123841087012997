<template>
    <div class="admin-history">
        <div class="doctor-item" v-for="user in users" :key="user">
            <div class="doctor-item-top">
                <div class="doctor-item-title" v-if="user.verified === 1">
                    Вы одобрили анкету
                </div>
                <div class="doctor-item-title" v-else>
                    Вы не одобрили анкету
                </div>
                <div class="doctor-item-date">
                    {{ getVerifiedDate(user.verified_at) }}
                </div>
            </div>

            <div class="doctor-item-content">
                <div class="doctor-item-main">
                    <div class="doctor-item-img-bg">
                        <img class="user-img" :src="`${main_url}/img/doctors/${user.img}`" alt=""/>
                        <div class="checked" v-if="user.verified === 1">
                            <font-awesome-icon icon="check" color="#FFFFFF"/>
                        </div>
                        <div class="checked red" v-else>
                            <font-awesome-icon icon="close" color="#FFFFFF"/>
                        </div>
                    </div>
                    <div class="doctor-item-text">
                        <span class="doctor-item-name">{{ user.full_name }}</span>
                        <span class="doctor-item-spec">{{ user.special }}</span>

                        <div class="doc-image" v-if="user.docs?.length">
                            <img
                                :src="`${main_url}/img/docs/${img}`"
                                @click="openSlider(user)"
                                v-for="img in user.docs"
                                :key="img"
                                alt=""
                                class="docs-img"
                            />
                        </div>
                        <div
                            class="icon-part"
                            @click="toggleVisibility(user)"
                        >
                            {{ !user.isVisible ? 'Показать историю' : 'Скрыть историю' }}
                            <arrow-down class="arrow-icon" style="margin-left: 10px"
                                        :class="{'arrow-icon-active': !user.isVisible}"/>
                        </div>
                    </div>
                </div>

                <div class="doctor-item-button">
                    <button-helper
                        style="border-color: #D3D8E2; min-width: 210px"
                        color="#656873"
                        background="#ffffff"
                        @click="toggleCheckDocs(user)"
                    >
                        {{ user.verified == 1 ? 'Документы проверены' : 'Требуется повторная проверка' }}
                        <arrow-down style="margin-left: 10px; transition: .2s"
                                    :class="{'arrow-icon-active': user.hidden_docs}"/>
                    </button-helper>
                    <div class="hidden" v-if="user.hidden_docs">
                        <div class="arrow-up"></div>
                        <div class="line-hidden">
                            <font-awesome-icon style="margin-right: 10px;" icon="check" color="#A4ACBD"></font-awesome-icon>
                            Документы проверены
                        </div>
                        <div class="line-hidden">
                            <font-awesome-icon style="margin-right: 10px;" icon="close" color="#A4ACBD"></font-awesome-icon>
                            Требуется повторная проверка
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--    <div class="container header-3">-->
    <!--        <div class="row">-->
    <!--            <div class="col" v-for="user in users" :key="user">-->
    <!--                <div class="col title" v-if="user.verified === 1">-->
    <!--                    Вы одобрили анкету <span class="small-date">{{ getVerifiedDate(user.verified_at) }}</span>-->
    <!--                </div>-->
    <!--                <div class="col title" v-else>-->
    <!--                    Вы не одобрили анкету <span class="small-date">{{ getVerifiedDate(user.verified_at) }}</span>-->
    <!--                </div>-->
    <!--                <div class="container header-3 content" >-->
    <!--                    <div class="row row-block">-->
    <!--                        <div class="row">-->
    <!--                            <div class="col-md-1 img-parent">-->
    <!--                                <img class="user-img" :src="`${main_url}/img/doctors/${user.img}`" alt="" />-->
    <!--                                <div class="checked" v-if="user.verified === 1">-->
    <!--                                    <font-awesome-icon icon="check" color="#FFFFFF"></font-awesome-icon>-->
    <!--                                </div>-->
    <!--                                  <div class="checked red" v-else>-->
    <!--                                    <font-awesome-icon icon="close" color="#FFFFFF"></font-awesome-icon>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                            <div class="col-md-8">-->
    <!--                                <div class="name">-->
    <!--                                    {{ user.name }}-->
    <!--                                </div>-->
    <!--                                <div class="spec">-->
    <!--                                    {{ user.special }}-->
    <!--                                </div>-->
    <!--                                <div class="doc-image" v-if="user.docs.length">-->
    <!--                                    <img-->
    <!--                                        :src="`${main_url}/img/docs/${img}`"-->
    <!--                                        @click="openSlider(user)"-->
    <!--                                        v-for="img in user.docs"-->
    <!--                                        :key="img"-->
    <!--                                        alt=""-->
    <!--                                        class="docs-img"-->
    <!--                                    />-->
    <!--                                </div>-->
    <!--                                <div-->
    <!--                                    class="icon-part"-->
    <!--                                    @click="toggleVisibility(user)"-->
    <!--                                >-->
    <!--                                    {{ !user.isVisible ? 'Показать историю' : 'Скрыть историю' }}-->
    <!--                                    <arrow-down class="arrow-icon" style="margin-left: 10px" :class="{'arrow-icon-active': !user.isVisible}"/>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                            <div class="col-md-3 button-right">-->
    <!--                                <button-helper-->
    <!--                                    style="border-color: #D3D8E2"-->
    <!--                                    color="#656873"-->
    <!--                                    background="#ffffff"-->
    <!--                                    @click="toggleCheckDocs(user)"-->
    <!--                                >-->
    <!--                                    {{ user.verified == 1 ? 'Документы проверены' : 'Требуется повторная проверка' }}-->
    <!--                                    <arrow-down style="margin-left: 10px; transition: .2s" :class="{'arrow-icon-active': user.hidden_docs}"/>-->
    <!--                                </button-helper>-->
    <!--                                <div class="hidden" v-if="user.hidden_docs">-->
    <!--                                    <div class="arrow-up"></div>-->
    <!--                                    <div class="line-hidden">-->
    <!--                                        <font-awesome-icon style="margin-right: 10px;" icon="check" color="#A4ACBD"></font-awesome-icon>-->
    <!--                                        Документы проверены-->
    <!--                                    </div>-->
    <!--                                    <div class="line-hidden">-->
    <!--                                        <font-awesome-icon style="margin-right: 10px;" icon="close" color="#A4ACBD"></font-awesome-icon>-->
    <!--                                        Требуется повторная проверка-->
    <!--                                    </div>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                        <div v-if="user.isVisible" style="display: flex; flex-direction: column; margin-top: 20px;">-->
    <!--                            <div style="display: flex">-->
    <!--                                <div-->
    <!--                                    style="position: relative; width: 6%"-->
    <!--                                >-->
    <!--                                    <img class="user-img" :src="`${main_url}/img/doctors/${user.img}`" alt="" />-->
    <!--                                    <div class="checked" style="left: 25px" v-if="user.verified === 1">-->
    <!--                                    <font-awesome-icon icon="check" color="#FFFFFF"></font-awesome-icon>-->
    <!--                                    </div>-->
    <!--                                      <div class="checked red" style="left: 25px" v-else>-->
    <!--                                        <font-awesome-icon icon="close" color="#FFFFFF"></font-awesome-icon>-->
    <!--                                    </div>-->
    <!--                                </div>-->
    <!--                                <div class="">-->
    <!--                                    <div class="name">-->
    <!--                                        {{ user.name }}-->
    <!--                                    </div>-->
    <!--                                    <div class="doc-image" v-if="user.docs.length">-->
    <!--                                        <img-->
    <!--                                            :src="`${main_url}/img/docs/${img}`"-->
    <!--                                            @click="openSlider(user)"-->
    <!--                                            v-for="img in user.docs"-->
    <!--                                            :key="img"-->
    <!--                                            alt=""-->
    <!--                                            class="docs-img"-->
    <!--                                        />-->
    <!--                                    </div>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                            <div style="display: flex; justify-content: end;">-->
    <!--                                <div-->
    <!--                                    style="position: relative; width: 5%"-->
    <!--                                >-->
    <!--                                    <img class="user-img" :src="`${main_url}/img/doctors/${user.img}`" alt="" />-->
    <!--                                </div>-->
    <!--                                <div>-->
    <!--                                    <div class="name">-->
    <!--                                        Administator-->
    <!--                                    </div>-->
    <!--                                    <div class="admin-message">-->
    <!--                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci animi et iste minus placeat porro quibusdam repudiandae sed, veniam voluptatum? Aperiam cupiditate illo maxime, omnis perspiciatis quam quos reprehenderit sunt!-->
    <!--                                    </div>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--                <span class="line" v-if="user.isVisible">-->
    <!--                    <span class="line-date" :style="{top: user.docs.length == 0 ? '33%' : '37%'}">-->
    <!--                        <span class="line-date-text">19 декабря 2022</span>-->
    <!--                    </span>-->
    <!--                    <hr class="line-hr" :style="{top: user.docs.length == 0 ? '33%' : '37%'}"/>-->
    <!--                </span>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->

    <photo-slider v-if="slider" @close-slider="slider = false" :images="userSlider?.docs"/>
</template>

<script>
import config from '@/config/config'
import ButtonHelper from '@/components/helppers/ButtonHelper.vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import ArrowDown from "@/components/icons/HeaderNameArrowDown.vue";
import PhotoSlider from "@/components/helppers/PhotoSlider.vue";


export default {
    name: 'VerifideArchive',
    components: {
        PhotoSlider,
        FontAwesomeIcon,
        ButtonHelper,
        ArrowDown,
    },
    props: {
        users: {
            type: Array,
            required: true
        },
        type: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            slider: false,
            sildeImage: 0,
            userSlider: null,
        }
    },
    computed: {
        main_url() {
            return config.URL
        },
        sildeImageUrl() {
            return `${config.URL}/img/docs/${this.userSlider.docs[this.sildeImage]}`
        },
    },
    methods: {
        openSlider(user) {
            this.userSlider = user;
            this.slider = true;
        },
        toggleVisibility(user) {
            user.isVisible = !user.isVisible;
        },
        toggleCheckDocs(user) {
            user.hidden_docs = !user.hidden_docs;
        },
        getVerifiedDate(verifiedDate) {
            return new Date(verifiedDate).toLocaleDateString('ru-RU', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            })
        }
    },
}
</script>

<style scoped lang="scss">

.admin-history {
    display: flex;
    flex-direction: column;
    row-gap: 70px;
}

.doctor-item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

}

.doctor-item-top {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 769px) {
        flex-direction: column;
    }
}

.doctor-item-date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #656873;
}

.doctor-item-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #373a43;
}

.doctor-item-content {
    border: 1px solid #DBE0EB;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 18px 25px;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
}

.doctor-item-main {
    position: relative;
    display: flex;
    gap: 20px;
    text-align: left;
}

.doctor-item-deleted {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #FFFFFF;
    opacity: 0.7;
}

.doctor-item-img-bg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    background: #e9ebf0;
}

.doctor-item-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.doctor-item-text {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.doctor-item-name {
    margin-top: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #373a43;
}

.doctor-item-spec {
    margin-top: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #656873;
}

.doctor-item-button {
    cursor: pointer;
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #24ad6f;
}

.no-img {
    width: 14px !important;
}

.header-3 {
    max-width: 1597px !important;
    padding: 0 !important;
}

.header-3:not(:last-child) {
    margin-bottom: 90px;
}

.col {
    position: relative;
    flex: auto;
}

.col-md-1 {
    width: 5% !important;
}

.arrow-icon {
    filter: invert(43%) sepia(48%) saturate(3071%) hue-rotate(126deg) brightness(96%) contrast(98%);
    transition: .2s;
}

.arrow-icon-active {
    transition: .2s;
    transform: rotateX(-180deg);
}

.hidden {
    position: absolute;
    background: #ffffff;
    border: 1px solid #dbe0eb;
    box-shadow: 0px 4px 50px rgba(132, 132, 151, 0.3);
    border-radius: 10px;
    padding: 20px;
    top: 65px;
    right: 3%;
    z-index: 10;
}

.arrow-up {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1px;
    height: 1px;
    background-color: #ffffff;
    box-shadow: 0px 4px 50px rgba(132, 132, 151, 0.3);
    border: 2px solid #dbe0eb;
}

.arrow-up:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    background-color: inherit;
}

.line-hidden {
    margin-top: 10px;
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #373a43;
    cursor: pointer;
}

.title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #373a43;
    margin-top: 20px;
}

.small-date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #656873;
}

.row-block {
    padding: 30px;
    margin-top: 20px;
    border: 1px solid #dbe0eb;
    border-radius: 20px;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #373a43;
}

.spec {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #656873;
    margin-bottom: 13px;
}

.button-right {
    text-align: right;
    position: relative;
}

.docs-img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
}

.docs-img:not(:first-child) {
    margin: 0 10px;
}

.img-parent {
    position: relative;
}

.checked {
    position: absolute;
    width: 14px;
    height: 14px;
    text-align: center;
    line-height: 14px;
    background-color: #02b061;
    border-radius: 50%;
    left: 35px;
    top: 35px;
    font-size: 8px;
}

.icon-part {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: underline;
    cursor: pointer;
    /* text/green */

    color: #24ad6f;
    margin: 10px 0 20px 0;
}

.red {
    background-color: #e7486e !important;
}

.slider {
    position: fixed;
    width: 100%;
    margin: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    background: #edeff3c7;
    z-index: 1;
}

.slider-row {
    position: relative;
}

.slider-close {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #dbe0eb;
    background-color: #fff;
    text-align: center;
    line-height: 49px;
    right: 10px;
    top: 10px;
    color: #a4acbd;
    font-size: 20px;
    z-index: 10;
}

.img-parent {
    position: relative;
    width: 80vw;
    text-align: center;
}
.img {
    width: 75vw;
    height: 70vh;
    margin-top: 10vh;
}

.left-slider {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #dbe0eb;
    text-align: center;
    background-color: #ffffff;
    line-height: 52px;
    font-size: 13px;
    left: 10vw;
    top: 40vh;
}

.right-slider {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #dbe0eb;
    text-align: center;
    background-color: #ffffff;
    line-height: 52px;
    font-size: 13px;
    right: 10vw;
    top: 40vh;
}

.docs-img-slide {
    width: 80px;
    height: 60px;
}

.slide-small {
    cursor: pointer;
    text-align: center;
    padding: 13px;
}

.slide-small:not(:last-child) {
    margin-right: 13px;
}

.images-small {
    text-align: center;
    display: flex;
    width: 200px;
    margin: 30px auto;
}

.admin-message {
    margin-top: 10px;
    background: #EDEFF3;
    padding: 15px;
    max-width: 400px;
    font-size: 13px;
    color: rgb(101, 104, 115);
    border-radius: 12px;
}

.line-date {
    position: absolute;
    top: 33%;
    left: 0;
    width: 100%;
    font-size: 13px;
    text-align: center;
}

.line-date-text {
    display: inline-block;
    padding: 6px 12px;
    border: 1px solid #dbe0eb;
    border-radius: 15px;
    color: rgb(101, 104, 115);
}

.line-hr {
    position: absolute;
    top: 33%;
    left: 0;
    width: 100%;
}
</style>

<template>
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8894 5.16992V5.18107"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.8894 12.585L13.1819 7.2885C12.825 6.64343 12.6425 5.91639 12.6526 5.17923C12.6627 4.44207 12.865 3.72029 13.2394 3.08522C13.6138 2.45015 14.1474 1.92375 14.7876 1.55805C15.4277 1.19235 16.1522 1 16.8894 1C17.6266 1 18.3511 1.19235 18.9913 1.55805C19.6314 1.92375 20.165 2.45015 20.5395 3.08522C20.9139 3.72029 21.1161 4.44207 21.1262 5.17923C21.1363 5.91639 20.9539 6.64343 20.597 7.2885L16.8894 12.585Z"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.94472 3.84568L7.35578 3.05121L1 6.2291V20L7.35578 16.8221L13.7116 20L20.0673 16.8221V14.7035"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.35579 3.05121V16.8221"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7116 14.7036V20"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'MapIcon'
}
</script>

<style scoped></style>

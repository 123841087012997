<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="1295"
    viewBox="0 0 1295 563"
    fill="none"
  >
    <line x1="722.727" y1="480.038" x2="803.727" y2="457.038" stroke="#dce0e8" stroke-width="2" />
    <line x1="828.101" y1="506.439" x2="807.101" y2="463.439" stroke="#dce0e8" stroke-width="2" />
    <line x1="948.863" y1="478.991" x2="803.863" y2="458.991" stroke="#dce0e8" stroke-width="2" />
    <line x1="948.687" y1="477.05" x2="1130.69" y2="417.05" stroke="#dce0e8" stroke-width="2" />
    <line x1="259.22" y1="523.975" x2="135.22" y2="551.975" stroke="#dce0e8" stroke-width="2" />
    <line x1="1107.04" y1="493.71" x2="1130.04" y2="417.71" stroke="#dce0e8" stroke-width="2" />
    <line x1="258.331" y1="522.257" x2="359.331" y2="431.257" stroke="#dce0e8" stroke-width="2" />
    <line x1="1254.73" y1="453.962" x2="1130.73" y2="418.962" stroke="#dce0e8" stroke-width="2" />
    <line x1="360.764" y1="431.355" x2="409.764" y2="489.355" stroke="#dce0e8" stroke-width="2" />
    <line x1="1280.66" y1="287.751" x2="1131.66" y2="418.751" stroke="#dce0e8" stroke-width="2" />
    <line x1="350.997" y1="310.918" x2="360.997" y2="431.918" stroke="#dce0e8" stroke-width="2" />
    <line x1="1279.08" y1="287.381" x2="1220.08" y2="144.381" stroke="#dce0e8" stroke-width="2" />
    <line x1="349.068" y1="310.638" x2="425.068" y2="114.638" stroke="#dce0e8" stroke-width="2" />
    <line x1="989.243" y1="85.0299" x2="1221.24" y2="143.03" stroke="#dce0e8" stroke-width="2" />
    <line x1="20.0099" y1="50" x2="219.01" y2="51.9757" stroke="#dce0e8" stroke-width="2" />
    <line x1="426.453" y1="111.891" x2="365.453" y2="142.891" stroke="#dce0e8" stroke-width="2" />
    <line x1="988.553" y1="86.8944" x2="836.553" y2="10.8944" stroke="#dce0e8" stroke-width="2" />
    <line x1="224.276" y1="52.0388" x2="426.276" y2="110.039" stroke="#dce0e8" stroke-width="2" />
    <line x1="601.703" y1="82.045" x2="836.703" y2="9.04502" stroke="#dce0e8" stroke-width="2" />
    <line x1="425.821" y1="110.016" x2="606.821" y2="77.0162" stroke="#dce0e8" stroke-width="2" />

    <path
      d="M803.727 457.038,L722.727 480.038"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p1"
        begin="indefinite"
        values="1800;0"
        dur="0.6s"
        calcMode="linear"
      />
    </path>
    <path
      d="M807.101 463.439,L828.101 506.439"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p2"
        begin="indefinite"
        values="1800;0"
        dur="0.6s"
        calcMode="linear"
      />
    </path>
    <path
      d="M948.863 478.991,L803.863 458.991"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p3"
        begin="indefinite"
        values="1800;0"
        dur="1s"
        calcMode="linear"
      />
    </path>
    <path
      d="M1130.69 417.05,L948.687 477.05"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p4"
        begin="indefinite"
        values="1800;0"
        dur="1.4s"
        calcMode="linear"
      />
    </path>
    <path
      d="M259.22 523.975,L135.22 551.975"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p5"
        begin="indefinite"
        values="1800;0"
        dur="1.4s"
        calcMode="linear"
      />
    </path>
    <path
      d="M1130.04 417.71,L1107.04 493.71"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p6"
        begin="indefinite"
        values="1800;0"
        dur="1.8s"
        calcMode="linear"
      />
    </path>
    <path
      d="M359.331 431.257,L258.331 522.257"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p7"
        begin="indefinite"
        values="1800;0"
        dur="1.8s"
        calcMode="linear"
      />
    </path>
    <path
      d="M1130.73 418.962,L1254.73 453.962"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p8"
        begin="indefinite"
        values="1800;0"
        dur="2.2s"
        calcMode="linear"
      />
    </path>
    <path
      d="M360.764 431.355,L409.764 489.355"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p9"
        begin="indefinite"
        values="1800;0"
        dur="2.2s"
        calcMode="linear"
      />
    </path>
    <path
      d="M1280.66 287.751,L1131.66 418.751"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p10"
        begin="indefinite"
        values="1800;0"
        dur="2.6s"
        calcMode="linear"
      />
    </path>
    <path
      d="M350.997 310.918,L360.997 431.918"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p11"
        begin="indefinite"
        values="1800;0"
        dur="2.6s"
        calcMode="linear"
      />
    </path>
    <path
      d="M1220.08 144.381,L1279.08 287.381"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p12"
        begin="indefinite"
        values="1800;0"
        dur="3s"
        calcMode="linear"
      />
    </path>
    <path
      d="M425.068 114.638,L349.068 310.638"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p13"
        begin="indefinite"
        values="1800;0"
        dur="3s"
        calcMode="linear"
      />
    </path>
    <path
      d="M989.243 85.0299,L1221.24 143.03"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p14"
        begin="indefinite"
        values="1800;0"
        dur="3.4s"
        calcMode="linear"
      />
    </path>
    <path
      d="M219.01 51.9757,L20.0099 50"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p15"
        begin="indefinite"
        values="1800;0"
        dur="3.4s"
        calcMode="linear"
      />
    </path>
    <path
      d="M426.453 111.891,L365.453 142.891"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p16"
        begin="indefinite"
        values="1800;0"
        dur="3.8s"
        calcMode="linear"
      />
    </path>
    <path
      d="M836.553 10.8944,L988.553 86.8944"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p17"
        begin="indefinite"
        values="1800;0"
        dur="3.8s"
        calcMode="linear"
      />
    </path>
    <path
      d="M426.276 110.039,L224.276 52.0388"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        attributeName="stroke-dashoffset"
        id="p18"
        begin="indefinite"
        values="1800;0"
        dur="3.8s"
        calcMode="linear"
      />
    </path>
    <path
      d="M601.703 82.045,L836.703 9.04502"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        id="p19"
        attributeName="stroke-dashoffset"
        begin="indefinite"
        values="1800;0"
        dur="4.2s"
        calcMode="linear"
      />
    </path>
    <path
      d="M606.821 77.0162,L425.821 110.016"
      stroke-dasharray="1800"
      stroke-dashoffset="1800"
      stroke-width="2"
      stroke="#02B061"
    >
      <animate
        id="p20"
        attributeName="stroke-dashoffset"
        begin="indefinite"
        values="1800;0"
        dur="4.2s"
        calcMode="linear"
      />
    </path>

    <circle cx="723" cy="481" r="10" fill="#DDE1E9" />
    <mask
      id="mask0_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="713"
      y="471"
      width="20"
      height="20"
    >
      <circle cx="723" cy="481" r="10" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask0_634_314)">
      <rect
        x="704.599"
        y="471"
        width="33.6406"
        filter="grayscale(1)"
        height="50.8"
        fill="url(#pattern0)"
      >
        <animate
          id="m1"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="0.4s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="834" cy="513" r="10" fill="#DDE1E9" />
    <mask
      id="mask1_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="824"
      y="503"
      width="20"
      height="20"
    >
      <circle cx="834" cy="513" r="10" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask1_634_314)">
      <rect
        width="31.2"
        height="47.1145"
        filter="grayscale(1)"
        transform="matrix(-1 0 0 1 848.406 503)"
        fill="url(#pattern1)"
      >
        <animate
          id="m2"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="0.4s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="804" cy="458" r="20" fill="#DDE1E9" />
    <mask
      id="mask2_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="784"
      y="438"
      width="40"
      height="40"
    >
      <circle cx="804" cy="458" r="20" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask2_634_314)">
      <rect
        x="758.396"
        y="435.602"
        width="81.0553"
        height="122.4"
        filter="grayscale(1)"
        fill="url(#pattern2)"
      >
        <animate
          id="m3"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="0.7s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="954" cy="471" r="35" fill="#DDE1E9" />
    <mask
      id="mask3_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="919"
      y="436"
      width="70"
      height="70"
    >
      <circle cx="954" cy="471" r="35" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask3_634_314)">
      <rect
        x="878.162"
        y="435"
        width="145.246"
        height="222.833"
        filter="grayscale(1)"
        fill="url(#pattern3)"
      >
        <animate
          id="m4"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="1s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="135" cy="548" r="15" fill="#DDE1E9" />
    <mask
      id="mask4_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="120"
      y="533"
      width="30"
      height="30"
    >
      <circle cx="135" cy="548" r="15" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask4_634_314)">
      <rect
        x="100.8"
        y="533"
        width="68.4"
        height="105.089"
        filter="grayscale(1)"
        fill="url(#pattern4)"
      >
        <animate
          id="m5"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="1s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="1104" cy="496" r="10" fill="#DDE1E9" />
    <mask
      id="mask5_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1094"
      y="486"
      width="20"
      height="20"
    >
      <circle cx="1104" cy="496" r="10" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask5_634_314)">
      <rect
        x="1087.67"
        y="486"
        width="32.4136"
        height="45.4998"
        filter="grayscale(1)"
        fill="url(#pattern5)"
      >
        <animate
          id="m6"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="1.4s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="259" cy="516" r="35" fill="#DDE1E9" />
    <mask
      id="mask6_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="224"
      y="481"
      width="70"
      height="70"
    >
      <circle cx="259" cy="516" r="35" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask6_634_314)">
      <rect
        width="148.4"
        height="224.096"
        filter="grayscale(1)"
        transform="matrix(-1 0 0 1 331.8 480)"
        fill="url(#pattern6)"
      >
        <animate
          id="m7"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="1.4s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="1255" cy="453" r="10" fill="#DDE1E9" />
    <mask
      id="mask7_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1245"
      y="443"
      width="20"
      height="20"
    >
      <circle cx="1255" cy="453" r="10" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask7_634_314)">
      <rect
        x="1233.67"
        y="443"
        width="46"
        height="70.7972"
        filter="grayscale(1)"
        fill="url(#pattern7)"
      >
        <animate
          id="m8"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="1.8s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 426 483)" fill="#DDE1E9" />
    <mask
      id="mask8_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="406"
      y="483"
      width="20"
      height="20"
    >
      <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 426 483)" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask8_634_314)">
      <rect
        x="397.594"
        y="483"
        width="36.5543"
        height="55.5984"
        filter="grayscale(1)"
        fill="url(#pattern8)"
      >
        <animate
          id="m9"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="1.8s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="35" cy="35" r="35" transform="matrix(-1 0 0 1 1171 378)" fill="#DDE1E9" />
    <mask
      id="mask9_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1101"
      y="378"
      width="70"
      height="70"
    >
      <circle cx="35" cy="35" r="35" transform="matrix(-1 0 0 1 1171 378)" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask9_634_314)">
      <rect
        x="1064.6"
        y="377"
        width="151.2"
        height="235.324"
        filter="grayscale(1)"
        fill="url(#pattern9)"
      >
        <animate
          id="m10"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="2.2s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="360" cy="432" r="10" fill="#DDE1E9" />
    <mask
      id="mask10_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="350"
      y="422"
      width="20"
      height="20"
    >
      <circle cx="360" cy="432" r="10" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask10_634_314)">
      <rect
        x="334.8"
        y="415.2"
        width="48.8"
        height="73.6919"
        filter="grayscale(1)"
        fill="url(#pattern10)"
      >
        <animate
          id="m11"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="2.2s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 1295 264)" fill="#DDE1E9" />
    <mask
      id="mask11_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1255"
      y="264"
      width="40"
      height="40"
    >
      <circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 1295 264)" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask11_634_314)">
      <rect
        x="1230.67"
        y="264"
        width="90"
        height="135"
        filter="grayscale(1)"
        fill="url(#pattern11)"
      >
        <animate
          id="m12"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="2.6s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="350" cy="311" r="20" fill="#DDE1E9" />
    <mask
      id="mask12_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="330"
      y="291"
      width="40"
      height="40"
    >
      <circle cx="350" cy="311" r="20" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask12_634_314)">
      <rect
        x="300"
        y="288.967"
        width="94"
        height="144.648"
        filter="grayscale(1)"
        fill="url(#pattern12)"
      >
        <animate
          id="m13"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="2.6s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="1216" cy="142" r="35" fill="#DDE1E9" />
    <mask
      id="mask13_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="1181"
      y="107"
      width="70"
      height="70"
    >
      <circle cx="1216" cy="142" r="35" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask13_634_314)">
      <rect
        x="1167"
        y="106"
        width="102.754"
        height="163.334"
        filter="grayscale(1)"
        fill="url(#pattern13)"
      >
        <animate
          id="m14"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="3s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="20" cy="51" r="20" fill="#DDE1E9" />
    <mask
      id="mask14_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="31"
      width="40"
      height="40"
    >
      <circle cx="20" cy="51" r="20" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask14_634_314)">
      <rect
        x="-20.6641"
        y="31"
        width="80"
        height="125.474"
        filter="grayscale(1)"
        fill="url(#pattern14)"
      >
        <animate
          id="m15"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="3s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="989" cy="78" r="20" fill="#DDE1E9" />
    <mask
      id="mask15_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="969"
      y="58"
      width="40"
      height="40"
    >
      <circle cx="989" cy="78" r="20" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask15_634_314)">
      <rect
        x="954.333"
        y="58"
        width="63.5728"
        height="99.3335"
        filter="grayscale(1)"
        fill="url(#pattern15)"
      >
        <animate
          id="m16"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="3.4s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 370 135)" fill="#DDE1E9" />
    <mask
      id="mask16_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="350"
      y="135"
      width="20"
      height="20"
    >
      <circle cx="10" cy="10" r="10" transform="matrix(-1 0 0 1 370 135)" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask16_634_314)">
      <rect
        width="42.3333"
        height="64.5947"
        filter="grayscale(1)"
        transform="matrix(-1 0 0 1 380.664 135)"
        fill="url(#pattern16)"
      >
        <animate
          id="m17"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="3.4s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="35" cy="35" r="35" transform="matrix(-1 0 0 1 254 12)" fill="#DDE1E9" />
    <mask
      id="mask17_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="184"
      y="12"
      width="70"
      height="70"
    >
      <circle cx="35" cy="35" r="35" transform="matrix(-1 0 0 1 254 12)" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask17_634_314)">
      <rect
        width="102.754"
        height="160.999"
        filter="grayscale(1)"
        transform="matrix(-1 0 0 1 273.835 11)"
        fill="url(#pattern17)"
      >
        <animate
          id="m18"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="3.8s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="837" cy="10" r="10" fill="#DDE1E9" />
    <mask
      id="mask18_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="827"
      y="0"
      width="20"
      height="20"
    >
      <circle cx="837" cy="10" r="10" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask18_634_314)">
      <rect
        width="31.2"
        height="49.1145"
        filter="grayscale(1)"
        transform="matrix(-1 0 0 1 851.401 0)"
        fill="url(#pattern18)"
      >
        <animate
          id="m19"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="3.8s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 446 91)" fill="#DDE1E9" />
    <mask
      id="mask19_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="406"
      y="91"
      width="40"
      height="40"
    >
      <circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 446 91)" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask19_634_314)">
      <rect
        width="76.3756"
        height="119.333"
        filter="grayscale(1)"
        transform="matrix(-1 0 0 1 464.664 90)"
        fill="url(#pattern19)"
      >
        <animate
          id="m20"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="3.8s"
          calcMode="linear"
        />
      </rect>
    </g>
    <circle cx="602" cy="73" r="35" fill="#DDE1E9" />
    <mask
      id="mask20_634_314"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="567"
      y="38"
      width="70"
      height="70"
    >
      <circle cx="602" cy="73" r="35" fill="#DDE1E9" />
    </mask>
    <g mask="url(#mask20_634_314)">
      <rect
        x="539"
        y="37"
        width="105.844"
        filter="grayscale(1)"
        height="166.833"
        fill="url(#pattern20)"
      >
        <animate
          id="m21"
          attributeName="filter"
          begin="indefinite"
          values="grayscale(0)"
          dur="4.2s"
          calcMode="linear"
        />
      </rect>
    </g>
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image0_634_314" transform="scale(0.00201613 0.00133511)" />
      </pattern>
      <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image1_634_314" transform="scale(0.00201613 0.00133511)" />
      </pattern>
      <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image2_634_314" transform="scale(0.00201613 0.00133511)" />
      </pattern>
      <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image3_634_314"
          transform="matrix(0.0020483 0 0 0.00133511 -0.00797874 0)"
        />
      </pattern>
      <pattern id="pattern4" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image4_634_314"
          transform="matrix(0.00205126 0 0 0.00133511 -0.00871244 0)"
        />
      </pattern>
      <pattern id="pattern5" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image5_634_314"
          transform="matrix(0.00184701 0 0 0.00131579 -0.0190095 0)"
        />
      </pattern>
      <pattern id="pattern6" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image6_634_314" transform="scale(0.00201613 0.00133511)" />
      </pattern>
      <pattern id="pattern7" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image7_634_314"
          transform="matrix(0.00205483 0 0 0.00133511 -0.00959849 0)"
        />
      </pattern>
      <pattern id="pattern8" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image8_634_314"
          transform="matrix(0.00203068 0 0 0.00133511 -0.00360908 0)"
        />
      </pattern>
      <pattern id="pattern9" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image9_634_314"
          transform="matrix(0.00207794 0 0 0.00133511 -0.015329 0)"
        />
      </pattern>
      <pattern id="pattern10" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image10_634_314" transform="scale(0.00201613 0.00133511)" />
      </pattern>
      <pattern id="pattern11" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlink:href="#image11_634_314" transform="scale(0.002 0.00133333)" />
      </pattern>
      <pattern id="pattern12" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image12_634_314"
          transform="matrix(0.00205448 0 0 0.00133511 -0.00951126 0)"
        />
      </pattern>
      <pattern id="pattern13" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image13_634_314"
          transform="matrix(0.00212225 0 0 0.00133511 -0.0263185 0)"
        />
      </pattern>
      <pattern id="pattern14" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image14_634_314"
          transform="matrix(0.00209403 0 0 0.00133511 -0.01932 0)"
        />
      </pattern>
      <pattern id="pattern15" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image15_634_314"
          transform="matrix(0.00208614 0 0 0.00133511 -0.0173619 0)"
        />
      </pattern>
      <pattern id="pattern16" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image16_634_314"
          transform="matrix(0.0020372 0 0 0.00133511 -0.00522446 0)"
        />
      </pattern>
      <pattern id="pattern17" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image17_634_314"
          transform="matrix(0.00209191 0 0 0.00133511 -0.0187944 0)"
        />
      </pattern>
      <pattern id="pattern18" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image1_634_314"
          transform="matrix(0.00210171 0 0 0.00133511 -0.0212249 0)"
        />
      </pattern>
      <pattern id="pattern19" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image18_634_314"
          transform="matrix(0.00208605 0 0 0.00133511 -0.0173411 0)"
        />
      </pattern>
      <pattern id="pattern20" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use
          xlink:href="#image19_634_314"
          transform="matrix(0.00210443 0 0 0.00133511 -0.0218978 0)"
        />
      </pattern>
      <image
        id="image0_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo1.png'"
      />
      <image
        id="image1_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo2.png'"
      />
      <image
        id="image2_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo3.png'"
      />
      <image
        id="image3_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo4.png'"
      />
      <image
        id="image4_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo5.png'"
      />
      <image
        id="image5_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo6.png'"
      />
      <image
        id="image6_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo7.png'"
      />
      <image
        id="image7_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo8.png'"
      />
      <image
        id="image8_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo9.png'"
      />
      <image
        id="image9_634_314"
        width="496"
        height="749"
        :href="'./img/animation_photos/anim_photo10.png'"
      />
      <image
        id="image10_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo11.png'"
      />
      <image
        id="image11_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo12.png'"
      />
      <image
        id="image12_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo13.png'"
      />
      <image
        id="image13_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo14.png'"
      />
      <image
        id="image14_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo15.png'"
      />
      <image
        id="image15_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo16.png'"
      />
      <image
        id="image16_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo17.png'"
      />
      <image
        id="image17_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo18.png'"
      />
      <image
        id="image18_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo19.png'"
      />
      <image
        id="image19_634_314"
        width="496"
        height="749"
        :href="'/img/animation_photos/anim_photo20.png'"
      />
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'AnimationPhoto',
  data() {
    return {
      timer: null
    }
  },
  methods: {
    async runLine() {
      document.getElementById('p20').beginElement()
      document.getElementById('p19').beginElement()
      document.getElementById('p18').beginElementAt(0.4)
      document.getElementById('p17').beginElementAt(0.4)
      document.getElementById('p16').beginElementAt(0.4)
      document.getElementById('p15').beginElementAt(0.8)
      document.getElementById('p14').beginElementAt(0.8)
      document.getElementById('p13').beginElementAt(1.2)
      document.getElementById('p12').beginElementAt(1.2)
      document.getElementById('p11').beginElementAt(1.6)
      document.getElementById('p10').beginElementAt(1.6)
      document.getElementById('p9').beginElementAt(2)
      document.getElementById('p8').beginElementAt(2)
      document.getElementById('p7').beginElementAt(2.4)
      document.getElementById('p6').beginElementAt(2.4)
      document.getElementById('p5').beginElementAt(2.8)
      document.getElementById('p4').beginElementAt(2.8)
      document.getElementById('p3').beginElementAt(3.2)
      document.getElementById('p2').beginElementAt(3.6)
      document.getElementById('p1').beginElementAt(3.6)
    },
    async runMask() {
      document.getElementById('m21').beginElementAt(0.2)
      document.getElementById('m20').beginElementAt(0.4)
      document.getElementById('m19').beginElementAt(0.4)
      document.getElementById('m18').beginElementAt(0.4)
      document.getElementById('m17').beginElementAt(0.8)
      document.getElementById('m16').beginElementAt(0.8)
      document.getElementById('m15').beginElementAt(1.2)
      document.getElementById('m14').beginElementAt(1.2)
      document.getElementById('m13').beginElementAt(1.6)
      document.getElementById('m12').beginElementAt(1.6)
      document.getElementById('m11').beginElementAt(2)
      document.getElementById('m10').beginElementAt(2)
      document.getElementById('m9').beginElementAt(2.4)
      document.getElementById('m8').beginElementAt(2.4)
      document.getElementById('m7').beginElementAt(2.8)
      document.getElementById('m6').beginElementAt(2.8)
      document.getElementById('m5').beginElementAt(3.2)
      document.getElementById('m4').beginElementAt(3.2)
      document.getElementById('m3').beginElementAt(3.5)
      document.getElementById('m2').beginElementAt(3.8)
      document.getElementById('m1').beginElementAt(3.8)
    }
  },
  async mounted() {
    await this.runLine()
    await this.runMask()
    setTimeout(() => {
      this.timer = setInterval(() => {
          if (this.$route.path === '/'){
              this.runLine()
              this.runMask()
          }
      }, 4200)
    }, 300)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped></style>

<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3652 1L13.4875 4.11114L10.3652 7.22228" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.68555 4.11133H13.4913" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.12229 8.77734L1 11.8885L4.12229 14.9996" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 11.8887H8.02516" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CreateSubIcon"
}
</script>

<style scoped>

</style>

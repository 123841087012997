<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 15H4.65334L14.2434 5.40997C14.7278 4.92551 15 4.26844 15 3.5833C15 2.89817 14.7278 2.2411 14.2434 1.75663C13.7589 1.27217 13.1018 1 12.4167 1C11.7316 1 11.0745 1.27217 10.59 1.75663L1 11.3467V15Z" stroke="#C0C4CD" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.67676 2.66992L13.3301 6.32326" stroke="#C0C4CD" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'EditIcon'
}
</script>

<style scoped></style>

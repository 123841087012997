<template>
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.27797 5.5L0 1.22203L1.22203 0L6.72203 5.5L1.22203 11L0 9.77797L4.27797 5.5Z"
      fill="#A4ACBD"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRight'
}
</script>

<style scoped></style>

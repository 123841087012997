<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99967 2.99989C6.99967 2.46949 7.21037 1.96081 7.58543 1.58575C7.96048 1.2107 8.46916 1 8.99956 1C9.52997 1 10.0386 1.2107 10.4137 1.58575C10.7888 1.96081 10.9995 2.46949 10.9995 2.99989C12.1478 3.54289 13.1267 4.38814 13.8313 5.44506C14.536 6.50197 14.9397 7.73071 14.9992 8.99956V11.9994C15.0745 12.6211 15.2947 13.2164 15.642 13.7374C15.9894 14.2585 16.4542 14.6907 16.9991 14.9992H1C1.54491 14.6907 2.00975 14.2585 2.35711 13.7374C2.70447 13.2164 2.92464 12.6211 2.99989 11.9994V8.99956C3.05945 7.73071 3.46317 6.50197 4.16778 5.44506C4.87239 4.38814 5.85133 3.54289 6.99967 2.99989" stroke="#9FD6BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 15V15.9999C6 16.7956 6.31605 17.5586 6.87863 18.1212C7.44121 18.6837 8.20423 18.9998 8.99984 18.9998C9.79544 18.9998 10.5585 18.6837 11.121 18.1212C11.6836 17.5586 11.9997 16.7956 11.9997 15.9999V15" stroke="#9FD6BD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMenuBell"
}
</script>

<style scoped>

</style>

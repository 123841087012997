<template>
    <modal-layout @close-modal="closeModal">
        <div class="change-content-popup">
            <div class="change-content-popup__title">
                {{title}}
            </div>

            <div class="change-content-popup__input">
                <div class="change-content-popup__input-title">
                    {{titleInput1}}
                </div>
                <input-layout :placeholder="titleInput1" :input-value="value1" @update:input-value="changeValue1($event)"/>
            </div>

            <div class="change-content-popup__input" v-if="value2">
                <div class="change-content-popup__input-title">
                    {{titleInput2}}
                </div>
                <input-layout :placeholder="titleInput2" :input-value="value2" @update:input-value="changeValue2($event)"/>
            </div>

            <button-helper
                style="max-height: 50px;
                display: flex;
                align-items: center"
                color="#ffffff"
                background="#02B061"
                class="change-content-popup__btn"
                @click="save"
            >
                Сохранить
            </button-helper>
        </div>
    </modal-layout>
</template>

<script>
import ModalLayout from "@/components/layout/ModalLayout.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";

export default {
    name: 'ChangeContentPopup',

    components: {ButtonHelper, InputLayout, ModalLayout},

    emits: ['closeModal','update:value1', 'update:value2', 'save'],

    props: {

        title: {
            required: true
        },

        value1: {
            required: true
        },

        titleInput1: {
            required: true
        },

        value2: {
            required: false
        },

        titleInput2: {
            required: false
        }
    },

    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
        changeValue1(event) {
            this.$emit('update:value1', event)
        },
        changeValue2(event) {
            this.$emit('update:value2', event)
        },
        save() {
            this.$emit('save')
            this.closeModal()
        },
    },



}
</script>

<style scoped lang="scss">

.change-content-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;

    &__title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #373A43;
    }

    &__input {
        display: flex;
        row-gap: 15px;
        flex-direction: column;
        align-items: center;
        width: 100%;


        &-title {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;

            /* text/black */

            color: #373A43;
        }
    }

}


</style>

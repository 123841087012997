<template>
    <div class="user-dashboard-container">
        <user-dashboard-subscrib v-if="pageId == 1"/>
        <user-dashboard-notification v-else-if="pageId == 2"/>
        <user-dashboard-settings v-else/>
    </div>
</template>

<script>
import config from '@/config/config'
import UserDashboardSubscrib from '@/components/user/UserDashboardSubscrib.vue'
import UserDashboardNotification from '@/components/user/UserDashboardNotification.vue'
import UserDashboardSettings from '@/components/user/UserDashboardSettings.vue'
export default {
  name: 'UserDashboard',

  components: {
    UserDashboardSettings,
    UserDashboardNotification,
    UserDashboardSubscrib,
  },

  computed: {
      pageId() {
          return this.$route.params.id
      },
      pageName() {
          let arr = config.USER_MANU_ITEM.filter((item) => item.id == this.pageId)
          if (arr.length){
            return arr[0].title;
          }
          return '';
      },
    config() {
      return config
    },
    user() {
      return this.$store.getters.getUser
    },
    getDoctorDetalies() {
      return this.$store.getters.getDoctorDetalies
    }
  },

  async mounted() {
    await this.$store.dispatch('getUserByToken')
    await this.$store.dispatch('getDoctorDetalies', this.user.id)
    await this.$store.dispatch('getNotifications')
    if (this.user?.recommendations?.length) {
      let ids = this.user.recommendations.map((item) => item.recommendation_id)
      await this.$store.dispatch('getSubscribeDoctors', { page: ids })
    }
  }
}
</script>

<style scoped>
.dashboard-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 1597px !important;
}

.dashboard-header {
  margin-top: 17px;
  margin-bottom: 37px;
}

.row-footer {
  margin-top: auto;
}
.menu-way {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  margin-top: 40px;
  margin-bottom: 9px;
  color: #9195a4;
}

.page-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  color: #373a43;
  margin-bottom: 60px;
  line-height: 30px;
  display: flex;
  align-items: baseline;
  column-gap: 6px;
}

.small {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #9195a4;
}

.notification-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #02b061;
  width: 17px;
  height: 17px;
  align-self: flex-start;
}

.notification {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #ffffff;
}

.row-menu {
  margin-top: -18px;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #373a43;
  margin-left: 15px;
  line-height: 50px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .center-mobile {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .menu-way {
    margin-top: 20px;
  }
  .page-title {
    justify-content: center;
    margin-bottom: 5px;
  }
  .mobile-no-padding {
    padding: 0;
  }
}
</style>

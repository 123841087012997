<template>
    <SearchPageTopPanel
                    :sort-value="this.sort"
                    :list-type="this.listType"
                    :switch="this.switch"
                    :show-sort="this.showSort"
                    @changeShowSort="this.showSort = $event"
                    @changeSwitch="this.switch = $event"
                    @changeListType="this.listType = $event"
                    @changeSortValue="sortingShow($event)"
                />
    <div class="doctors-reg-list-large" v-if="doctors?.length">


        <div class="doctors-reg-list-large-grid-container"
             :style="{gridTemplateColumns: gridContainerOptions}"
             v-if="!listType"
        >
            <div v-for="doctor in doctors" :key="doctor.id">
                <router-link
                    style="text-decoration: none"
                    :to="{ name: 'doctor-page', params: { id: doctor.id }, query: {spec: doctor.specialities[0].id} }"
                >
                    <doctor-item :user="doctor" :list-type="listType"/>
                </router-link>
            </div>

        </div>

        <div class="doctors-reg-list-large-container" v-else-if="!mobile">

            <div v-for="doctor in doctors" :key="doctor.id">
                <router-link
                    style="text-decoration: none"
                    :to="{ name: 'doctor-page', params: { id: doctor.id } }"
                >
                    <doctor-item :user="doctor" :listType="listType"/>
                </router-link>
            </div>

        </div>
    </div>

    <pagination-helper
        v-if="doctors?.length"
        class="doctors-reg-list-large-pagination"
        @updateCurrentPage="updateCurrentPage"
        :currentPage="currentPage"
        :page-count="pageCount"
    />
</template>

<script>
import DoctorItem from '@/components/doctor/DoctorItem.vue'
import PaginationHelper from '@/components/helppers/PaginationHelper.vue'
import sortingShow from '@/mixins/sortingShow'
import SearchPageTopPanel from "@/components/layout/SearchPageTopPanel.vue";

export default {
    name: 'DoctorsListLarge',
    components: {
        DoctorItem,
        PaginationHelper,
        SearchPageTopPanel
    },
    data() {
        return {
            currentPage: 1,
            numberShowCards: 4,
            switch: false,
            showSort: false,
            listType: false,
            sort: 'По популярности',
        }
    },
    mixins: [sortingShow],

    computed: {
        pageCount() {
            return Math.ceil(this.$store.getters.getPageCount / 24)
        },
        catId() {
            return this.$route.params.id
        },
        mobile() {
            return this.$store.getters.getMobile
        },
        gridContainerOptions() {
            switch (this.numberShowCards) {
                case 4:
                    return 'repeat(4, 1fr)'
                case 3:
                    return 'repeat(3, 1fr)'
                case 2:
                    return 'repeat(2, 1fr)'
                default:
                    return 'repeat(1, 1fr)'
            }
        },
        doctors(){
            let doctors = this.$store.getters.getAllDoctores
            if(this.switch === true){
                doctors = doctors.filter(doctor => {
                    return doctor.specialities.some(specie => specie.specie_name === "Детский врач")
                });
                doctors = this.filterDoctors(doctors);
            }else{
                doctors = this.filterDoctors(doctors);
            }

            return doctors
        }
    },
    watch: {
        async currentPage(newVal) {
            await this.$store.dispatch('getAllDoctors', {
                page: newVal,
                spec: this.$route.query.spec,
                name: this.$route.query.name
            })
        },
        async catId(newVal) {
            await this.$store.dispatch('getAllDoctors', {
                page: 1,
                spec: newVal,
                name: this.$route.query.name
            })
            await this.$store.dispatch('getPageCount', {spec_id: newVal, name: this.$route.query.name})
        },
        $route() {
            this.switch === false;
        }
    },
    methods: {
        filterDoctors(doctors){
            if(this.sort == 'По дате регистрации'){
                doctors = doctors.sort((a, b) => a.id - b.id);
            }
            if(this.sort == 'По количеству рекомендаций'){
                doctors = doctors.sort((a, b) => b.my_recommendations.length - a.my_recommendations.length);
                console.log(doctors);
            }
            if(this.sort == 'По популярности'){
                doctors = doctors.sort((a, b) => b.me_favorites_count - a.me_favorites_count);
            }
            return doctors
        },
        updateCurrentPage(newVal) {
            this.currentPage = newVal
        },
        onResize() {
            if (window.innerWidth > 1600) {
                this.numberShowCards = 4
            } else if (window.innerWidth < 1600 && window.innerWidth > 1380) {
                this.numberShowCards = 3
            } else if (window.innerWidth < 1380 && window.innerWidth > 769) {
                this.numberShowCards = 2
            } else {
                this.numberShowCards = 1
            }
        }
    },

    async mounted() {
        if (screen.width < 769) {
            this.listType = false
        }
        await this.$store.dispatch('getPageCount', {
            spec_id: this.$route.query.spec,
            name: this.$route.query.name
        })
    },

    created() {
        window.addEventListener('resize', this.onResize)
        this.onResize()
    }
}
</script>

<style scoped>

.doctors-reg-list-large {
    position: relative;
    width: auto;
}

.doctors-reg-list-large-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.doctors-reg-list-large-grid-container {
    position: relative;
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(4, 1fr);
}

.doctors-reg-list-large-pagination {
    margin-top: 40px;
}

@media screen and (max-width: 769px) {

}
</style>

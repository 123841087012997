export default {
  errors: [],
  login(user) {
    this.errors = []
    if (!user.password) {
      this.errors.push({ password: 'required' })
    } else if (user.password.length < 6) {
      this.errors.push({ password: 'invalid password length' })
    }
    if (!user.email) {
      this.errors.push({ email: 'required' })
    }
    return this.errors
  },
  register(user) {
    this.errors = []
    if (!user.first_name) {
      this.errors.push({ first_name: 'required' })
    }
    if (!user.password) {
      this.errors.push({ password: 'required' })
    } else if (user.password.length < 6) {
      this.errors.push({ password: 'invalid password length' })
    }
    if (!user.passwordConfirmed) {
      this.errors.push({ passwordConfirmed: 'required' })
    } else if (user.passwordConfirmed.length < 6) {
      this.errors.push({ passwordConfirmed: 'invalid password length' })
    }
    if (!user.email) {
      this.errors.push({ email: 'required' })
    } else if (!this.validateEmail(user.email)) {
      this.errors.push({ email: 'invalid email' })
    }
    return this.errors
  },
  validateEmail(email) {
    let re = /\S+@\S+\.\S+/
    return re.test(email)
  },
  updateUserDetailes(user) {
    this.errors = []
    if (!user.name) {
      this.errors.push({ name: 'required' })
    }
    if (user.age && !isFinite(user.age)) {
      this.errors.push({ age: 'Mus be a Number' })
    }
    if (user.phone && !isFinite(user.phone)) {
      this.errors.push({ phone: 'Mus be a Number' })
    }
    if (!user.email) {
      this.errors.push({ email: 'required' })
    } else if (!this.validateEmail(user.email)) {
      this.errors.push({ email: 'invalid email' })
    }

    if (user.pass && user.pass.length < 6) {
      this.errors.push({ passwordConfirmed: 'invalid password length' })
    }
    return this.errors
  }
}

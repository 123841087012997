<template>
    <div v-if="notifications.length" class="notifications">
        <div class="notification" :class="{'first-not':!notification.read}" v-for="notification in notifications"
             :key="notification.id">
            <div class="date">{{ dateFormatting(notification.created_at) }}</div>
            <div class="title">{{ notification.title }}</div>
            <div class="context">{{ notification.description }}</div>
        </div>
    </div>
    <div v-else class="no-notifications">
        На данный момент у вас нет уведомлений
    </div>
</template>

<script>
export default {
    name: 'DoctorNotification',
    computed: {
        notifications() {
            return this.$store.getters.getAllNotifications
        }
    },
    methods: {
        dateFormatting(dateTime) {
            const date = new Date(dateTime);
            const options = {day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric'};
            return date.toLocaleString('ru', options).replace(',', '').replace('г. в ', '');
        }
    }
}
</script>

<style scoped>

.no-notifications {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #656873;
}


.notifications {
    display: flex;
    flex-direction: column;
    row-gap: 75px;
}

.notification {
    text-align: left;
    padding: 30px;
}

.first-not {
    background: #edf3f1;
    border-radius: 20px;
}

.date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #656873;
}

.title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #373a43;
    margin-top: 14px;
}

.context {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #656873;
    margin-top: 14px;
}

@media screen and (max-width: 769px) {
    .notifications {
        padding: 0 20px;
    }
}

</style>

<template>
  <div class="form-doctor-container">
    <div class="col">
      <div class="info" v-if="doctor?.user_detales">
        <div class="info-title col-md-9">
          <div class="title">
              <img class="sheiled-icon" alt="" :src="iconUrl + doctorDashboardContent.doctor_form?.icon"/>
            <span v-if="doctor?.user_detales?.verified === 1">Анкета верифицирована</span>
            <span v-if="doctor?.user_detales?.verified === 3">Документы на проверке</span>
            <span v-if="doctor?.user_detales?.verified === 2 || doctor?.user_detales?.verified === 0">{{doctorDashboardContent.doctor_form?.title}}</span>
          </div>
          <div class="text-information" v-if="doctor?.user_detales?.verified === 0 || doctor?.user_detales?.verified === 2">
              {{doctorDashboardContent.doctor_form?.annotation}}
          </div>
        </div>
        <button-helper
          v-if="doctor?.user_detales?.verified === 0 || doctor?.user_detales?.verified === 2"
          class="doctor-details-btn"
          color="#24AD6F"
          background="#EDF3F1"
          @click="this.$store.dispatch('changeVerified', { show: true })"
        >
            {{doctorDashboardContent.doctor_form?.btn}}
        </button-helper>
      </div>
    </div>
    <div>
      <div class="form-card">
      <div class="speciality">Специальность</div>
      <div class="col-md-2 right-plus" @click="this.$store.dispatch('changeSpecial', true)">
              <font-awesome-icon icon="plus" color="#24AD6F" />
              <span class="add-btn">Добавить <span v-if="doctor?.user_detales?.instagram || doctor?.user_detales?.whatsapp || doctor?.user_detales?.telegram">ещё</span></span>
        </div>
    </div>
      <div v-if="specials.length" style="margin-top: 30px;">
          <div v-if="!mobile">
            <button-helper
              style="margin: 0; margin-right: 20px; border: 1px solid #DBE0EB; width: auto;"
              v-for="spicial in specials"
              :key="spicial.species_id"
              class="desktop-row"
              color="#656873"
              background="#ffff"
              @click="deleteSpecial(spicial.species_id)"
              >{{ spicial.specie_name }}
              <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
            </button-helper>
          </div>
          <div class="row-main" v-else>
            <div
              v-for="(spicial, index) in specials"
              :key="spicial.id"
              class="mobile-row"
              :class="{
                'row-top': index === 0, 
                'row-bottom': index === specials.length - 1,
                }"
              :style="{'border-bottom': index === 0 && index !== specials.length - 1 || index < specials.length - 1 ? 'none' : '1px solid #DBE0EB'}"
              color="#656873"
              background="#ffff"
              @click="deleteSpecial(spicial.species_id)"
              >{{ spicial.specie_name }}
              <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
            </div>
          </div>
      </div>
    </div>
    <div>
      <div class="form-card">
          <div class="form-card-title">
              <div class="speciality">Выберите социальные сети, где пациент сможет с вами связаться</div>
              <div class="text"
              v-if="!doctor?.user_detales?.instagram && !doctor?.user_detales?.whatsapp && !doctor?.user_detales?.telegram"
              >Вы не добавили ссылки на свои соц. сети</div>
          </div>
          <div class="col-md-2 right-plus" @click="this.$store.dispatch('changeSocial', true)">
             <font-awesome-icon icon="plus" color="#24AD6F" />
             <span class="add-btn">Добавить
              <span v-if="doctor?.user_detales?.instagram || doctor?.user_detales?.whatsapp || doctor?.user_detales?.telegram">
                ещё
              </span>
            </span>
          </div>
        </div>

        <div v-if="doctor?.user_detales?.instagram || doctor?.user_detales?.whatsapp || doctor?.user_detales?.telegram" style="margin-top: 30px;">
          <div>
            <button-helper
              style="margin: 0; margin-right: 20px; border: 1px solid #DBE0EB; width: auto;"
              class="doctor-details-btn"
              color="#656873"
              background="#ffff"
              v-if="doctor?.user_detales?.instagram && !mobile"
              @click="deleteSocial('instagram')"
            >
              <font-awesome-icon icon="fa-brands fa-instagram" :color="left_instagram_color" class="icon-left"></font-awesome-icon>
              {{ doctor?.user_detales?.instagram }}
              <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
            </button-helper>

            <button-helper
              style="margin: 0; margin-right: 20px; border: 1px solid #DBE0EB; width: auto;"
              class="doctor-details-btn"
              color="#656873"
              background="#ffff"
              v-if="doctor?.user_detales?.telegram && !mobile"
              @click="deleteSocial('telegram')"
              >
              <font-awesome-icon icon="fa-brands fa-telegram" :color="left_telegram_color" class="icon-left"></font-awesome-icon>
              {{ doctor?.user_detales?.telegram }}
              <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
            </button-helper>

            <button-helper
              style="margin: 0; margin-right: 20px; border: 1px solid #DBE0EB; width: auto;"
              class="doctor-details-btn"
              color="#656873"
              background="#ffff"
              v-if="doctor?.user_detales?.whatsapp && !mobile"
              @click="deleteSocial('whatsapp')"
            >
              <font-awesome-icon icon="fa-brands fa-whatsapp" :color="left_whatsapp_color" class="icon-left"></font-awesome-icon>
              {{ doctor?.user_detales?.whatsapp }}
              <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
            </button-helper>

            <div
              class="mobile-row row-top"
              :class="{
                'row-bottom': !doctor?.user_detales?.whatsapp && !doctor?.user_detales?.telegram,
                }"
              :style="{'border-bottom': doctor?.user_detales?.whatsapp || doctor?.user_detales?.telegram ? 'none' : '1px solid #DBE0EB'}"
              color="#656873"
              background="#ffff"
              v-if="doctor?.user_detales?.instagram && mobile"
              @click="deleteSocial('instagram')"
            >
              <div>
                <font-awesome-icon style="margin-right: 10px;" icon="fa-brands fa-instagram" :color="left_instagram_color" class="icon-left"></font-awesome-icon>
                {{ doctor?.user_detales?.instagram }}
              </div>
              <font-awesome-icon icon="close" color="#BDC3C9"></font-awesome-icon>
            </div>

            <div
              class="mobile-row"
              :class="{
                'row-top': !doctor?.user_detales?.instagram, 
                'row-bottom': !doctor?.user_detales?.whatsapp,
                }"
              :style="{'border-bottom': doctor?.user_detales?.whatsapp ? 'none' : '1px solid #DBE0EB'}"
              color="#656873"
              background="#ffff"
              v-if="doctor?.user_detales?.telegram && mobile"
              @click="deleteSocial('telegram')"
            >
              <div>
                <font-awesome-icon style="margin-right: 10px;" icon="fa-brands fa-telegram" :color="left_telegram_color" class="icon-left"></font-awesome-icon>
                {{ doctor?.user_detales?.telegram }}
              </div>
              <font-awesome-icon icon="close" color="#BDC3C9"></font-awesome-icon>
            </div>

            <div
              class="mobile-row row-bottom"
              :class="{
                'row-top': !doctor?.user_detales?.telegram && !doctor?.user_detales?.instagram, 
                }"
              style="'border-bottom': 1px solid #DBE0EB;"
              color="#656873"
              background="#ffff"
              v-if="doctor?.user_detales?.whatsapp && mobile"
              @click="deleteSocial('whatsapp')"
            >
              <div>
                <font-awesome-icon style="margin-right: 10px;" icon="fa-brands fa-whatsapp" :color="left_whatsapp_color" class="icon-left"></font-awesome-icon>
                {{ doctor?.user_detales?.whatsapp }}
              </div>
              <font-awesome-icon icon="close" color="#BDC3C9"></font-awesome-icon>
            </div>
          </div>
      </div>

    </div>

    <div>
      <div class="form-card">
      <div class="form-card-title">
        <div class="speciality">
          Навыки
          <span class="info-icon" @click="first_popup = !first_popup">
            <info-icon />
          </span>
            <info-helpper class="info-help" v-if="first_popup">
                Эта информация будет видна только другим врачам и администратору
            </info-helpper>
        </div>
        <div class="text" v-if="!talents.length">Вы не добавили информации о своих навыках</div>
      </div>
      <div class="col-md-2 right-plus" @click="this.$store.dispatch('changeTalant', true)">
        <font-awesome-icon icon="plus" color="#24AD6F" />
        <span class="add-btn">Добавить <span v-if="talents.length">ещё</span></span>
      </div>
    </div>
      <div v-if="talents.length" style="margin-top: 30px;">
          <div v-if="!mobile">
            <button-helper
              class="desktop-row"
              v-for="talent in talents"
              :key="talent.id"
              color="#656873"
              background="#ffff"
              @click="deleteTalent(talent.id)"
            >
              {{ talent.name }}
              <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
            </button-helper>
          </div>
          <div class="row-main" v-else>
            <div
              v-for="(talent, index) in talents"
              :key="talent.id"
              class="mobile-row"
              :class="{
                'row-top': index === 0, 
                'row-bottom': index === talents.length - 1,
                }"
              :style="{'border-bottom': index === 0 && index !== talents.length - 1 || index < talents.length - 1 ? 'none' : '1px solid #DBE0EB'}"
              color="#656873"
              background="#ffff"
              @click="deleteTalent(talent.id)"
              >{{ talent.name }}
              <font-awesome-icon style="margin-left: 10px;" icon="close" color="#BDC3C9"></font-awesome-icon>
            </div>
          </div>
      </div>
    </div>


    <div class="form-cards">
      <div class="col-md-12">
        <div class="speciality">
          Образование и опыт
          <span class="info-icon" @click="second_popup = !second_popup">
            <info-icon />
          </span>
            <info-helpper class="info-help" style="left: 115px" v-if="second_popup">
                Эта информация будет видна только другим врачам и администратору
            </info-helpper>
        </div>
        <div class="container" style="margin: 23px 0; padding: 0">
          <div class="row-main">
              <div class="row-top"
              :style="{'border-bottom': !courses.length ? 'none' : '', 'margin-bottom': styleEducation ? '15px' : '0'}"
              :class="{'row-bord': !educations.length, 'row-bord-active': educations.length, 'row-bottom': courses.length}">
                  <div class="form-card-title">
                      <div class="small-title">Высшее образование</div>
                      <div class="text" v-if="!educations.length">Вы не добавили информации о своем высшем образовании</div>
                  </div>
                  <div class="col-md-2 right-plus" @click="this.$store.dispatch('showEducation', true)">
                      <font-awesome-icon icon="plus" color="#24AD6F" />

                      <span class="add-btn">Добавить <span v-if="educations.length">ещё</span></span>
                  </div>
              </div>
              <div class="row-info" v-if="educations.length">
                  <div class="row-info-inner" :key="educ.id" v-for="educ in educations">
                      <div>
                          <span class="row-info-text">
                            Диплом по специальности "{{ educ.speciality }}",
                            {{ educ.school }}, {{ educ.faculty }} <span class="row-info-date">{{ educ.start_year }}-{{ educ.end_year }}</span></span>
                      </div>
                      <div class="row-info-icons">
                          <span @click="editEducation({...educ})" class="row-info-icon-1"><edit-icon/></span>
                          <span @click="deleteEducation(educ.id)" class="row-info-icon-2"><green-trash-icon :fill="'none'" :stroke="'#C0C4CD'"/></span>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row-main">
              <div :class="{'row-top': educations.length, 'row-bord': !courses.length, 'row-bord-active': courses.length, 'row-bottom' : jobs.length}"
              :style="{'border-bottom': !jobs.length ? 'none' : '', 'margin-bottom': styleCourse ? '15px' : '0'}">
                  <div class="form-card-title">
                      <div class="small-title">Курсы повышения квалификации</div>
                      <div class="text" v-if="!courses.length">Вы не добавили информации о повышении квалификации</div>
                  </div>
                  <div class="col-md-2 right-plus" @click="this.$store.dispatch('showCourse', true)">
                      <font-awesome-icon icon="plus" color="#24AD6F" />
                      <span class="add-btn">Добавить <span v-if="courses.length">ещё</span></span>
                  </div>
              </div>
              <div class="row-info" v-if="courses.length">
                  <div class="row-info-inner" :key="course.id" v-for="course in courses">
                      <div>
                          <span class="row-info-text">{{ course.city  }}, {{ course.name  }} {{ course.year  }}</span>
                      </div>
                      <div class="row-info-icons">
                          <span @click="editCourse({...course})" class="row-info-icon-1"><edit-icon/></span>
                          <span @click="deleteCourse(course.id)" class="row-info-icon-2"><green-trash-icon :fill="'none'" :stroke="'#C0C4CD'"/></span>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row-main">
              <div class="row-bottom" :class="{'row-top': courses.length, 'row-bord': !jobs.length, 'row-bord-active': jobs.length}">
                  <div class="form-card-title">
                      <div class="small-title">Опыт работы</div>
                      <div class="text" v-if="!jobs.length">Вы не добавили информации об опыте работы</div>
                  </div>
                  <div class="col-md-2 right-plus" @click="this.$store.dispatch('showJob', true)">
                      <font-awesome-icon icon="plus" color="#24AD6F" />

                      <span class="add-btn">Добавить <span v-if="jobs.length">ещё</span></span>
                  </div>
              </div>
              <div class="row-info" v-if="jobs.length">
                  <div class="row-info-inner" :key="work.id" v-for="work in jobs">
                      <div>
                          <span class="row-info-text">
                            Должость-{{ work.title }}, Город-{{ work.city }}, Компания-{{ work.company }}
                            <span class="row-info-date">
                              {{ work.start_year }}.{{ work.start_month }} - {{ work.current_date ? 'По настоящее время' : work.end_year + '.' + work.end_month }}
                            </span>
                          </span>
                      </div>
                      <div class="row-info-icons">
                          <span @click="editJob({...work})" class="row-info-icon-1"><edit-icon/></span>
                          <span @click="deleteJob(work.id)" class="row-info-icon-2"><green-trash-icon :fill="'none'" :stroke="'#C0C4CD'"/></span>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="delete-account">
      <div class="col-md-9">
        <div class="delete-account-title">
          <green-info-icon />
          Вы также можете удалить свою анкету
        </div>
        <div class="text-delete">Ваш аккаунт на сайте останется даже после удаления анкеты</div>
      </div>
      <div class="col-md-3 right-plus delete-account-btn">
        <button-helper
          color="#24AD6F"
          background="#ffffff"
          class="delete-button"
          @click="this.$store.dispatch('changeDelete', true)"
          v-on:mouseover="deleteHover = true"
          v-on:mouseleave="deleteHover = false"
        >
          <green-trash-icon
              :fill="deleteHover ? '#02B061' : 'none'"
              :stroke="deleteHover ? '#fff' : '#02B061'"
              class="trash-icon" style="margin-right: 5px"
          />
          Удалить анкету
        </button-helper>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/ButtonHelper.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import GreenInfoIcon from '@/components/icons/GreenInfoIcon.vue'
import InfoHelpper from "@/components/helppers/InfoHelpper.vue";
import GreenTrashIcon from "@/components/icons/GreenTrashIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";

export default {
  name: 'DoctorDertalis',
  components: {GreenTrashIcon, InfoHelpper, GreenInfoIcon, InfoIcon, ButtonHelper, EditIcon },
  data() {
    return {
      spec: '',
      first_popup: false,
      second_popup: false,
      deleteHover: false,
    }
  },
  computed: {
    doctor() {
      return this.$store.getters.getDoctorDetalies
    },
      iconUrl() {
          return `/img/icons/`
      },
      doctorDashboardContent() {
          return this.$store.getters.getDoctorDashboardContent
      },
    educations(){
      return this.$store.getters.getEducations;
    },

    courses(){
      return this.$store.getters.getCourses;
    },

    jobs(){
      return this.$store.getters.getJobs;
    },

    talents(){
      return this.$store.getters.getTalents;
    },
    specials(){
      return this.$store.getters.getSpecials;
    },

    styleEducation() {
      if(this.courses.length && !this.educations.length){
        return true
      }
      return false;
    },

    styleCourse() {
      if(this.jobs.length && !this.courses.length){
        return true
      }
      return false;
    },
    mobile() {
      return this.$store.getters.getMobile;
    }
  },
  methods: {
    editEducation(educ) {
      this.$store.dispatch('showEducation', true);
      this.$store.dispatch('changeEducation', educ);
    },
    deleteEducation(educId) {
      this.$store.dispatch('deleteEducation', educId);
    },

    deleteCourse(courseId) {
      this.$store.dispatch('deleteCourse', courseId);
    },

    editCourse(course){
      this.$store.dispatch('showCourse', true);
      this.$store.dispatch('changeCourse', course);
    },

    editJob(job) {
      this.$store.dispatch('showJob', true);
      this.$store.dispatch('changeJob', job);
    },

    deleteJob(jobId) {
      this.$store.dispatch('deleteJob', jobId);
    },

    deleteTalent(talentId) {
      this.$store.dispatch('deleteTalent', talentId);
    },

    async deleteSpecial(specialId) {
      await this.$store.dispatch('deleteSpecial', specialId);
      await this.$store.dispatch('getSpecialities');
    },

    async deleteSocial(socialName) {
      await this.$store.dispatch('deleteSocial', {socialName})
      await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
    }
  },
  mounted(){
    this.$store.dispatch('getUserEducations');
    this.$store.dispatch('getUserCourses');
    this.$store.dispatch('getUserJobs');
    this.$store.dispatch('getUserTalents');
    this.$store.dispatch('getUserSpecials');
  }
}
</script>

<style scoped>
.row {
  margin-top: 30px;
}

.form-doctor-container {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
}

.mobile-row {
  margin: 0;
  border: 1px solid #DBE0EB; 
  width: auto; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 18px;
}

.desktop-row {
  margin: 0;
  margin-right: 20px;
  border: 1px solid #DBE0EB !important; 
  width: auto;
}

.title {
  margin-left: 10px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #373a43;
}
.sheiled-icon {
  margin-right: 18px;
}
.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #656873;
}
.info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #edf3f1;
  border-radius: 20px;
  padding: 33px;
  row-gap: 20px;
}


.info-help {
  width: 265px !important;
  z-index: 100;
  position: absolute;
  top: 45px;
  left: -33px;
}

.doctor-details-btn {
  width: 187px;
  white-space: nowrap;
  padding: 16px 26px;
  margin-left: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

.text-information {
  margin-left: 51px;
  margin-top: 7px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #656873;
}

.speciality {
  position: relative;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #373a43;
}

.speciality-input {
  padding-left: 21px;
  width: 380px;
}

.right-plus {
  text-align: right;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #24ad6f;
  cursor: pointer;
}
.add-btn {
  display: inline-block;
  text-decoration-line: underline;
  text-decoration-skip-ink: none;
}
.info-icon {
  font-size: 9px;
  cursor: pointer;
}

.info-icon svg {
   vertical-align: baseline !important;
}
.small-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #373a43;
}

.row-main {
    display: flex;
    flex-direction: column;
}

.row-info:last-child {
    margin-bottom: 84px;
}

.row-info-inner {
    display: flex;
    justify-content: space-between;
    padding: 40px 29px 40px 42px;
    border: 1px solid #DBE0EB;
    border-radius: 20px;
    margin-top: 24px;
}

.row-info-text {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #373A43;
}

.row-info-date {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;

  color: #656873;
}

.row-info-icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-info-icon-1 {
    padding: 8px 11px 8px 15px;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
}

.row-info-icon-2 {
    padding: 8px 15px 8px 10px;
    border-left: none !important;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
}

.row-info-icons span {
    border: 1px solid #DBE0EB;
    line-height: 0;
    cursor: pointer;
}

.row-bord {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border: 1px solid #dbe0eb;
  margin: 0;
  padding: 30px 45px;
}

.row-bord-active {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.row-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.row-bottom {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.delete-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  row-gap: 30px;
}

.delete-account-title {
  display: flex;
  align-items: center;
  column-gap: 18px;
  row-gap: 12px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #373a43;
}

.text-delete {
  margin-left: 36px;
  margin-top: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #656873;
}

.form-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-card-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.form-cards {
}

.delete-button {
  width: 161px;
  height: 46px;
}

.delete-button:hover {
  background: #02B061 !important;
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .row-bord-active {
    flex-direction: column;
    align-items: flex-start;
  }

  .small-title {
    margin-bottom: 16px;
  }
  .form-doctor-container {
    text-align: center;
    row-gap: 40px;
  }

  .info {
    flex-direction: column;
  }

  .doctor-details-btn {
    padding: 10px 18px;
    margin-left: 0;
  }

  .speciality-input {
    font-size: 12px;
    width: 100%;
  }

  .speciality {
    text-align: left;
    margin-bottom: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    column-gap: 10px;
  }

  .title {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .info-title {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .text-information {
    margin-left: 0;
    margin-top: 0;
  }

  .text {
    font-size: 13px;
  }

  .right-plus {
    white-space: nowrap;
  }

  .form-card {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 35px;
    padding: 23px 30px;
    border: 1px solid #dbe0eb;
    border-radius: 20px;
  }

  .form-card-title {
  }

  .speciality {
    font-size: 16px;
  }

  .row-bord {
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 30px;
  }

  .delete-account {
    flex-direction: column;
  }

  .delete-account-title {
    flex-direction: column;
  }

  .text-delete {
    margin-top: 15px;
    margin-left: 0;
  }
}
</style>

<template>
    <modal-layout max-width="750px" max-height="90vh" @close-modal="closeModal">
        <div class="change-content-popup">
            <div class="change-content-popup__title">
                Четвертый блок
            </div>

            <div class="change-content-popup__content">
                <div class="change-content-popup__item change-content-popup__item-top">
                    <div class="change-content-popup__icon">
                        <div class="change-content-popup__preview">
                            <img alt="" :src="previewUrl1 ? previewUrl1 : iconUrl + homeContent.fourth_block?.first_benefit_icon"/>
                        </div>
                        <div class="change-content-popup__desc">
                            Загрузите иконку svg
                            (макс. 20х20 пикс.)
                        </div>
                        <div class="change-content-popup__upload">
                            <label style="cursor: pointer" for="file1" class="change-content-popup__upload">Сменить иконку</label>
                            <input type="file" ref="fileInput1" id="file1" hidden="" @change="previewFile(1)"/>
                        </div>
                    </div>
                    <div class="change-content-popup__input">
                        <div class="change-content-popup__input-title">
                            Первое преимущество
                        </div>
                        <input-layout placeholder="Первое преимущество" :input-value="homeContent.fourth_block?.first_benefit"
                                      @update:input-value="homeContent.fourth_block.first_benefit = $event"/>
                    </div>
                </div>

                <div class="change-content-popup__item">
                    <div class="change-content-popup__icon">
                        <div class="change-content-popup__preview">
                            <img alt="" :src="previewUrl2 ? previewUrl2 : iconUrl + homeContent.fourth_block?.second_benefit_icon"/>
                        </div>
                        <div class="change-content-popup__desc">
                            Загрузите иконку svg
                            (макс. 20х20 пикс.)
                        </div>
                        <div class="change-content-popup__upload">
                            <label style="cursor: pointer" for="file2" class="change-content-popup__upload">Сменить иконку</label>
                            <input type="file" ref="fileInput2" id="file2" hidden="" @change="previewFile(2)"/>
                        </div>
                    </div>
                    <div class="change-content-popup__input">
                        <div class="change-content-popup__input-title">
                            Второе преимущество
                        </div>
                        <input-layout placeholder="Второе преимущество" :input-value="homeContent.fourth_block?.second_benefit"
                                      @update:input-value="homeContent.fourth_block.second_benefit = $event"/>
                    </div>
                </div>

                <div class="change-content-popup__item change-content-popup__item-bottom">
                    <div class="change-content-popup__icon">
                        <div class="change-content-popup__preview">
                            <img alt="" :src="previewUrl3 ? previewUrl3 : iconUrl + homeContent.fourth_block?.third_benefit_icon"/>
                        </div>
                        <div class="change-content-popup__desc">
                            Загрузите иконку svg
                            (макс. 20х20 пикс.)
                        </div>
                        <div class="change-content-popup__upload">
                            <label style="cursor: pointer" for="file3" class="change-content-popup__upload">Сменить иконку</label>
                            <input type="file" ref="fileInput3" id="file3" hidden="" @change="previewFile(3)"/>
                        </div>
                    </div>
                    <div class="change-content-popup__input">
                        <div class="change-content-popup__input-title">
                            Третье преимущество
                        </div>
                        <input-layout placeholder="Третье преимущество" :input-value="homeContent.fourth_block?.third_benefit"
                                      @update:input-value="homeContent.fourth_block.third_benefit = $event"/>
                    </div>
                </div>
            </div>


            <button-helper
                style="max-height: 50px;
                display: flex;
                align-items: center"
                color="#ffffff"
                background="#02B061"
                class="change-content-popup__btn"
                @click="save"
            >
                Сохранить
            </button-helper>
        </div>
    </modal-layout>
</template>

<script>
import ModalLayout from "@/components/layout/ModalLayout.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";
import config from "@/config/config";

export default {
    name: 'ChangeIconsContentPopup',

    components: {ButtonHelper, InputLayout, ModalLayout},

    emits: [
        'closeModal',
    ],

    data() {
        return {
            file1: null,
            file2: null,
            file3: null,
            previewUrl1: null,
            previewUrl2: null,
            previewUrl3: null,
        }
    },

    computed: {
        homeContent() {
            return this.$store.getters.getHomeContent
        },
        iconUrl() {
            return `${config.URL}/img/icons/`
        },
    },

    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
        async save() {
            if (this.previewUrl1) {
                await this.changeIcons(this.file1, 'first')
            }
            if (this.previewUrl2) {
                await this.changeIcons(this.file2, 'second')
            }
            if (this.previewUrl3) {
                await this.changeIcons(this.file3,'third')
            }

            this.$store.dispatch('updateHomeContent', this.homeContent)

            this.closeModal()

        },
        async changeIcons(file, id) {
            this.url = URL.createObjectURL(file)
            let formData = new FormData()
            formData.append('file', file)

            this.$store.dispatch('changeHomeIcon', { file: formData, id: id })
        },
        previewFile(id) {
            if (id === 1) {
                const file = this.$refs.fileInput1.files;
                const reader = new FileReader();

                reader.onload = () => {
                    this.previewUrl1 = reader.result;
                }

                reader.readAsDataURL(file[0]);
                this.file1 = file[0];
            }
            else if (id === 2) {
                const file = this.$refs.fileInput2.files;
                const reader = new FileReader();

                reader.onload = () => {
                    this.previewUrl2 = reader.result;
                }

                reader.readAsDataURL(file[0]);
                this.file2 = file[0];
            }
            else {
                const file = this.$refs.fileInput3.files;
                const reader = new FileReader();

                reader.onload = () => {
                    this.previewUrl3 = reader.result;
                }

                reader.readAsDataURL(file[0]);
                this.file3 = file[0];
            }
        },
    },


}
</script>

<style scoped lang="scss">

.change-content-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    width: 100%;

    &__content {
        width: 100%;
    }

    &__item {
        width: 100%;
        padding: 40px;
        border: 1px solid #DBE0EB;
        display: flex;
        gap: 30px;
        justify-content: space-between;

        @media screen and (max-width: 769px) {
            flex-direction: column-reverse;
            align-items: center;
        }
    }

    &__item-top {
        border-radius: 20px 20px 0 0;
    }

    &__item-bottom {
        border-radius: 0 0 20px 20px;
    }

    &__icon {
        width: 33%;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        max-width: 150px;

        @media screen and (max-width: 769px) {
            width: 100%;
        }
    }

    &__desc {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #656873;
    }

    &__preview {
        width: 150px;
        height: 150px;
        border: 1px dashed #C0C4CD;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__upload {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #24AD6F;
    }

    &__title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #373A43;
    }

    &__input {
        display: flex;
        row-gap: 15px;
        flex-direction: column;
        width: 100%;


        &-title {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #373A43;
        }
    }

}


</style>

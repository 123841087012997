<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('changeTalant', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Навыки</div>
      <div class="inputs" v-for="(item, index) in talants" :key="item + index">
        <div class="icon-div">
          <input
            type="text"
            placeholder="Введите название"
            @input="onChange"
            class="input-soc"
            v-model="tal[index]"
          />
        </div>
      </div>
      <div class="button">
        <button-helper class="save-button" color="#ffffff" background="#02B061" @click="submit">
            Сохранить
        </button-helper
        >
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'

export default {
  name: 'TalantPopup',
  components: {
    ButtonHelper
  },
  data() {
    return {
      talants: [''],
      tal: [],
      next: false
    }
  },
  computed: {
    doctor() {
      return this.$store.getters.getDoctorDetalies
    },
    loginUser(){
      return this.$store.getters.getUser
    },
  },
  methods: {
    add() {
      let str = ''
      this.talants.push(str);
    },
    onChange() {
      if (this.tal[this.tal.length - 1].length === 1 && this.tal[this.tal.length - 1].trim() !== '') {
          this.tal.push('');
          this.add();
      }
    },
    async submit() {
      const lastItem = this.tal[this.tal.length - 1];
      if (lastItem.trim() === '') {
        this.tal.pop();
      }
      if (this.loginUser.id == this.doctor.id) {
        await this.$store.dispatch('addUserTalents', {'talents': this.tal})
      }else{
        await this.$store.dispatch('addTalentDoctor', {'talents': this.tal, doctor_id: this.doctor.id});
        this.$store.dispatch('getDoctorDetalies', this.doctor.id)
      }
        this.$store.dispatch('changeTalant', false)
        this.$store.dispatch('getUserTalents')

    }
  }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  width: 483px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}
.inputs {
  width: 330px;
  margin: 10px auto;
}

.input {
  width: 330px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}
.input-soc::-webkit-outer-spin-button,
.input-soc::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-soc[type='number'] {
  -moz-appearance: textfield;
}
.input-soc {
  width: 300px;
  height: 46px;
  margin-top: 20px;
  margin-left: 15px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}

.input:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.input-soc:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.icon-left {
  position: absolute;
  top: 35px;
  left: 30px;
}

.icon-right {
  position: absolute;
  top: 35px;
  right: 15px;
}

.icon-div {
  display: flex;
  position: relative;
}
.button {
  margin: 45px 0;
  text-align: center;
}

.save-button {
    padding: 17px 24px;
}

@media screen and (max-width: 425px) {
    .form {
        width: 80%;
    }

    .inputs {
        width: 80%;
    }

    .input-soc {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }

    .icon-div {
        justify-content: center;
        align-items: center;
    }
}
</style>

<template>
  <form @submit.prevent="register">
    <div class="form-group">
      <label for="">Email address</label>
      <select name="role" id="" v-model="roles">
        <option v-for="role in roles" :key="role" :value="role.value">
          {{ role.type }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="first_name">First Name</label>
      <input
        type="text"
        class="form-control"
        id="first_name"
        v-model="first_name"
        aria-describedby="emailHelp"
        placeholder="Enter first name"
      />
    </div>
    <div class="form-group">
      <label for="last_name">Last Name</label>
      <input
        type="text"
        class="form-control"
        id="last_name"
        v-model="last_name"
        aria-describedby="emailHelp"
        placeholder="Enter last name"
      />
    </div>
    <div class="form-group">
      <label for="patronymic">Last Name</label>
      <input
        type="text"
        class="form-control"
        id="patronymic"
        v-model="patronymic"
        aria-describedby="emailHelp"
        placeholder="Enter patronymic"
      />
    </div>
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        v-model="email"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      />
      <small id="emailHelp" class="form-text text-muted"
        >We'll never share your email with anyone else.</small
      >
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input
        type="password"
        class="form-control"
        id="exampleInputPassword1"
        v-model="password"
        placeholder="Password"
      />
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1Confirmed">Password</label>
      <input
        type="password"
        class="form-control"
        id="exampleInputPassword1Confirmed"
        v-model="passwordConfirmed"
        placeholder="Password Confirmed"
      />
    </div>

    <div v-if="errors.length">
      <div v-for="error in errors" :key="error">
        <div>{{ error }}</div>
      </div>
    </div>
    <div v-if="serverError">{{ serverError }}</div>

    <button type="submit" class="btn btn-primary">Login</button>
  </form>
</template>

<script>
import validators from '@/config/validators'

export default {
  name: 'register-page',
  data() {
    return {
      roles: [
        {
          type: 'user',
          value: 4
        },
        {
          type: 'doctor',
          value: 3
        }
      ],
      first_name: '',
      last_name: '',
      patronymic: '',
      email: '',
      password: '',
      passwordConfirmed: '',
      errors: []
    }
  },
  computed: {
    serverError() {
      return this.$store.getters.getServerError
    },
    user() {
      return this.$store.getters.getUser
    }
  },
  methods: {
    async register() {
      this.errors = validators.register({
        email: this.email,
        password: this.password,
        first_name: this.first_name,
        last_name: this.last_name,
        patronymic: this.patronymic,
        passwordConfirmed: this.passwordConfirmed,
        roles: this.roles
      })

      if (!this.errors.length) {
        await this.$store.dispatch('register', {
          email: this.email,
          password: this.password,
          first_name: this.first_name,
          last_name: this.last_name,
          patronymic: this.patronymic,
          password_confirmed: this.passwordConfirmed,
          role_id: this.roles
        })
        if (this.user) {
          this.$router.push({
            name: 'login'
          })
        }
      }
    }
  },
  mounted() {
    if (localStorage.userToken) {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style scoped></style>

<template>
  <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.35904 11.5001L9.48329 13.8334L13.7318 9.16675"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5455 1C13.0264 3.411 16.2644 4.66633 19.5735 4.5C20.0553 6.30018 20.2027 8.18838 20.007 10.0524C19.8113 11.9164 19.2765 13.7181 18.4343 15.3506C17.5921 16.9831 16.4598 18.4129 15.1046 19.5552C13.7494 20.6975 12.199 21.5289 10.5455 22C8.89195 21.5289 7.34154 20.6975 5.98634 19.5552C4.63114 18.4129 3.4988 16.9831 2.65663 15.3506C1.81446 13.7181 1.27963 11.9164 1.08392 10.0524C0.888218 8.18838 1.03563 6.30018 1.5174 4.5C4.82648 4.66633 8.06451 3.411 10.5455 1"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShildIcon'
}
</script>

<style scoped></style>

<template>
    <div class="full-screen">
        <div class="form">
            <div class="close-parent">
                <close-icon @click="closeModal"/>
            </div>
            <h3>Забыли пароль?</h3>
            <p>Укажите свой e-mail, под которым вы зарегистрированы на сайте, и мы отправим на него письмо с ссылкой
                для создания нового пароля</p>
            <input type="text" placeholder="E-mail" v-model="email">
            <default-button @click="reset" :disabled="disabled">
                Отправить
            </default-button>
        </div>
    </div>
</template>

<script>
import DefaultButton from "@/components/helppers/DefaultButton";

export default {
    name: 'ForgetPopup',
    components: {DefaultButton},
    data(){
        return{
            email:'',
            disabled:false
        }
    },
    methods:{
        async reset(){
            this.disabled = true
            await this.$store.dispatch('resetPassword',{email:this.email})
            this.closeModal()
        },
        closeModal(){
            this.$store.dispatch('changeForgetPopup', false)
        }
    }
}
</script>

<style scoped lang="scss">
.full-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(238, 240, 244, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

.form {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    max-width: 490px;
    height: max-content;
    min-height: 355px;
    background: #ffffff;
    box-shadow: 0 4px 50px rgba(189, 189, 189, 0.7);
    border-radius: 20px;
    right: 0;
    bottom: 0;
    padding: 47px 59px 59px 59px;
    display: flex;
    flex-direction: column;

    h3 {
        margin-bottom: 15px;
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #373A43;
    }

    p {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #373A43;
        margin-bottom: 35px;
    }

    input {
        background: #EDEFF3;
        border-radius: 5px;
        border: none;
        font-family: 'Roboto',sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #373A43;
        margin-bottom: 26px;
        padding: 15px 17px;

        &:focus-visible {
            outline: none;
        }
    }

    button {
        width: 121px;
        height: 50px;
        margin: 0 auto;
    }
}

.close-parent {
    width: 11px;
    height: 11px;
    position: absolute;
    top: 16px;
    right: 27px;
    cursor: pointer;

    svg {
        width: inherit;
        height: inherit;
        top: 0;
        position: absolute;
    }
}

@media screen and (max-width: 769px) {
    .form {
        padding: 25px;
    }
}

</style>

<template>
    <div class="container header-3">
        <div class="row">
            <div class="col">
                <div class="menu-map">
                    Главная . Терапевты
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="page-title">
                    Терапевты в Москве
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="container header-3">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-check form-switch">
                                <switch-icon/>
                                <label class="form-check-label" for="flexSwitchCheckDefault">Показать детских
                                    врачей</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="search-div" @click="openSortDrop">
                                <div class="search-input">
                  <span class="sort desktop-only">
                    Сортировать:
                  </span>
                                    <span> {{ sortArray[sortIndex] }} </span>
                                    <search-list-arrow-down style="margin-left: 9px"></search-list-arrow-down>
                                </div>
                            </div>
                            <div class="sort-popup" v-if="sortShow">
                                <div class="sort-popup-item" :key="index" @click="selectSort(index)"
                                     v-for="(item,index) in sortArray">
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <div>
                                <div class="size-parent" @click="openSizeShow">
                                    <div class="img-size">
                                        {{ zoomArray[zoomIndex].name }}
                                        <search-list-arrow-down style="margin-left: 9px"></search-list-arrow-down>
                                    </div>
                                    <div class="size-drop" v-if="sizeShow">
                                        <div class="drop-item" @click="zoomIn">
                                            Увеличить
                                        </div>
                                        <div class="drop-item" @click="zoomOut">
                                            Уменьшить
                                        </div>
                                        <div class="drop-item" :key="index" @click="zoomTo(item.value,index)"
                                             v-for="(item,index) in zoomArray">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="eclips">
                                    <div class="eclipse-left" @click="zoomOut">
                                        -
                                    </div>
                                    <div class="eclipse-right" @click="zoomIn">
                                        +
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="d3-chart">
        <svg ref="chart" width="100%" height="500" :viewBox="`0 0 ${viewWidth} ${viewHeight}`"></svg>
    </div>
</template>

<script setup>
import * as d3 from 'd3';
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import SwitchIcon from "@/components/icons/SwitchIcon.vue";
import SearchListArrowDown from "@/components/icons/SearchListArrowDown.vue";

onMounted(async ()=>{
    if (localStorage.getItem('neuralParams')) {
        await store.commit('setNeuralParams', JSON.parse(localStorage.getItem('neuralParams')))
    }
    await store.dispatch('getNeural');
})

const chart = ref(null)
const zoom = ref(null)
const svg = ref(null)
const simulation = ref(null)
let transformGlobal = d3.zoomIdentity

const viewWidth = ref(2500)
const viewHeight = ref(2500)

const width = ref(0)
const height = ref(0)

const maxRange = ref(2300);

const router = useRouter();

const zoomArray = ref([
    {name: 'Показать сразу', value: 1},
    {name: '100%', value: 10},
    {name: '50%', value: 5},
    {name: '25%', value: 3},
    {name: '13%', value: 2},
    {name: '6%', value: 1},
])

const zoomIndex = ref(0)

const sortArray = ref([
    "По популярности",
    "По дате регистрации",
    "По активности на сайте",
    "По количеству рекомендаций"
])

const sortIndex = ref(0)

const sortShow = ref(false)
const sizeShow = ref(false)

const openSortDrop = () => {
    if (!sortShow.value) {
        setTimeout(() => {
            document.addEventListener('click', closeSortDrop)
        })
    } else {
        setTimeout(() => {
            document.removeEventListener('click', closeSortDrop)
        }, 100)
    }
    sortShow.value = !sortShow.value
}
const closeSortDrop = (e) => {
    if (!e.target.closest('.sort-popup') && sortShow.value) {
        sortShow.value = false
        setTimeout(() => {
            document.removeEventListener('click', closeSortDrop)
        }, 100)
    }
}

const openSizeShow = () => {
    if (!sizeShow.value) {
        setTimeout(() => {
            document.addEventListener('click', closeSizeShow)
        })
    } else {
        setTimeout(() => {
            document.removeEventListener('click', closeSizeShow)
        }, 100)
    }
    sizeShow.value = !sizeShow.value
}
const closeSizeShow = (e) => {
    if (!e.target.closest('.size-drop') && sizeShow.value) {
        sizeShow.value = false
        setTimeout(() => {
            document.removeEventListener('click', closeSizeShow)
        }, 100)
    }
}


const zoomIn = () => {
    const transform = transformGlobal || d3.zoomTransform(svg.value.node());
    const newScale = transform.k + 0.4 > 10 ? 10 : transform.k + 0.4;

    svg.value.transition()
        .call(zoom.value.transform, d3.zoomIdentity.translate(transform.x, transform.y).scale(newScale));
};

const zoomOut = () => {
    const transform = transformGlobal || d3.zoomTransform(svg.value.node());
    const newScale = transform.k - 0.4 < 2 ? 2 : transform.k - 0.4;

    svg.value.transition()
        .call(zoom.value.transform, d3.zoomIdentity.translate(transform.x, transform.y).scale(newScale));
};
const zoomTo = (to, index) => {
    console.log(to)
    zoomIndex.value = index
    const transform = transformGlobal || d3.zoomTransform(svg.value.node());
    svg.value.transition()
        .call(zoom.value.transform, d3.zoomIdentity.translate(transform.x, transform.y).scale(to));
};


const selectSort = (index) => {
    sortIndex.value = index
}

const store = useStore();
const getNeuralData = computed(() => {
    return store.getters.getNeural
})

watch(
    () => getNeuralData.value,
    (newValue) => {
        if (newValue) {
            svg.value?.selectAll('*').remove();
            const data = JSON.parse(JSON.stringify(newValue))
            viewWidth.value = data.nodes.length * 300 > 2500 ? data.nodes.length * 300 : 2500
            viewHeight.value = data.nodes.length * 300 > 2500 ? data.nodes.length * 300 : 2500
            maxRange.value = data.nodes.length * 300 - 200 > 2300 ? data.nodes.length * 300 - 200 : 2300
            init(data)
        }
    }
)


const init = (data) => {
    width.value = chart.value.clientWidth
    height.value = chart.value.clientHeight

    svg.value = d3.select(chart.value)


    zoom.value = d3.zoom()
        .scaleExtent([1, 20])
        .on('zoom', (event) => {
            transformGlobal = event.transform
            svg.value.attr('transform', transformGlobal)
        })

    const scale = viewWidth.value / 1000
    svg.value
        .call(zoom.value.transform, d3.zoomIdentity.scale(scale))

    const drag = d3.drag()
        .on('drag', (event) => {
            const {dx, dy} = event
            transformGlobal = transformGlobal.translate(dx / transformGlobal.k, dy / transformGlobal.k)
            svg.value.attr('transform', transformGlobal)
        })

    svg.value.call(drag)

    // d3.select(chart.value).call(zoom.value);

    const links = svg.value.selectAll("path")
        .data(data.links)
        .enter()
        .append("path")
        .attr("stroke", d => {
            return d.source === data.nodes[0].id ? "#02B061" : "#C0C4CD"
        })
        .attr("stroke-width", d => {
            return d.source === data.nodes[0].id ? "2" : "1"
        })
        .attr("fill", "none")
        .attr("id", (d, i) => `link${i}`)

    svg.value.append("defs").append("marker")
        .attr("id", "arrowhead")
        .attr("viewBox", "0 0 10 10")
        .attr("refX", 6) // x-coordinate of the arrow's endpoint
        .attr("refY", 5) // y-coordinate of the arrow's endpoint
        .attr("markerWidth", 10) // width of the marker viewport
        .attr("markerHeight", 10) // height of the marker viewport
        .attr("orient", "auto-start-reverse")
        .append("path")
        .attr("d", "M0,0 L6,5 L0,10 L1,10 L7,5 L1,0 Z") // path for the arrowhead shape
        .attr("fill", "none") // color of the arrowhead
        .attr("stroke", "#C0C4CD") // no stroke on the arrowhead


    const linkCircleGreenParent = svg.value
        .selectAll("circle.link-circle-parent")
        .data(data.links.filter(d => d.source === data.nodes[0].id))
        .enter()
        .append('circle')
        .attr("stroke-width", 2)
        .attr("stroke", "#02B061")
        .attr("fill", "white")
        .attr('class', 'link-circle-parent');

    const linkCircleGreenChild = svg.value
        .selectAll("circle.link-circle-child")
        .data(data.links.filter(d => d.source === data.nodes[0].id))
        .enter()
        .append('circle')
        .attr("stroke-width", 2)
        .attr("stroke", "#02B061")
        .attr("fill", "white")
        .attr('class', 'link-circle-child');

    const linkCircleGray = svg.value
        .selectAll("circle.link-circle")
        .data(data.links.filter(d => d.source !== data.nodes[0].id))
        .enter()
        .append('circle')
        .attr("stroke-width", 1.)
        .attr("stroke", "#D3D8E2")
        .attr("fill", "white")
        .attr('class', 'link-circle');


    const text = svg.value.selectAll("text")
        .data(data.links)
        .enter()
        .append("text")

    const textPath = text
        .append('textPath')
        .attr("class", "line-text")
        .attr("dy", "10")
        .attr('xlink:href', (d, i) => `#link${i}`)
        .attr("startOffset", "50%")
        .attr("text-anchor", "middle")

    const nodes = svg.value.selectAll("circle.node-circle")
        .data(data.nodes)
        .enter()
        .append("circle")
        .attr('class', 'node-circle')
        .attr("stroke", d => {
            switch (d.level) {
                case 3:
                    return "#E8EAED";
                default:
                    return "#02B061"
            }
        })
        .attr("stroke-width", d => {
            switch (d.level) {
                case 3:
                    return 2;
                default:
                    return 3.33
            }
        })
        .attr("r", d => {
            switch (d.level) {
                case 1:
                    return 106.5;
                case 2:
                    return 96.5;
                case 3:
                    return 86.5;
            }
        })
        .on("click", function (e) {
            let id = e.target.__data__.id
            router.push({
                name: 'doctor-page',
                params: {id}
            })
        })
        .attr("fill", "white");

    const nodeGroupImages = svg.value.selectAll("g.circle-images")
        .data(data.nodes)
        .enter()
        .append("g")
        .attr('class', 'circle-images')
        .style("pointer-events", "none");


    const nodeGroupCircle = nodeGroupImages
        .append("defs")
        .append("clipPath")
        .attr('id', d => `clip${d.id}`)
        .append("circle")
        .attr("r", d => {
            switch (d.level) {
                case 1:
                    return 26.5;
                case 2:
                    return 20;
                case 3:
                    return 16.5;
            }
        })
        .attr("fill", "#000000")

    const nodeGroupCircleImages = nodeGroupImages
        .append("image")
        .attr("width", d => {
            switch (d.level) {
                case 1:
                    return 53;
                case 2:
                    return 40;
                case 3:
                    return 33;
            }
        })
        .attr("height", d => {
            switch (d.level) {
                case 1:
                    return 53;
                case 2:
                    return 40;
                case 3:
                    return 33;
            }
        })
        .attr("href", d => `/img/doctors/${d.image}`)
        .attr("clip-path", d => `url(#clip${d.id}`)


    const nodeGroupFavoriteImages = svg.value.selectAll("g.circle-favorite-images")
        .data(data.nodes)
        .enter()
        .append("g")
        .attr('class', 'circle-favorite-images')
        .style("pointer-events", "none");


    const nodeGroupFavoriteCircle = nodeGroupFavoriteImages
        .append("defs")
        .append("clipPath")
        .attr('id', d => `clipFavorite${d.id}`)
        .append("circle")
        .attr("r", d => {
            switch (d.level) {
                case 1:
                    return 10;
                case 2:
                    return 10;
                case 3:
                    return 6.5;
            }
        })
        .attr("fill", "#000000")

    const nodeGroupFavoriteCircleImages = nodeGroupFavoriteImages
        .append("image")
        .attr("width", d => {
            switch (d.level) {
                case 1:
                    return 20;
                case 2:
                    return 20;
                case 3:
                    return 13;
            }
        })
        .attr("height", d => {
            switch (d.level) {
                case 1:
                    return 20;
                case 2:
                    return 20;
                case 3:
                    return 13;
            }
        })
        .attr("href", "./img/graph_photos/favorite.svg")
        .attr("clip-path", d => `url(#clipFavorite${d.id}`)


    const nodeText = svg.value.selectAll('foreignObject.obj-doctor-name')
        .data(data.nodes)
        .enter()
        .append("foreignObject")
        .attr('class', 'obj-doctor-name')
        .attr("width", d => {
            switch (d.level) {
                case 1:
                    return 200;
                case 2:
                    return 180;
                case 3:
                    return 160;
            }
        })
        .attr("height", d => {
            switch (d.level) {
                case 1:
                    return 70;
                case 2:
                    return 65;
                case 3:
                    return 63;
            }
        })
        .style("pointer-events", "none");


    nodeText.append("xhtml:body")
        .attr("class", "doctor-body")
        .html(d =>
            `<p class="doctor-name${d.level}">${d.fullName}</p><p class="doctor-profession${d.level}">${d.specialization.join()}</p>
             <div class="recommendations${d.level}">
             <div class="recommended"><img src="/img/graph_photos/recommendedArrow.svg" alt="arrow">${d.my_recommendations_count}</div>
             <div class="recommends"><img src="/img/graph_photos/recommendsArrow.svg" alt="arrow">${d.recommended_count}</div> </div>`
        );


    const arcPath = (leftHand, d, circle = false) => {
        const angle = Math.atan2(d.target.y - d.source.y, d.target.x - d.source.x);
        let distance
        switch (d.source.level) {
            case 1:
                distance = 120
                break
            case 2:
                distance = 110
                break
            default:
                distance = 100

        }
        const start = leftHand
                ? d.source
                : d.target,
            end = leftHand
                ? d.target
                : d.source;
        if (!circle) {
            return leftHand
                ? `M${start.x + Math.cos(angle) * distance},${start.y + Math.sin(angle) * distance} L${end.x - Math.cos(angle) * distance},${end.y - Math.sin(angle) * distance}`
                : `M${start.x - Math.cos(angle) * distance},${start.y - Math.sin(angle) * distance} L${end.x + Math.cos(angle) * distance},${end.y + Math.sin(angle) * distance}`
        } else {
            let coords = {}
            coords.x = leftHand ? start.x + Math.cos(angle) * distance : end.x + Math.cos(angle) * distance
            coords.y = leftHand ? start.y + Math.sin(angle) * distance : end.y + Math.sin(angle) * distance
            coords.bx = leftHand ? end.x - Math.cos(angle) * distance : start.x - Math.cos(angle) * distance
            coords.by = leftHand ? end.y - Math.sin(angle) * distance : start.y - Math.sin(angle) * distance
            return coords
        }

    }

    simulation.value = d3.forceSimulation(data.nodes)
        .force("link", d3.forceLink(data.links).id(d => d.id).distance(250))
        .force("charge", d3.forceManyBody().strength(-500))
        .force("center", d3.forceCenter(viewWidth.value / 2, viewHeight.value / 2))
        .force("collision", d3.forceCollide().radius(() => 250))
        .on("tick", () => {
            nodes.attr("cx", d => {
                const maxExtent = d3.max(data.nodes, d => Math.max(Math.abs(d.x - viewWidth.value / 2), Math.abs(d.y - height.value / 2)));
                if (maxExtent + 115 > maxRange.value) {
                    const scalingFactor = maxRange.value / (maxExtent + 115);
                    d.x = (d.x - viewWidth.value / 2) * scalingFactor + viewWidth.value / 2;
                }
                return d.x
            })
                .attr("cy", d => {
                    const maxExtent = d3.max(data.nodes, d => Math.max(Math.abs(d.x - viewWidth.value / 2), Math.abs(d.y - height.value / 2)));
                    if (maxExtent + 115 > maxRange.value) {
                        const scalingFactor = maxRange.value / (maxExtent + 115);
                        d.y = (d.y - viewHeight.value / 2) * scalingFactor + viewHeight.value / 2;
                    }
                    return d.y
                })


            nodeText.attr("x", d => {
                switch (d.level) {
                    case 1:
                        return d.x - 100;
                    case 2:
                        return d.x - 90;
                    case 3:
                        return d.x - 80;
                }
            })
                .attr("y", d => d.y - 8)


            nodeGroupCircle.attr("cx", d => d.x)
                .attr("cy", d => {
                    switch (d.level) {
                        case 1:
                            return d.y - 43;
                        case 2:
                            return d.y - 45;
                        case 3:
                            return d.y - 39;
                    }
                })
            nodeGroupCircleImages.attr("x", d => {
                switch (d.level) {
                    case 1:
                        return d.x - 26.5;
                    case 2:
                        return d.x - 20;
                    case 3:
                        return d.x - 16.5;
                }
            })
                .attr("y", d => {
                    switch (d.level) {
                        case 1:
                            return d.y - 69.5;
                        case 2:
                            return d.y - 65;
                        case 3:
                            return d.y - 55.5;
                    }
                })


            nodeGroupFavoriteCircle.attr("cx", d => {
                switch (d.level) {
                    case 1:
                        return d.x + 16.5;
                    case 2:
                        return d.x + 16;
                    case 3:
                        return d.x + 12;
                }
            })
                .attr("cy", d => {
                    switch (d.level) {
                        case 1:
                            return d.y - 20.5;
                        case 2:
                            return d.y - 31.5;
                        case 3:
                            return d.y - 25.5;
                    }
                })
            nodeGroupFavoriteCircleImages.attr("x", d => {
                switch (d.level) {
                    case 1:
                        return d.x + 6.5;
                    case 2:
                        return d.x + 6;
                    case 3:
                        return d.x + 5.5;
                }
            })
                .attr("y", d => {
                    switch (d.level) {
                        case 1:
                            return d.y - 30.5;
                        case 2:
                            return d.y - 41.5;
                        case 3:
                            return d.y - 32;
                    }
                })


            links
                .attr("d", d => arcPath(d.source.x < d.target.x, d))
                .attr("marker-start", d => {
                    return d.source.id !== data.nodes[0].id && d.source.x > d.target.x ? "url(#arrowhead)" : ""
                })
                .attr("marker-end", d => {
                    return d.source.id !== data.nodes[0].id && d.source.x < d.target.x ? "url(#arrowhead)" : ""
                })
            linkCircleGreenParent
                .attr("r", "4")
                .attr("cx", d => arcPath(d.source.x < d.target.x, d, true).x)
                .attr("cy", d => arcPath(d.source.x < d.target.x, d, true).y)

            linkCircleGreenChild
                .attr("r", "4")
                .attr("cx", d => arcPath(d.source.x < d.target.x, d, true).bx)
                .attr("cy", d => arcPath(d.source.x < d.target.x, d, true).by)

            linkCircleGray
                .attr("r", "2.7")
                .attr("cx", d => arcPath(d.source.x < d.target.x, d, true).x)
                .attr("cy", d => arcPath(d.source.x < d.target.x, d, true).y)

            text.attr("dy", "-10")

            textPath.text(d => d.text)
        })
}

</script>

<style lang="scss">
.d3-chart {
    width: 100%;
    overflow: hidden;
    height: 500px;

    .node-circle {
        cursor: pointer;
    }

    .link {
        stroke: #999;
        stroke-opacity: 0.6;
    }

    .node {
        fill: #ccc;
        stroke: #fff;
        stroke-width: 1.5px;
    }

    .line-text {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 8.66706px;
        line-height: 12px;
        color: #656873;
    }

    .doctor-body {
        background: none;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            margin: 0;
        }

        .doctor-name1 {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #373A43;
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 11.3338px;
            line-height: 13px;
            margin-bottom: 5.5px;
            text-align: center;
            max-width: 166px;
        }

        .doctor-profession1 {
            color: #656873;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 8.66706px;
            line-height: 12px;
            text-align: center;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-width: 166px;
        }

        .doctor-name2 {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #373A43;
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10.6671px;
            line-height: 13px;
            margin-bottom: 3.5px;
            text-align: center;
            max-width: 166px;
        }

        .doctor-profession2 {
            color: #656873;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 8.66706px;
            line-height: 12px;
            text-align: center;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-width: 166px;
        }

        .doctor-name3 {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #373A43;
            margin-bottom: 3.5px;
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10.0004px;
            line-height: 12px;
            text-align: center;
            max-width: 160px;
        }

        .doctor-profession3 {
            color: #656873;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 8.00036px;
            line-height: 12px;
            text-align: center;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-width: 160px;
        }

        .recommendations1, .recommendations2, .recommendations3 {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 8.00036px;
            line-height: 9px;
            color: #373A43;
            display: flex;
        }

        .recommendations1 {
            gap: 6.6px;

            .recommended,
            .recommends {
                width: 31.3px;
                height: 20px;
                border: 0.666697px solid #DBE0EB;
                border-radius: 33.3348px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                position: relative;
            }

            .recommended img, .recommends img {
                width: 7.62px;
                height: 8px;
            }

            .recommends img {
                position: relative;
                top: -2px;
            }
        }

        .recommendations2 {
            gap: 5.8px;

            .recommended,
            .recommends {
                width: 27px;
                height: 17.3px;
                border: 0.666697px solid #DBE0EB;
                border-radius: 33.3348px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                position: relative;
            }

            .recommended img, .recommends img {
                width: 6.6px;
                height: 6.93px;
            }

            .recommends img {
                position: relative;
                top: -2px;
            }
        }

        .recommendations3 {
            gap: 5.3px;

            .recommended,
            .recommends {
                width: 25px;
                height: 16px;
                border: 0.666697px solid #DBE0EB;
                border-radius: 33.3348px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                position: relative;
            }

            .recommended img, .recommends img {
                width: 6.1px;
                height: 6.4px;
            }

            .recommends img {
                position: relative;
                top: -2px;
            }
        }

    }
}

.header-3 {
    max-width: 1597px !important;
    margin-top: 43px;
    padding: 0 !important;

    .size-parent {
        position: relative;
        cursor: pointer;
    }

    .size-drop {
        box-sizing: border-box;
        position: absolute;
        width: 148px;
        height: 342px;
        background: #FFFFFF;
        border: 1px solid #DBE0EB;
        box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.3);
        border-radius: 10px;
        right: 0;
        top: 63px;
        z-index: 9;
    }

    .drop-item {
        width: 130px;
        height: 36px;
        line-height: 36px;
        margin: auto;
        padding-left: 19px;
        border-radius: 5px;
        text-align: left;
        margin-top: 6px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        color: #373A43;
        cursor: pointer;
    }

    .drop-item:hover {
        background: #EDEFF3;
    }

    .form-switch {
        padding: 0 !important;
    }

    .col-md-3 {
        padding: 0 !important;
    }

    .menu-map {
        font-family: 'Museo Sans Cyrl';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        color: #9195A4;
    }

    .page-title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        color: #373A43;
    }

    .eclips {
        float: right;
        text-align: right;
        cursor: pointer;
        display: flex;
        margin-right: 31px;
    }

    .eclipse-left {
        width: 50px;
        height: 46px;
        line-height: 40px;
        border: 1px solid #DBE0EB;
        text-align: center;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .eclipse-right {
        width: 50px;
        height: 46px;
        line-height: 40px;
        border: 1px solid #DBE0EB;
        text-align: center;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
    }

    .form-check-label {
        margin: 5px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        color: #656873;
    }

    .search-div {
        cursor: pointer;
        min-width: 235px;
        height: 46px;
        line-height: 46px;
        border: 1px solid #DBE0EB;
        border-radius: 50px;
        width: fit-content;
        display: flex;
    }

    .search-input {
        margin-right: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        color: #373A43;
    }

    .sort {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        color: #7F818A;
        margin-left: 17px;
        margin-right: 3px;
    }

    .sort-popup {
        z-index: 99999;
        position: absolute;
        width: 235px;
        height: 181px;
        background: #FFFFFF;
        border: 1px solid #DBE0EB;
        box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.3);
        border-radius: 10px;
        top: 50px;
    }

    .sort-popup-item {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 40px;
        color: #373A43;
        text-align: left;
        padding-left: 20px;
    }

    .sort-popup-item:hover {
        background: #EDEFF3;
        border-radius: 5px;
    }

    .img-size {
        width: auto;
        height: 46px;
        border: 1px solid #DBE0EB;
        border-radius: 50px;
        font-family: 'Roboto';
        padding: 0 12px;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        color: #373A43;
        text-align: center;
        line-height: 46px;
        float: right;
    }

}

</style>

<template>
  <div class="location-popup">
    <font-awesome-icon
      class="close"
      icon="close"
      @click="$emit('close')"
      color="#BDC3C9"
    ></font-awesome-icon>
    <div class="location-title">Ваш город определен верно?</div>
    <div class="location-choose">
      <button-helper @click="$emit('close')">Да, верно</button-helper>
      <span class="location-no" @click="openSelectCity">Нет, выбрать другой</span>
    </div>
  </div>
</template>

<script>
import buttonHelper from '@/components/helppers/DefaultButton'

export default {
  name: 'LocationQuestion',
  components: {
    buttonHelper
  },
  mounted() {
    setTimeout(()=>{
      document.addEventListener('click',this.closeLocationPopup)
    },100)
  },
  methods: {
    openSelectCity() {
      this.$emit('close')
      this.$store.dispatch('changeSelectCityPopup', true)
    },
    closeLocationPopup(e){
      if(!e.target.closest('.location-popup')){
        this.$emit('close')
        document.addEventListener('click',this.closeLocationPopup)
      }
    }
  }
}
</script>

<style scoped>
.button-helper {
  color: #ffffff;
  background: #02b061;
  height: 46px;
  width: 102px;
  padding: 0;
  border: none;
  line-height: 15px;
}

.close {
  position: absolute;
  right: 12px;
  top: 13px;
  cursor: pointer;
}

.location-popup {
  padding: 26px 36px;
  position: absolute;
  width: 352px;
  text-align: center;
  height: 145px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
  border-radius: 20px;
  left: 50;
  top: 50px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.location-choose {
  margin-top: 21px;
}

.location-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #373a43;
}

.location-no {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #24ad6f;
  margin-left: 34px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .location-popup {
    width: 200px;
    height: auto;
    text-align: center;
  }
  .location-choose {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }
  .location-no {
    margin-left: 0;
  }
}
</style>

<template>
    <div class="doctors-list-top">
        <div class="doctors-list-sort">
            <div class="search-div" @click="changeShowSort(!showSort)">
                <div class="search-input">
                    <span class="sort"> {{ sortBy }}: </span>
                    <span v-if="sortValue">{{ sortValue }}</span>
                    <span v-else>Все</span>
                    <search-list-arrow-down
                        v-if="!showSort"
                        style="margin-left: 9px"
                    />
                    <arrow-up v-else style="margin-left: 9px"/>
                </div>
            </div>
            <div class="sort-popup" v-if="showSort">
                <div class="sort-popup-item" @click="changeSortValue(null)">
                    Все
                </div>
                <div class="sort-popup-item" v-for="item in dropDownList" :key="item.id" @click="changeSortValue(item.name)">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchListArrowDown from "@/components/icons/SearchListArrowDown.vue";
import ArrowUp from "@/components/icons/ArrowUp.vue";

export default {
    name: 'FilterPersonalCabinet',

    components: {
        ArrowUp,
        SearchListArrowDown,
    },

    props: {
        showSort: {
            type: Boolean,
            required: true,
            default: false
        },
        sortValue: {
            type: String,
            required: true
        },
        dropDownList:{
            type:Array
        },
        sortBy: {
            type: String,
            default: 'Сортировать'
        }
    },

    methods: {
        changeShowSort(showSort) {
            this.$emit('changeShowSort', showSort)
        },
        changeSortValue(sorting) {
            this.$emit('changeSortValue', sorting)
            this.changeShowSort(false)
        }
    }

}
</script>

<style scoped>

.doctors-list-top {
    display: flex;
    align-items: center;
    column-gap: 150px;

}

.doctors-list-sort {
    position: relative
}

.search-div {
    display: flex;
    cursor: pointer;
    padding: 10px 16px;
    flex-wrap: nowrap;
    border: 1px solid #dbe0eb;
    border-radius: 50px;
    width: fit-content;
}

.search-input {
    white-space: nowrap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #373a43;
}

.sort {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #7f818a;
}

.sort-popup {
    z-index: 100;
    position: absolute;
    width: 235px;
    background: #ffffff;
    border: 1px solid #dbe0eb;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.3);
    border-radius: 10px;
    top: 60px;
    max-height: 355px;
    overflow-y: auto;
}

.sort-popup::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
}

.sort-popup::-webkit-scrollbar-track {
    background: rgba(203, 208, 217, 0.5);
    border-radius: 50px;
}

.sort-popup-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 40px;
    color: #373a43;
    cursor: pointer;
    text-align: left;
    padding-left: 20px;
}

.sort-popup-item:hover {
    background: #edeff3;
    border-radius: 5px;
}

#flexSwitchCheckDefault {
    width: 48px;
    height: 26px;
}

@media screen and (max-width: 1200px) {
    .doctors-list-top {
        column-gap: 25px;
    }
}

@media screen and (max-width: 769px) {
    .doctors-list-top {
        justify-content: center;
    }
}

</style>

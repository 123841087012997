<template>
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.13867 8.69942V3.45023C4.13867 3.1718 4.24928 2.90476 4.44616 2.70788C4.64305 2.511 4.91008 2.40039 5.18851 2.40039C5.46695 2.40039 5.73398 2.511 5.93086 2.70788C6.12774 2.90476 6.23835 3.1718 6.23835 3.45023V7.99953" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.23242 3.44962V2.04984C6.23242 1.91197 6.25958 1.77546 6.31234 1.64808C6.3651 1.52071 6.44243 1.40498 6.53991 1.30749C6.6374 1.21 6.75313 1.13267 6.8805 1.07991C7.00788 1.02715 7.14439 1 7.28226 1C7.42013 1 7.55664 1.02715 7.68402 1.07991C7.81139 1.13267 7.92712 1.21 8.02461 1.30749C8.1221 1.40498 8.19943 1.52071 8.25219 1.64808C8.30495 1.77546 8.3321 1.91197 8.3321 2.04984V7.99893" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.33594 3.45023C8.33594 3.1718 8.44655 2.90476 8.64343 2.70788C8.84031 2.511 9.10734 2.40039 9.38578 2.40039C9.66421 2.40039 9.93124 2.511 10.1281 2.70788C10.325 2.90476 10.4356 3.1718 10.4356 3.45023V7.99953" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4336 4.8516C10.4336 4.57316 10.5443 4.30613 10.7411 4.10925C10.938 3.91237 11.205 3.80176 11.4835 3.80176C11.7619 3.80176 12.0289 3.91237 12.2258 4.10925C12.4227 4.30613 12.5333 4.57316 12.5333 4.8516V10.8007C12.5333 11.9144 12.0909 12.9825 11.3034 13.7701C10.5158 14.5576 9.4477 15 8.33396 15H6.93418H7.07976C6.3843 15.0002 5.6997 14.8276 5.08743 14.4977C4.47516 14.1679 3.9544 13.6912 3.5719 13.1103C3.52599 13.0405 3.48026 12.9705 3.43472 12.9004C3.21635 12.5651 2.44997 11.229 1.13487 8.89138C1.0008 8.65309 0.964993 8.37189 1.03505 8.1076C1.10511 7.84332 1.27551 7.61679 1.51001 7.47619C1.75979 7.32632 2.05248 7.26421 2.3416 7.29971C2.63071 7.33522 2.89968 7.46631 3.10577 7.67216L4.13461 8.70101" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "DoctorBlockMobileIcon"
}
</script>

<style scoped>

</style>

<template>
    <div class="slider">
        <div class="row slider-row">
            <div class="col slider-close" @click="closeSlider">x</div>
            <div class="col img-parent">
                <div class="left-slider" @click="slideLeft">
                    <font-awesome-icon icon="chevron-left" color="#A4ACBD"/>
                </div>
                <div class="right-slider" @click="slideRight">
                    <font-awesome-icon icon="chevron-right" color="#A4ACBD"/>
                </div>
                <img class="img" :src="slideImageUrl" alt=""/>
                <div class="col images-small">
                    <div
                        class="slide-small"
                        v-for="(img, index) in images"
                        :key="img"
                        :class="index === slideImage ? 'border-slide' : ''"
                        @click="slideImage = index"
                    >
                        <img :src="`${main_url}/img/docs/${img}`" alt="" class="docs-img-slide"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config/config";

export default {
    name: 'PhotoSlider',

    emits: ['closeSlider'],

    props: {
        images: {
            required: true
        }
    },

    data() {
        return {
            slideImage: 0,
        }
    },

    computed: {
        main_url() {
            return config.URL
        },
        slideImageUrl() {
            return `${config.URL}/img/docs/${this.images[this.slideImage]}`
        },
    },

    methods: {
        closeSlider() {
            this.$emit('closeSlider')
        },

        slideRight() {
            if (this.slideImage < this.images.length - 1) {
                this.slideImage++
            } else {
                this.slideImage = 0
            }
        },
        slideLeft() {
            if (this.slideImage > 0) {
                this.slideImage--
            } else {
                this.slideImage = this.images.length - 1
            }
        },
    }
}
</script>

<style scoped lang="scss">

.slider {
    position: fixed;
    width: 100%;
    margin: 0;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #edeff3c7;
    z-index: 1;
}

.slider-row {
    position: relative;
}

.slider-close {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #dbe0eb;
    background-color: #fff;
    text-align: center;
    line-height: 49px;
    right: 20px;
    top: 10px;
    color: #a4acbd;
    font-size: 20px;
    z-index: 10;
}

.img-parent {
    position: relative;
    width: 80vw;
    text-align: center;
}
.img {
    object-fit: contain;
    width: 75vw;
    height: 70vh;
    margin-top: 10vh;
}

.left-slider {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #dbe0eb;
    text-align: center;
    background-color: #ffffff;
    line-height: 52px;
    font-size: 13px;
    left: 10vw;
    top: 42vh;
    box-shadow: 0 4px 40px rgba(132, 132, 151, 0.5);
}

.right-slider {
    position: absolute;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #dbe0eb;
    text-align: center;
    background-color: #ffffff;
    line-height: 52px;
    font-size: 13px;
    right: 10vw;
    top: 42vh;
    box-shadow: 0 4px 40px rgba(132, 132, 151, 0.5);
}

.docs-img-slide {
    width: 80px;
    height: 60px;
}

.slide-small {
    cursor: pointer;
    text-align: center;
    padding: 13px;
}

.slide-small:not(:last-child) {
    margin-right: 13px;
}

.images-small {
    text-align: center;
    display: flex;
    width: 200px;
    margin: 30px auto;
}

.border-slide {
    border: 1px solid #c0c4cd;
    border-radius: 5px;
}

</style>

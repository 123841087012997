<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.73194 2.39017C9.18161 0.536611 11.8184 0.536611 12.2681 2.39017C12.3355 2.66862 12.4678 2.92721 12.6541 3.14489C12.8404 3.36257 13.0754 3.53318 13.3401 3.64284C13.6048 3.75251 13.8917 3.79812 14.1773 3.77597C14.463 3.75383 14.7394 3.66454 14.984 3.51539C16.6127 2.52317 18.4779 4.38728 17.4857 6.01706C17.3367 6.26158 17.2476 6.5378 17.2255 6.82326C17.2034 7.10872 17.249 7.39537 17.3585 7.6599C17.468 7.92443 17.6384 8.15939 17.8559 8.34567C18.0733 8.53194 18.3316 8.66429 18.6098 8.73194C20.4634 9.18161 20.4634 11.8184 18.6098 12.2681C18.3314 12.3355 18.0728 12.4678 17.8551 12.6541C17.6374 12.8404 17.4668 13.0754 17.3572 13.3401C17.2475 13.6048 17.2019 13.8917 17.224 14.1773C17.2462 14.463 17.3355 14.7394 17.4846 14.984C18.4768 16.6127 16.6127 18.4779 14.9829 17.4857C14.7384 17.3367 14.4622 17.2476 14.1767 17.2255C13.8913 17.2034 13.6046 17.249 13.3401 17.3585C13.0756 17.468 12.8406 17.6384 12.6543 17.8559C12.4681 18.0733 12.3357 18.3316 12.2681 18.6098C11.8184 20.4634 9.18161 20.4634 8.73194 18.6098C8.66448 18.3314 8.53221 18.0728 8.34592 17.8551C8.15962 17.6374 7.92456 17.4668 7.65987 17.3572C7.39517 17.2475 7.10833 17.2019 6.82267 17.224C6.53702 17.2462 6.26063 17.3355 6.016 17.4846C4.38728 18.4768 2.52211 16.6127 3.51433 14.9829C3.66327 14.7384 3.75241 14.4622 3.77451 14.1767C3.79661 13.8913 3.75103 13.6046 3.6415 13.3401C3.53196 13.0756 3.36155 12.8406 3.14412 12.6543C2.92669 12.4681 2.66837 12.3357 2.39017 12.2681C0.536611 11.8184 0.536611 9.18161 2.39017 8.73194C2.66862 8.66448 2.92721 8.53221 3.14489 8.34592C3.36257 8.15962 3.53318 7.92456 3.64284 7.65987C3.75251 7.39517 3.79812 7.10833 3.77597 6.82267C3.75383 6.53702 3.66454 6.26063 3.51539 6.016C2.52317 4.38728 4.38728 2.52211 6.01706 3.51433C7.07261 4.15611 8.44061 3.58822 8.73194 2.39017Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4997 13.6663C12.2486 13.6663 13.6663 12.2486 13.6663 10.4997C13.6663 8.75077 12.2486 7.33301 10.4997 7.33301C8.75077 7.33301 7.33301 8.75077 7.33301 10.4997C7.33301 12.2486 8.75077 13.6663 10.4997 13.6663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMenuSettingActive"
}
</script>

<style scoped>

</style>

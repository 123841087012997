<template>
    <div class="category-left-menu desktop-only">

        <div class="category-left-menu__title">Все врачи</div>

        <div class="category-left-menu__items" v-if="specList">
            <div class="category-left-menu__item" @click="newUsers(spec.id)" v-for="spec in specialities" :key="spec.id">
                {{ spec.name }}
                <span class="doctors-count">
                    {{ spec.doctors.length }}
                </span>
            </div>
        </div>
        <div class="category-left-menu__items" v-else-if="specialities.length">
            <div class="category-left-menu__item"
                 v-for="item in 10"
                 :key="item"
                 @click="newUsers(specialities[item].id)"
            >
                {{ specialities[item].name }}
                <span class="doctors-count">
                    {{ specialities[item].doctors.length }}
                </span>
            </div>
        </div>

        <div class="category-left-menu__btn" @click="specList = !specList">
            {{ specListName }}
        </div>
    </div>
</template>

<script>
export default {

    name: 'CategoryLeftMenu',

    props: {
        specialities: {
            required: true
        }
    },

    data() {
        return {
            specList: false,
        }
    },

    computed: {
        specListName() {
            return this.specList ? 'Скрыть' : 'Развернуть все'
        },
    },

    methods: {
        async newUsers(id) {
            await this.$store.dispatch('getAllDoctors', {page: 1, spec: id})
            await this.$store.dispatch('getPageCount', {spec_id: id})
            this.$router.push(`category?spec=${id}`)
        }
    },

}
</script>

<style scoped lang="scss">

.category-left-menu {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &__title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #373a43;
    }

    &__items {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }

    &__item {
        cursor: pointer;
        font-family: 'Raleway';
        font-weight: 400;
        font-size: 15px;
        color: #373A43;

        &:hover {
            text-decoration-line: underline;
        }

        @media screen and (max-width: 1000px) {
            font-size: 12px;
        }
    }

    &__btn {
        cursor: pointer;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        text-decoration-line: underline;
        color: #656873;
    }
}

.doctors-count {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #7f818a;
    margin-left: 3px;
}

</style>

<template>
  <div class="user-footer desktop-only">
    <div class="row row-footer">
        <div class="col-md-2">
            <div class="col grow-1 title">Сервис</div>
            <div class="col grow-1 text">О нас</div>
            <div class="col grow-1 text">Вакансии</div>
            <div class="col grow-1 text">Контакты</div>
            <div class="col grow-1 text">FAQ</div>
            <div class="col grow-1 text">Отзывы</div>
            <div class="col grow-1 text">Карта сайта</div>
        </div>
        <div class="col-md-3">
            <div class="col grow-2 title">Пользователям</div>
            <div class="col grow-2 text">Врачи</div>
            <div class="col grow-2 text">Клиники</div>
            <div class="col grow-2 text">Диагностика</div>
            <div class="col grow-2 text">Услуги</div>
            <div class="col grow-2 text">Все врачи</div>
            <div class="col grow-2 text">Все клиники</div>
        </div>
        <div class="col-md-3">
            <div class="col grow-3 title">Правила сервиса</div>
            <div class="col grow-3 text">Персональные данные пользователей</div>
            <div class="col grow-3 text">Использование материалов сайта</div>
            <div class="col grow-3 text">Ответственность за информацию</div>
            <div class="col grow-3 text">Правила использования сервиса</div>
            <div class="col grow-3 text">Программа лояльности</div>
        </div>
        <div class="col-md-2">
            <div class="col grow-4 title">Справка</div>
            <div class="col grow-4 text">Вопросы и ответы</div>
            <div class="col grow-4 text">Пользователю</div>
            <div class="col grow-4 text">Специалисту</div>
            <div class="col grow-4 text">Клинике</div>
            <div class="col grow-4 text">Помощь</div>
        </div>
        <div class="col next-line">
            <div class="col grow-5 title next-line">Связаться с нами</div>
            <div class="col grow-5 text next-line">Телефон: 8 (495) 28-43-21</div>
            <div class="col grow-5 text next-line">E-mail: info@doc-connect.ru</div>
            <div class="col next-line" style="margin-bottom: -20px">
                <div class="container icon-container">
                    <div class="circle">
                        <vk-icon class="footer-icons"/>
                    </div>
                    <div class="circle">
                        <telegram-icon class="footer-icons"/>
                    </div>
                    <div class="circle">
                        <ok-icon class="footer-icons"/>
                    </div>
                    <div class="circle">
                        <youtube-icon class="footer-icons"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row line" style="border-bottom: 1px solid #dbe0eb; margin-top: 27px"></div>
    <div class="row new-line new-footer">
      <div class="col grow-1 text">© 2022, ООО «Связи врачей»</div>
      <div class="col grow-2 text">ОГРН 1122311003760</div>
      <div class="col grow-3 text">Телефон: 8 (495) 28-43-21</div>
      <div class="col grow-4 text">
        <div class="container icon-container">
            <div class="circle">
              <vk-icon class="footer-icons"/>
            </div>
            <div class="circle">
              <telegram-icon class="footer-icons"/>
            </div>
            <div class="circle">
              <ok-icon class="footer-icons"/>
            </div>
            <div class="circle">
              <youtube-icon class="footer-icons"/>
            </div>
        </div>
      </div>
    </div>
    <div class="row new-line">
      <div class="col grow-1 text"></div>
      <div class="col grow-2 text"></div>
      <div class="col grow-3 text">E-mail: info@doc-connect.ru</div>
      <div class="col grow-4 text"></div>
    </div>
    <div class="row line new-line" style="border-bottom: 1px solid #dbe0eb; margin-top: 27px"></div>
    <div class="row row-height next-line">
      <div class="col grow-1 text">© 2022, ООО «Связи врачей»</div>
      <div class="col grow-2 text">ОГРН 1122311003760</div>
      <div class="col grow-3 text footer-grow">
        Информация, представленная на сайте, не может быть использована для постановки диагноза,
        назначения лечения и не заменяет прием врача.
      </div>
    </div>
    <div class="row grow-4 row-height new-line">
      <div class="col text footer-grow">
        Информация, представленная на сайте, не может быть использована для постановки диагноза,
        назначения лечения и не заменяет прием врача.
      </div>
    </div>
  </div>
</template>

<script>
import VkIcon from '@/components/icons/VkIcon.vue'
import TelegramIcon from '@/components/icons/TelegramFooterIcon.vue'
import OkIcon from '@/components/icons/OkIcon.vue'
import YoutubeIcon from '@/components/icons/YoutubeIcon.vue'

export default {
  components: { YoutubeIcon, OkIcon, TelegramIcon, VkIcon }
}
</script>

<style scoped>
.footer-grow {
  flex-grow: 3;
  padding-right: 58px;
}

.user-footer {
  margin-top: auto;
  padding-top: 100px;
}

.row-footer {
    display: flex;
    justify-content: space-between;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 9px;
  color: #373a43;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 38px;
  color: #656873;
}

.row-icon {
  margin-top: 0px !important;
}
.icon-container {
  margin-top: 18px !important;
  display: flex;
  row-gap: 10px;
  column-gap: 20px;
    flex-wrap: wrap;
}

.row-height {
  margin-top: 31px;
}

.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #d3d8e2;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle:hover {
    border: 1px solid #02B061;
    .footer-icons {
        fill: #02B061;
    }
}
.new-line {
  display: none;
}

@media screen and (max-width: 1450px) {
  .new-line {
    display: flex;
  }
  .next-line {
    display: none;
  }
  .icon-container {
    margin-top: 4px !important;
  }
  .circle {
    width: 34px;
    height: 34px;
    padding: 0;
    margin-right: 11px;
  }
  .new-footer {
    margin-top: 30px !important;
  }
  .footer-grow {
    flex-grow: 3;
    padding-right: 0;
  }

  .grow-1 {
    flex-grow: 4;
  }
  .grow-2 {
    flex-grow: 3;
  }
  .grow-3 {
    flex-grow: 6;
  }
  .grow-4 {
    flex-grow: 4;
  }
}
@media screen and (max-width: 1450px) {
  .circle {
    margin-right: 5px;
  }
  .line {
    margin-left: 0;
    margin-right: 0;
  }
  .footer {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (max-width: 950px) {
  .footer {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

import axios from 'axios'
import store from '@/store'

console.log(store)
const instance = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.userToken?'Bearer '+ localStorage.userToken:''
    }
})
instance.interceptors.request.use(
    (config) => {
        store.commit('setLoader', true);
        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        }
        if(localStorage.userToken && !config.headers['Authorization']){
            config.headers['Authorization'] = 'Bearer '+localStorage.userToken
        }
        return config;
    }
)

instance.interceptors.response.use(
    (response) => {
        store.commit('setLoader', false);

        return response
    },
    (error) => {
        store.commit('setLoader', false);
        if(error.response.status === 401){
            localStorage.removeItem('userToken')
            window.location.reload()
        }

    }
)

export default instance

<template>
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 21L2.49875 16.1314C1.20342 14.057 0.734829 11.6004 1.1801 9.2184C1.62538 6.83639 2.95421 4.69107 4.91955 3.18131C6.88488 1.67155 9.35296 0.900098 11.8649 1.01038C14.3768 1.12066 16.7617 2.10518 18.5761 3.78088C20.3904 5.45658 21.5109 7.70943 21.729 10.1206C21.9471 12.5317 21.2481 14.937 19.7619 16.8893C18.2758 18.8416 16.1036 20.2081 13.6492 20.7345C11.1949 21.261 8.62542 20.9117 6.41857 19.7516L1 21"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.376 11.0131V11.0256"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.7644 11.0131V11.0256"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.9875 11.0131V11.0256"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SMSIcon'
}
</script>

<style scoped></style>

<template>
    <div class="auth-bg" @click.self="closeAuth">
        <div class="auth">
            <div class="name" v-if="user?.first_name">
                {{ user?.last_name }} {{ user?.first_name }} {{ user?.father_name }}
            </div>
            <div class="email">
                {{ user.email }}
            </div>
            <div class="user-menu-container">
                <div class="user-menu" @click="closeAuth" v-for="menuItem in menu" :key="menuItem.id">
                    <router-link class="user-menu-item" v-if="menuItem.icon" :to="{name:'dashboard',params:{id:menuItem.id}}"
                                 style="text-decoration: none;color: #373a43;">
                        <component :is="menuItem.icon" color="#02B061"/>
                        {{ menuItem.title }}
                    </router-link>
                </div>
            </div>
            <div class="logout-button">
                <button-helper color="#24AD6F" background="#FFFFFF"
                               @click="logout">Выйти
                </button-helper>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import config from "@/config/config";

export default {

    name: 'UserMenuPopup',

    components: {ButtonHelper},

    methods: {
        async logout() {
        await this.$store.dispatch('logout');
          this.$router.push({name: "home"})
          this.closeAuth()
        },
        closeAuth() {
            this.$emit('closeAuth')
        }
    },

    computed: {
        user() {
            return this.$store.getters.getUser
        },

        menu() {
            switch (this.user.role_id) {
                case 1 || 2:
                    return config.ADMIN_MANU_ITEMS
                case 3:
                    return config.DOCTOR_MANU_ITEM
                case 4:
                    return config.USER_MANU_ITEM
                default:
                    return null
            }
        },
    }
}
</script>

<style scoped lang="scss">

.auth-bg {
    position: absolute;
    top: 50px;
    z-index: 99999;
    right: 0;

    @media screen and (max-width: 769px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(237, 239, 243, 0.9)
    }
}

.auth {
    width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    text-align: left;
    padding: 34px 28px 28px;

    @media screen and (max-width: 769px) {
        position: absolute;
        bottom: 20px;
        width: 100%;
        padding: 30px 25px;
    }
}

.email {
    margin-top: 4px;
    margin-bottom: 20px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #373A43;
    word-break: break-all;
}

.name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #9195A4;
}

.user-menu-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.user-menu {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #373A43;
}
.user-menu-item {
    display: flex;
    align-items: center;
    column-gap: 12px;
    flex-wrap: nowrap;
}

.logout-button {
    margin-top: 23px;
}

</style>

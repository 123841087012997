<template>
  <div class="prompt-layout">
      <div class="prompt-layout__close">
          <font-awesome-icon
              icon="close"
              @click="closeModal"
              color="#BDC3C9"
          />
      </div>

      <slot></slot>

  </div>
</template>

<script>
export default {
  name: 'PromptPopupLayout',

    emits: ['closeModal'],

    methods: {
        closeModal() {
            this.$emit('closeModal')
        }
    },

}
</script>

<style scoped lang="scss">

.prompt-layout {
    background: #373A43;
    opacity: 0.7;
    border-radius: 10px;
    width: max-content;
    position: fixed;
    top: 70px;
    right: 50px;
    padding: 20px 15px 20px 20px;

    &__close {
        font-size: 20px;
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
    }
}

</style>

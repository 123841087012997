<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('changeSocial', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Социальные сети</div>
      <div class="inputs">
        <div class="icon-div">
          <font-awesome-icon
            icon="fa-brands fa-whatsapp"
            :color="left_whatsapp_color"
            class="icon-left"
          ></font-awesome-icon>
          <input type="number" placeholder="Whatsapp" class="input-soc" v-model="whatsapp" />
          <font-awesome-icon
            icon="circle-check"
            v-if="whatsapp"
            color="#02B061"
            class="icon-right"
            style="margin-right: 10px;"
          ></font-awesome-icon>
        </div>
        <div class="icon-div">
          <font-awesome-icon
            icon="fa-brands fa-telegram"
            :color="left_telegram_color"
            class="icon-left"
          ></font-awesome-icon>
          <input type="text" placeholder="Telegram" class="input-soc" v-model="telegram" />
          <font-awesome-icon
            icon="circle-check"
            v-if="telegram"
            color="#02B061"
            class="icon-right"
            style="margin-right: 10px;"
          ></font-awesome-icon>
        </div>
      </div>
      <div class="inputs">
        <div class="icon-div">
          <font-awesome-icon
            icon="fa-brands fa-instagram"
            :color="left_instagram_color"
            class="icon-left"
          ></font-awesome-icon>
          <input type="text" placeholder="Instagram" class="input-soc" v-model="instagram" />
          <font-awesome-icon
            icon="circle-check"
            v-if="instagram"
            color="#02B061"
            class="icon-right"
            style="margin-right: 10px;"
          ></font-awesome-icon>
        </div>
      </div>
      <div class="button">
        <button-helper color="#ffffff" background="#02B061" @click="addSocial"
          >Сохранить</button-helper
        >
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'

export default {
  name: 'SocialPopup',
  components: {
    ButtonHelper
  },
  data(){
    return {
      whatsapp: null,
      telegram: null,
      instagram: null
    }
  },
  computed: {
    doctor() {
      return this.$store.getters.getDoctorDetalies
    },
    loginUser(){
      return this.$store.getters.getUser
    },
  },
  methods: {
    async addSocial() {
        let data = {
            whatsapp: this.whatsapp,
            telegram: this.telegram,
            instagram: this.instagram,
        }

        if (this.loginUser.id == this.doctor.id) {
          await this.$store.dispatch('addSocial', data)
          
        }else{
          data.doctor_id = this.doctor.id;
          await this.$store.dispatch('addSocialDoctor', data);
        }
          this.$store.dispatch('getDoctorDetalies', this.doctor.id)
          this.$store.dispatch('changeSocial', false)

    }
  },
  mounted(){
    this.whatsapp = this.doctor.user_detales.whatsapp
    this.instagram = this.doctor.user_detales.instagram
    this.telegram = this.doctor.user_detales.telegram
  }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  width: 483px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}
.inputs {
  width: 330px;
  margin: 10px auto;
}

.input {
  width: 330px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}
.input-soc::-webkit-outer-spin-button,
.input-soc::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-soc[type='number'] {
  -moz-appearance: textfield;
}
.input-soc {
  width: 300px;
  height: 46px;
  margin-top: 20px;
  margin-left: 15px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 45px;
  color: #7f818a;
  autline: none;
}

.input:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.input-soc:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.icon-left {
  position: absolute;
  top: 35px;
  left: 30px;
}

.icon-right {
  position: absolute;
  top: 35px;
  right: 15px;
}

.icon-div {
  display: flex;
  position: relative;
}
.button {
  margin-top: 45px;
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 425px) {
    .form {
        width: 80%;
    }

    .inputs {
        width: 80%;
    }

    .input-soc {
        width: 100%;
        margin-left: 0;
    }

    .icon-div {
        justify-content: center;
        align-items: center;
    }

    .icon-left {
        left: 19px;
    }
}
</style>

<template>
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 3.66602H11.6667" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.66797 3.66602L2.33464 11.666C2.33464 12.0196 2.47511 12.3588 2.72516 12.6088C2.97521 12.8589 3.31435 12.9994 3.66797 12.9994H9.00131C9.35493 12.9994 9.69407 12.8589 9.94412 12.6088C10.1942 12.3588 10.3346 12.0196 10.3346 11.666L11.0013 3.66602" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.33594 3.66667V1.66667C4.33594 1.48986 4.40618 1.32029 4.5312 1.19526C4.65622 1.07024 4.82579 1 5.0026 1H7.66927C7.84608 1 8.01565 1.07024 8.14068 1.19526C8.2657 1.32029 8.33594 1.48986 8.33594 1.66667V3.66667" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "DoctorTrashMobileIcon"
}
</script>

<style scoped>

</style>

<template>

        <div class="doctor-item">
            <div class="doctor-item-main">
                <div class="doctor-item-img-bg">
                    <img :src="`${main_url}/img/doctors/${user.img}`" class="doctor-item-img"/>
                </div>
                <div class="doctor-item-text">
                    <span class="doctor-item-date">{{ getVerifiedDate(user.date) }}</span>
                    <span class="doctor-item-name">{{ user.full_name }}</span>
                    <span class="doctor-item-spec">{{ user.special }}</span>

                    <div class="doc-images" v-if="user.docs.length" @mouseenter="showSeacrhIcon = true" @mouseleave="showSeacrhIcon = false">
                        <div class="doc-image" v-for="img in user.docs" :key="img" @click="openSlider">
                            <img
                                v-if="!showSeacrhIcon"
                                :src="`${main_url}/img/docs/${img}`"
                                alt=""
                                class="docs-img"
                            />
                            <div class="icon-part" v-if="showSeacrhIcon">
                                <admin-search-plus/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="doctor-item-button">
                <button-helper
                    style="border-color: #D3D8E2; min-width: 120px"
                    color="#373A43"
                    background="#ffffff"
                    @click="openDrop"
                >
                    Выбрать
                    <arrow-down style="margin-left: 10px; transition: .2s" :class="{'arrow-icon': hidden}"/>
                </button-helper>

                <div class="hidden" v-if="hidden">
                    <div class="arrow-up"></div>
                    <div class="line-hidden" @click="approveDoctor">
                        <font-awesome-icon style="margin-right: 10px;" icon="check" color="#A4ACBD"></font-awesome-icon>
                        Документы проверены
                    </div>
                    <div class="line-hidden" @click="openModal">
                        <font-awesome-icon style="margin-right: 10px;" icon="close" color="#A4ACBD"></font-awesome-icon>
                        Требуется повторная проверка
                    </div>
                </div>
            </div>
        </div>

    <photo-slider v-if="slider" @close-slider="slider = false" :images="user?.docs"/>

  <div class="full-screen" v-if="showModal">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          color="#BDC3C9"
          @click="openModal"
        ></font-awesome-icon>
      </div>
      <div class="container">
        <label class="title" for="">Причина повторной проверки</label>
        <textarea v-model="approveMessage" class="form-control" cols="5" rows="3"></textarea>
      </div>
      <div class="button">
        <button-helper :is-disabled="isDisabled" color="#ffffff" background="#02B061" @click="dontApproveDoctor"
          >Отправить</button-helper
        >
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config/config'
import ButtonHelper from '@/components/helppers/ButtonHelper.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ArrowDown from "@/components/icons/HeaderNameArrowDown.vue";
import AdminSearchPlus from "@/components/icons/AdminSearchPlus.vue";
import PhotoSlider from "@/components/helppers/PhotoSlider.vue";

export default {
  name: 'VerifiedDoctor',
  components: {
      PhotoSlider,
      AdminSearchPlus,
      FontAwesomeIcon,
      ButtonHelper,
      ArrowDown
  },
  props: {
    user: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      hidden: false,
      slider: false,
      sildeImage: 0,
      showModal: false,
      showSeacrhIcon: false,
      approveMessage: '',
      isDisabled: true,
    }
  },
  watch: {
    approveMessage(){
      if(this.approveMessage.length !== 0){
        this.isDisabled = false;
      }else{
        this.isDisabled = true;
      }
    }
  },
  computed: {
    main_url() {
      return config.URL
    },
    sildeImageUrl() {
      return `${config.URL}/img/docs/${this.user.docs[this.sildeImage]}`
    },
  },
  methods: {
    closeModal(e){
      if (!e.target.closest('.form') && this.showModal) {
        this.showModal = false
        setTimeout(() => {
          document.removeEventListener('click', this.closeModal)
        }, 100)
      }
    },
    openModal(){
      if (!this.showModal) {
        setTimeout(() => {
          document.addEventListener('click', this.closeModal)
        })
      } else {
        setTimeout(() => {
          document.removeEventListener('click', this.closeModal)
        }, 100)
      }
      this.showModal = !this.showModal
    },
    openSlider() {
      this.slider = true
    },
    slideRight() {
      if (this.sildeImage < this.user.docs.length - 1) {
        this.sildeImage++
      } else {
        this.sildeImage = 0
      }
    },
    slideLeft() {
      if (this.sildeImage > 0) {
        this.sildeImage--
      } else {
        this.sildeImage = this.user.docs.length - 1
      }
    },
    openDrop() {
      if (!this.hidden) {
        setTimeout(() => {
          document.addEventListener('click', this.closeDrop)
        })
      } else {
        setTimeout(() => {
          document.removeEventListener('click', this.closeDrop)
        }, 100)
      }
      this.hidden = !this.hidden
    },
    closeDrop(e){
      if (!e.target.closest('.hidden') && this.hidden) {
        this.hidden = false
        setTimeout(() => {
          document.removeEventListener('click', this.closeDrop)
        }, 100)
      }
    },
    async approveDoctor(){
      await this.$store.dispatch('approveDoctor', {id:this.user.id}).then(response=>{
          this.$emit('id-selected', {id: this.user.id, verified: true, verified_at : response.verified_at});
      })
    },
      async dontApproveDoctor() {
          let params = {
              id: this.user.id,
              'message': this.approveMessage
          }
          await this.$store.dispatch('dontApproveDoctor', params).then(response => {
              this.$emit('id-selected', {id: this.user.id, verified: false, verified_at: response.verified_at});
              this.showModal = false;

          })
    },
    getVerifiedDate(verifiedDate){
      return new Date(verifiedDate).toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    }
  }
}
</script>

<style scoped lang="scss">

.doctor-item-date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #656873;
}

.doctor-item-title {
    margin-top: 14px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #373a43;
}

.doctor-item {
    border: 1px solid #DBE0EB;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 18px 25px;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
}

.doctor-item-main {
    position: relative;
    display: flex;
    gap: 20px;
    text-align: left;
}

.doctor-item-img-bg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    background: #e9ebf0;
}

.doctor-item-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.doctor-item-text {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 10px;
}

.doc-images {
    display: flex;
    gap: 10px;
}

.doctor-item-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #373a43;
}

.doctor-item-spec {
    margin-top: 7px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #656873;
}

.doctor-item-button {
    cursor: pointer;
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #24ad6f;
}

.no-img {
    width: 14px !important;
}

.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  width: 483px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #656873;
  margin: 7px auto;
}

.text-center {
  width: 288px;
}
.first {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
.icon {
  text-align: center;
  margin: auto;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: #edeff3;
  border-radius: 50%;
}
.icon-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #656873;
  margin-top: 13px;
}
.grid {
  margin: 40px auto;
  display: grid;
  grid-template-columns: auto auto;
}
.grid-item {
  width: 150px;
  height: 150px;
  margin: 32px;
  border-radius: 10px;
}
.img {
  width: 150px;
  height: 150px;
}

.newImages {
  border: 1px dashed #ccd1db;
  line-height: 150px;
  text-align: center;
}

.button {
  margin-top: 45px;
  text-align: center;
  margin-bottom: 20px;
}

.header-3{
  max-width: 1597px !important;
  padding: 0 !important;
}
.col-md-1 {
  width: 5% !important;
}
.arrow-icon {
  transition: .2s;
  transform: rotateX(-180deg);
}
.row-block {
  padding: 30px;
  margin-top: 20px;
  border: 1px solid #dbe0eb;
  border-radius: 20px;
}
.user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #656873;
  margin-bottom: 7px;
}

.name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #373a43;
  margin-bottom: 7px;
}

.spec {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #656873;
  margin-bottom: 10px;
}

.button-right {
  text-align: right;
  position: relative;
}
.hidden {
    position: absolute;
    background: #ffffff;
    border: 1px solid #dbe0eb;
    box-shadow: 0px 4px 50px rgba(132, 132, 151, 0.3);
    border-radius: 10px;
    padding: 20px;
    top: 65px;
    right: -15%;
}
.arrow-up {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1px;
    height: 1px;
    background-color: #ffffff;
    box-shadow: 0px 4px 50px rgba(132, 132, 151, 0.3);
    border: 2px solid #dbe0eb;
}
.arrow-up:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    background-color: inherit;
}
.line-hidden {
  margin-top: 10px;
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #373a43;
  cursor: pointer;
}

.docs-img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
}

.icon-part {
  width: 40px;
  height: 40px;
  opacity: 0.8;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.slider {
  position: fixed;
  width: 100%;
  margin: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  background: #edeff3c7;
  z-index: 1;
}

.slider-row {
  position: relative;
}

.slider-close {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #dbe0eb;
  background-color: #fff;
  text-align: center;
  line-height: 49px;
  right: 10px;
  top: 10px;
  color: #a4acbd;
  font-size: 20px;
  z-index: 10;
}

.img-parent {
  position: relative;
  width: 80vw;
  text-align: center;
}
.img {
  width: 75vw;
  height: 70vh;
  margin-top: 10vh;
}

.left-slider {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #dbe0eb;
  text-align: center;
  background-color: #ffffff;
  line-height: 52px;
  font-size: 13px;
  left: 10vw;
  top: 40vh;
}

.right-slider {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #dbe0eb;
  text-align: center;
  background-color: #ffffff;
  line-height: 52px;
  font-size: 13px;
  right: 10vw;
  top: 40vh;
}

.docs-img-slide {
  width: 80px;
  height: 60px;
}

.slide-small {
  cursor: pointer;
  text-align: center;
  padding: 13px;
}

.slide-small:not(:last-child) {
  margin-right: 13px;
}

.images-small {
  text-align: center;
  display: flex;
  width: 200px;
  margin: 30px auto;
}

.border-slide {
  border: 1px solid #c0c4cd;
  border-radius: 5px;
}
</style>

<template>
  <svg width="18" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.49895 8.87684V1.87895C4.49895 1.64695 4.40679 1.42446 4.24274 1.26042C4.0787 1.09637 3.8562 1.00421 3.62421 1.00421H1.87474C1.64274 1.00421 1.42025 1.09637 1.2562 1.26042C1.09216 1.42446 1 1.64695 1 1.87895V8.00211C1 8.2341 1.09216 8.45659 1.2562 8.62064C1.42025 8.78468 1.64274 8.87684 1.87474 8.87684H4.49895ZM4.49895 8.87684C5.42693 8.87684 6.3169 9.24548 6.97308 9.90166C7.62926 10.5578 7.99789 11.4478 7.99789 12.3758V13.2505C7.99789 13.7145 8.18221 14.1595 8.5103 14.4876C8.83839 14.8157 9.28338 15 9.74737 15C10.2114 15 10.6563 14.8157 10.9844 14.4876C11.3125 14.1595 11.4968 13.7145 11.4968 13.2505V8.87684H14.1211C14.585 8.87684 15.03 8.69252 15.3581 8.36443C15.6862 8.03634 15.8705 7.59136 15.8705 7.12737L14.9958 2.75369C14.87 2.21706 14.6313 1.75627 14.3158 1.44074C14.0003 1.1252 13.6249 0.972002 13.2463 1.00421H7.12316C6.42717 1.00421 5.75969 1.28069 5.26756 1.77283C4.77543 2.26496 4.49895 2.93244 4.49895 3.62842"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ThambDownDocIcon',

  props: {
    color: {
      type: String,
      required: false,
      default: '#A4ACBD'
    }
  }
}
</script>

<style scoped></style>

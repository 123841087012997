<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.99996 8.99943C4.10451 8.99943 4.99992 8.10402 4.99992 6.99947C4.99992 5.89492 4.10451 4.99951 2.99996 4.99951C1.89541 4.99951 1 5.89492 1 6.99947C1 8.10402 1.89541 8.99943 2.99996 8.99943Z"
      stroke="#C0C4CD"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0019 4.99992C12.1065 4.99992 13.0019 4.10451 13.0019 2.99996C13.0019 1.89541 12.1065 1 11.0019 1C9.89737 1 9.00195 1.89541 9.00195 2.99996C9.00195 4.10451 9.89737 4.99992 11.0019 4.99992Z"
      stroke="#C0C4CD"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0019 13.0004C12.1065 13.0004 13.0019 12.105 13.0019 11.0004C13.0019 9.8959 12.1065 9.00049 11.0019 9.00049C9.89737 9.00049 9.00195 9.8959 9.00195 11.0004C9.00195 12.105 9.89737 13.0004 11.0019 13.0004Z"
      stroke="#C0C4CD"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.80029 6.13332L9.2002 3.8667"
      stroke="#C0C4CD"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.80029 7.86621L9.2002 10.1328"
      stroke="#C0C4CD"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SharedIcon'
}
</script>

<style scoped></style>

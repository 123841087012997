export default {
    state: {
        subscribe: {}
    },

    getters: {},

    mutations: {},

    actions: {
        async setSubscribe(_, info) {
            await this.$axios.post(`/api/recommendation`, info);
        },
        async deleteSubscribe({commit}, info) {
            await this.$axios.post(`/api/recommendationDelete`, info).then((response) => {
                commit('subscribe', response.data)
            })
        },
        async setUnsubscribe({commit}, info) {
            await this.$axios.post(`/api/unrecommendation`, info).then((response) => {
                commit('unsubscribe', response.data)
            })
        },
        async createSubscribe(_, params) {
            await this.$axios.post(`/api/createRecommendation`, params)
        },
        async recommendateNotAuth(_, params) {
            await this.$axios.post(`/api/notAuthRecommendation`, params)
        }
    }
}

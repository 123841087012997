<template>
    <div class="doctors-list-top">

        <div class="doctors-list-eclipse desktop-only">
            <div class="eclipse-left" @click="changeListType(false)">
                <tile-icon v-if="!listType"/>
                <tile-icon-grey v-else/>
            </div>
            <div class="eclipse-right" @click="changeListType(true)">
                <list-icon v-if="!listType"/>
                <list-icon-green v-else/>
            </div>
        </div>

        <div class="doctors-list-switch form-check form-switch desktop-only" @click="changeSwitch(!this.switch)">
            <switch-icon v-if="!this.switch"/>
            <switch-on-icon v-else/>
            <label class="form-check-label" for="flexSwitchCheckDefault">
                Показать детских врачей
            </label>
        </div>

        <div class="doctors-list-sort">
            <div class="search-div" @click="changeShowSort(!showSort)">
                <div class="search-input">
                    <span class="sort"> Сортировать: </span>
                    {{ sortValue }}
                    <search-list-arrow-down
                        v-if="!showSort"
                        style="margin-left: 9px"
                    />
                    <arrow-up v-else style="margin-left: 9px"/>
                </div>
            </div>
            <div class="sort-popup" v-if="showSort">
                <div class="sort-popup-item" @click="changeSortValue('По популярности')">
                    По популярности
                </div>
                <div class="sort-popup-item" @click="changeSortValue('По дате регистрации')">
                    По дате регистрации
                </div>
                <div class="sort-popup-item" @click="changeSortValue('По количеству рекомендаций')">
                    По количеству рекомендаций
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchListArrowDown from "@/components/icons/SearchListArrowDown.vue";
import SwitchIcon from "@/components/icons/SwitchIcon.vue";
import ArrowUp from "@/components/icons/ArrowUp.vue";
import SwitchOnIcon from "@/components/icons/SwitchOnIcon.vue";
import ListIconGreen from "@/components/icons/ListIconGreen.vue";
import ListIcon from "@/components/icons/ListIcon.vue";
import TileIconGrey from "@/components/icons/TileIconGrey.vue";
import TileIcon from "@/components/icons/TileIcon.vue";

export default {
    name: 'SearchPageTopPanel',

    components: {
        TileIcon,
        TileIconGrey,
        ListIcon,
        ListIconGreen,
        SwitchOnIcon,
        ArrowUp,
        SwitchIcon,
        SearchListArrowDown,
    },

    props: {
        showSort: {
            type: Boolean,
            required: true,
            default: false
        },
        switch: {
            type: Boolean,
            required: true,
            default: false
        },
        listType: {
            type: Boolean,
            required: true,
            default: false
        },
        sortValue: {
            type: String,
            required: true,
        }
    },

    methods: {
        changeShowSort(showSort) {
            this.$emit('changeShowSort', showSort)
        },
        changeSwitch(switching) {
            this.$emit('changeSwitch', switching)
        },
        changeListType(listType) {
            this.$emit('changeListType', listType)
        },
        changeSortValue(sorting) {
            this.$emit('changeSortValue', sorting)
        }
    }

}
</script>

<style scoped>

.doctors-list-top {
    display: flex;
    align-items: center;
    column-gap: 150px;
    margin-bottom: 30px;
}

.doctors-list-eclipse {
    cursor: pointer;
    display: flex;
}

.eclipse-left {
    width: 52px;
    height: 46px;
    border: 1px solid #dbe0eb;
    text-align: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    line-height: 43px;
    border-right: none;
}

.eclipse-right {
    width: 52px;
    height: 46px;
    border: 1px solid #dbe0eb;
    text-align: center;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    line-height: 43px;
}

.doctors-list-switch {
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    column-gap: 15px;
}

.form-switch {
    padding: 0 !important;
}

.form-check-label {
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #656873;
}

.doctors-list-sort {
    position: relative
}

.search-div {
    display: flex;
    cursor: pointer;
    padding: 14px 16px;
    flex-wrap: nowrap;
    border: 1px solid #dbe0eb;
    border-radius: 50px;
    width: fit-content;
}

.search-input {
    white-space: nowrap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #373a43;
}

.sort {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #7f818a;
}

.sort-popup {
    z-index: 99999;
    position: absolute;
    width: 235px;
    background: #ffffff;
    border: 1px solid #dbe0eb;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.3);
    border-radius: 10px;
    top: 60px;
}

.sort-popup-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 40px;
    color: #373a43;
    cursor: pointer;
    text-align: left;
    padding-left: 20px;
}

.sort-popup-item:hover {
    background: #edeff3;
    border-radius: 5px;
}

#flexSwitchCheckDefault {
    width: 48px;
    height: 26px;
}

@media screen and (max-width: 1200px) {
    .doctors-list-top {
        column-gap: 25px;
    }
}

@media screen and (max-width: 769px) {
    .search-div {
        padding: 5px 15px;
    }
    .doctors-list-top {
        justify-content: center;
    }
}

</style>

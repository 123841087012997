<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.1591 2.76112C14.381 0.981541 12.0163 0.00100024 9.49697 0C4.30592 0 0.0810449 4.22326 0.0789675 9.41434C0.078275 11.0737 0.511968 12.6935 1.33609 14.1212L0 19L4.99262 17.6908C6.36818 18.4408 7.91696 18.8361 9.4932 18.8368H9.49708C14.6876 18.8368 18.9129 14.613 18.915 9.42185C18.916 6.90618 17.9373 4.54069 16.1591 2.76112ZM9.49708 17.2466H9.49387C8.08928 17.2461 6.71159 16.8689 5.50977 16.1559L5.22391 15.9863L2.26126 16.7632L3.05206 13.8755L2.86591 13.5795C2.08234 12.3335 1.66847 10.8935 1.66909 9.41493C1.67081 5.10031 5.18238 1.59012 9.50018 1.59012C11.591 1.59092 13.5564 2.40596 15.0343 3.88508C16.5122 5.3642 17.3257 7.33031 17.3249 9.42124C17.3231 13.7362 13.8115 17.2466 9.49708 17.2466ZM13.7908 11.3859C13.5555 11.2681 12.3985 10.699 12.1828 10.6205C11.9671 10.542 11.8102 10.5028 11.6533 10.7383C11.4965 10.9738 11.0455 11.5037 10.9082 11.6606C10.7709 11.8177 10.6337 11.8373 10.3983 11.7195C10.163 11.6018 9.4048 11.3534 8.50594 10.5519C7.80642 9.92818 7.3341 9.15774 7.19684 8.92225C7.05957 8.68673 7.18224 8.55939 7.30004 8.44213C7.40589 8.33672 7.53535 8.16738 7.65302 8.02998C7.77067 7.89262 7.80988 7.79449 7.88831 7.63751C7.96676 7.48049 7.92755 7.3431 7.86872 7.22536C7.80988 7.10761 7.33928 5.9497 7.14316 5.47869C6.95216 5.01996 6.75814 5.08203 6.6137 5.07485C6.47659 5.06802 6.31955 5.06656 6.16269 5.06656C6.00581 5.06656 5.75088 5.12545 5.53516 5.36094C5.31947 5.59646 4.71158 6.16559 4.71158 7.32348C4.71158 8.48137 5.55478 9.6 5.67245 9.75701C5.79009 9.91402 7.33179 12.2902 9.6924 13.3092C10.2538 13.5516 10.6922 13.6963 11.0339 13.8048C11.5977 13.9838 12.1107 13.9585 12.5162 13.8979C12.9683 13.8304 13.9084 13.3288 14.1045 12.7793C14.3006 12.2298 14.3006 11.7588 14.2418 11.6607C14.183 11.5625 14.0261 11.5037 13.7908 11.3859Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'WhatsappDoctorPageIcon'
}
</script>

<style scoped></style>

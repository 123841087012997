<template>
  <div class="content">
    <div class="icon-div">
      <SMSIcon v-if="icon == 'comment-dots'"></SMSIcon>
      <farid-icon v-if="icon == 'stethoscope'"></farid-icon>
      <map-icon v-if="icon == 'map-location-dot'"></map-icon>
      <shild-icon v-if="icon == 'shield'"></shild-icon>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import SMSIcon from '@/components/icons/SMSIcon.vue'
import FaridIcon from '@/components/icons/FaridIcon.vue'
import MapIcon from '@/components/icons/MapIcon.vue'
import ShildIcon from '@/components/icons/ShildIcon.vue'

export default {
  name: 'InfoBlokHelper',
  components: { ShildIcon, MapIcon, FaridIcon, SMSIcon },
  props: {
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.content {
  display: flex;
  width: 367px;
  height: 103px;
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  padding: 26px 23px;
  color: #373a43;
  border: 1px solid #dbe0eb;
  border-radius: 20px;
}
.icon-div {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  background: #edeff3;
  text-align: center;
  margin-right: 21px;
}

@media screen and (max-width: 1600px) {
  .content {
    width: 335px;
  }
}

@media screen and (max-width: 1536px) {
  .content {
    width: 305px;
  }
  .icon-div {
    margin-right: 15px;
  }
}

@media screen and (max-width: 1440px) {
  .content {
    width: 285px;
    font-size: 12px;
  }
  .icon-div {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1366px) {
  .content {
    width: 250px;
    font-size: 10px;
  }
  .icon-div {
    margin-right: 15px;
  }
}

@media screen and (max-width: 1080px) {
  .content {
    width: 210px;
    font-size: 8px;
  }
  .icon-div {
    margin-right: 10px;
  }
}
</style>

<template>
  <div class="search">
    <img
      v-if="doctorImg"
      class="rec-img"
      :class="{ 'no-img': doctorImg === 'no_doctor_photo.svg' }"
      :src="`/img/doctors/${doctorImg}`"
      :key="doctorId"
    />
    <input
      type="text"
      class="input-search mobile-btn mobile-center"
      placeholder="ФИО"
      :value="search"
      @input="searchDoctor"
    />
    <caret-down-icon
      @click="this.$emit('closeDrop')"
      class="down-icon"
      :class="{ drop: doctors.length }"
    />
    <div class="drop-menu" v-if="doctors.length">
      <div class="drop-scroll">
        <div
          class="drop-item"
          v-for="doctor in doctors"
          :key="doctor.id"
          @click="dropDoctor(doctor)"
        >
          {{ doctor.full_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectSpecialist from '@/mixins/selectSpecialist'

export default {
  name: 'SearchInput',
  props: {
    doctors: Array,
    search: String,
    doctorImg: String
  },
  emits: ['update:search'],
  data() {
    return {
      step: 1,
      login: false,
      drop: false
    }
  },
  mixins: [selectSpecialist],
  methods: {
    searchDoctor(e) {
      this.$emit('updateSearch', e.target.value)
    },
    dropDoctor(doctor) {
      this.$emit('checkedDoctor', doctor)
    }
  }
}
</script>

<style scoped lang="scss">
.rec-img {
  position: absolute;
  top: 25px;
  left: 0;
  transition: 0.2s;
  @media screen and (max-width: 1330px) {
    left: 193px;
    top: 16px;
    width: 5px;
    height: 3px;
  }
  width: 30px;
  height: 30px;
}
.search {
  position: relative;
  margin-top: 20px;

  @media screen and (max-width: 1330px) {
    width: 287px;
    padding: 0;
    display: flex;
    gap: 12px;
  }

  .search-icon {
    position: absolute;
    top: 29px;
    left: 20px;
    @media screen and (max-width: 1330px) {
      width: 16px;
      height: 16px;
      stroke: #c0c4cd;
      top: 10px;
      left: 12px;
    }
  }

  .down-icon.drop {
    transform: rotateX(180deg);
  }
  .down-icon {
    position: absolute;
    top: 40px;
    left: 275px;
    transition: 0.2s;
    @media screen and (max-width: 1330px) {
      left: 193px;
      top: 16px;
      width: 5px;
      height: 3px;
    }
  }

  .default-button {
    width: 97px;
    height: 56px;

    @media screen and (max-width: 1330px) {
      width: 65px;
      height: 36px;
      font-size: 12px;
      line-height: 14px;
      padding: 0;
    }
  }

  .input-search {
    width: 300px;
    height: 46px;
    margin-top: 20px;
    background: #edeff3;
    border-radius: 10px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    padding-left: 35px;
    color: #7f818a;
    autline: none;

    &:focus-visible {
      outline: none;
    }

    @media screen and (max-width: 1330px) {
      width: 210px;
      padding: 11px 35px 11px 37px;
      min-width: unset;
      margin: 0;
      height: 36px;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .drop-menu {
    background: #ffffff;
    box-shadow: 0 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    z-index: 999;
    position: absolute;
    width: 335px;
    height: 351px;
    overflow-y: hidden;
    top: 75px;
    left: 0;
    padding: 14px 10px 17px 4px;
    @media screen and (max-width: 769px) {
      width: 225px;
      height: 351px;
      top: 71px;
      left: 0;
    }

    .drop-scroll {
      overflow: auto !important;
      width: 100%;
      height: 100%;
      margin: auto;

      .drop-item {
        height: 37px;
        width: 100%;
        cursor: pointer;
        z-index: 9999;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 40px;
        color: #373a43;
        padding-left: 23px;
        margin: auto;
        @media screen and (max-width: 769px) {
          font-size: 12px;
        }
        @media screen and (max-width: 426px) {
          font-size: 11px;
        }
       

        &:hover {
          background: #edeff3;
          border-radius: 5px;
        }
      }
    }

    .drop-scroll::-webkit-scrollbar {
      width: 5px;
      border-radius: 50px;
    }

    .drop-scroll::-webkit-scrollbar-track {
      background: rgba(203, 208, 217, 0.5);
      border-radius: 50px;
    }

    .drop-scroll::-webkit-scrollbar-thumb {
      background-color: #d6d9e0;
      border-radius: 50px;
      border: 5px solid #d6d9e0;
    }
  }
}
</style>

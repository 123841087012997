<template>
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0803 3.57895L8.55484 5.10436L9.40537 5.95489L12.3828 2.97744L9.40537 0L8.55484 0.850526L10.0803 2.37594H5.76627C4.49004 2.37594 3.26608 2.88292 2.36365 3.78535C1.46122 4.68778 0.954244 5.91174 0.954244 7.18797C0.954244 8.4642 1.46122 9.68816 2.36365 10.5906C3.26608 11.493 4.49004 12 5.76627 12H11.1798V10.797H5.76627C4.8091 10.797 3.89113 10.4168 3.21431 9.73993C2.53749 9.06311 2.15725 8.14514 2.15725 7.18797C2.15725 6.2308 2.53749 5.31283 3.21431 4.63601C3.89113 3.95918 4.8091 3.57895 5.76627 3.57895H10.0803Z"
      fill="#02B061"
    />
  </svg>
</template>

<script>
export default {
  name: 'RecomendIcon'
}
</script>

<style scoped></style>

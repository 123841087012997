<template>
    <modal-layout @close-modal="closePopup">
        <div class="change-icon">
            <div class="change-icon__top">
                <div class="change-icon__title">
                    Сменить иконку
                </div>
                <div class="change-icon__desc">
                    Загрузите иконку svg (макс. 20х20 пикселей)
                </div>
            </div>

            <div class="change-icon__preview">
                <img alt="" :src="previewUrl ? previewUrl : iconUrl + icon">
            </div>

            <div class="change-icon__upload">
                <label style="cursor: pointer" for="file" class="change-icon__upload">+ Добавить иконку</label>
                <input type="file" ref="fileInput" id="file" hidden="" @change="previewFile"/>
            </div>

            <button-helper color="#ffffff" background="#02B061" class="change-icon__save" @click="save">
                Сохранить
            </button-helper>
        </div>
    </modal-layout>
</template>

<script>
import ModalLayout from "@/components/layout/ModalLayout.vue";
import config from "@/config/config";
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";

export default {
    name: 'ChangeIconPopup',

    components: {ButtonHelper, ModalLayout},

    emits: ['closePopup', 'save'],

    props: {
        icon: {
            required: true
        }
    },

    data() {
        return {
            previewUrl: null,
            file: null
        }
    },

    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        previewFile() {
            const file = this.$refs.fileInput.files;
            const reader = new FileReader();

            reader.onload = () => {
                this.previewUrl = reader.result;
            }

            reader.readAsDataURL(file[0]);
            this.file = file[0];
        },
        save() {
            if (this.file) {
                this.$emit('save', this.file)
                this.closePopup()
            }

        }
    },

    computed: {
        iconUrl() {
            return `${config.URL}/img/icons/`
        },
    }
}
</script>

<style scoped lang="scss">

.change-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;

    &__top {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    &__title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #373A43;
    }

    &__desc {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 24px;
        color: #656873;
    }

    &__preview {
        width: 150px;
        height: 150px;
        border: 1px dashed #C0C4CD;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__upload {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        //text-decoration-line: underline;
        color: #24AD6F;
    }
}

</style>

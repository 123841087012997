<template>
    <div class="doctor-subscribes-container">
        <div class="top-buttons">
            <div class="top-filters">
                <FilterPersonalCabinet
                    :sort-by="'Специальность'"
                />

                <button-helper
                    color="#656873"
                    style="border-color: #D3D8E2; background: #fff; font-weight: 300"
                    @click="showSortCity = true;"
                >
                    Город: <span style="font-weight: 400">{{ sortCity ? sortCity : 'Все' }}</span>
                    <arrow-down style="margin-left: 10px; transition: .2s" :class="{'arrow-icon-active': hidden_city}"/>
                </button-helper>
            </div>
            <div class="top-button-right desktop-only">
                <button-helper style="background-color: #FFFFFF; border: 1px solid #02B061; color: #02B061"
                               v-if="message == 1"
                               @click="message = 2">
                    Отправить приглашение
                </button-helper>
            </div>
        </div>

        <div class="doctor-items-container">
            <div class="doctor-items-city">
                Москва <span class="count"> 1 </span>
            </div>

            <div class="sub-doctor-item">
                <div class="sub-doctor-item-img">
                    <img alt="Фото профиля" src="/img/doctors/image_3.png" class="user-image"/>
                    <div class="heart">
                        <heart-icon/>
                    </div>
                </div>
                <div class="sub-doctor-item-content">
                    <div class="sub-doctor-item-main">

                        <div class="sub-doctor-item-text">
                            <div class="sub-doctor-item-name">Попова Мария Алексеевна</div>
                            <div class="sub-doctor-item-spec">
                                Врач терапевт, кардиолог, ревматолог, высшей категории
                            </div>
                        </div>

                        <div class="sub-doctor-item-main-bottom">
                            <div class="sub-doctor-item-likes">
                                <div
                                    class="sub-doctor-item-like">
                                    <doctor-like-icon style="margin-right: 4px"/>
                                    5
                                </div>

                                <div
                                    class="sub-doctor-item-like">
                                    <doctor-dislike-icon style="margin-right: 4px"/>
                                    4
                                </div>
                            </div>
                            <div class="sub-doctor-item-main-delete mobile-only">
                                Удалить
                            </div>
                        </div>
                    </div>
                    <delete-icon class="sub-doctor-item-button desktop-only"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/ButtonHelper.vue';
import ArrowDown from "@/components/icons/HeaderNameArrowDown.vue";
import FilterPersonalCabinet from "@/components/layout/FilterPersonalCabinet.vue";
import HeartIcon from "@/components/icons/HeartIcon.vue";
import DoctorDislikeIcon from "@/components/icons/DoctorDislike.vue";
import DoctorLikeIcon from "@/components/icons/DoctorLike.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";


export default {
    name: 'AdminPreviewDoctorSubscribes',
    components: {
        DeleteIcon, DoctorLikeIcon, DoctorDislikeIcon, HeartIcon,
        ButtonHelper,
        ArrowDown,
        FilterPersonalCabinet,
    },
    data() {
        return {
            hidden_speciality: false,
            hidden_city: false,
            hidden_share: false,
            message: 1,
            sortSpecia: null,
            sortCity: null,
            showSortSpecia: false,
            showSortCity: false,
            specialities: [],
            // cities: []
        }
    },
    computed: {
        subs() {
            return this.$store.getters.getdocSubscribes
        },
        subscribeDoctors() {
            return this.$store.getters.getSubDoctors
        },
        unsubs() {
            return this.$store.getters.getunsubscribesDoc
        },
        doctors() {
            let sortedDoctors = [];
            if (this.type == 1) {
                sortedDoctors = this.subscribeDoctors;
            } else if (this.type == 3) {
                sortedDoctors = this.subs;
            } else if (this.type == 2) {
                sortedDoctors = this.unsubs;
            }
            if (this.sortSpecia && this.sortSpecia !== null) {
                sortedDoctors = sortedDoctors.filter(doctor => {
                    return doctor.specialities.some(specie => specie.specie_name === this.sortSpecia)
                });
            }

            if (this.sortCity && this.sortCity !== null) {
                sortedDoctors = sortedDoctors.filter(doctor => {
                    return doctor.user_detales.city === this.sortCity
                });
            }
            return sortedDoctors;
        },
        cities() {
            let cities = []
            this.doctors.forEach((item) => {
                if (item.user_detales.city && cities.indexOf(item.user_detales.city) == -1) {
                    cities.push(item.user_detales.city)
                }
            })

            return cities
        },
        docByCities() {
            let docByCities = this.cities.map((item) => {
                return this.doctors.filter((i) => {
                    if (item == i.user_detales.city) {
                        return i
                    }
                })
            })
            return docByCities
        },
        loginUser() {
            return this.$store.getters.getUser
        }
    },
    methods: {
        invateDoctor() {
            let message = `${this.invate.last_name} ${this.invate.first_name} ${this.invate.father_name}, \n${this.loginUser.full_name} отметил вас, как доверенного ${this.invate.spec} в Системе Связи врачей. \nУзнать больше о системе и пройти регистрацию вы можете по ссылке. \nhttps://doctorsconnection.nomadicdemo.com/`;
            let phone = this.invate.phone
            phone = phone.replace(/[\s()-]/g, '');
            if (this.invate.social === 'Whatsapp') {
                window.open(`https://wa.me/${phone}?text=${encodeURIComponent(message)}`)
            } else {
                window.open(`https://t.me/share/url?url=${encodeURIComponent(message)}`)
            }
            this.message = 3
        },
        sortingShowSpecia($event) {
            this.sortSpecia = $event;
        },
        sortingShowCity($event) {
            this.sortCity = $event;
        },
        filterByCity($event) {
            this.sortCity = $event;
            this.showSortCity = false;
        }
    },
}
</script>

<style scoped lang="scss">

.doctor-subscribes-container {
    display: flex;
    flex-direction: column;
}

.doctor-items-container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.doctor-items {
    display: flex;
    flex-direction: column;
}

.arrow-icon-active {
    transition: .2s;
    transform: rotateX(-180deg);
}

.top-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.top-filters {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    align-items: center;

    @media screen and (max-width: 400px) {
        flex-direction: column;
    }
}

.doctor-items-city {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: #373a43;
}

.count {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
}

@media screen and (max-width: 869px) {
    .top-button-right {
        display: none;
    }
}

@media screen and (max-width: 769px) {
    .top-buttons {
        justify-content: center;
    }
    .share-btn {
        margin-top: 50px;
    }
}

.invate-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #373a43;
    margin: 10px;
}

.invate-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    margin: 10px;
    color: #656873;
}

.invate-button {
    margin-top: 20px;
}

.invate {
    text-align: center;
}

.invite-form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 100px;
}

.invite-form-category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 40px;
}

.invite-form-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #373a43;
}

.invite-form-category-inputs {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 80px;
    row-gap: 20px;
}

.invite-form-input-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    row-gap: 10px;
    max-width: 350px;
    width: 100%;
}

.invite-btn {
    max-width: 130px;
}

.label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #373a43;
}


.invite-input {
    width: 100%;
    padding: 10px;
    height: 40px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #7f818a;
    background: #edeff3;
    border-radius: 10px;
}

.invite-end-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #373a43;
}

.invite-end-items {
    display: flex;
    column-gap: 100px;
    flex-wrap: nowrap;
}

.invite-end-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #373a43;
}

.invite-end {
    margin-top: 30px;
    background: #edf3f1;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.invite-end-top {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: nowrap;
}

.invite-spec {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #7f818a;
}

@media screen and (max-width: 1080px) {

    .invite-form-category-inputs {
        flex-wrap: wrap;
    }

}

@media screen and (max-width: 769px) {

    .invate-text {
        font-size: 13px;
    }

    .invate-title {
        font-size: 16px;
    }


    .invite-form-category-inputs {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .invite-form-input-container {
        max-width: 100%;
    }

    .invite-btn {
        margin: 0 auto;
    }

    .invite-form {
        row-gap: 60px;
    }

    .invite-end {
        align-items: center;
        padding: 30px 20px;
    }

    .invite-end-items {
        flex-direction: column;
        align-items: center;
        row-gap: 30px;
    }

    .invite-end-title {
        white-space: nowrap;
        font-size: 15px;
    }
}

.sub-doctor-item {
    border: 1px solid #dbe0eb;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 30px;
    column-gap: 25px;
}

.sub-doctor-item-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sub-doctor-item-main {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.sub-doctor-item-main-bottom {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.hidden {
    position: absolute;
    background: #373A43;
    border-radius: 10px;
    padding: 12px 20px;
    top: 48px;
    right: -9%;
    opacity: 0.9;
}

.arrow-up {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1px;
    height: 1px;
    background-color: #373A43;
    box-shadow: 0px 4px 50px rgba(132, 132, 151, 0.3);
    border: 2px solid #dbe0eb;
}
.arrow-up:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    background-color: inherit;
}
.line-hidden {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #fff;
}

.sub-doctor-item-main-delete {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #24AD6F;
}

.sub-doctor-item-text {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.sub-doctor-item-likes {
    display: flex;
    column-gap: 10px;
    position: relative;
}

.sub-doctor-item-like {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 30px;
    border: 1px solid #dbe0eb;
    border-radius: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #373a43;
    cursor: pointer;
}

.sub-doctor-item-name {
    text-align: left;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #373a43;
}

.sub-doctor-item-spec {
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #656873;
}

.sub-doctor-item-button {
    cursor: pointer;
}

.sub-doctor-item-img {
    position: relative;
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
    border-radius: 50%;
    background: #e9ebf0;
}

.user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.heart {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    line-height: 36px;
    border-radius: 50%;
    background: #ffffff;
    bottom: -8px;
    right: 0;
}

@media screen and (max-width: 769px){
    .sub-doctor-item {
        column-gap: 15px;
        align-items: baseline;
        padding: 20px;
    }
    .user-image {
        width: 60px;
        height: 60px;
    }
    .sub-doctor-item-img {
        width: 60px;
        height: 60px;
    }
}
</style>

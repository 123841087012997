<template>
    <div class="change-content-prompt">
        <div class="arrow-up"/>
        <div class="change-content-prompt__title" @click="showPrompt = !showPrompt">
            <link-icon/> <span style="text-decoration-line: underline">Развернуть во весь экран</span>
        </div>
        <img
            alt=""
            class="change-content-prompt__img"
            :src="promptUrl + img"
        >
    </div>
    <modal-layout max-width="1400px" max-height="100%" @close-modal="showPrompt = false" v-if="showPrompt">
        <img class="change-content-prompt__full" alt="" :src="promptUrl + img">
    </modal-layout>
</template>

<script>
import config from "@/config/config";
import LinkIcon from "@/components/icons/LinkIcon.vue";
import ModalLayout from "@/components/layout/ModalLayout.vue";

export default {
  name: 'changeContentPrompt',
    components: {ModalLayout, LinkIcon},

  props: {
    img: {
        required: true
    }
  },

    data() {
        return {
            showPrompt: false
        }
    },

    computed: {
        promptUrl() {
            return `${config.URL}/img/prompts/`
        },
    }

}
</script>

<style scoped lang="scss">
.change-content-prompt {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    position: absolute;
    background-color: #373A43;
    opacity: 0.8;
    border-radius: 10px;
    padding: 15px;
    top: 45px;
    left: -50%;
    transform: translateX(-45%);
    z-index: 5;
    width: max-content;

    &__title {
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
    }

    &__img {
        opacity: 1 !important;
        border-radius: 10px;
        max-width: 315px;
    }

    &__full {
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
}

.line-hidden {
    margin-top: 10px;
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #373a43;
    cursor: pointer;
}

.arrow-up {
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 20px;
    height: 20px;
    background: rgba(55,58,67,.8);
    background-color: #373A43;
}

</style>

<template>
    <div class="dashboard-route" v-if="menu">
        <div class="dashboard-route__text">
            <div class="dashboard-route__url">
                Личный кабинет
                <ellipse-icon class="dashboard-route__url-dot"/>
                <div class="dashboard-route__url">
                    {{ pageName }}
                </div>
            </div>
            <div class="dashboard-route__title" v-if="pageName !== 'Статистика'">
                {{ pageName }}

                <div v-if="pageName === 'Избранное' && subscribeDoctors.length">
                    <span class="small">
                        {{ subscribeDoctors.length }}
                    </span>
                </div>

                <span v-else-if="pageName === 'Лента уведомлений' && notifications.length > 0" class="notification-bg">
                    <span class="notification">
                        {{ notifications.length }}
                     </span>
                </span>
            </div>

        </div>
    </div>
</template>

<script>
import config from "@/config/config";
import EllipseIcon from "@/components/icons/EllipseIcon.vue";

export default {

    name: 'AdminPreviewTopDashboardRoute',

    components: {
        EllipseIcon,
    },

    data() {
        return {
            showStats: false
        }
    },

    computed: {
        pageId() {
            return this.$route.params.id
        },
        pageName() {
            let arr = this.menu.filter((item) => item.id == this.pageId)
            return arr.length ? arr[0].title : config.USER_MANU_ITEM[0].title;
        },
        menu() {
            return config.DOCTOR_MANU_ITEM
        },
        subscribeDoctors() {
            return this.$store.getters.getdocSubscribes
        },
        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        },
        notifications() {
            return this.$store.getters.getAllNotifications;
        }
    }
}
</script>

<style scoped lang="scss">

.dashboard-route {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 40px;

    @media screen and (max-width: 769px) {
        flex-direction: column;
        align-items: center;
    }

    &__text {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        @media screen and (max-width: 769px) {
            align-items: center;
        }
    }

    &__url {
        display: flex;
        align-items: center;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #9195A4;
        overflow-x: scroll;

        white-space: nowrap;
    }

    &__url::-webkit-scrollbar {
        display: none;
    }

    &__url-dot {
        margin: 0 10px;
    }

    &__title {
        width: max-content;
        display: flex;
        align-items: center;
        column-gap: 5px;
        font-family: 'Raleway';
        font-weight: 400;
        font-size: 30px;
        line-height: 24px;
        margin-top: 6px;
        color: #373A43;

        @media screen and (max-width: 769px) {
            font-size: 25px;
        }
    }

    &__stats {
        display: flex;
        flex-direction: column;
        row-gap: 25px;

        &-items {
            display: flex;
            flex-direction: column;
        }

        &-item {
            display: flex;
            align-items: center;
            column-gap: 10px;
            flex-wrap: nowrap;
        }

        &-text {
            font-family: 'Roboto';
            font-weight: 300;
            font-size: 16px;
            line-height: 30px;
            color: #373A43;

            @media screen and (max-width: 769px) {
                font-size: 13px;
                line-height: 26px;
            }
        }

        &-type {
            color: #373A43;
        }

        &-count {
            color: #656873;
        }
    }
}

.small {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #9195a4;
}

.notification-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #02b061;
    width: 17px;
    height: 17px;
    min-height: 17px;
    min-width: 17px;
    align-self: flex-end;
}

.notification {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    color: #ffffff;
}

.arrow-down {
    margin-left: 5px;
}

</style>

<template>
    <div class="unreg-user">
        <div class="doctors-list-container">
            <div class="doctors-list-content" v-if="doctors.length">

                <doctors-list-large />

            </div>
            <div class="doctors-list-no-items" v-else>Такой специалист не найден.</div>
        </div>

    </div>
</template>

<script>
import DoctorsListLarge from '@/components/doctor/DoctorsListLarge.vue'
import sortingShow from '@/mixins/sortingShow'

export default {
    name: 'UnregistredUserContent',
    components: {
        DoctorsListLarge,
    },
    data() {
        return {
            specList: false,
            listType: false,
            showSort: false,
            switch: false,
            sort: 'По популярности'
        }
    },
    computed: {
        doctors() {
            let docs = this.$store.getters.getAllDoctores

            return docs
        },
        specialities() {
            return this.$store.getters.getAllSpecialities
        },
        spacListName() {
            return this.specList ? 'Скрыть' : 'Развернуть все'
        },
        colorLarge() {
            return this.listType ? '#DBE0EB' : '#02B061'
        },
        colorList() {
            return this.listType ? '#02B061' : '#DBE0EB'
        },
        user() {
            return this.$store.getters.getUser
        },
        login() {
            return localStorage.userToken ? true : false
        },
    },
    mixins: [sortingShow],
    methods: {
        async newUsers(id) {
            await this.$store.dispatch('getAllDoctors', {page: 1, spec: id})
            await this.$store.dispatch('getPageCount', {spec_id: id})
            this.$router.push(`category?spec=${id}`)
        }
    },
    async mounted() {
        if (screen.width < 769) {
            this.listType = true
        }
        await this.$store.dispatch('getAllDoctors', {
            page: 1,
            spec: this.$route.query.spec,
            name: this.$route.query.name
        })
    }
}
</script>

<style scoped>

#flexSwitchCheckDefault {
    width: 48px;
    height: 26px;
}


.doctors-list-content {

}

@media screen and (max-width: 769px) {

    .doctors-list-content {
        margin-left: 0px;
    }

}

.doctors-list-no-items {
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    color: #373a43;
}
</style>

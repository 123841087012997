<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.81067 4.58028C2.81067 5.51791 3.18314 6.41714 3.84615 7.08015C4.50916 7.74316 5.40839 8.11563 6.34602 8.11563C7.28366 8.11563 8.18289 7.74316 8.84589 7.08015C9.5089 6.41714 9.88138 5.51791 9.88138 4.58028C9.88138 3.64264 9.5089 2.74341 8.84589 2.0804C8.18289 1.4174 7.28366 1.04492 6.34602 1.04492C5.40839 1.04492 4.50916 1.4174 3.84615 2.0804C3.18314 2.74341 2.81067 3.64264 2.81067 4.58028Z"
      :stroke="color"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.04401 16.9534V15.1857C1.04401 14.2481 1.41648 13.3489 2.07949 12.6859C2.74249 12.0229 3.64173 11.6504 4.57936 11.6504H8.11471C9.05235 11.6504 9.95158 12.0229 10.6146 12.6859C11.2776 13.3489 11.6501 14.2481 11.6501 15.1857V16.9534"
      :stroke="color"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5319 1.16016C13.2924 1.35487 13.9664 1.79714 14.4478 2.41725C14.9291 3.03736 15.1904 3.80003 15.1904 4.58503C15.1904 5.37003 14.9291 6.1327 14.4478 6.75281C13.9664 7.37292 13.2924 7.81519 12.5319 8.0099"
      :stroke="color"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.9502 16.9541V15.1865C16.9457 14.4062 16.6832 13.6493 16.2036 13.0338C15.7239 12.4183 15.0542 11.9788 14.2986 11.7837"
      :stroke="color"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#02B061'
    }
  },

  name: 'PeopleIcon'
}
</script>

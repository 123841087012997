<template>
  <button :disabled="isDisabled" class="button-helper" :style="style">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'button-helper',
  props: {
    background: {
      type: String
    },
    color: {
      type: String
    },
    isDisabled: {
      type: Boolean
    }
  },
  computed: {
    style() {
      return `color: ${this.color};background: ${this.background};border:1px solid ${this.color}`
    }
  }
}
</script>

<style scoped>
.button-helper {
  border-radius: 50px;
  padding: 10px 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}
.button-helper:disabled{
  background: rgb(206, 184, 184) !important;
}
</style>

<template>
  <svg width="9" height="15" viewBox="0 0 9 15" fill="#A4ACBD" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.31764 2.25508C5.19617 2.25508 5.91079 2.96969 5.91079 3.84822C5.91079 4.72601 5.19599 5.44062 4.31764 5.44062C3.43967 5.44062 2.72487 4.72601 2.72487 3.84822C2.72469 2.9695 3.43985 2.25508 4.31764 2.25508ZM4.31764 7.69459C6.43974 7.69459 8.16549 5.9694 8.16549 3.84822C8.16549 1.72593 6.43993 0 4.31764 0C2.19573 0 0.469796 1.72612 0.469796 3.84822C0.469796 5.9694 2.19573 7.69459 4.31764 7.69459ZM5.87432 10.8332C6.66588 10.6529 7.42128 10.3401 8.10858 9.90804C8.36161 9.74885 8.54103 9.49566 8.60738 9.20418C8.67373 8.9127 8.62158 8.60679 8.46239 8.35377C8.38366 8.22835 8.28098 8.11969 8.16022 8.034C8.03946 7.9483 7.90299 7.88725 7.75862 7.85434C7.61424 7.82143 7.4648 7.8173 7.31883 7.84219C7.17286 7.86709 7.03323 7.92051 6.90793 7.99941C5.33155 8.99047 3.30264 8.98973 1.72755 7.99941C1.60226 7.92049 1.46265 7.86705 1.31669 7.84215C1.17073 7.81725 1.02129 7.82137 0.876927 7.85428C0.732563 7.8872 0.596105 7.94825 0.475364 8.03396C0.354622 8.11967 0.251966 8.22834 0.173269 8.35377C0.0140298 8.60673 -0.0382112 8.91258 0.0280349 9.20406C0.094281 9.49553 0.273589 9.74876 0.526522 9.90804C1.21377 10.34 1.96902 10.6527 2.76042 10.8332L0.609403 12.9846C0.398017 13.196 0.279291 13.4828 0.279343 13.7818C0.279395 14.0808 0.39822 14.3675 0.609679 14.5789C0.821138 14.7903 1.10791 14.909 1.40691 14.909C1.7059 14.9089 1.99263 14.7901 2.20402 14.5786L4.31728 12.465L6.43183 14.5788C6.53638 14.6835 6.66055 14.7666 6.79723 14.8232C6.93391 14.8799 7.08043 14.9091 7.2284 14.9091C7.37636 14.9091 7.52288 14.8799 7.65956 14.8232C7.79624 14.7666 7.92041 14.6835 8.02497 14.5788C8.12983 14.4743 8.21302 14.3501 8.26979 14.2133C8.32656 14.0765 8.35578 13.9299 8.35578 13.7819C8.35578 13.6338 8.32656 13.4872 8.26979 13.3504C8.21302 13.2137 8.12983 13.0895 8.02497 12.9849L5.87432 10.8332Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OkIcon'
}
</script>

<style scoped></style>

<template>
    <div class="doctors-list-top">
        <div class="doctors-list-sort">
            <div class="search-div" :style="{width: styles?.width, marginTop: styles?.marginTop}" @click="changeShowSelect(!showSelect)">
                <div class="search-input">
                    <span class="sort"> {{ selectBy }}: </span>
                    <span>{{ selectValue }}</span>
                    <search-list-arrow-down
                        v-if="!showSelect"
                        style="margin-left: 9px"
                    />
                    <arrow-up v-else style="margin-left: 9px"/>
                </div>
            </div>
            <div class="sort-popup" :style="{width: styles?.width}" v-if="showSelect">
                <div class="sort-popup-item" v-for="(item, index) in dropDownList" :key="index" @click="changeSelectValue(item)">
                    {{ item }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchListArrowDown from "@/components/icons/SearchListArrowDown.vue";
import ArrowUp from "@/components/icons/ArrowUp.vue";

export default {
    name: 'SelectHelper',

    components: {
        ArrowUp,
        SearchListArrowDown,
    },

    props: {
        showSelect: {
            type: Boolean,
            required: true,
            default: false
        },
        selectValue: {
            type: String,
            required: true
        },
        dropDownList:[],
        selectBy: {
            type: String,
            default: 'Дата'
        },
        styles: {
            type: Object,
            required: true,
        },
    },

    methods: {
        changeShowSelect(showSelect) {
            this.$emit('changeShowSelect', showSelect)
        },
        changeSelectValue(sorting) {
            this.$emit('changeSelectValue', sorting)
            this.changeShowSelect(false)
        }
    },
}
</script>

<style scoped>

.doctors-list-top {
    display: flex;
    align-items: center;
    column-gap: 150px;
    justify-content: center;
}

.doctors-list-sort {
    position: relative
}

.search-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    height: 46px;
    background: #edeff3;
    border-radius: 10px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    padding: 0 15px;
    color: #7f818a;
}

.search-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    color: #373a43;
}

.sort {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #7f818a;
}

.sort-popup {
    z-index: 100;
    position: absolute;
    background: #ffffff;
    border: 1px solid #dbe0eb;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.3);
    border-radius: 10px;
    top: 65px;
    max-height: 355px;
    overflow-y: auto;
}

.sort-popup::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
}

.sort-popup::-webkit-scrollbar-track {
    background: rgba(203, 208, 217, 0.5);
    border-radius: 50px;
}

.sort-popup-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 40px;
    color: #373a43;
    cursor: pointer;
    text-align: left;
    padding-left: 20px;
}

.sort-popup-item:hover {
    background: #edeff3;
    border-radius: 5px;
}

#flexSwitchCheckDefault {
    width: 48px;
    height: 26px;
}

@media screen and (max-width: 1200px) {
    .doctors-list-top {
        column-gap: 25px;
    }
}

@media screen and (max-width: 769px) {
    .doctors-list-top {
        justify-content: center;
    }
}

</style>

<template>
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8285 1.04492L15.3766 4.58031L11.8285 8.1157"
      stroke="#02B061"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.51068 4.58057H15.3808"
      stroke="#02B061"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.73202 9.88281L1.18396 13.4182L4.73202 16.9536"
      stroke="#02B061"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.18396 13.4185H9.1671"
      stroke="#02B061"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowsIcon'
}
</script>

<style scoped></style>

<template>
    <div v-if="doctors" class="doctors-list-layout">

        <div class="doctors-list-top">
            <div class="doctor-type-my">
                <answer-icon style="cursor: pointer" @click="openPopup"/>
                {{ title }} <span class="icon-size">{{doctors.length}}</span>
                <div class="hidden" v-if="openAnswer">
                    <div class="arrow-up"></div>
                    <div class="line-hidden" v-if="title == 'Самые подходящие'">
                        Врачи, которым доверяют специалисты, которым доверяю я
                    </div>
                    <div class="line-hidden" v-else-if="title == 'Возможно, вас заинтересуют'">
                        Врачи, которым доверяют специалисты из списка выше
                    </div>
                    <div class="line-hidden" v-else-if="title == 'Остальные врачи'">
                        Остальные наиболее релевантные специалисты
                    </div>
                    <div class="line-hidden" v-else>
                        Остальные наиболее релевантные специалисты
                    </div>
                </div>
            </div>
            <div
                class="doctors-list-top-see-all"
                 v-if="!mobile && !listType && this.doctors.length > this.numberShowCards"
                 @click="changeScreenType(!screenType)"
            >
                Смотреть все
            </div>
        </div>

        <doctors-reg-list :style="{filter: !this.$store.getters.getUser.id ? 'blur(4px)' : 'blur(0px)'}" :recommendation-type="recommendationType" :title="title" :screen-type="screenType" :list-type="listType" :doctors="doctors" :number-show-cards="numberShowCards"/>

        <div
            class="doctors-list-top-see-all"
             style="text-align: center"
             v-if="mobile || listType && this.doctors.length > this.numberShowCards"
             @click="changeScreenType(!screenType)"
        >
            Смотреть все
        </div>
    </div>
</template>

<script>
import DoctorsRegList from "@/components/doctor/DoctorsRegList.vue";
import AnswerIcon from "@/components/icons/AnswerIcon.vue";
export default {

    name: 'DoctorsRegListLayout',

    components: {
        DoctorsRegList,
        AnswerIcon
    },

    props: {
        title: {
            type: String,
            required: true
        },
        listType: {
            type: Boolean,
            required: true,
            default: false,
        },
        screenType: {
            type: Boolean,
            required: true
        },
        doctors: {
            required: true
        },
        recommendationType: {
            type: Number,
            default: 0
        },
    },

    data() {
        return {
            numberShowCards: 4,
            openAnswer: false,
        }
    },

    methods: {
        changeScreenType(screenType) {
            this.$emit('changeScreenType', screenType)
        },
        onResize() {
            if (window.innerWidth > 1600) {
                this.numberShowCards = 4
            } else if (window.innerWidth < 1600 && window.innerWidth > 1380) {
                this.numberShowCards = 3
            } else if (window.innerWidth < 1380 && window.innerWidth > 769) {
                this.numberShowCards = 2
            } else {
                this.numberShowCards = 1
            }
        },
        openPopup() {
            this.openAnswer = !this.openAnswer;
        }
    },

    computed: {
        mobile() {
            return this.$store.getters.getMobile
        },
    },

    created() {
        window.addEventListener('resize', this.onResize)
        this.onResize()
    }

}
</script>

<style scoped>

.doctors-list-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    margin-top: 75px;
}

.doctors-list-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.doctors-list-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.doctor-type-my {
    position: relative;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #373a43;
}

.doctors-list-top-see-all {
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-decoration-line: underline;
    color: #24ad6f;
}

.icon-size {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
}
.hidden {
    position: absolute;
    background: #373A43;
    border-radius: 10px;
    padding: 12px 20px;
    top: 44px;
    left: -125px;
    opacity: 0.9;
    z-index: 9;
    height: 62px;
    width: 266px;
}

@media screen and (max-width: 769px){
    .doctor-type-my {
        font-size: 16px;
    }
    .doctors-list-layout {
        text-align: center;
    }
    .doctors-list-top {
        justify-content: center;
    }
}



.arrow-up {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 1px;
    height: 1px;
    background-color: #373A43;
    box-shadow: 0px 4px 50px rgba(132, 132, 151, 0.3);
    border: 2px solid #dbe0eb;
}
.arrow-up:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
    background-color: inherit;
}
.line-hidden {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 230px;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #fff;
}

@media screen and (max-width: 420px){
    .arrow-up {
        left: 23px;
        top: -15px;
    }
    .line-hidden{
        width: 100%;
        height: 100%;
    }

    .hidden{
        height: 115px;
        width: 140px;
        top: 35px;
        left: -14px;
    }
}
</style>

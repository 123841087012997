<template>
    <div class="dashboard-route" v-if="menu">
        <div class="dashboard-route__text">
            <div class="dashboard-route__url">
                <router-link style="text-decoration: none; color: #9195a4" :to="{name: 'dashboard', params:{id: 1}}">
                    Личный кабинет
                </router-link>
                <ellipse-icon class="dashboard-route__url-dot"/>
                <span v-if="pageName === 'Отправить приглашение'">
                    <router-link style="text-decoration: none; color: #9195a4" :to="{name: 'dashboard', params:{id: 4}}">
                    Приглашения
                    </router-link>
                    <ellipse-icon class="dashboard-route__url-dot"/>
                    {{ pageName }}
                </span>
                <span v-else>
                    {{ pageName }}
                </span>
            </div>
            <div class="dashboard-route__title" v-if="pageName !== 'Статистика'">
                {{ pageName }}

                <div v-if="pageName === 'Избранное' && subscribeDoctors.length">
                    <span class="small">
                        {{ subscribeDoctors.length }}
                    </span>
                </div>

                <span v-else-if="pageName === 'Лента уведомлений' && notifications.length > 0" class="notification-bg">
                    <span class="notification">
                        {{ notifications.length }}
                     </span>
                </span>
            </div>

            <div class="dashboard-route__stats" v-else>
                <div class="dashboard-route__title" style="cursor: pointer" @click="showStats = !showStats">
                    {{ pageName }}
                    <arrow-down v-if="!showStats" class="arrow-down"/>
                    <arrow-down v-else style="transform: rotate(180deg)" class="arrow-down"/>
                </div>

                <div class="dashboard-route__stats-items" v-if="showStats">
                    <div class="dashboard-route__stats-item">
                        <ellipse-icon/>
                        <div class="dashboard-route__stats-text">
                            <span class="dashboard-route__stats-type">Всего зарегестрировалось: </span>
                            <span class="dashboard-route__stats-count">{{ stats.users_count }}</span>
                        </div>
                    </div>
                    <div class="dashboard-route__stats-item">
                        <ellipse-icon/>
                        <div class="dashboard-route__stats-text">
                            <span class="dashboard-route__stats-type">За последний месяц: </span>
                            <span class="dashboard-route__stats-count">{{ stats.users_month_count }}</span>
                        </div>
                    </div>
                    <div class="dashboard-route__stats-item">
                        <ellipse-icon/>
                        <div class="dashboard-route__stats-text">
                            <span class="dashboard-route__stats-type">За сегодня: </span>
                            <span class="dashboard-route__stats-count">{{ stats.users_to_day_count }}</span>
                        </div>
                    </div>
                    <div class="dashboard-route__stats-item">
                        <ellipse-icon/>
                        <div class="dashboard-route__stats-text">
                            <span class="dashboard-route__stats-type">Верифицированных: </span>
                            <span class="dashboard-route__stats-count">{{ stats.user_verification_count }}</span>
                        </div>
                    </div>
                    <div class="dashboard-route__stats-item">
                        <ellipse-icon/>
                        <div class="dashboard-route__stats-text">
                            <span class="dashboard-route__stats-type">Рекомендации: </span>
                            <span class="dashboard-route__stats-count">{{ stats.recommendation_count }}</span>
                        </div>
                    </div>
                    <div class="dashboard-route__stats-item">
                        <ellipse-icon/>
                        <div class="dashboard-route__stats-text">
                            <span class="dashboard-route__stats-type">Городов: </span>
                            <span class="dashboard-route__stats-count">{{ stats.cities_count }}</span>
                        </div>
                    </div>
                    <div class="dashboard-route__stats-item">
                        <ellipse-icon/>
                        <div class="dashboard-route__stats-text">
                            <span class="dashboard-route__stats-type">Регионов: </span>
                            <span class="dashboard-route__stats-count">{{ stats.regions_count }}</span>
                        </div>
                    </div>
                    <div class="dashboard-route__stats-item">
                        <ellipse-icon/>
                        <div class="dashboard-route__stats-text">
                            <span class="dashboard-route__stats-type">Округов: </span>
                            <span class="dashboard-route__stats-count">{{ stats.states_count }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dashboard-route__btn"
             v-if="pageName === 'Заявки на верификацию' || pageName === 'Отправить приглашение'">
            <router-link to="/dashboard/9" style="text-decoration: none; color: #24AD6F">
                <button-helper
                    color="#24AD6F"
                    background="#ffffff"
                    style="width: 227px;height: 56px"
                >
                    <InvateButtonIcon style="margin-right: 8px"/>
                    Отправить приглашение
                </button-helper>
            </router-link>
        </div>
    </div>
</template>

<script>
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";
import InvateButtonIcon from "@/components/icons/InvateButtonIcon.vue";
import config from "@/config/config";
import EllipseIcon from "@/components/icons/EllipseIcon.vue";
import ArrowDown from "@/components/icons/HeaderNameArrowDown.vue";

export default {

    name: 'TopDashboardRoute',

    components: {
        ArrowDown,
        EllipseIcon,
        InvateButtonIcon,
        ButtonHelper
    },

    props: {
        role: Number
    },

    data() {
        return {
            showStats: false
        }
    },

    computed: {
        pageId() {
            return this.$route.params.id
        },
        pageName() {
            let arr = this.menu.filter((item) => item.id == this.pageId)
            return arr.length ? arr[0].title : config.USER_MANU_ITEM[0].title;
        },
        user() {
            return this.$store.getters.getUser
        },
        menu() {
            switch (this.user.role_id) {
                case 1 || 2:
                    return config.ADMIN_MANU_ITEMS
                case 3:
                    return config.DOCTOR_MANU_ITEM
                default:
                    return config.USER_MANU_ITEM
            }
        },
        subscribeDoctors() {
            return this.$store.getters.getdocSubscribes
        },
        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        },
        notifications() {
            return this.$store.getters.getAllNotifications;
        },
        stats(){
            return this.$store.getters.getStats
        }
    },
     mounted(){
        if (this.role && (this.role === 1 || this.role === 2)) {
            this.$store.dispatch('getStats');
        }
    }
}
</script>

<style scoped lang="scss">

.dashboard-route {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 40px;

    @media screen and (max-width: 769px) {
        flex-direction: column;
        align-items: center;
    }

    &__text {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        @media screen and (max-width: 769px) {
            align-items: center;
        }
    }

    &__url {
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #9195A4;

        @media screen and (max-width: 769px) {
            white-space: nowrap;
            width: 85vw;
            text-align: center;
            overflow: auto;
        }
    }

    &__url::-webkit-scrollbar {
        display: none;
    }

    &__url-dot {
        min-width: 3px;
        margin: 0 10px;
    }

    &__title {
        width: max-content;
        display: flex;
        align-items: center;
        column-gap: 5px;
        font-family: 'Raleway';
        font-weight: 400;
        font-size: 30px;
        line-height: 24px;
        margin-top: 6px;
        color: #373A43;

        @media screen and (max-width: 769px) {
            font-size: 25px;
            margin: 0 auto;
        }
    }

    &__stats {
        display: flex;
        flex-direction: column;
        row-gap: 25px;

        &-items {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 20px;

            @media screen and (max-width: 520px) {
                grid-template-columns: auto;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            column-gap: 10px;
            flex-wrap: nowrap;
        }

        &-text {
            font-family: 'Roboto';
            font-weight: 300;
            font-size: 16px;
            line-height: 30px;
            color: #373A43;

            @media screen and (max-width: 769px) {
                font-size: 13px;
                line-height: 26px;
            }
        }

        &-type {
            color: #373A43;
        }

        &-count {
            color: #656873;
        }
    }
}

.small {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #9195a4;
}

.notification-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #02b061;
    width: 17px;
    height: 17px;
    min-height: 17px;
    min-width: 17px;
    align-self: flex-end;
}

.notification {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    color: #ffffff;
}

.arrow-down {
    margin-left: 5px;
}

</style>

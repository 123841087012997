<template>
    <div class="change-contents__item" v-if="doctorDashboardContent">
        <div class="change-contents__title" @click="showChangeContent = !showChangeContent">
            Авторизованный врач (Личный кабинет)

            <big-arrow-icon style="transition: .2s; min-width: 14px" :class="{'rotate-icon': !showChangeContent}"/>
        </div>

        <div class="change-contents__desc" v-if="!showChangeContent">
            Баннеры на страницах “Я рекомендую” и “Анкета врача”
        </div>

        <div class="change-contents__main" v-if="showChangeContent">

            <div class="change-contents__main-item">
                <div class="change-contents__main-title">
                    Баннер на странице “Я рекомендую”
                    <div style="position: relative">
                        <question-icon
                            style="cursor: pointer"
                            @click="showPrompt1 = !showPrompt1"
                            :color="hoverQuestion1 ? '#02B061' : '#C0C4CD'"
                            @mouseenter="hoverQuestion1 = true"
                            @mouseleave="hoverQuestion1 = false"
                        />
                        <change-content-prompt v-if="showPrompt1" img="recommend_banner.jpg"/>
                    </div>
                </div>
                <div class="change-contents__inputs">
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок
                        </div>
                        <input-layout
                            placeholder="Заголовок"
                            :input-value="doctorDashboardContent.banner_recommend?.title"
                            @update:input-value="doctorDashboardContent.banner_recommend.title = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Аннотация
                        </div>
                        <input-layout
                            placeholder="Аннотация"
                            :input-value="doctorDashboardContent.banner_recommend?.annotation"
                            @update:input-value="doctorDashboardContent.banner_recommend.annotation = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Кнопка
                        </div>
                        <input-layout
                            placeholder="Кнопка"
                            :input-value="doctorDashboardContent.banner_recommend?.btn"
                            @update:input-value="doctorDashboardContent.banner_recommend.btn = $event"
                        />
                    </div>
                </div>
                <div class="change-contents__btns">
                    <button-helper class="change-contents__btn" color="#FFFFFF" background="#02B061"
                                   @click="updateDoctorDashboardContent">
                        <check-button-docs color="#FFFFFF"/>
                        Сохранить
                    </button-helper>
                    <button-helper class="change-contents__btn" color="#02B061" background="#FFFFFF"
                                   @click="preview(1)">
                        <open-eye-icon color="#02B061"/>
                        Предпросмотр
                    </button-helper>
                </div>
            </div>

            <div class="change-contents__separator"/>

            <div class="change-contents__main-item">
                <div class="change-contents__main-title">
                    Баннер на странице “Анкета врача”
                    <div style="position: relative">
                        <question-icon
                            style="cursor: pointer"
                            @click="showPrompt2 = !showPrompt2"
                            :color="hoverQuestion2 ? '#02B061' : '#C0C4CD'"
                            @mouseenter="hoverQuestion2 = true"
                            @mouseleave="hoverQuestion2 = false"
                        />
                        <change-content-prompt v-if="showPrompt2" img="doctor_form_banner.jpg"/>
                    </div>
                </div>
                <div class="change-contents__inputs">
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок
                        </div>
                        <input-layout
                            placeholder="Заголовок"
                            :input-value="doctorDashboardContent.doctor_form?.title"
                            @update:input-value="doctorDashboardContent.doctor_form.title = $event"
                        />
                        <div class="change-contents__upload-icon" @click="showChangeIcon = !showChangeIcon">
                            + Сменить иконку
                        </div>

                        <change-icon-popup
                            @close-popup="showChangeIcon = false"
                            v-if="showChangeIcon"
                            :icon="doctorDashboardContent.doctor_form?.icon"
                            @save="changeIcon($event)"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Аннотация
                        </div>
                        <input-layout
                            placeholder="Аннотация"
                            :input-value="doctorDashboardContent.doctor_form?.annotation"
                            @update:input-value="doctorDashboardContent.doctor_form.annotation = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Кнопка
                        </div>
                        <input-layout
                            placeholder="Кнопка"
                            :input-value="doctorDashboardContent.doctor_form?.btn"
                            @update:input-value="doctorDashboardContent.doctor_form.btn = $event"
                        />
                    </div>
                </div>
                <div class="change-contents__btns">
                    <button-helper class="change-contents__btn" color="#FFFFFF" background="#02B061"
                                   @click="updateDoctorDashboardContent">
                        <check-button-docs color="#FFFFFF"/>
                        Сохранить
                    </button-helper>
                    <button-helper class="change-contents__btn" color="#02B061" background="#FFFFFF"
                                   @click="preview(5)">
                        <open-eye-icon color="#02B061"/>
                        Предпросмотр
                    </button-helper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import BigArrowIcon from "@/components/icons/BigArrowIcon.vue";
import QuestionIcon from "@/components/icons/QuestionIcon.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";
import CheckButtonDocs from "@/components/icons/CheckButtonDocs.vue";
import OpenEyeIcon from "@/components/icons/OpenEyeIcon.vue";
import ChangeIconPopup from "@/components/helppers/ChangeIconPopup.vue";
import ChangeContentPrompt from "@/components/helppers/changeContentPrompt.vue";

export default {
    name: 'AdminChangeDoctorDashboardContent',

    components: {
        ChangeContentPrompt,
        ChangeIconPopup, OpenEyeIcon, CheckButtonDocs, ButtonHelper, InputLayout, QuestionIcon, BigArrowIcon},

    emits: ['showPrompt'],

    data() {
        return {
            showChangeContent: false,
            showChangeIcon: false,
            showPrompt1: false,
            hoverQuestion1: false,
            showPrompt2: false,
            hoverQuestion2: false
        }
    },

    computed: {
        doctorDashboardContent() {
            return this.$store.getters.getDoctorDashboardContent
        },
    },

    methods: {
        async updateDoctorDashboardContent() {
            await this.$store.dispatch('updateDoctorDashboardContent', this.doctorDashboardContent)
            this.$store.dispatch('getDoctorDashboardContent')
            this.$emit('showPrompt')
        },
        async preview(id) {
            await this.$store.commit('setShowTopChangeContent', true)
            this.$router.push({name: 'dashboard-preview', params: {id: id}})
        },
        async changeIcon(file) {
            let formData = new FormData()
            formData.append('file', file)

            this.$store.dispatch('changeDoctorDashboardIcon', formData)
        }
    },

}
</script>

<style scoped lang="scss">

.change-contents {

    &__item {
        border: 1px solid #D3D8E2;
        border-radius: 20px;
        padding: 45px 70px;
        overflow: hidden;

        @media screen and (max-width: 769px) {
            padding: 25px;
        }
    }

    &__title {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
        color: #373A43;
        column-gap: 10px;

        @media screen and (max-width: 769px) {
            justify-content: center;
            font-size: 23px;
        }
    }

    &__desc {
        margin-top: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #7F818A;
    }

    &__main {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        row-gap: 60px;
    }

    &__main-item {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
    }

    &__main-title {
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #373A43;
    }

    &__inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 50px;
    }

    &__input {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        max-width: 380px;
        width: 100%;

        @media screen and (max-width: 769px) {
            max-width: 100%;
        }
    }

    &__upload-icon {
        cursor: pointer;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        //text-decoration-line: underline;
        color: #24AD6F;
    }

    &__input-title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #373A43;
    }

    &__btns {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        @media screen and (max-width: 769px) {
            flex-direction: column-reverse;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 12px;
    }

    &__separator {
        border: 1px solid #DBE0EB;
        width: 100%;
        height: 1px;
    }

}

.rotate-icon {
    transition: .2s;
    transform: rotateX(-180deg);
}

</style>

<template>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14.4582 2.47047C12.8672 0.878221 10.7514 0.000894949 8.49729 0C3.85267 0 0.0725138 3.77871 0.0706551 8.42336C0.0700355 9.90803 0.458076 11.3573 1.19545 12.6347L0 17L4.46708 15.8286C5.69784 16.4997 7.08359 16.8533 8.49392 16.8539H8.49738C13.1415 16.8539 16.922 13.0748 16.9239 8.43008C16.9248 6.17921 16.0491 4.06273 14.4582 2.47047ZM8.49738 15.4312H8.49452C7.23778 15.4307 6.00511 15.0932 4.92979 14.4552L4.67402 14.3035L2.02323 14.9986L2.73079 12.4149L2.56424 12.1501C1.86315 11.0353 1.49284 9.7468 1.4934 8.42388C1.49493 4.56344 4.63687 1.42274 8.50016 1.42274C10.3709 1.42345 12.1294 2.1527 13.4517 3.47612C14.7741 4.79954 15.5019 6.55869 15.5012 8.42953C15.4996 12.2902 12.3577 15.4312 8.49738 15.4312ZM12.3391 10.1874C12.1286 10.082 11.0934 9.57279 10.9004 9.50255C10.7074 9.43231 10.5671 9.3972 10.4267 9.60792C10.2863 9.81863 9.88281 10.2927 9.75995 10.4332C9.63716 10.5737 9.51435 10.5913 9.30378 10.4859C9.09324 10.3805 8.41482 10.1583 7.61058 9.44119C6.98469 8.88311 6.56209 8.19377 6.43928 7.98306C6.31646 7.77234 6.42622 7.6584 6.53162 7.55349C6.62632 7.45917 6.74216 7.30765 6.84744 7.18472C6.9527 7.06182 6.98779 6.97402 7.05796 6.83356C7.12816 6.69307 7.09307 6.57014 7.04043 6.46479C6.98779 6.35944 6.56673 5.32341 6.39125 4.90198C6.22036 4.49154 6.04676 4.54708 5.91752 4.54065C5.79484 4.53455 5.65434 4.53324 5.51399 4.53324C5.37362 4.53324 5.14552 4.58593 4.95251 4.79663C4.75952 5.00736 4.21562 5.51658 4.21562 6.55259C4.21562 7.5886 4.97007 8.58947 5.07535 8.72996C5.18061 8.87044 6.56003 10.9965 8.67215 11.9082C9.17449 12.1251 9.56669 12.2546 9.87246 12.3516C10.3769 12.5118 10.8359 12.4892 11.1987 12.435C11.6032 12.3746 12.4444 11.9258 12.6198 11.4341C12.7953 10.9425 12.7953 10.521 12.7427 10.4332C12.69 10.3454 12.5497 10.2927 12.3391 10.1874Z"
              :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: "WhatsappIcon",

    props: {
        color: {
            type: String,
            required: false,
            default: '#C0C4CD'
        }
    },

}
</script>

<style scoped>

</style>

<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
      stroke="#C0C4CD"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M7 4.33325H7.00762" stroke="#C0C4CD" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M6.3335 7H7.00016V9.66667H7.66683"
      stroke="#C0C4CD"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon'
}
</script>

<style scoped></style>

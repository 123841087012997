<template>
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.87882 1.18164H6.81821L9.77276 4.13619H16.6667C17.1891 4.13619 17.6901 4.34371 18.0595 4.7131C18.4289 5.08249 18.6364 5.58349 18.6364 6.10588V13.9847C18.6364 14.5071 18.4289 15.0081 18.0595 15.3775C17.6901 15.7468 17.1891 15.9544 16.6667 15.9544H2.87882C2.35642 15.9544 1.85542 15.7468 1.48603 15.3775C1.11664 15.0081 0.909119 14.5071 0.909119 13.9847V3.15134C0.909119 2.62894 1.11664 2.12794 1.48603 1.75855C1.85542 1.38916 2.35642 1.18164 2.87882 1.18164Z"
      stroke="#02B061"
      stroke-width="1.33"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'FolderIcon'
}
</script>

<style scoped></style>

<template>
    <div class="doctor-item" :class="{'doctor-item-row': listType}" v-if="user">
        <router-link
            style="text-decoration: none"
            :to="{ name: 'doctor-page', params: { id: user.id }, query: {spec: this.$route.query.spec} }"
        >
            <div class="doctor-item-main" :class="{'doctor-item-main-row': listType}">
                <div class="content-img">
                    <img :src="img" :class="{'no-img': user.user_detales?.image === 'no_doctor_photo.svg'}" class="user-image"/>
                    <div class="heart">
                        <heart-icon/>
                    </div>
                </div>

                <div class="doctor-item-text">
                    <div class="content-name">{{ user.last_name }} {{ user.first_name }} {{ user.father_name }}</div>
                    <div class="content-spec">
                        <span class="special">{{ user.user_detales?.description }} </span>
                    </div>
                </div>
            </div>
        </router-link>

        <div class="doctor-item-buttons">
            <div class="like" @mouseleave="this.info = false" @mouseover="this.info = true">
                <recomend-me-icon/>
                {{ user?.my_recommendations?.length }}
                <info-helpper class="info-help" v-if="this.info">
                    Его рекомендуют
                    <span>{{ user?.my_recommendations?.length }} специалистов</span>
                </info-helpper>
            </div>
            <div class="like" @mouseleave="this.myinfo = false" @mouseover="myinfo = true">
                <recomend-icon/>
                {{ user?.recommendations?.length }}
                <info-helpper class="info-help" v-if="this.myinfo">
                    Он рекомендует
                    <span>{{ user?.recommendations?.length }} специалистов</span>
                </info-helpper>
            </div>
        </div>
        <div class="doctor-item-buttons" v-if="loginUser && (loginUser.role_id == 1 || loginUser.role_id == 2)">
                <button-helper
                  class="doctor-details-btn"
                  color="#ffff"
                  background="#02B061"
                  @click="editRecommendation(user)"
                  >Редактировать связь
                </button-helper>
        </div>
    </div>
</template>

<script>
import config from '@/config/config'
import HeartIcon from '@/components/icons/HeartIcon.vue'
import RecomendMeIcon from '@/components/icons/RecomendMeIcon.vue'
import RecomendIcon from '@/components/icons/RecomendIcon.vue'
import InfoHelpper from '@/components/helppers/InfoHelpper.vue'
import ButtonHelper from '@/components/helppers/ButtonHelper.vue'

export default {
    name: 'DoctorItem',
    components: {InfoHelpper, RecomendIcon, RecomendMeIcon, HeartIcon, ButtonHelper},
    props: {
        user: {
            type: Object,
        },
        listType: {
            type: Boolean,
            required: true
        },
        recommendationType: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            myinfo: false,
            info: false
        }
    },
    computed: {
        img() {
            return `${config.URL}/img/doctors/${this.user.user_detales.image}`
        },
        loginUser() {
            return this.$store.getters.getUser
        },
    },
    methods: {
        editRecommendation(user){
            let doctor = user;
            this.$store.dispatch('editRecommendationDoctor', true);
            this.$store.dispatch('editRecommendationDoctorInfo', doctor);
            this.$store.dispatch('editRecommendationDoctorType', this.recommendationType);
        }
    }
}
</script>

<style scoped>
.doctor-item {
    border: 1px solid #DBE0EB;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding: 20px 25px;
}

.doctor-item-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    column-gap: 40px;
    row-gap: 20px;
    text-align: center;
}

.doctor-item-main-row {
    flex-direction: row;
    text-align: left;
}

.doctor-item-row {
    flex-direction: row;
    justify-content: space-between;
}

.doctor-item-buttons {
    display: flex;
    column-gap: 10px;
}

.content-img {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #e9ebf0;
}

.no-img {
    width: 14px !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.user-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.content-name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #373a43;
}

.content-spec {
    font-family: 'Roboto';
    font-style: normal;
    font-weilght: 300;
    font-size: 13px;
    color: #656873;
}

.heart {
    text-align: center;
    position: absolute;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 50%;
    background: #ffffff;
    top: 75px;
    right: 10px;
}

.like {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
    width: 45px;
    height: 30px;
    border: 1px solid #dbe0eb;
    border-radius: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #373a43;
}

.info-help {
    z-index: 100;
    position: absolute;
    top: 45px;
    left: -45px;
}

.info-help span {
    display: block;
    text-decoration-line: underline;
}

@media screen and (max-width: 1440px) {

    .content-img {
        width: 80px;
        height: 80px;
    }

    .user-image {
        width: 80px;
        height: 80px;
    }

    .content-name {
        font-size: 15px;
    }

    .heart {
        width: 30px;
        height: 30px;
        line-height: 30px;
        top: 55px;
    }

}

@media screen and (max-width: 1366px) {

    .content-img {
        width: 70px;
        height: 70px;
    }

    .user-image {
        width: 70px;
        height: 70px;
    }

    .content-name {
        font-size: 13px;
    }

    .content-spec {
        font-size: 11px;
    }

    .heart {
        width: 25px;
        height: 25px;
        line-height: 25px;
        top: 50px;
    }

}

@media screen and (max-width: 1080px) {
    .content-img {
        width: 80px;
        height: 80px;
    }

    .user-image {
        width: 80px;
        height: 80px;
    }

    .content-name {
        font-size: 11px;
    }

    .content-spec {
        font-size: 9px;
    }

    .heart {
        width: 20px;
        height: 20px;
        line-height: 20px;
        top: 45px;
    }
}

@media screen and (max-width: 769px) {

    .user-image {
        width: 100%;
        height: 100%;
    }

    .content-img {
        width: 80px;
        height: 80px;
    }

    .heart {
        width: 26px;
        height: 26px;
        line-height: 26px;
        top: 56px;
        right: -3px;
    }

}

</style>

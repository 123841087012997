<template>
  <svg width="48" height="26" viewBox="0 0 48 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="26" rx="13" fill="#E8EAED" />
    <circle cx="13" cy="13" r="9" fill="white" />
  </svg>
</template>

<script>
export default {
  name: 'SwitchIcon'
}
</script>

<style scoped></style>

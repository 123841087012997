<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.33333 1H14.6667C15 1 15.2222 1.11111 15.4444 1.33333L20.6667 6.55556C20.8889 6.77778 21 7 21 7.33333V14.6667C21 15 20.8889 15.2222 20.6667 15.4444L15.4444 20.6667C15.2222 20.8889 15 21 14.6667 21H7.33333C7 21 6.77778 20.8889 6.55556 20.6667L1.33333 15.4444C1.11111 15.2222 1 15 1 14.6667V7.33333C1 7 1.11111 6.77778 1.33333 6.55556L6.55556 1.33333C6.77778 1.11111 7 1 7.33333 1V1Z" stroke="#02B061" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 8.71311C9 8.25876 9.21057 7.82303 9.58538 7.50176C9.9602 7.18049 10.4686 7 10.9986 7H11.5697C12.0997 7 12.6081 7.18049 12.9829 7.50176C13.3577 7.82303 13.5683 8.25876 13.5683 8.71311C13.5893 9.08385 13.4893 9.45139 13.2833 9.76037C13.0773 10.0693 12.7765 10.303 12.4262 10.4262C12.0759 10.5905 11.7751 10.902 11.5691 11.314C11.3631 11.726 11.2631 12.216 11.2841 12.7104" stroke="#02B061" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.2842 14.9937V14.9995" stroke="#02B061" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'RecommendQuestion'
}
</script>

<style scoped></style>

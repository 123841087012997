<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('showJob', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Опыт работы</div>
      <div class="cont-flex">
        <div class="inputes">
          <span class="input-title">Начало работы</span>
          <div class="small-inputes">
            <select-helper
              class="right-border"
              :styles="selectStyles"
              :select-value="this.job?.start_year"
              :show-select="this.showSelectStart"
              :select-by="'Год'"
              :drop-down-list="this.dropYear"
              @changeShowSelect="this.showSelectStart = $event"
              @changeSelectValue="sortingSelect($event, 'start_year')"
            />
            <select-helper
              :styles="selectStyles"
              :select-value="this.job?.start_month"
              :show-select="this.showSelectStartMonth"
              :select-by="'Месяц'"
              :drop-down-list="this.dropMonth"
              @changeShowSelect="this.showSelectStartMonth = $event"
              @changeSelectValue="checkedMonth($event, 'start_month')"
            />
          </div>
        </div>
        <div class="inputes" style="margin-left: 46px">
          <span class="input-title">Завершение работы</span>
          <div class="small-inputes">
            <select-helper
              class="right-border"
              :styles="selectStyles"
              :select-value="this.job?.end_year"
              :show-select="this.showSelectEnd"
              :select-by="'Год'"
              :drop-down-list="this.dropYear"
              @changeShowSelect="this.showSelectEnd = $event"
              @changeSelectValue="sortingSelect($event, 'end_year')"
            />
            <select-helper
              :styles="selectStyles"
              :select-value="this.job?.end_month"
              :show-select="this.showSelectEndMonth"
              :select-by="'Месяц'"
              :drop-down-list="this.dropMonth"
              @changeShowSelect="this.showSelectEndMonth = $event"
              @changeSelectValue="checkedMonth($event, 'end_month')"
            />
            <!-- <input type="text" placeholder="Месяц" v-model="job.end_month" class="small-input" /> -->
          </div>
          <div class="checkbox">
            <input type="checkbox" v-model="job.current_date"  :checked="Boolean(job.current_date)"/> <span class="check-text">По настоящее время</span>
          </div>
        </div>
      </div>
      <div class="cont-flex">
        <div class="inputes">
          <input type="text" class="input" v-model="job.company" placeholder="Организация" />
        </div>
        <div class="inputes" style="margin-left: 46px">
          <input type="text" class="input" v-model="job.city" placeholder="Город" />
        </div>
      </div>
      <div class="cont-flex">
        <div class="inputes">
          <input type="text" class="input" v-model="job.title" placeholder="Должность" />
        </div>
      </div>
      <div class="buttons">
        <button-helper class="save-button" color="#ffffff" @click="subscribe" background="#02B061">
          Сохранить
        </button-helper>
        <span class="text" @click="this.$store.dispatch('showJob', false)"> Отмена </span>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import SelectHelper from '@/components/helppers/SelectHelper.vue'

export default {
  name: 'JobPopup',
  components: {
    ButtonHelper,
    SelectHelper
  },
  data() {
    return {
      job: {},
      editJob: null,
      showSelectEnd: false,
      showSelectStart: false,
      showSelectStartMonth: false,
      showSelectEndMonth: false,
      dropYear: [],
      dropMonth: ['Январь', 'Ферваль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      selectStyles: {
          width: '120px',
          marginTop: '0px'
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    doctor() {
      return this.$store.getters.getDoctorDetalies
    }
  },
  methods: {
    async subscribe() {
      if(this.editJob){
        if(this.doctor.id == this.user.id){
          await this.$store.dispatch('editJob', this.job)
        }else{
          await this.$store.dispatch('editJobDoctor', this.job)
          await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        }
      }else{
        if(this.doctor.id == this.user.id){
          await this.$store.dispatch('addJob', this.job)
        }else{
          this.job.doctor_id = this.doctor.id;
          await this.$store.dispatch('addJobDoctor', this.job)
          await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        }
      }
      this.$store.dispatch('showJob', false)
      this.editJob = null;
      this.job = null;
      this.$store.dispatch('resetPersonal');
    },
    sortingSelect($event, year){
      this.job[year] = $event;
    },
    checkedMonth($event, month){
      let numberMonth = this.sortingMonth($event);
      this.job[month] = numberMonth;
    },
    sortingMonth(month){
      switch (month) {
        case 'Январь':
          return 1
        case 'Ферваль':
          return 2
        case 'Март':
          return 3
        case 'Апрель':
          return 4
        case 'Май':
          return 5
        case 'Июнь':
          return 6
        case 'Июль':
          return 7
        case 'Август':
          return 8
        case 'Сентябрь':
          return 9
        case 'Октябрь':
          return 10
        case 'Ноябрь':
          return 11
        case 'Декабрь':
          return 12
        default:
          return 0
      }
    }
  },
  mounted() {
    this.editJob = this.$store.getters.getJob;
    if(this.editJob){
      this.job = this.editJob;
    }

    const currentYear = new Date().getFullYear();
    const startYear = 1923;

    for (let year = currentYear; year >= startYear; year--) {
      this.dropYear.push(year);
    }
  }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  width: 674px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}
.buttons {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 45px;
}
.text {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #24ad6f;
  margin-left: 20px;
}
.cont-flex {
  margin-top: 40px !important;
  width: 556px;
  margin: auto;
  display: flex;
}
.inputes {
  width: 245px;
}
.small-inputes {
  display: flex;
  width: 245px;
  height: 46px;
  background: #edeff3;
  border-radius: 10px;
}
.small-input {
  width: 120px;
  height: 46px;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #7f818a;
  padding-left: 21px;
}
.small-input:focus {
  outline: none;
}

.small-input::-webkit-outer-spin-button,
.small-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.small-input[type='number'] {
  -moz-appearance: textfield;
}
.right-border {
  border-right: 1px solid #dbe0eb;
}

.check-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #373a43;
}
.checkbox {
  margin-top: 19px;
}
.input {
  width: 245px;
  height: 46px;
  background: #edeff3;
  border: none;
  border-radius: 10px;
  padding-left: 21px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #7f818a;
}

.input:focus {
  outline: none;
}
.save-button {
  padding: 17px 24px;
}
.input-title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373A43;
}

@media screen and (max-width: 425px) {
    .form {
        width: 90%;
        padding: 7px;
    }

    .title {
        margin-bottom: 10px;
    }

    .cont-flex {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin-top: 0 !important;
    }

    .inputes {
        margin-left: 0 !important;
        text-align: center;
    }

    .input {
        margin-bottom: 20px;
    }

    .checkbox {
        margin-top: 3px;
        margin-bottom: 20px;
    }

    .input-title {
        display: inline-block;
        margin-bottom: 7px;
    }
}
</style>

<template>
  <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.99385 16.4515L6.63976 10.5276C6.51489 10.2133 6.51996 9.86237 6.65386 9.55189C6.78775 9.2414 7.0395 8.99682 7.35373 8.87196C7.66795 8.74709 8.01891 8.75216 8.32939 8.88605C8.63988 9.01995 8.88446 9.2717 9.00933 9.58592L11.0495 14.72" stroke="#02B061" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.99994 9.59066L8.37218 8.01095C8.31036 7.85537 8.27978 7.68912 8.2822 7.52172C8.28462 7.35431 8.31998 7.18902 8.38628 7.03529C8.45258 6.88155 8.54851 6.74238 8.66859 6.62571C8.78867 6.50905 8.93056 6.41718 9.08615 6.35535C9.24174 6.29353 9.40798 6.26295 9.57538 6.26537C9.74279 6.26779 9.90808 6.30315 10.0618 6.36945C10.2156 6.43575 10.3547 6.53168 10.4714 6.65176C10.588 6.77184 10.6799 6.91373 10.7417 7.06932L13.4097 13.7831" stroke="#02B061" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.3732 8.6467C11.2483 8.33247 11.2534 7.98151 11.3873 7.67103C11.5212 7.36054 11.7729 7.11597 12.0871 6.9911C12.4013 6.86623 12.7523 6.8713 13.0628 7.00519C13.3733 7.13909 13.6179 7.39084 13.7427 7.70506L15.7829 12.8391" stroke="#02B061" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.3719 9.2881C14.247 8.97387 14.2521 8.62291 14.386 8.31243C14.5199 8.00194 14.7716 7.75737 15.0859 7.6325C15.4001 7.50763 15.7511 7.5127 16.0615 7.64659C16.372 7.78049 16.6166 8.03224 16.7415 8.34646L19.4094 15.0602C19.9089 16.3171 19.8886 17.7209 19.353 18.9629C18.8175 20.2048 17.8105 21.1831 16.5536 21.6826L14.9739 22.3104L15.1382 22.2451C14.3534 22.5571 13.5034 22.6693 12.6645 22.5717C11.8256 22.474 11.0241 22.1696 10.3319 21.6856C10.2488 21.6274 10.1658 21.5689 10.083 21.5102C9.68618 21.2298 8.2221 20.0657 5.68961 18.0173C5.43145 17.8085 5.26493 17.5072 5.22547 17.1776C5.18601 16.8479 5.27672 16.5158 5.47831 16.252C5.69298 15.9708 5.99543 15.7695 6.33764 15.6799C6.67984 15.5903 7.04216 15.6176 7.36706 15.7575L8.98955 16.4572" stroke="#02B061" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.81496 5C3.81602 5 2.31632 7.5 3.31648 9.5" stroke="#02B061" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "InvateButtonIcon"
}
</script>

<style scoped>

</style>

<template>
  <div class="mobile-fixed-menu mobile-only"
       :class="{'mobile-fixed-menu__doctor': role == 3, 'mobile-fixed-menu__admin': role == 1 || role == 2}">
    <div class="mobile-fixed-menu__item" :class="{'mobile-fixed-menu__item-no-icon':  !item.icon}" v-for="item in menu" :key="item.id">
      <router-link :to="{ name: 'dashboard', params: { id: item.id } }">
        <component :is="item.icon" :color="'#A4ACBD'"/>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileFixedMenu',

  props: {
    menu: {
      required: true
    },
    role: {
        required: true
    }
  },

}
</script>

<style scoped lang="scss">
.mobile-fixed-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 40px rgba(132, 132, 151, 0.3);
  padding: 15px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

    &__doctor {
        @media screen and (max-width: 380px) {
            column-gap: 30px;
        }
    }

    &__admin {
        @media screen and (max-width: 460px) {
            column-gap: 30px;
        }

        @media screen and (max-width: 380px) {
            column-gap: 20px;
        }
    }
}

.mobile-fixed-menu__item {

}

.mobile-fixed-menu__item-no-icon {
    display: none;
}

</style>

<template>
  <svg width="14" height="11" viewBox="0 0 14 11" fill="#A4ACBD" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4358 1.71738C13.75 2.9425 13.75 5.5 13.75 5.5C13.75 5.5 13.75 8.0575 13.4358 9.28263C13.2612 9.95981 12.7504 10.4926 12.1034 10.6728C10.9285 11 6.875 11 6.875 11C6.875 11 2.82356 11 1.64656 10.6728C0.996875 10.4899 0.48675 9.95775 0.314188 9.28263C2.04891e-08 8.0575 0 5.5 0 5.5C0 5.5 2.04891e-08 2.9425 0.314188 1.71738C0.488813 1.04019 0.999625 0.507375 1.64656 0.32725C2.82356 -1.22935e-07 6.875 0 6.875 0C6.875 0 10.9285 -1.22935e-07 12.1034 0.32725C12.7531 0.510125 13.2632 1.04225 13.4358 1.71738ZM5.5 7.90625L9.625 5.5L5.5 3.09375V7.90625Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'YoutubeIcon'
}
</script>

<style scoped></style>

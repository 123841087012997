<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" rx="5" fill="#02B061"/>
    <path d="M6 9L8 11L12 7" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CheckboxOnIcon"
}
</script>

<style scoped>

</style>

<template>
    <div class="modal-layout__shadow" @click.self="closeModal">
        <div class="modal-layout" :style="{maxWidth: maxWidth, maxHeight: maxHeight}" >
            <div class="modal-layout__close" @click="closeModal">
                x
            </div>

            <slot> <!--CONTENT--> </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalLayout',

    emits: ['closeModal'],

    props: {
        maxWidth: {
            required: false,
            type: String,
            default: '500px'
        },
        maxHeight: {
            required: false,
            type: String,
            default: '600px'
        },
        noOverflow: {
            required: false,
            default: false
        }
    },

    methods: {
        closeModal() {
            this.$emit('closeModal')
        }
    },



}
</script>

<style scoped lang="scss">
.modal-layout__shadow {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(238, 240, 244, .8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-layout {
    position: relative;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 4px 50px rgba(189, 189, 189, 0.7);
    border-radius: 20px;
    padding: 40px 60px;
    animation: show .3s forwards;
    overflow: auto;

    @media screen and (max-width: 769px) {
        padding: 20px;
    }
}

.no-overflow {
    overflow: hidden;
}

.modal-layout::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
}

.modal-layout::-webkit-scrollbar-track {
    background: rgba(203, 208, 217, 0.5);
    border-radius: 50px;
}

.modal-layout__close {
    line-height: 16px;
    position: absolute;
    font-size: 16px;
    top: 10px;
    right: 12px;
    cursor: pointer;
    color: #BDC3C9;
}


</style>

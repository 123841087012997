<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
            icon="close"
            @click="this.$store.dispatch('changeBlockUser', false)"
            color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="icon">
        <font-awesome-icon icon="circle-question" color="#02B061"></font-awesome-icon>
      </div>
      <div class="title">{{ user.text}}</div>
      <div class="buttons">
        <button-helper color="#ffffff" @click="blockDoctor" background="#02B061">
            {{ user.buttonText }}
        </button-helper>
        <span class="text" @click="this.$store.dispatch('changeBlockUser', false)"> Отмена </span>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'

export default {
  name: "BlockHelpper",
  components: { ButtonHelper },
  computed: {
    user() {
      return this.$store.getters.getBlockUser
    }
  },
  methods: {
    async blockDoctor() {
      if (this.user.block){
          await this.$store.dispatch('blockDoctor', {user_id: this.user.doctor.id});
          await this.$store.dispatch('changeDelete', false);
          await this.$store.dispatch('changeBlockUser', false)
          location.reload();
      }else{
          await this.$store.dispatch('unBlockDoctor', {user_id: this.user.doctor.id});
          await this.$store.dispatch('changeDelete', false);
          await this.$store.dispatch('changeBlockUser', false)
          location.reload();
      }
    }
  }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 483px;
  height: 230px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 10px;
}
.icon {
  text-align: center;
  margin-top: 40px;
}
.buttons {
  text-align: center;
  margin-top: 20px;
}
.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #24ad6f;
  margin-left: 20px;
}
.text-main {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #656873;
  margin-top: 10px;
  text-align: center;
}

@media screen and (max-width: 792px) {
  .form{
    width: 100%;
  }

}
</style>

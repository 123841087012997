<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('showEducation', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Высшее образование</div>
      <div class="inputs">
        <div class="icon-div">
          <input type="text" placeholder="Уровень" class="input-soc" v-model="education.level" />
        </div>
      </div>
      <div class="inputs">
        <div class="icon-div">
          <input
            type="text"
            placeholder="Учебное заведение"
            class="input-soc"
            v-model="education.school"
          />
        </div>
      </div>
      <div class="inputs">
        <div class="icon-div">
          <input
            type="text"
            placeholder="Факультет"
            class="input-soc"
            v-model="education.faculty"
          />
        </div>
      </div>
      <div class="inputs">
        <div class="icon-div">
          <input
            type="text"
            placeholder="Специализация"
            class="input-soc"
            v-model="education.speciality"
          />
        </div>
      </div>
      <div class="input-flex">
        <div class="inputs inputs-flex">
          <div class="icon-div">
            <select-helper
              :styles="selectStyles"
              :select-value="this.education?.start_year"
              :show-select="this.showSelectStart"
              :select-by="'Год начала'"
              :drop-down-list="this.dropYear"
              @changeShowSelect="this.showSelectStart = $event"
              @changeSelectValue="sortingSelect($event, 'start_year')"
            />

          </div>
        </div>
        <div class="inputs inputs-flex" style="text-align: right">
          <div class="icon-div">
            <select-helper
              :styles="selectStyles"
              :select-value="this.education?.end_year"
              :show-select="this.showSelectEnd"
              :select-by="'Год завершения'"
              :drop-down-list="this.dropYear"
              @changeShowSelect="this.showSelectEnd = $event"
              @changeSelectValue="sortingSelect($event, 'end_year')"
            />
          </div>
        </div>
      </div>
      <div class="buttons">
        <button-helper class="save-button" color="#ffffff" @click="submit" background="#02B061">
          Сохранить
        </button-helper>
        <span class="text" @click="this.$store.dispatch('showEducation', false)"> Отмена </span>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import SelectHelper from '@/components/helppers/SelectHelper.vue'
export default {
  name: 'EducationPopup',
  components: {
    ButtonHelper,
    SelectHelper
  },
  data() {
    return {
      education: {},
      editEducation: null,
      showSelectEnd: false,
      showSelectStart: false,
      dropYear: [],
      selectStyles: {
          width: '200px',
          marginTop: '5px'
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    doctor() {
      return this.$store.getters.getDoctorDetalies
    }
  },
  methods: {
    async submit() {
      if(this.editEducation){
        if(this.doctor.id == this.user.id){
          await this.$store.dispatch('editEducation', this.education)
        }else{
          await this.$store.dispatch('editEducationDoctor', this.education)
          await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        }
      }else{
        if(this.doctor.id == this.user.id){
          await this.$store.dispatch('addEducation', this.education)
        }else{
          this.education.doctor_id = this.doctor.id;
          await this.$store.dispatch('addEducationDoctor', this.education)
          await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        }
      }

      this.$store.dispatch('showEducation', false)
      this.editEducation = null;
      this.education = null;
      this.$store.dispatch('resetPersonal');
    },
    sortingSelect($event, year){
      this.education[year] = $event;
    },
    handleResize() {
      if (window.innerWidth === 425) {
        this.selectStyles.width = '271px';
      } else if (window.innerWidth === 375) {
        this.selectStyles.width = '237px';
      } else if (window.innerWidth === 320) {
        this.selectStyles.width = '204px';
      } else {
        this.selectStyles.width = '200px';
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();
    this.editEducation = this.$store.getters.getEducation;
    if(this.editEducation){
      this.education = this.editEducation;
    }
    const currentYear = new Date().getFullYear();
    const startYear = 1923;

    for (let year = currentYear; year >= startYear; year--) {
      this.dropYear.push(year);
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  width: 530px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}
.inputs {
  width: 420px;
  margin: 10px auto;
}

.input {
  width: 200px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}

.input-soc::-webkit-outer-spin-button,
.input-soc::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-soc[type='number'] {
  -moz-appearance: textfield;
}
.input-soc {
  width: 420px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}

.input:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.input-soc:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.icon-left {
  position: absolute;
  top: 35px;
  left: 30px;
}

.icon-right {
  position: absolute;
  top: 35px;
  right: 15px;
}

.icon-div {
  display: flex;
  position: relative;
}
.button {
  margin-top: 45px;
  text-align: center;
  margin-bottom: 20px;
}

.input-flex {
  display: flex;
  width: 420px;
  margin: auto;
}
.buttons {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 45px;
}
.text {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #24ad6f;
  margin-left: 20px;
}
.save-button {
    padding: 17px 24px;
}

@media screen and (max-width: 425px) {
    .form {
        width: 80%;
    }

    .inputs {
        width: 80%;
    }

    .inputs-flex {
        width: 84%;
    }

    .input-flex {
        flex-direction: column;
    }
}

@media screen and (max-width: 375px) {
    .inputs-flex {
        width: 85%;
    }
}

@media screen and (max-width: 320px) {
    .inputs-flex {
        width: 88%;
    }
}
</style>

<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="4.5" stroke="#C2C7D0"/>
  </svg>
</template>

<script>
export default {
  name: "CheckboxIcon"
}
</script>

<style scoped>

</style>

<template>
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33333 2.77778C6.33333 2.30628 6.52063 1.8541 6.85403 1.5207C7.18743 1.1873 7.63961 1 8.11111 1C8.5826 1 9.03479 1.1873 9.36819 1.5207C9.70158 1.8541 9.88888 2.30628 9.88888 2.77778C10.9097 3.26047 11.7799 4.01184 12.4063 4.95138C13.0326 5.89091 13.3915 6.98317 13.4444 8.11111V10.7778C13.5113 11.3304 13.707 11.8596 14.0158 12.3228C14.3246 12.7859 14.7378 13.1701 15.2222 13.4444H1C1.48439 13.1701 1.89761 12.7859 2.20639 12.3228C2.51517 11.8596 2.71088 11.3304 2.77778 10.7778V8.11111C2.83072 6.98317 3.1896 5.89091 3.81595 4.95138C4.44231 4.01184 5.31252 3.26047 6.33333 2.77778"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.44434 13.4443V14.3332C5.44434 15.0405 5.72529 15.7187 6.22538 16.2188C6.72548 16.7189 7.40376 16.9999 8.111 16.9999C8.81824 16.9999 9.49652 16.7189 9.99662 16.2188C10.4967 15.7187 10.7777 15.0405 10.7777 14.3332V13.4443"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#02B061'
    }
  },

  name: 'NotificationIcon'
}
</script>

<style scoped></style>

<template>
    <div class="full-screen">
        <div class="form">
            <div class="close-parent">
                <close-icon @click="closeModal"/>
            </div>
            <div class="header">Выбор города</div>
            <label class="search">
                <magnify-icon/>
                <input type="text" placeholder="Ваш город" v-model="city"/>
            </label>
            <template v-if="!city.length">
            <div class="popular">
                <div class="popular-item" @click="chooseCity(city.id)" v-for="city in popularCities" :key="city.id">
                    {{ city.city }}
                </div>
            </div>
            <div class="choose">
                <div class="items">
                    <div class="title">Округ</div>
                    <div class="item" :class="{'chosen':key === stateKey}" @click="stateKey = key"
                         v-for="(state,key) in states"
                         :key="state.id">{{ state.state }}
                    </div>
                </div>
                <div class="items">
                    <div class="title">Регион</div>
                    <div class="item" :class="{'chosen':key === regionKey}" @click="regionKey = key"
                         v-for="(region,key) in states[stateKey]?.regions"
                         :key="region.id">{{ region.region }}
                    </div>
                </div>
                <div class="items">
                    <div class="title">Город</div>
                    <div class="item" :class="{'chosen':key === cityKey}" @click="chooseCity(city.id)"
                         v-for="(city,key) in states[stateKey]?.regions[regionKey]?.cities" :key="city.id">
                        {{ city.city }}
                    </div>
                </div>
            </div>
            </template>
            <div class="search-result" v-else>
                <div class="item" :key="city.id" v-for="city in cities" @click="chooseCity(city.id)">{{city.city}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectCityPopup',
    data() {
        return {
            stateKey: 0,
            regionKey: 0,
            cityKey: 0,
            city:''
        }
    },
    async mounted() {
        await this.$store.dispatch('getCities')
        await this.$store.dispatch('getPopularCities')
    },
    methods: {
        async chooseCity(id) {
            let params = {
                city_id:id,
                user_id:this.user.id
            }
            await this.$store.dispatch('setLocation',params)
            this.closeModal()
        },
        closeModal(){
            this.$store.dispatch('changeSelectCityPopup', false)
        }
    },
    computed: {
        cities() {
            return this.$store.getters.getCities.filter(city=>{
                return city.city.toUpperCase().match(this.city.toUpperCase())
            })
        },
        states() {
            return this.$store.getters.getStates
        },
        popularCities() {
            return this.$store.getters.getPopularCities
        },
        user(){
            return this.$store.getters.getUser
        }
    }
}
</script>

<style scoped lang="scss">
.full-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(238, 240, 244, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

.form {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    width: 781px;
    height: max-content;
    min-height: 680px;
    background: #ffffff;
    box-shadow: 0 4px 50px rgba(189, 189, 189, 0.7);
    border-radius: 20px;
    right: 0;
    bottom: 0;
    padding: 58px 56px;
}

.close-parent {
    width: 11px;
    height: 11px;
    position: absolute;
    top: 16px;
    right: 27px;
    cursor: pointer;

    svg {
        width: inherit;
        height: inherit;
        top: 0;
        position: absolute;
    }
}

.header {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 50px;
}

.search {
    position: relative;
    width: 100%;
    margin-bottom: 38px;

    svg {
        position: absolute;
        stroke: #a4acbd;
        top: 15px;
        left: 14px;
    }

    input {
        border-radius: 10px;
        background: #edeff3;
        padding: 16px 43px 15px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #7f818a;
        border: none;
        width: 100%;

        &:focus-visible {
            outline: none;
        }
    }
}

.popular {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 13px;
    margin-bottom: 72px;

    .popular-item {
        border: 1px solid #dbe0eb;
        border-radius: 50px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #656873;
        padding: 11px 15px 10px;
        cursor: pointer;
    }
}

.choose {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
    gap: 10px;
    max-height: 320px;
    overflow: auto;

    .items {
        display: flex;
        flex-direction: column;

        .title {
            padding: 0 16px;
            margin-bottom: 16px;
        }

        .item {
            padding: 5px 16px;
            border-radius: 50px;
            color: #656873;
            width: max-content;
            margin-bottom: 5px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 34px;
            cursor: pointer;

            &.chosen {
                background: #eaf2ef;
                color: #24ad6f;
            }
        }
    }
}

.search-result {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    max-height: 403px;
    margin-bottom: 0;
overflow: auto;
    .item {
        border: none;
        padding: 0;
        line-height: 30px;
        border-radius: 50px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        color: #656873;
        cursor: pointer;
    }
}

@media screen and (max-width: 800px) {
    .form {
        width: auto;
        padding: 30px;
    }
    .choose {
        overflow: auto;

        &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
            border-radius: 50px;
        }

        &::-webkit-scrollbar-track {
            background: rgba(203, 208, 217, 0.5);
            border-radius: 50px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d6d9e0;
            border-radius: 50px;
            border: 5px solid #d6d9e0;
        }
    }
    .popular {
        overflow-y: auto;
        max-height: 200px;

        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 50px;
        }

        &::-webkit-scrollbar-track {
            background: rgba(203, 208, 217, 0.5);
            border-radius: 50px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d6d9e0;
            border-radius: 50px;
            border: 5px solid #d6d9e0;
        }
    }
}

@media screen and (max-width: 600px) {
    .choose {
        display: none;
    }
    .close-parent {
        top: 14px;
        right: 13px;
    }
    .form {
        min-height: 380px;
        padding: 22px 20px 20px 20px;
    }
    .header {
        margin-bottom: 24px;
    }
    .search {
        margin-bottom: 21px;
    }
    .popular,.search-result {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 10px;
        max-height: 260px;
        margin-bottom: 0;

        .popular-item {
            border: none;
            padding: 0;
            line-height: 30px;
        }
    }
}
</style>

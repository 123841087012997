export default {
  state: {
    favorites: [],
    favoritesIds: []
  },

  getters: {
    getFavoritesIds(state) {
      return state.favoritesIds
    },
    getFavorites(state) {
      return state.favorites
    },
  },

  mutations: {
    setFavoritesIds(state, favoritesIds) {
      state.favoritesIds = favoritesIds
    },
    setFavorites(state, favorites) {
      state.favorites = favorites
    },
  },

  actions: {
      async addFavorite(_,params) {
          await this.$axios.post(`/api/favorite/${params.id}`)
      },
    async getUserFavoritesIds({ commit }) {
      await this.$axios.get(`/api/favoritesIds`).then((response) => {
        commit('setFavoritesIds', response.data)
      })
    },

    async getUserFavorites({ commit }) {
      await this.$axios.get(`/api/favoritesDoctors`).then((response) => {
        commit('setFavorites', response.data)
      })
    },

    async deleteFavorite({ commit }, data) {
      await this.$axios.delete(`/api/favorite/${data.favorite_id}`).then((response) => {
        commit('setFavorites', response.data)
      })
    },
  }
}

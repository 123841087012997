<template>
    <div class="loader">
        <LoaderIcon/>
    </div>
</template>

<script>
import LoaderIcon from "@/components/icons/LoaderIcon.vue";

export default {
    name: "PageLoader",
    components: {LoaderIcon}
}
</script>

<style scoped>
.loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
}

.loader svg{
    width: 100px;
}
</style>

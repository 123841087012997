<template>
  <svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.48114 0.130289C3.48514 0.135089 3.48674 0.141489 3.49074 0.146289L6.28362 3.23198C6.43962 3.40558 6.43962 3.68719 6.28362 3.8608C6.28202 3.8624 6.28042 3.8632 6.27882 3.864C6.24411 3.90603 6.20064 3.93998 6.15145 3.96347C6.10225 3.98697 6.04852 3.99943 5.99401 4H0.407452C0.35194 3.9991 0.297302 3.98603 0.247398 3.9617C0.197494 3.93737 0.153541 3.90238 0.118644 3.8592L0.117044 3.8608C0.0415455 3.77345 0 3.66184 0 3.54639C0 3.43093 0.0415455 3.31933 0.117044 3.23198L2.91632 0.130289C2.95117 0.0894396 2.99447 0.0566363 3.04322 0.0341425C3.09198 0.0116487 3.14504 0 3.19873 0C3.25243 0 3.30548 0.0116487 3.35424 0.0341425C3.40299 0.0566363 3.44629 0.0894396 3.48114 0.130289Z"
      fill="#5B5F6C"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowUp'
}
</script>

<style scoped></style>

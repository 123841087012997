<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('changeSubscribPopup', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="icon">
        <recommend-question/>
      </div>
      <div class="title">
        {{ subscribeType }}
      </div>
      <div class="buttons">
        <button-helper color="#ffffff" @click="subscribe" background="#02B061" style="padding: 17px 26px">
            {{ subscribeInfo.subscribeText }}
        </button-helper>
        <span class="text" @click="this.$store.dispatch('changeSubscribPopup', false)">
          Отмена
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import RecommendQuestion from "@/components/icons/RecommendQuestion.vue";

export default {
  name: 'SubscribePopup',
  components: { ButtonHelper, RecommendQuestion },
  computed: {
    subscribeType() {
      return this.$store.getters.getSubscribeType
    },
    subscribeInfo() {
      return this.$store.getters.getSubscribeInfo
    }
  },
  methods: {
    async subscribe() {
      if (
          this.subscribeInfo.subscribeType &&
          this.subscribeInfo.subscribeType !== 'delete' &&
          this.subscribeInfo.subscribeType !== 'unsubscribe' &&
          this.subscribeInfo.subscribeType !== 'deleteFavorite'
        )
      {
        await this.$store.dispatch('setSubscribe', {
          doctor_id: this.subscribeInfo.user_id,
          recommendation_id: this.subscribeInfo.doctor_id
        })
      } else if (this.subscribeInfo.subscribeType == 'delete') {
        await this.$store.dispatch('deleteSubscribe', {
          id: this.subscribeInfo.id
        })
      } else if (this.subscribeInfo.subscribeType == 'unsubscribe') {
          await this.$store.dispatch('setUnsubscribe', {
              doctor_id: this.subscribeInfo.user_id,
              unrecommendation_id: this.subscribeInfo.doctor_id
          })
      } else if (this.subscribeInfo.subscribeType == 'deleteFavorite') {
          await this.$store.dispatch('deleteFavorite', {
              favorite_id: this.subscribeInfo.favorite_id,
              token: localStorage.userToken
          })
          await this.$store.dispatch('getUserFavorites', localStorage.userToken);
      }
      await this.$store.dispatch('getUserByToken')
      this.$store.dispatch('changeSubscribPopup', false)
    }
  }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 483px;
  height: 230px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 10px;
}
.icon {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}
.buttons {
  text-align: center;
  margin-top: 27px;
}
.text {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #24ad6f;
  cursor: pointer;
  margin-left: 31px;
}

@media screen and (max-width: 792px) {
    .form{
        width: 100%;
    }

}
</style>

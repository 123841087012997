<template>
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.33333 2.11127H3.22222C2.63285 2.11127 2.06762 2.34539 1.65087 2.76214C1.23413 3.17889 1 3.74412 1 4.33349V8.22238C1 9.84315 1.64385 11.3975 2.7899 12.5436C3.93596 13.6896 5.49034 14.3335 7.11111 14.3335C8.73188 14.3335 10.2863 13.6896 11.4323 12.5436C12.5784 11.3975 13.2222 9.84315 13.2222 8.22238V4.33349C13.2222 3.74412 12.9881 3.17889 12.5713 2.76214C12.1546 2.34539 11.5894 2.11127 11 2.11127H9.88889"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.55554 14.3333C6.55554 15.2088 6.72798 16.0757 7.06301 16.8846C7.39804 17.6934 7.88911 18.4283 8.50816 19.0474C9.12722 19.6664 9.86215 20.1575 10.671 20.4925C11.4798 20.8276 12.3467 21 13.2222 21C14.0977 21 14.9646 20.8276 15.7734 20.4925C16.5823 20.1575 17.3172 19.6664 17.9363 19.0474C18.5553 18.4283 19.0464 17.6934 19.3814 16.8846C19.7164 16.0757 19.8889 15.2088 19.8889 14.3333V11"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.88882 1V3.22222"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.33328 1V3.22222"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.8889 11C21.1162 11 22.1112 10.0051 22.1112 8.77776C22.1112 7.55046 21.1162 6.55554 19.8889 6.55554C18.6616 6.55554 17.6667 7.55046 17.6667 8.77776C17.6667 10.0051 18.6616 11 19.8889 11Z"
      stroke="#02B061"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'FaridIcon'
}
</script>

<style scoped></style>

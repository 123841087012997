<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 12.6C8.48521 12.6 9.90959 12.01 10.9598 10.9598C12.01 9.90959 12.6 8.48521 12.6 7C12.6 5.51479 12.01 4.09041 10.9598 3.0402C9.90959 1.99 8.48521 1.4 7 1.4C5.51479 1.4 4.09041 1.99 3.0402 3.0402C1.99 4.09041 1.4 5.51479 1.4 7C1.4 8.48521 1.99 9.90959 3.0402 10.9598C4.09041 12.01 5.51479 12.6 7 12.6ZM6.3 9.1H7.7V10.5H6.3V9.1ZM7.7 7.9485V8.4H6.3V7.35C6.3 7.16435 6.37375 6.9863 6.50503 6.85503C6.6363 6.72375 6.81435 6.65 7 6.65C7.19885 6.64999 7.39362 6.59351 7.56163 6.48713C7.72964 6.38075 7.86399 6.22886 7.94904 6.04911C8.03409 5.86936 8.06636 5.66916 8.04208 5.47179C8.0178 5.27442 7.93797 5.08801 7.81188 4.93424C7.6858 4.78047 7.51864 4.66566 7.32985 4.60318C7.14107 4.5407 6.93843 4.53311 6.7455 4.58129C6.55257 4.62948 6.37729 4.73146 6.24006 4.87537C6.10282 5.01928 6.00928 5.1992 5.9703 5.3942L4.5969 5.1191C4.68204 4.69356 4.87866 4.29826 5.16664 3.97361C5.45463 3.64896 5.82366 3.40661 6.23601 3.27132C6.64836 3.13604 7.08924 3.11268 7.51358 3.20363C7.93791 3.29458 8.33049 3.49658 8.65117 3.78898C8.97186 4.08138 9.20914 4.45368 9.33878 4.86784C9.46841 5.282 9.48575 5.72316 9.389 6.14622C9.29226 6.56927 9.08492 6.95905 8.78816 7.2757C8.49141 7.59236 8.11589 7.82454 7.7 7.9485Z"
            :fill="color"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: false,
            default: '#C0C4CD'
        }
    },

    name: 'QuestionIcon'
}
</script>

<style scoped></style>

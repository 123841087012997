<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 13H4.15077L12.4215 4.77998C12.8393 4.36472 13.0741 3.80152 13.0741 3.21426C13.0741 2.627 12.8393 2.0638 12.4215 1.64854C12.0037 1.23329 11.437 1 10.8461 1C10.2553 1 9.68858 1.23329 9.27076 1.64854L1 9.86856V13Z" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.48242 2.43164L11.6332 5.56308" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "DoctorEditMobileIcon"
}
</script>

<style scoped>

</style>

<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.49895 7.12316V14.1211C4.49895 14.353 4.40679 14.5755 4.24274 14.7396C4.0787 14.9036 3.8562 14.9958 3.62421 14.9958H1.87474C1.64274 14.9958 1.42025 14.9036 1.2562 14.7396C1.09216 14.5755 1 14.353 1 14.1211V7.99789C1 7.7659 1.09216 7.54341 1.2562 7.37936C1.42025 7.21532 1.64274 7.12316 1.87474 7.12316H4.49895ZM4.49895 7.12316C5.42693 7.12316 6.3169 6.75452 6.97308 6.09834C7.62926 5.44216 7.99789 4.55219 7.99789 3.62421V2.74947C7.99789 2.28548 8.18221 1.8405 8.5103 1.51241C8.83839 1.18432 9.28338 1 9.74737 1C10.2114 1 10.6563 1.18432 10.9844 1.51241C11.3125 1.8405 11.4968 2.28548 11.4968 2.74947V7.12316H14.1211C14.585 7.12316 15.03 7.30748 15.3581 7.63557C15.6862 7.96366 15.8705 8.40864 15.8705 8.87263L14.9958 13.2463C14.87 13.7829 14.6313 14.2437 14.3158 14.5593C14.0003 14.8748 13.6249 15.028 13.2463 14.9958H7.12316C6.42717 14.9958 5.75969 14.7193 5.26756 14.2272C4.77543 13.735 4.49895 13.0676 4.49895 12.3716" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMobileThumb"
}
</script>

<style scoped>

</style>

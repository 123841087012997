<template>
    <div class="search">
        <magnify-icon class="search-icon"/>
        <input
            v-model="search"
            type="text"
            class="input-search mobile-btn mobile-center"
            placeholder="Специальность или ФИО"
            @click="openSpecialistMenu"
        />
        <caret-down-icon class="down-icon" :class="{drop}"/>
        <div class="drop-menu" v-if="drop">
            <div class="drop-scroll">
                <div
                    class="drop-item"
                    v-for="spec in specialities"
                    :key="spec.id"
                    @click="dropSpec(spec)"
                >
                    {{ spec.name }} <span class="doc-count">{{ spec.doctors.length }}</span>
                </div>
            </div>
        </div>
        <default-button @click="searchFunc">Поиск</default-button>
    </div>
</template>

<script>
import selectSpecialist from '@/mixins/selectSpecialist'
import DefaultButton from "@/components/helppers/DefaultButton";

export default {
    name: 'SearchInput',
    components: {DefaultButton},
    props: {
        specialities: Array
    },
    data() {
        return {
            step: 1,
            search: '',
            login: false,
            drop: false
        }
    },
    mixins: [selectSpecialist],
    methods: {
        openSpecialistMenu() {
            if (!this.drop) {
                setTimeout(() => {
                    document.addEventListener('click', this.closeSpecialistMenu)
                })
            } else {
                setTimeout(() => {
                    document.removeEventListener('click', this.closeSpecialistMenu)
                }, 100)
            }
            this.drop = !this.drop
        },
        closeSpecialistMenu(e) {
            if (!e.target.closest('.drop-menu') && this.drop) {
                this.drop = !this.drop
                setTimeout(() => {
                    document.removeEventListener('click', this.closeSpecialistMenu)
                }, 100)
            }
        },
        dropSpec(spec) {
            this.selectSpecialist(spec)
            setTimeout(() => {
                document.removeEventListener('click', this.closeSpecialistMenu)
            }, 100)
            this.drop = false
        },
        async searchFunc() {
            if (this.specName == '') {
                await this.$store.dispatch('getAllDoctors', {page: 1, name: this.search})
                await this.$store.dispatch('getPageCount', {name: this.search})
                this.$router.push(`/category?name=${this.search}`)
            } else {
                await this.$store.dispatch('getAllDoctors', {page: 1, spec: this.special?this.special:1})
                await this.$store.dispatch('getPageCount', {spec_id: this.special?this.special:1})
                this.$router.push(`/category?spec=${this.special?this.special:1}&name=${this.search}`)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.search {
    position: relative;
    margin-top: 20px;

    @media screen and (max-width: 1330px) {
        width: 287px;
        padding: 0;
        display: flex;
        gap: 12px;
    }

    .search-icon {
        position: absolute;
        top: 29px;
        left: 20px;
        @media screen and (max-width: 1330px) {
            width: 16px;
            height: 16px;
            stroke: #c0c4cd;
            top: 10px;
            left: 12px;
        }
    }

    .down-icon.drop{
        transform: rotateX(180deg);
    }
    .down-icon {
        position: absolute;
        top: 36px;
        left: 395px;
        transition: 0.2s;
        @media screen and (max-width: 1330px) {
            left: 193px;
            top: 16px;
            width: 5px;
            height: 3px;
        }
    }

    .default-button {
        width: 97px;
        height: 56px;

        @media screen and (max-width: 1330px) {
            width: 65px;
            height: 36px;
            font-size: 12px;
            line-height: 14px;
            padding: 0;
        }
    }

    .input-search {
        text-size-adjust: 100%;
        background: #ffffff;
        box-shadow: 0 4px 50px rgba(210, 212, 221, 0.25);
        border-radius: 50px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
        color: #7f818a;
        height: 56px;
        width: 422px;
        border: none;
        min-width: 350px;
        padding: 10px 53px;
        margin: 10px 10px 0 0;

        &:focus-visible {
            outline: none;
        }

        @media screen and (max-width: 1330px) {
            width: 225px;
            padding: 11px 35px 11px 37px;
            min-width: unset;
            margin: 0;
            height: 36px;
            font-size: 12px;
            line-height: 14px;
        }
    }

    .drop-menu {
        background: #ffffff;
        box-shadow: 0 4px 40px rgba(138, 138, 138, 0.45);
        border-radius: 20px;
        z-index: 999;
        position: absolute;
        width: 422px;
        height: 351px;
        overflow-y: hidden;
        top: 101px;
        left: 0;
        padding: 14px 10px 17px 4px;
        @media screen and (max-width: 1330px) {
            width: 245px;
            height: 351px;
            top: 71px;
            left: 0;
        }

        .drop-scroll {
            overflow: auto !important;
            width: 100%;
            height: 100%;
            margin: auto;

            .drop-item {
                height: 37px;
                width: 100%;
                cursor: pointer;
                z-index: 9999;
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 40px;
                color: #373a43;
                padding-left: 23px;
                margin: auto;

                &:hover {
                    background: #edeff3;
                    border-radius: 5px;
                }

            }
        }

        .drop-scroll::-webkit-scrollbar {
            width: 5px;
            border-radius: 50px;
        }

        .drop-scroll::-webkit-scrollbar-track {
            background: rgba(203, 208, 217, 0.5);
            border-radius: 50px;
        }

        .drop-scroll::-webkit-scrollbar-thumb {
            background-color: #d6d9e0;
            border-radius: 50px;
            border: 5px solid #d6d9e0;
        }

    }
}

</style>

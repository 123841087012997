<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.69722 2.02433C7.02856 0.658556 8.97144 0.658556 9.30278 2.02433C9.35249 2.22951 9.44995 2.42005 9.58722 2.58044C9.72449 2.74084 9.89769 2.86655 10.0927 2.94736C10.2878 3.02816 10.4991 3.06177 10.7096 3.04545C10.9201 3.02913 11.1237 2.96335 11.304 2.85344C12.5041 2.12233 13.8784 3.49589 13.1473 4.69678C13.0376 4.87695 12.9719 5.08048 12.9556 5.29082C12.9393 5.50116 12.9729 5.71238 13.0536 5.9073C13.1343 6.10221 13.2599 6.27534 13.4201 6.4126C13.5803 6.54985 13.7707 6.64737 13.9757 6.69722C15.3414 7.02856 15.3414 8.97144 13.9757 9.30278C13.7705 9.35249 13.5799 9.44995 13.4196 9.58722C13.2592 9.72449 13.1334 9.89769 13.0526 10.0927C12.9718 10.2878 12.9382 10.4991 12.9545 10.7096C12.9709 10.9201 13.0367 11.1237 13.1466 11.304C13.8777 12.5041 12.5041 13.8784 11.3032 13.1473C11.123 13.0376 10.9195 12.9719 10.7092 12.9556C10.4988 12.9393 10.2876 12.9729 10.0927 13.0536C9.89779 13.1343 9.72466 13.2599 9.5874 13.4201C9.45015 13.5803 9.35263 13.7707 9.30278 13.9757C8.97144 15.3414 7.02856 15.3414 6.69722 13.9757C6.64751 13.7705 6.55005 13.5799 6.41278 13.4196C6.27551 13.2592 6.10231 13.1334 5.90727 13.0526C5.71223 12.9718 5.50087 12.9382 5.29039 12.9545C5.07991 12.9709 4.87625 13.0367 4.696 13.1466C3.49589 13.8777 2.12156 12.5041 2.85267 11.3032C2.96241 11.123 3.02809 10.9195 3.04438 10.7092C3.06066 10.4988 3.02708 10.2876 2.94637 10.0927C2.86566 9.89779 2.74009 9.72466 2.57988 9.5874C2.41967 9.45015 2.22933 9.35263 2.02433 9.30278C0.658556 8.97144 0.658556 7.02856 2.02433 6.69722C2.22951 6.64751 2.42005 6.55005 2.58044 6.41278C2.74084 6.27551 2.86655 6.10231 2.94736 5.90727C3.02816 5.71223 3.06177 5.50087 3.04545 5.29039C3.02913 5.07991 2.96335 4.87625 2.85344 4.696C2.12233 3.49589 3.49589 2.12156 4.69678 2.85267C5.47456 3.32556 6.48256 2.90711 6.69722 2.02433Z" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.99935 10.3337C9.28801 10.3337 10.3327 9.28899 10.3327 8.00033C10.3327 6.71166 9.28801 5.66699 7.99935 5.66699C6.71068 5.66699 5.66602 6.71166 5.66602 8.00033C5.66602 9.28899 6.71068 10.3337 7.99935 10.3337Z" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMobileSetiing"
}
</script>

<style scoped>

</style>

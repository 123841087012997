<template>
  <svg width="3" height="34" viewBox="0 0 3 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0C1.65685 0 3 1.34315 3 3L3 31C3 32.6569 1.65685 34 0 34L0 0Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "AdminActiveIcon"
}
</script>

<style scoped>

</style>

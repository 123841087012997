<template>
    <div class="drop-down" v-if="items">
        <div class="drop-down-scroll">
            <div class="drop-down-item" @click="dropChosen(item)" v-for="(item, index) in items" :key="index">
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'DropDown',

  props: {
    items: {
        required: true
    }
  },

  methods: {
      closeDrop() {
          this.$emit('closeDrop')
      },
      dropChosen(item) {
          this.$emit('dropChosen', item)
      }
  }

}
</script>

<style scoped>

.drop-down {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    z-index: 999;
    position: absolute;
    width: 330px;
    height: 351px;
    overflow: hidden;
    top: 81px;
    left: 0;
    padding: 14px 10px 17px 6px;
}

.drop-down-scroll {
    overflow: auto !important;
    width: 315px;
    height: 320px;
    margin: auto;
}

.drop-down-item {
    height: 37px;
    width: 310px;
    line-height: 37px;
    margin: auto;
    cursor: pointer;
    z-index: 9999;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 40px;
    color: #373A43;
    padding-left: 23px;
    margin: auto;

}

</style>

<template>
    <user-layout>

        <top-dashboard-route :role="1" v-if="user?.role_id == 1 || user?.role_id == 2"/>
        <top-dashboard-route :role="0" v-else/>

        <!--  <admin-dashboard v-if="role && role== 2"></admin-dashboard>-->
        <super-admin-dashboard v-if="role && role == 1"/>

        <div v-else-if="role && role == 3 || role == 4" class="dashboard-page-container">
            <user-dashboard-left-menu v-if="!mobile" class="dashboard-page-menu"/>

            <doctor-dashboard class="dashboard-page-content" v-if="role == 3"/>
            <user-dashboard class="dashboard-page-content" v-else/>
        </div>

    </user-layout>
</template>

<script>
// import AdminDashboard from "@/components/admin/AdminDashboard.vue";
import SuperAdminDashboard from '@/components/admin/SuperAdminDashboard.vue'
import DoctorDashboard from '@/components/doctor/DoctorDashboard.vue'
import UserDashboard from '@/components/user/UserDashboard.vue'
import UserLayout from "@/components/layout/UserLayout.vue";
import TopDashboardRoute from "@/components/layout/TopDashboardRoute.vue";
import UserDashboardLeftMenu from "@/components/layout/UserDashboardLeftMenu.vue";

export default {
    name: 'dashboard-page',
    components: {
        UserDashboardLeftMenu,
        TopDashboardRoute,
        UserLayout,
        // AdminDashboard,
        SuperAdminDashboard,
        DoctorDashboard,
        UserDashboard
    },
    data() {
        return {
            role: null
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser
        },
        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        },
        doctorDashboardContent() {
            return this.$store.getters.getDoctorDashboardContent
        },
    },

    methods: {
        async saveDoctorDashboardContent() {
            await this.$store.dispatch('updateDoctorDashboardContent', this.doctorDashboardContent)
        }
    },

    async mounted() {
        let token = localStorage.userToken
        if (!token) {
            this.$router.push({name: 'home'})
        }
        await this.$store.dispatch('getUserByToken')
        this.role = this.user.role_id
    }
}
</script>

<style scoped lang="scss">

.dashboard-page-container {
    display: flex;
    column-gap: 25px;
    width: 100%;
}

.dashboard-page-menu {
    flex-basis: calc(15%);
    max-width: calc(15%);
}

.dashboard-page-content {
    flex-basis: calc(85% - 20px);
    max-width: calc(85% - 20px);
}

@media screen and (max-width: 1450px) {
    .dashboard-page-menu {
        flex-basis: calc(18%);
        max-width: calc(18%);
    }

    .dashboard-page-content {
        flex-basis: calc(82% - 20px);
        max-width: calc(82% - 20px);
    }
}

@media screen and (max-width: 969px) {
    .dashboard-page-menu {
        flex-basis: calc(22%);
        max-width: calc(22%);
    }

    .dashboard-page-content {
        flex-basis: calc(78% - 20px);
        max-width: calc(78% - 20px);
    }
}

@media screen and (max-width: 769px) {
    .dashboard-page-content {
        flex-basis: calc(100%);
        max-width: calc(100%);
    }
}

</style>

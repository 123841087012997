<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
            icon="close"
            @click="this.$store.dispatch('changeCreateSub', false)"
            color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Образовать связь</div>
      <div class="inputes">
        <input type="text" class="input" :value="doctor.full_name" disabled="true">
        <circle-check-icon class="check-icon"/>
        <img :src="`/img/doctors/${doctor.user_detales.image}`" class="img" alt="doctor">
      </div>
      <div class="text">
        Рекомендует
      </div>
      <div class="inputes" style="margin-top: 18px">
        <input type="text" @click="show=true" class="input" placeholder="ФИО" v-model="search">
        <circle-check-icon v-if="name" class="check-icon"/>
        <img :src="`/img/doctors/${checkDoctor.user_detales.image}`" v-if="this.checkDoctor?.user_detales.image" class="img" alt="">
        <div class="users" v-if="show && doctors.length">
          <div class="doctor-item" @click="checkRecommendation(doctor)" v-for="doctor in doctors"
               :key="doctor.id">
            <img :src="`/img/doctors/${doctor.user_detales.image}`" class="img-2" alt="">
            {{ doctor.full_name }}
          </div>
        </div>
      </div>
      <div class="buttons">
        <button-helper color="#ffffff" @click="saveRecommendation" background="#02B061">
          Сохранить
        </button-helper>
        <span class="text-cencel" @click="checked?checked=false:checked=true">
          <checkbox-on-icon v-if="checked" style="margin-right: 10px"/>
          <checkbox-icon v-else style="margin-right: 10px"/>
          Двустороняя связь
          <info-smal-icon style="margin-left:5px"/> </span>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import CircleCheckIcon from "@/components/icons/CircleCheckIcon.vue";
import InfoSmalIcon from "@/components/icons/InfoSmalIcon.vue";
import CheckboxIcon from "@/components/icons/CheckboxIcon.vue";
import CheckboxOnIcon from "@/components/icons/CheckboxOnIcon.vue";

export default {
  name:       "CreateSubScribePopup",
  components: {
    CheckboxOnIcon,
    CheckboxIcon,
    InfoSmalIcon,
    CircleCheckIcon,
    ButtonHelper
  },
  data() {
    return {
      show: false,
      checkDoctor: null,
      search: '',
      doctors: [],
      checked: false
    }
  },
  computed: {
    doctor() {
      return this.$store.getters.getDoctorDetalies
    },
  },
  methods:  {
    checkRecommendation(doctor){
      this.show = false;
      this.checkDoctor = doctor;
      this.search = doctor.full_name
    },
    async saveRecommendation(){
      let params = {
          doctor_id: this.doctor.id,
          recommendation_id: this.checkDoctor.id,
          two_connect: this.checked
        }
      await this.$store.dispatch('addRecommendationDoctor', params);
      await this.$store.dispatch('getDoctorDetalies', this.doctor.id);
      this.$store.dispatch('closePopUp')
    }
  },
  watch: {
    search() {
      this.$axios.get(`/api/get-doctors-filter?name=${this.search}`).then((response) => {
        this.doctors = response.data
      })
    }
  },
  async mounted() {

  }


}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 505px;
  height: 438px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 53px;
}
.inputes {
  margin-top: 38px;
  text-align: center;
  position: relative;
}
.check-icon {
  position: absolute;
  top: 15px;
  right: 75px;
}
.input {
  width: 385px;
  height: 46px;
  background: #EAF2EF;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373A43;
  border: none;
  padding-left: 51px;
}
.buttons {
  width: 385px;
  margin: 48px auto;
  text-align: left;
}

.img {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 8px;
  left: 70px;
}

.img-2 {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #373A43;
  text-align: center;
  margin-top: 39px;
}

.users {
  position: absolute;
  overflow: auto;
  width: 385px;
  height: 300px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  left: 55px;
  top: 60px;
  text-align: center;
}
.users::-webkit-scrollbar {
  width: 5px;
}

.users::-webkit-scrollbar-track {
  background: #CBD0D9;
}
.users::-webkit-scrollbar-thumb {
  background-color: #D6D9E0;
  border-radius: 20px;
  border: 5px solid #D6D9E0;
}
.doctor-item {
  width: 350px;
  height: 40px;
  border: 1px solid #EAF2EF;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #373A43;
  text-align: center;
  margin: 10px auto;
  line-height: 40px;
  cursor: pointer;
}

.text-cencel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #373A43;
  text-align: center;
  float: right;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (max-width: 792px) {
    .form {
        width: 100%;
    }
    .input{
        width: 90%;
    }
    .img{
        left:7%
    }
    .buttons{
        width: 90%;
    }
    .users {
        left: 5%;
        width: 90%;

    }

}
</style>

import LocationIcon from '@/components/icons/LocationIcon.vue'
import OkIcon from '@/components/icons/OkIcon.vue'
import TelegramIcon from '@/components/icons/TelegramIcon.vue'
import VkIcon from '@/components/icons/VkIcon.vue'
import YoutubeIcon from '@/components/icons/YoutubeIcon.vue'
import HeartIcon from '@/components/icons/HeartIcon.vue'
import NotificationIcon from '@/components/icons/NotificationIcon.vue'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import CircleCheckIcon from '@/components/icons/CircleCheckIcon.vue'
import SharedIcon from '@/components/icons/SharedIcon.vue'
import PeopleIcon from '@/components/icons/PeopleIcon.vue'
import HumanIcon from "@/components/icons/HumanIcon.vue";
import ArrowsIcon from '@/components/icons/ArrowsIcon.vue'
import FolderIcon from '@/components/icons/FolderIcon.vue'
import CaretDownIcon from '@/components/icons/CaretDownIcon.vue'
import MagnifyIcon from '@/components/icons/MagnifyIcon.vue'
import GoogleIcon from '@/components/icons/GoogleIcon.vue'
import YandexIcon from '@/components/icons/YandexIcon.vue'
import VkCircleIcon from '@/components/icons/VkCircleIcon.vue'
import TileIcon from '@/components/icons/TileIcon.vue'
import ListIcon from '@/components/icons/ListIcon.vue'
import HeaderSearchIcon from '@/components/icons/HeaderSearchIcon.vue'
import HeaderArrowDown from '@/components/icons/HeaderArrowDown.vue'
import SwitchIcon from '@/components/icons/SwitchIcon.vue'
import SearchListArrowDown from '@/components/icons/SearchListArrowDown.vue'
import RecomendMeIcon from '@/components/icons/RecomendMeIcon.vue'
import RecomendIcon from '@/components/icons/RecomendIcon.vue'
import SMSIcon from '@/components/icons/SMSIcon.vue'
import FaridIcon from '@/components/icons/FaridIcon.vue'
import MapIcon from '@/components/icons/MapIcon.vue'
import ShildIcon from '@/components/icons/ShildIcon.vue'
import AnswerIcon from '@/components/icons/AnswerIcon.vue'
import ArrowRight from '@/components/icons/ArrowRight.vue'
import ArrowLeft from '@/components/icons/ArrowLeft.vue'
import ListIconGreen from '@/components/icons/ListIconGreen.vue'
import TileIconGrey from '@/components/icons/TileIconGrey.vue'
import ShieldDoctorPageIcon from '@/components/icons/ShieldDoctorPageIcon.vue'
import WhatsappDoctorPageIcon from '@/components/icons/WhatsappDoctorPageIcon.vue'
import TelegramDoctorPageIcon from '@/components/icons/TelegramDoctorPageIcon.vue'
import InstagramDoctorPageIcon from '@/components/icons/InstagramDoctorPageIcon.vue'
import ThambDownDocIcon from '@/components/icons/ThambDownDocIcon.vue'
import ThambUpDocIcon from '@/components/icons/ThambUpDocIcon.vue'
import CircleInfoDocIcon from '@/components/icons/CircleInfoDocIcon.vue'
import LikeIcon from '@/components/icons/LikeIcon.vue'
import DislikeIcon from '@/components/icons/DislikeIcon.vue'
import RotatedArrowIcon from '@/components/icons/RotatedArrowIcon.vue'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import TickIcon from '@/components/icons/TickIcon'
import InfoIcon from '@/components/icons/InfoIcon'
import EyeIcon from '@/components/icons/EyeIcon'
import EllipseIcon from '@/components/icons/EllipseIcon'
import CloseIcon from '@/components/icons/CloseIcon'
import SwitchOnIcon from '@/components/icons/SwitchOnIcon'
import ArrowUp from '@/components/icons/ArrowUp.vue'
import CheckButtonDocs from '@/components/icons/CheckButtonDocs.vue'
import GreenInfoIcon from '@/components/icons/GreenInfoIcon.vue'
import GreenTrashIcon from '@/components/icons/GreenTrashIcon.vue'
import TelegramOutlineIcon from '@/components/icons/TelegramOutlineIcon'
import AdminMobilePepolr from "@/components/icons/AdminMobilePepolr.vue"
import AdminMobileSetiing from "@/components/icons/AdminMobileSetiing.vue"
import AdminMobileStat from "@/components/icons/AdminMobileStat.vue"
import AdminMobileStory from "@/components/icons/AdminMobileStory.vue"
import AdminMobileThumb from "@/components/icons/AdminMobileThumb.vue"
import AdminMobileVerified from "@/components/icons/AdminMobileVerified.vue"
import AdminMobileBell from "@/components/icons/AdminMobileBell.vue"
import AdminMenuVerifiedActiv from "@/components/icons/AdminMenuVerifiedActiv.vue"
import AdminMenuThumbActiv from "@/components/icons/AdminMenuThumbActiv.vue"
import AdminMenuStoryActive from "@/components/icons/AdminMenuStoryActive.vue"
import AdminMenuSettingActive from "@/components/icons/AdminMenuSettingActive.vue"
import AdminMenuSetting from "@/components/icons/AdminMenuSetting.vue"
import AdminMenuThambUp from "@/components/icons/AdminMenuThambUp.vue"
import AdminMenuPepole from "@/components/icons/AdminMenuPepole.vue"
import AdminMenuPepoleActive from "@/components/icons/AdminMenuPepoleActive.vue"
import AdminMenuBell from "@/components/icons/AdminMenuBell.vue"
import AdminMenuStatistic from "@/components/icons/AdminMenuStatistic.vue"
import AdminMenuBellActiv from "@/components/icons/AdminMenuBellActiv.vue"
import OpenEyeIcon from "@/components/icons/OpenEyeIcon";
import HistoryIcon from "@/components/icons/AdminActiveIcon.vue";
import StatIcon from "@/components/icons/StatIcon.vue";
import CatalogIcon from "@/components/icons/CatalogIcon.vue";
import StoryIcon from "@/components/icons/StoryIcon.vue";
import DoctorBlockMobileIcon from "@/components/icons/DoctorBlockMobileIcon.vue";
import DoctorEditMobileIcon from "@/components/icons/DoctorEditMobileIcon.vue";
import DoctorTrashMobileIcon from "@/components/icons/DoctorTrashMobileIcon.vue";
import CreateSubIcon from "@/components/icons/CreateSubIcon.vue";
import AdminDoctorMobileThumb from "@/components/icons/AdminDoctorMobileThumb.vue";
import CreateSubIconDoc from "@/components/icons/CreateSubIconDoc.vue";
import InfoSmalIcon from "@/components/icons/InfoSmalIcon.vue";
import CheckboxIcon from "@/components/icons/CheckboxIcon.vue";
import CheckboxOnIcon from "@/components/icons/CheckboxOnIcon.vue";
import HelloIcon from "@/components/icons/HelloIcon.vue";
import AdminBlockIconMobile from "@/components/icons/AdminBlockIconMobile.vue";
import WhatsappIcon from "@/components/icons/WhatsappIcon.vue";
import ChangeIcon from "@/components/icons/ChangeIcon.vue";

export default [
    WhatsappIcon,
  LocationIcon,
  ChangeIcon,
  OkIcon,
  TelegramIcon,
  VkIcon,
  YoutubeIcon,
  HeartIcon,
  NotificationIcon,
  SettingsIcon,
  CircleCheckIcon,
  SharedIcon,
  PeopleIcon,
  ArrowsIcon,
  FolderIcon,
  CaretDownIcon,
  MagnifyIcon,
  GoogleIcon,
  YandexIcon,
  VkCircleIcon,
  TileIcon,
  ListIcon,
  HeaderSearchIcon,
  HeaderArrowDown,
  SwitchIcon,
  SearchListArrowDown,
  RecomendMeIcon,
  RecomendIcon,
  SMSIcon,
  FaridIcon,
  MapIcon,
  ShildIcon,
  AnswerIcon,
  ArrowRight,
  ArrowLeft,
  ListIconGreen,
  TileIconGrey,
  ShieldDoctorPageIcon,
  WhatsappDoctorPageIcon,
  TelegramDoctorPageIcon,
  InstagramDoctorPageIcon,
  ThambDownDocIcon,
  ThambUpDocIcon,
  CircleInfoDocIcon,
  DeleteIcon,
  RotatedArrowIcon,
  LikeIcon,
  DislikeIcon,
  TickIcon,
  InfoIcon,
  EyeIcon,
  EllipseIcon,
  CloseIcon,
  SwitchOnIcon,
  ArrowUp,
  CheckButtonDocs,
  GreenInfoIcon,
  GreenTrashIcon,
  TelegramOutlineIcon,
  AdminMobilePepolr,
  AdminMobileSetiing,
  AdminMobileStat,
  AdminMobileStory,
  AdminMobileThumb,
  AdminMobileVerified,
  AdminMobileBell,
  AdminMenuVerifiedActiv,
  AdminMenuThumbActiv,
  AdminMenuStoryActive,
  AdminMenuSettingActive,
  AdminMenuSetting,
  AdminMenuThambUp,
  AdminMenuPepole,
  AdminMenuPepoleActive,
  AdminMenuBell,
  AdminMenuStatistic,
  AdminMenuBellActiv,
  HumanIcon,
  HistoryIcon,
  OpenEyeIcon,
  StatIcon,
  CatalogIcon,
  StoryIcon,
  DoctorBlockMobileIcon,
  DoctorEditMobileIcon,
  DoctorTrashMobileIcon,
  CreateSubIcon,
  AdminDoctorMobileThumb,
  CreateSubIconDoc,
  InfoSmalIcon,
  CheckboxIcon,
  CheckboxOnIcon,
  HelloIcon,
  AdminBlockIconMobile
]

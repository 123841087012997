<template>
    <prompt-popup-layout @close-modal="closeModal">
        <div class="prompt-text">
            {{text}}
        </div>
    </prompt-popup-layout>
</template>

<script>
import PromptPopupLayout from "@/components/layout/PromptPopupLayout.vue";

export default {
  name: 'PromptTextPopup',
    components: {PromptPopupLayout},

    emits: ['closePrompt'],

    props: {
        text: {
            type: String,
            required: true
        }
    },

    computed: {
        promptText() {
            return this.$store.getters.promptText
        }
    },

    methods: {
        closeModal() {
            this.$emit('closePrompt')
        }
    },

}
</script>

<style scoped lang="scss">

.prompt-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    padding-right: 70px;
    line-height: 18px;
    color: #FFFFFF;
}

</style>

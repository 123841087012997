<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.24902 6.68579V13.1838C4.24902 13.3993 4.16344 13.6059 4.01112 13.7582C3.85879 13.9105 3.65219 13.9961 3.43677 13.9961H1.81226C1.59683 13.9961 1.39023 13.9105 1.2379 13.7582C1.08558 13.6059 1 13.3993 1 13.1838V7.49804C1 7.28262 1.08558 7.07602 1.2379 6.92369C1.39023 6.77137 1.59683 6.68579 1.81226 6.68579H4.24902ZM4.24902 6.68579C5.11072 6.68579 5.93712 6.34348 6.54643 5.73417C7.15574 5.12486 7.49804 4.29846 7.49804 3.43677V2.62451C7.49804 2.19366 7.6692 1.78046 7.97385 1.47581C8.27851 1.17115 8.69171 1 9.12255 1C9.5534 1 9.9666 1.17115 10.2713 1.47581C10.5759 1.78046 10.7471 2.19366 10.7471 2.62451V6.68579H13.1838C13.6147 6.68579 14.0279 6.85694 14.3325 7.1616C14.6372 7.46625 14.8083 7.87945 14.8083 8.3103L13.9961 12.3716C13.8793 12.8699 13.6577 13.2977 13.3647 13.5907C13.0717 13.8837 12.7232 14.026 12.3716 13.9961H6.68579C6.03952 13.9961 5.41972 13.7394 4.96273 13.2824C4.50575 12.8254 4.24902 12.2056 4.24902 11.5593" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminDoctorMobileThumb"
}
</script>

<style scoped>

</style>

<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('changeSpecial', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Специальность</div>
      <div class="inputs">
        <div class="icon-div" @click="openSpecialistMenu">
          <input-layout
            :is-disable="true"
            placeholder="Специальность"
            :input-value="special.name"
            :complete="special.name"
            :disabled="true"
          />
          <div class="drop-menu" v-if="drop">
            <div class="drop-scroll">
              <div
                class="drop-item"
                v-for="spec in specialities"
                :key="spec.id"
                @click.stop="dropSpec(spec)"
              >
                {{ spec.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button">
        <button-helper class="save-button" color="#ffffff" background="#02B061" @click="submit">
          Сохранить
        </button-helper>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue';
import InputLayout from '../layout/inputs/InputLayout.vue';

export default {
  name: 'SpecialPopup',
  components: {
    ButtonHelper,
    InputLayout
  },
  data() {
    return {
      special: {},
      drop: false
    }
  },
  methods: {
    openSpecialistMenu() {
      if (!this.drop) {
        setTimeout(() => {
          document.addEventListener('click', this.closeMenu)
        })
      } else {
        setTimeout(() => {
          document.removeEventListener('click', this.closeMenu)
        }, 100)
      }
      this.drop = !this.drop
    },
    dropSpec(spec) {
      this.special = spec
      setTimeout(() => {
        document.removeEventListener('click', this.closeMenu);
      }, 100)
      this.drop = false;
    },
    async submit() {
        if (this.loginUser.id == this.doctor.id) {
          await this.$store.dispatch('addSpecial', this.special);
        }else{
          let params = {
              doctor_id: this.doctor.id,
              special_id: this.special.id
          };
          await this.$store.dispatch('addSpecialDoctor', params);
          await this.$store.dispatch('getDoctorDetalies', this.doctor.id);
        }
        await this.$store.dispatch('getSpecialities');
        await this.$store.dispatch('changeSpecial', false);
    },
      closeMenu(e) {
          if (!e.target.closest('.drop-menu') && this.drop) {
              this.drop = !this.drop
              setTimeout(() => {
                  document.removeEventListener('click', this.closeMenu)
              }, 100)
          }
      },
  },

  computed: {
    specialities() {
      return this.$store.getters.getAllSpecialities;
    },
    loginUser(){
      return this.$store.getters.getUser
    },
    doctor(){
      return this.$store.getters.getDoctorDetalies
    }
  },
}
</script>

<style scoped lang="scss">

.drop-menu {
  background: #ffffff;
  box-shadow: 0 4px 40px rgba(138, 138, 138, 0.45);
  border-radius: 20px;
  z-index: 999;
  position: absolute;
  width: 335px;
  height: 351px;
  overflow-y: hidden;
  top: 55px;
  left: 0;
  padding: 14px 10px 17px 4px;
  @media screen and (max-width: 1330px) {
    width: 245px;
    height: 351px;
    top: 71px;
    left: 0;
  }

  .drop-scroll {
    overflow: auto !important;
    width: 100%;
    height: 100%;
    margin: auto;

    .drop-item {
      height: 37px;
      width: 100%;
      cursor: pointer;
      z-index: 9999;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 40px;
      color: #373a43;
      padding-left: 23px;
      margin: auto;

      &:hover {
        background: #edeff3;
        border-radius: 5px;
      }
    }
  }

  .drop-scroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }

  .drop-scroll::-webkit-scrollbar-track {
    background: rgba(203, 208, 217, 0.5);
    border-radius: 50px;
  }

  .drop-scroll::-webkit-scrollbar-thumb {
    background-color: #d6d9e0;
    border-radius: 50px;
    border: 5px solid #d6d9e0;
  }
}
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  width: 483px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}
.inputs {
  width: 330px;
  margin: 10px auto;
}

.input {
  width: 330px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}
.input-soc::-webkit-outer-spin-button,
.input-soc::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-soc[type='number'] {
  -moz-appearance: textfield;
}
.input-soc {
  width: 300px;
  height: 46px;
  margin-top: 20px;
  margin-left: 15px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}

.input:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.input-soc:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.icon-left {
  position: absolute;
  top: 35px;
  left: 30px;
}

.icon-right {
  position: absolute;
  top: 35px;
  right: 15px;
}

.icon-div {
  display: flex;
  position: relative;
}
.button {
  margin: 45px 0;
  text-align: center;
}

.save-button {
  padding: 17px 24px;
}

@media screen and (max-width: 425px) {
  .form {
    width: 80%;
  }

  .inputs {
    width: 80%;
  }

  .input-soc {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  .icon-div {
    justify-content: center;
    align-items: center;
  }
}
</style>

<template>
  <svg width="16" height="14" viewBox="0 0 16 14" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8158 0.330027C14.6174 0.164995 14.378 0.0568347 14.1231 0.0170579C13.8681 -0.0227189 13.6071 0.00737567 13.3679 0.104139L0.881827 5.14525C0.614804 5.25511 0.387506 5.44358 0.230103 5.68565C0.0726994 5.92771 -0.00736624 6.21192 0.000532976 6.50055C0.0084322 6.78918 0.103923 7.06859 0.27433 7.30168C0.444738 7.53477 0.682003 7.71053 0.954635 7.80562L3.48029 8.68402L4.88804 13.3393C4.90717 13.4015 4.93494 13.4607 4.97052 13.5152C4.97592 13.5236 4.98354 13.5298 4.98928 13.5379C5.03039 13.5953 5.08007 13.646 5.13659 13.6882C5.15266 13.7005 5.16805 13.712 5.18523 13.7228C5.25138 13.7667 5.32463 13.7988 5.40173 13.8177L5.40998 13.8184L5.41465 13.8204C5.46101 13.8298 5.50819 13.8346 5.5555 13.8347C5.56005 13.8347 5.56409 13.8325 5.5686 13.8324C5.63999 13.8312 5.71075 13.8189 5.77838 13.796C5.79411 13.7906 5.80759 13.7816 5.82286 13.7752C5.87328 13.7543 5.92106 13.7275 5.96519 13.6953C6.00053 13.6656 6.03588 13.6358 6.07125 13.606L7.95393 11.5274L10.762 13.7027C11.0092 13.8951 11.3135 13.9997 11.6267 14C11.955 13.9996 12.2732 13.8863 12.5279 13.6792C12.7826 13.4721 12.9583 13.1837 13.0257 12.8624L15.2989 1.70309C15.3504 1.45193 15.3327 1.19149 15.2476 0.94963C15.1625 0.707775 15.0132 0.493602 14.8158 0.330027ZM5.83177 8.90584C5.73516 9.00206 5.66912 9.12467 5.64193 9.25829L5.42629 10.3062L4.88001 8.49949L7.7124 7.02454L5.83177 8.90584ZM11.6159 12.6011L8.29755 10.0305C8.15872 9.92324 7.98456 9.87221 7.80979 9.88759C7.63502 9.90298 7.47246 9.98365 7.3545 10.1135L6.75155 10.779L6.96463 9.74339L11.8996 4.80843C12.0174 4.69079 12.0892 4.53486 12.1019 4.36887C12.1146 4.20289 12.0675 4.03783 11.9691 3.90359C11.8706 3.76935 11.7273 3.67482 11.5652 3.6371C11.403 3.59939 11.2327 3.621 11.0852 3.69801L4.00267 7.38556L1.40778 6.43597L13.934 1.42478L11.6159 12.6011Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TelegramOutlineIcon',
  props: {
    color: {
      default: '#C0C4CD'
    }
  }
}
</script>

<style scoped></style>

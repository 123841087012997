<template>
  <svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.48114 3.86971C3.48514 3.86491 3.48674 3.85851 3.49074 3.85371L6.28362 0.768022C6.43962 0.594417 6.43962 0.312809 6.28362 0.139204C6.28202 0.137604 6.28042 0.136804 6.27882 0.136004C6.24411 0.093967 6.20064 0.0600165 6.15145 0.0365252C6.10225 0.013034 6.04852 0.000568571 5.99401 0H0.407452C0.35194 0.000896997 0.297302 0.0139722 0.247398 0.0383022C0.197494 0.0626322 0.153541 0.0976236 0.118644 0.140804L0.117044 0.139204C0.0415455 0.226555 0 0.338156 0 0.453613C0 0.569069 0.0415455 0.680671 0.117044 0.768022L2.91632 3.86971C2.95117 3.91056 2.99447 3.94336 3.04322 3.96586C3.09198 3.98835 3.14504 4 3.19873 4C3.25243 4 3.30548 3.98835 3.35424 3.96586C3.40299 3.94336 3.44629 3.91056 3.48114 3.86971Z"
      fill="#A4ACBD"
    />
  </svg>
</template>

<script>
export default {
  name: 'CaretDownIcon'
}
</script>

<style scoped></style>

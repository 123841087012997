export default {
    state: {
        homeContent: {},
        doctorInviteContent: {},
        doctorDashboardContent: {},
        annotationHeader: '',
    },

    mutations: {
        setHomeContent(state,homeContent){
            state.homeContent = homeContent
        },
        setDoctorInviteContent(state,doctorInviteContent){
            state.doctorInviteContent = doctorInviteContent
        },
        setDoctorDashboardContent(state,doctorDashboardContent){
            state.doctorDashboardContent = doctorDashboardContent
        },

        setAnnotationHeader(state,annotationHeader){
            state.annotationHeader = annotationHeader
        },
    },
    getters: {
        getHomeContent(state){
            return state.homeContent
        },
        getDoctorInviteContent(state){
            return state.doctorInviteContent
        },
        getDoctorDashboardContent(state){
            return state.doctorDashboardContent
        },

        getAnnotationHeader(state){
            return state.annotationHeader
        },
    },

    actions: {
        async getHomeContent({commit}) {
            await this.$axios.get(`/api/content/get/home`).then(res => {
                commit('setHomeContent', res.data)
            })
        },
        async getDoctorInviteContent({commit}) {
            await this.$axios.get(`/api/content/get/doctor-invite`).then(res => {
                commit('setDoctorInviteContent', res.data)
            })
        },
        async getDoctorDashboardContent({commit}) {
            await this.$axios.get(`/api/content/get/doctor-dashboard`).then(res => {
                commit('setDoctorDashboardContent', res.data)
            })
        },

        async getAnnotationHeader({commit}) {
            await this.$axios.get(`/api/content/get/annotation-header`).then(res => {
                commit('setAnnotationHeader', res.data)
            })
        },

        async updateHomeContent({commit}, data) {
            await this.$axios.put(`/api/admin/content/update/home`, data).then((res) => {
                commit('setHomeContent', res.data)
            })
        },
        async updateDoctorInviteContent({commit}, data) {
            await this.$axios.put(`/api/admin/content/update/doctor-invite`, data).then((res) => {
                commit('setDoctorInviteContent', res.data)
            })
        },
        async updateDoctorDashboardContent({commit}, data) {
            await this.$axios.put(`/api/admin/content/update/doctor-dashboard`, data).then((res) => {
                commit('setDoctorDashboardContent', res.data)
            })
        },

        async changeDoctorDashboardIcon({commit}, file) {
            await this.$axios.post(`/api/admin/content/update/doctor-dashboard-icon`, file).then((res) => {
                commit('setDoctorDashboardContent', res.data)
            })
        },

        async changeHomeIcon({commit}, { file, id }) {
            await this.$axios.post(`/api/admin/content/update/home-${id}-icon`, file).then((res) => {
                commit('setHomeContent', res.data)
            })
        },
    }
}

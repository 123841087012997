<template>
    <div class="admin-settings">

        <div class="form-group">
            <label for="" class="label">E-mail</label>
            <input-layout
                placeholder="E-mail"
                :input-value="user.email"
                :complete="user.email"
                @update:input-value="user.email = $event"
            />
        </div>

<!--        <div class="form-group">-->
<!--            <label for="" class="label">Пароль</label>-->
<!--            <input-layout-password -->
<!--                placeholder="Пароль" -->
<!--                :input-value="this.pass"-->
<!--                @update:input-value="this.pass = $event"-->
<!--            />-->
<!--        </div>-->

        <div class="form-group">
            <label for="exampleInputEmail1" class="label">Фамилия</label>
            <input-layout
                placeholder="Фамилия"
                :input-value="user.user_detales.last_name"
                :complete="user.user_detales.last_name"
                @update:input-value="user.user_detales.last_name = $event"
            />
        </div>

        <div class="form-group">
            <label for="exampleInputEmail1" class="label">Имя</label>
            <input-layout
                placeholder="Имя"
                :input-value="user.user_detales.first_name"
                :complete="user.user_detales.first_name"
                @update:input-value="user.user_detales.first_name = $event"
            />
        </div>

        <div class="form-group">
            <label for="exampleInputEmail1" class="label">Отчество</label>
            <input-layout
                placeholder="Отчество"
                :input-value="user.user_detales.father_name"
                :complete="user.user_detales.father_name"
                @update:input-value="user.user_detales.father_name = $event"
            />
        </div>

        <div class="form-group">
            <label for="" class="label">Телефон</label>
            <input-layout-phone
                placeholder="Укажите ваш телефон"
                :input-value="user.phone"
                :complete="user.phone"
                @update:input-value="user.phone = $event"
            />
        </div>

        <button-helper
            class="form-btn"
            color="#ffffff"
            background="#02B061"
            @click="update"
        >
            <check-button-docs color="#ffffff" style="margin-right: 16px; position: absolute; left: 22px; top: 40%"/>
            Сохранить
        </button-helper>

    </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/ButtonHelper.vue'
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import CheckButtonDocs from "@/components/icons/CheckButtonDocs.vue";
import InputLayoutPhone from "@/components/layout/inputs/InputLayoutPhone.vue";

export default {
    name: "AdminSettings",
    components: {InputLayoutPhone, CheckButtonDocs, ButtonHelper, InputLayout},
    computed: {
        user() {
            return this.$store.getters.getUser
        },
        name() {
            if (this.user.first_name && this.user.last_name && this.user.patronymic) {
                return this.user.first_name + ' ' + this.user.last_name + ' ' + this.user.patronymic
            } else if (this.user.first_name && this.user.last_name) {
                return this.user.first_name + ' ' + this.user.last_name
            } else if (this.user.first_name && this.user.patronymic) {
                return this.user.first_name + ' ' + this.user.patronymic
            } else {
                return this.user.first_name
            }
        },
        detalies() {
            return this.user.user_detales ? this.user.user_detales : {}
        }
    },
    data() {
        return {
            pass: '',
            error: []
        }
    },
    methods: {
        async update() {
            let user = {
                id: this.user.id,
                first_name: this.user.user_detales.first_name,
                last_name: this.user.user_detales.last_name,
                father_name: this.user.user_detales.father_name,
                email: this.user.email,
                age: this.detalies.age,
                pass: this.pass,
                gender: this.detalies.gender,
                phone: this.user.phone,
                city: this.detalies.city
            }
            if (!this.error.length) {
                await this.$store.dispatch('updateUserDetailes', user)
            } else {
                console.log(this.error)
            }
        }
    }
}
</script>

<style scoped lang="scss">

.admin-settings {
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 50px;
    column-gap: 112px;
    max-width: 1162px;

    @media screen and (max-width: 1000px) {
        gap: 50px;
    }

    @media screen and (max-width: 769px) {
        grid-template-columns: auto;
        align-items: center;
    }
}

.form-control {
    background: #edeff3;
    border-radius: 5px;
    outline: none;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #9195a4;
    height: 46px;
}

.form-control:focus {
    outline: none;
    border: none;
    background: #eaf2ef;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #373a43;
}

.form-btn {
    align-self: flex-end;
    height: 50px;
    width: 156px;
    position: relative;

    @media screen and (max-width: 769px) {
        align-self: center;
        margin: 0 auto;
    }
}

.label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 9px;
    color: #373a43;
}

.form-group {
    position: relative;
}

.check-icon {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

</style>

<template>
  <div class="user-settings" v-if="user.user_detales">

    <div class="form-group">
              <label class="label">Имя</label>
                  <input-layout
                      :input-value="user.first_name"
                      placeholder="Имя"
                      :complete="user.first_name"
                      @update:input-value="user.first_name = $event"
                  />
          </div>
          <div class="form-group">
              <label class="label">Фамилия</label>
                  <input-layout
                      :input-value="user.last_name"
                      placeholder="Фамилия"
                      :complete="user.last_name"
                      @update:input-value="user.last_name = $event"
                  />
          </div>
          <div class="form-group">
              <label class="label">Отчество</label>
                  <input-layout
                      :input-value="user.father_name"
                      placeholder="Отчество"
                      :complete="user.father_name"
                      @update:input-value="user.father_name = $event"
                  />
          </div>


        <div class="form-group">
          <label class="label">E-mail</label>
                <input-layout
                    :input-value="user.email"
                    placeholder="Укажите ваш e-mail"
                    :complete="user.email"
                    @update:input-value="user.email = $event"
                    :is-disable="true"
                />
        </div>


        <div class="form-group">
          <label class="label">Пол</label>
                <input-layout
                    :input-value="user.user_detales.gender"
                    placeholder="Пол"
                    :complete="user.user_detales.gender"
                    type="dropdown"
                    @click="chooseGender = !chooseGender"
                />

            <drop-down style="height: 100px;" v-if="chooseGender" :items="['Мужской', 'Женский']" @closeDrop="chooseGender = false" @dropChosen="user.user_detales.gender = $event; chooseGender = !chooseGender"/>
        </div>

        <div class="form-group">
          <label class="label">Возраст</label>
                <input-layout
                    :input-value="user.user_detales?.age"
                    placeholder="Укажите возраст"
                    :complete="user.user_detales?.age"
                    type="number"
                    @update:input-value="user.user_detales.age = $event"
                />
        </div>

        <div class="form-group">
          <label class="label">Город</label>
                <input-layout
                    :input-value="user.user_detales.city"
                    :placeholder="user.user_detales.city ? user.user_detales?.city : 'Укажите город'"
                    :complete="user.user_detales.city"
                    type="dropdown"
                    @click="showSelectCity = true"
                />
        </div>
        <div class="form-group">
          <label class="label">Телефон</label>
                <input-layout-phone
                    :input-value="user.phone"
                    placeholder="Укажите ваш телефон"
                    :complete="user.phone"
                    @update:input-value="user.phone = $event"
                />
        </div>

        <div class="form-group save-btn">
          <label class="label"></label>
          <button-helper
              color="#ffffff"
              background="#02B061"
              style="height: 50px;width: 156px;"
              @click="update"
          >
            <check-button-docs color="#ffffff" style="margin-right: 5px"/>
            Сохранить
          </button-helper>
        </div>
        <SelectCity v-if="showSelectCity" @changeSortCity="selectCity($event)" @closeModal="showSelectCity = false"/>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/ButtonHelper.vue'
import DropDown from "@/components/layout/Dropdown.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import CheckButtonDocs from "@/components/icons/CheckButtonDocs.vue";
import InputLayoutPhone from "@/components/layout/inputs/InputLayoutPhone.vue";
import SelectCity from "@/components/layout/SelectCity.vue";

export default {
  name: 'UserDashboardSettings',
  components: {InputLayoutPhone, CheckButtonDocs, InputLayout, DropDown, ButtonHelper, SelectCity },
  computed: {
    user() {
      console.log(this.$store.getters.getUser);
        return this.$store.getters.getUser
    },
    name() {
      if (this.user.first_name && this.user.last_name && this.user.patronymic) {
        return this.user.first_name + ' ' + this.user.last_name + ' ' + this.user.patronymic
      } else if (this.user.first_name && this.user.last_name) {
        return this.user.first_name + ' ' + this.user.last_name
      } else if (this.user.first_name && this.user.patronymic) {
        return this.user.first_name + ' ' + this.user.patronymic
      } else {
        return this.user.first_name
      }
    },
  },
  data() {
    return {
      pass: '',
      error: [],
      chooseGender: false,
      showSelectCity: false,
    }
  },
  methods: {
    selectCity($event){
      this.user.user_detales.city = $event;
      this.showSelectCity = false;
    },
    async update() {
      let user = {
        id: this.user.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        father_name: this.user.father_name,
        login: this.user.login,
        age: this.user.user_detales.age,
        // pass: this.pass,
        gender: this.user.user_detales.gender,
        phone: this.user.phone,
        city: this.user.user_detales.city
        // email: this.user.email,
      }
      if (!this.error.length) {
        await this.$store.dispatch('updateUserDetailes', user)
      } else {
        console.log(this.error)
      }
    }
  },
}
</script>

<style scoped lang="scss">
.user-settings {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 30px;
}

.form-control {
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #9195a4;
  height: 46px;
}
.form-control:focus {
  outline: none;
  background: #eaf2ef;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}
.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 9px;
  color: #373a43;
}

.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.save-btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: max-content
}

.form-input {
    display: flex;
    align-items: center;
    position: relative;
}

.check-icon {
    margin-left: -28px;
}

.complete {
    background: #EAF2EF;
}

@media screen and (max-width: 769px) {
  .user-settings {
      display: flex;
      align-items: center;
      flex-direction: column;
  }
}
</style>

<template>
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.72203 5.5L7 1.22203L5.77797 0L0.27797 5.5L5.77797 11L7 9.77797L2.72203 5.5Z"
      fill="#A4ACBD"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowLeft'
}
</script>

<style scoped></style>

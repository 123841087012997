const sortingShow = {
  methods: {
    sortingShow(type) {
      this.showSort = false
      this.sort = type
    }
  }
}

export default sortingShow

<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.125 3.625H2.75C2.28587 3.625 1.84075 3.80937 1.51256 4.13756C1.18437 4.46575 1 4.91087 1 5.375V13.25C1 13.7141 1.18437 14.1592 1.51256 14.4874C1.84075 14.8156 2.28587 15 2.75 15H10.625C11.0891 15 11.5342 14.8156 11.8624 14.4874C12.1906 14.1592 12.375 13.7141 12.375 13.25V8.875"
            :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.25 9.75L15 1" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.625 1H15V5.375" :stroke="color" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: false,
            default: '#FFFFFF'
        }
    },

    name: 'LinkIcon'
}
</script>

<style scoped></style>

<template>
    <div class="change-content">

        <input v-if="editButton" :style="color ? 'color: #24ad6f' : ''"
               class="change-content-input" type="text" :value="firstInput" @change="changeFirstInput" style="">

        <input v-if="editButton && secondInput"
               class="change-content-input" type="text" :value="secondInput" @change="changeSecondInput" style="">

        <circle-button class="change-content-btn" :style="left ? 'left: -20px' : 'right: -20px'"
                       v-if="!editButton" @click="changeEditButton(true)">
            <change-icon style="min-width: 16px; min-height: 16px"/>
        </circle-button>
        <circle-button class="change-content-btn" :style="left ? 'left: -20px' : 'right: -20px'"
                       v-else @click="updateHomeContent">
            <check-button-docs style="min-width: 14px; min-height: 10px"/>
        </circle-button>

    </div>
</template>

<script>

import CircleButton from "@/components/helppers/CircleButton.vue";
import ChangeIcon from "@/components/icons/ChangeIcon.vue";
import CheckButtonDocs from "@/components/icons/CheckButtonDocs.vue";

export default {

    name: 'ChangeHomeContentHelper',

    components: {
        CheckButtonDocs,
        ChangeIcon,
        CircleButton
    },

    props: {
        firstInput: {
            required: true,
        },
        secondInput: {
            required: false,
        },
        editButton: {
            type: Boolean,
            required: true,
            default: false
        },
        left: {
            type: Boolean,
            default: true,
            required: false
        },
        color: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    methods: {
        changeFirstInput(event) {
            this.$emit('update:firstInput', event.target.value)
        },
        changeSecondInput(event) {
            this.$emit('update:secondInput', event.target.value)
        },
        changeEditButton(button) {
            this.$emit('changeEditButton', button)
        },
        updateHomeContent() {
            this.$emit('updateHomeContent')
            this.changeEditButton(false)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.change-content-input {
    border: none;
    background-color: transparent;
    width: 100%;
}

.change-content-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.change-content-input:focus {
    outline: none;
}

</style>

<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('showCourse', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Курсы повышения квалификации</div>
      <div class="inputs">
        <div class="icon-div">
          <input type="text" placeholder="Город" class="input-soc" v-model="course.city" />
        </div>
      </div>
      <div class="inputs">
        <div class="icon-div">
          <input type="text" placeholder="Курс" class="input-soc" v-model="course.name" />
        </div>
      </div>
      <div class="inputs">
        <div class="icon-div">
          <select-helper
              :styles="selectStyles"
              :select-value="this.course?.year"
              :show-select="this.showSelect"
              :select-by="'Год'"
              :drop-down-list="this.dropYear"
              @changeShowSelect="this.showSelect = $event"
              @changeSelectValue="sortingSelect($event)"
            />
        </div>
      </div>
      <div class="buttons">
        <button-helper class="save-button" color="#ffffff" @click="subscribe" background="#02B061">
          Сохранить
        </button-helper>
        <span class="text" @click="this.$store.dispatch('showCourse', false)"> Отмена </span>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue';
import SelectHelper from '@/components/helppers/SelectHelper.vue';

export default {
  name: 'CoursesPopup',
  components: {
    ButtonHelper,
    SelectHelper
  },
  data() {
    return {
      course: {},
      editCourse: null,
      showSelect: false,
      dropYear: [],
      selectStyles: {
          width: '300px',
          marginTop: '20px'
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    doctor() {
      return this.$store.getters.getDoctorDetalies
    }
  },
  methods: {
    async subscribe() {
      if(this.editCourse){
        if(this.doctor.id == this.user.id){
          await this.$store.dispatch('editCourse', this.course)
        }else{
          await this.$store.dispatch('editCourseDoctor', this.course)
          await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        }
      }else{
        if(this.doctor.id == this.user.id){
          await this.$store.dispatch('addCourse', this.course)
        }else{
          this.course.doctor_id = this.doctor.id;
          await this.$store.dispatch('addCourseDoctor', this.course)
          await this.$store.dispatch('getDoctorDetalies', this.doctor.id)
        }
      }
      this.$store.dispatch('showCourse', false);
      this.editCourse = null;
      this.course = null;
      this.$store.dispatch('resetPersonal');
    },
    sortingSelect($event){
      this.course.year = $event;
    },
    handleResize() {
        if (window.innerWidth <= 425 && window.innerWidth > 375) {
            this.selectStyles.width = '258px';
        } else if (window.innerWidth <= 375 && window.innerWidth > 320) {
            this.selectStyles.width = '227px';
        } else if (window.innerWidth <= 320) {
            this.selectStyles.width = '190px';
         } else if (window.innerWidth > 425) {
            this.selectStyles.width = '300px';
        }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.editCourse = this.$store.getters.getCourse;
    if(this.editCourse){
      this.course = this.editCourse;
    }

    const currentYear = new Date().getFullYear();
    const startYear = 1923;

    for (let year = currentYear; year >= startYear; year--) {
      this.dropYear.push(year);
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  min-height: 185px;
  width: 483px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}
.inputs {
  width: 330px;
  margin: 10px auto;
}

.input {
  width: 330px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}
.input-soc::-webkit-outer-spin-button,
.input-soc::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-soc[type='number'] {
  -moz-appearance: textfield;
}
.input-soc {
  width: 300px;
  height: 46px;
  margin-top: 20px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 15px;
  color: #7f818a;
  autline: none;
}

.input:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.input-soc:focus {
  outline: none;
  background: #eaf2ef;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.icon-left {
  position: absolute;
  top: 35px;
  left: 30px;
}

.icon-right {
  position: absolute;
  top: 35px;
  right: 15px;
}

.icon-div {
  display: flex;
  position: relative;
}
.buttons {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 45px;
}
.text {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #24ad6f;
  margin-left: 20px;
}
.save-button {
    padding: 17px 24px;
}

@media screen and (max-width: 425px) {
    .form {
        width: 80%;
    }

    .inputs {
        width: 80%;
    }
}
</style>

<template>
    <div class="admin-invite">
        <div class="date">
            {{ getVerifiedDate(invite.created_at) }}
        </div>

        <div class="name">
            {{ invite.send_to }} : {{ invite.last_name }} {{ invite.first_name }} {{ invite.father_name }}, {{ invite.phone }}
        </div>

        <div class="description">
            Вы отправили приглашение
        </div>
    </div>
</template>

<script>
export default {
  name: 'AdminInvite',
  props: {
    invite: {
      type: Object,
      required: true
    }
  },
  methods:{
    getVerifiedDate(created_at) {
        return new Date(created_at).toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        })
    },
  }
}
</script>

<style scoped>
.admin-invite {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.date {
    ont-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #656873;
}

.name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #373a43;
}

.description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #656873;
}

</style>

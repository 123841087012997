<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.77686 1L15.7769 15"
      stroke="#C0C4CD"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.67582 6.90063C7.38393 7.19231 7.21987 7.588 7.21973 8.00064C7.21958 8.41328 7.38336 8.80908 7.67504 9.10097C7.96672 9.39285 8.3624 9.55692 8.77505 9.55706C9.18769 9.55721 9.58349 9.39343 9.87538 9.10175"
      stroke="#C0C4CD"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.72678 2.83984C7.39366 2.64907 8.08415 2.5535 8.77778 2.55595C11.8889 2.55595 14.4812 4.37051 16.5556 8.0004C15.9504 9.05895 15.3018 9.96351 14.6088 10.7133M12.9443 12.1607C11.6758 13.0163 10.2882 13.4448 8.77778 13.4448C5.66667 13.4448 3.07433 11.6303 1 8.0004C2.06478 6.13762 3.26567 4.75318 4.60267 3.84629"
      stroke="#C0C4CD"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'EyeIcon'
}
</script>

<style scoped></style>

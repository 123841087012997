<template>
  <svg width="17" height="10" viewBox="0 0 17 10" fill="#A4ACBD" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.72868 10C3.2532 10 0.13013 6.24625 0 0H2.74274C2.83283 4.58458 4.8548 6.52653 6.45641 6.92693V0H9.03909V3.95395C10.6207 3.78378 12.2821 1.98198 12.8427 0H15.4253C14.9949 2.44244 13.1931 4.24424 11.9118 4.98498C13.1931 5.58559 15.2452 7.15716 16.026 10H13.1831C12.5725 8.0981 11.0511 6.62663 9.03909 6.42643V10H8.72868Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VkIcon'
}
</script>

<style scoped></style>

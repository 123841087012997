<template>
  <div class="content">
    <div
      :class="parseInt(currentPage) == i ? 'item active' : 'item'"
      v-for="i in pages"
      :key="i"
      @click="this.$emit('updateCurrentPage', i)"
    >
      {{ i }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationHelper',
  props: {
    pageCount: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    pages() {
      let pages = []
      if (this.pageCount <= 5) {
        for (let i = 1; i <= this.pageCount; i++) {
          pages.push(i)
        }
      } else {
        if (this.currentPage == 1 || this.currentPage == 2) {
          for (let i = 1; i <= 5; i++) {
            if (i == 4) {
              pages.push('...')
            } else if (i == 5) {
              pages.push(this.pageCount)
            } else {
              pages.push(i)
            }
          }
        } else {
          for (let i = 1; i <= 5; i++) {
            if (i == 4) {
              pages.push('...')
            } else if (i == 5) {
              pages.push(this.pageCount)
            } else {
              pages.push(i + parseInt(this.currentPage) - 2)
            }
          }
        }
      }
      return pages
    }
  }
}
</script>

<style scoped>
.content {
  display: flex;
  margin-top: 20px;
  column-gap: 10px;
}
.item {
  width: 55px;
  height: 55px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 55px;
  color: #373a43;
  text-align: center;
  cursor: pointer;
}
.active {
  border: 1px solid #d3d8e2;
  border-radius: 50%;
}
@media screen and (max-width: 769px) {
  .content {
    justify-content: center;
  }
}
</style>

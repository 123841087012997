<template>
  <router-view/>

  <login-popup v-if="loginPopup"/>
  <register-popup v-if="registerPopup && registerType === 'user'"/>
  <register-doctor-popup v-if="registerPopup && registerType === 'doctor'"/>
  <subscribe-popup v-if="subscribePopup"/>
  <verifide-popup v-if="verifiedPopup.show"/>
  <social-popup v-if="socialPopup"/>
  <talant-popup v-if="talantPopup"/>
  <education-popup v-if="educationPopup"/>
  <courses-popup v-if="coursePopup"/>
  <RocommendationNotAuthPopup v-if="recommendationNotAuth"/>
  <delete-popup v-if="deletePopup"/>
  <job-popup v-if="jobPopup"/>
  <select-city-popup v-if="selectCityPopup"/>
  <connect-popup v-if="connectPopup" />
  <forget-popup v-if="forgetPopup" />
  <block-helpper v-if="blockPopup"/>
  <create-sub-scribe-popup v-if="createSub"/>
  <edit-recommendation-popup v-if="editRecommendation"/>
  <special-popup v-if="specialPopup"/>
    <page-loader v-show="loader"/>
</template>

<script>
import LoginPopup from '@/components/layout/LoginPopup.vue'
import RegisterPopup from '@/components/layout/RegisterPopup.vue'
import RegisterDoctorPopup from '@/components/layout/RegisterDoctorPopup.vue'
import SubscribePopup from '@/components/helppers/SubscribePopup.vue'
import VerifidePopup from '@/components/helppers/VerifidePopup.vue'
import SocialPopup from '@/components/helppers/SocialPopup.vue'
import TalantPopup from '@/components/helppers/TalantPopup.vue'
import EducationPopup from '@/components/helppers/EducationPopup.vue'
import CoursesPopup from '@/components/helppers/CoursesPopup.vue'
import RocommendationNotAuthPopup from '@/components/helppers/RocommendationNotAuthPopup.vue'
import DeletePopup from '@/components/helppers/DeletePopup.vue'
import JobPopup from '@/components/helppers/JobPopup.vue'
import SelectCityPopup from '@/components/layout/SelectCityPopup'
import ConnectPopup from '@/components/layout/ConnectPopup'
import ForgetPopup from '@/components/layout/ForgetPopup'
import BlockHelpper from "@/components/helppers/BlockHelpper.vue";
import CreateSubScribePopup from "@/components/helppers/CreateSubScribePopup.vue";
import EditRecommendationPopup from './components/helppers/EditRecommendationPopup.vue';
import SpecialPopup from './components/helppers/SpecialPopup.vue'
import PageLoader from "@/components/helppers/PageLoader.vue";

export default {
  components: {
      PageLoader,
    CreateSubScribePopup,
    BlockHelpper,
    ConnectPopup,
    SelectCityPopup,
    JobPopup,
    DeletePopup,
    CoursesPopup,
    EducationPopup,
    TalantPopup,
    SocialPopup,
    VerifidePopup,
    SubscribePopup,
    RegisterDoctorPopup,
    RegisterPopup,
    LoginPopup,
    ForgetPopup,
    RocommendationNotAuthPopup,
    SpecialPopup,
    EditRecommendationPopup,
  },

  methods: {
    onResize() {
      this.$store.commit('setMobile')
    }
  },

  computed: {
      loader(){
        return this.$store.getters.getLoader
      },
    loginPopup() {
      return this.$store.getters.getloginPopup
    },
    registerPopup() {
      return this.$store.getters.getRegisterPopup
    },
    registerType() {
      return this.$store.getters.getRegisterType
    },
    subscribePopup() {
      return this.$store.getters.getsubscribePopup
    },
    verifiedPopup() {
      return this.$store.getters.getVerified
    },
    socialPopup() {
      return this.$store.getters.getSocial
    },
    talantPopup() {
      return this.$store.getters.getTalant
    },
    educationPopup() {
      return this.$store.getters.getShowEducation
    },
    coursePopup() {
      return this.$store.getters.getShowCourse
    },
    recommendationNotAuth() {
      return this.$store.getters.getRecommendationNotAuthPopup
    },
    deletePopup() {
      return this.$store.getters.getDelete
    },
    jobPopup() {
      return this.$store.getters.getShowJob
    },
    selectCityPopup() {
      return this.$store.getters.getSelectCityPopup
    },
    connectPopup() {
      return this.$store.getters.getConnectPopup
    },
    forgetPopup() {
      return this.$store.getters.getForgetPopup
    },
    blockPopup(){
      return this.$store.getters.getBlockUser
    },
    createSub(){
      return this.$store.getters.getCraeteSub
    },
    editRecommendation(){
      return this.$store.getters.getEditRecommendation
    },
    specialPopup() {
      return this.$store.getters.getSpecial;
    }
  },

  async mounted() {
    await this.$store.dispatch('getSpecialities')

    if (localStorage.userToken) {
      await this.$store.dispatch('getUserByToken')
      await this.$store.dispatch('getUserFavorites', localStorage.userToken);
    }

    this.$store.dispatch('closePopUp')
    document.addEventListener('click', (e) => {
      if (!!e.target.closest('.full-screen') && !e.target.closest('.form')) {
        this.$store.dispatch('closePopUp')
      }
    })

    window.addEventListener('resize', this.onResize)
    this.onResize()
  }
}
</script>

<style>
.lock body{
  overflow: hidden;
}
#app {
  position: relative;
  overflow: hidden;
}
@media (min-width: 769px) {
  .mobile-only {
    display: none !important;
  }
}
@media (max-width: 769px) {
  .desktop-only {
    display: none !important;
  }
}
</style>

export default {
    state: {
        notifications: []
    },

    getters: {
        getAllNotifications(state) {
            return state.notifications
        }
    },

    mutations: {
        setNotifications(state, notifications) {
            state.notifications = notifications
        }
    },

    actions: {
        async getNotifications({commit}) {
            await this.$axios.get(`/api/notification`).then((response) => {
                commit('setNotifications', response.data)
            })
        }
    }
}

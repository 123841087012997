<template>
  <svg width="18" height="20" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6667 1H3.11111C1.94518 1 1 1.94518 1 3.11111V17.8889C1 19.0548 1.94518 20 3.11111 20H13.6667C14.8326 20 15.7778 19.0548 15.7778 17.8889V3.11111C15.7778 1.94518 14.8326 1 13.6667 1Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.22266 5.22266H11.556" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.22266 9.44434H11.556" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.22266 13.667H9.44488" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CatalogIcon",

    props: {
        color: {
            type: String,
            required: false,
            default: '#9FD6BD'
        }
    },

}
</script>

<style scoped>

</style>

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from '../views/Login.vue'
import DashboardPage from '../views/Dashboard.vue'
import AdminDoctorDashboardPreview from "@/components/admin/AdminDoctorDashboardPreview.vue";
import RegisterPage from '@/views/Register.vue'
import DoctorPage from '@/views/DoctorPage.vue'
import SpecPage from '@/views/SpecPage.vue'
import AdminSpecPagePreview from "@/components/admin/AdminSpecPagePreview.vue";
import Search from '@/views/Search.vue'
import UserCollections from '@/views/UserCollections.vue'
import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage
  },
  {
    path: '/dashboard/:id?',
    name: 'dashboard',
    component: DashboardPage
  },
  {
    path: '/preview/:id?',
    name: 'dashboard-preview',
    component: AdminDoctorDashboardPreview
  },
  {
    path: '/doctor/:id',
    name: 'doctor-page',
    component: DoctorPage
  },
  {
    path: '/category',
    name: 'category-page',
    component: SpecPage
  },
    {
        path: '/category-preview',
        name: 'category-page-preview',
        component: AdminSpecPagePreview
    },
  {
    path: '/search',
    name: 'search-page',
    component: Search
  },
  {
    path: '/user-collections/:id',
    name: 'user-collections-page',
    component: UserCollections
  },
  {
      path: "/404",
      name: "404",
      component: NotFound,
      meta: {title: "Страница не найдена"}
  },
  {
      path: '/:catchAll(.*)', redirect: {name: '404'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
    scrollBehavior() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },

})

export default router

<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.00016 15.1666C4.04683 15.1666 0.833496 11.9533 0.833496 7.99992C0.833496 4.04659 4.04683 0.833252 8.00016 0.833252C11.9535 0.833252 15.1668 4.04659 15.1668 7.99992C15.1668 11.9533 11.9535 15.1666 8.00016 15.1666ZM8.00016 1.83325C4.60016 1.83325 1.8335 4.59992 1.8335 7.99992C1.8335 11.3999 4.60016 14.1666 8.00016 14.1666C11.4002 14.1666 14.1668 11.3999 14.1668 7.99992C14.1668 4.59992 11.4002 1.83325 8.00016 1.83325Z"
            fill="#F93232"/>
        <path
            d="M6.11308 10.3867C5.98641 10.3867 5.85975 10.34 5.75975 10.24C5.56641 10.0467 5.56641 9.72666 5.75975 9.53332L9.53308 5.75999C9.72641 5.56666 10.0464 5.56666 10.2397 5.75999C10.4331 5.95332 10.4331 6.27332 10.2397 6.46666L6.46641 10.24C6.37308 10.34 6.23975 10.3867 6.11308 10.3867Z"
            fill="#F93232"/>
        <path
            d="M9.88641 10.3867C9.75975 10.3867 9.63308 10.34 9.53308 10.24L5.75975 6.46666C5.56641 6.27332 5.56641 5.95332 5.75975 5.75999C5.95308 5.56666 6.27308 5.56666 6.46641 5.75999L10.2397 9.53332C10.4331 9.72666 10.4331 10.0467 10.2397 10.24C10.1397 10.34 10.0131 10.3867 9.88641 10.3867Z"
            fill="#F93232"/>
    </svg>
</template>

<script>
export default {
    name: 'CircleErrorIcon'
}
</script>

<style scoped></style>

<template>
    <svg width="18" height="13" viewBox="0 0 18 13" :stroke="color" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.99648 8.19853C9.88014 8.19853 10.5965 7.48219 10.5965 6.59854C10.5965 5.71488 9.88014 4.99854 8.99648 4.99854C8.11283 4.99854 7.39648 5.71488 7.39648 6.59854C7.39648 7.48219 8.11283 8.19853 8.99648 8.19853Z"
            stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M17 6.6C14.8664 10.3336 12.2 12.2 9 12.2C5.8 12.2 3.1336 10.3336 1 6.6C3.1336 2.8664 5.8 1 9 1C12.2 1 14.8664 2.8664 17 6.6Z"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

</template>

<script>
export default {
    name: "OpenEyeIcon",

    props: {
        color: {
            type: String,
            required: false,
            default: '#02B061'
        }
    },
}
</script>

<style scoped>

</style>

import {createStore} from 'vuex'
import user from '@/store/user'
import categories from '@/store/categories'
import notification from '@/store/notification'
import subscribes from '@/store/subscribes'
import popup from '@/store/popup'
import breakpoint from '@/store/breakpoint'
import location from "@/store/location";
import homeContent from "@/store/content";
import favorite from "@/store/favorite";
import doctor from "@/store/doctor";
import auth from "@/store/auth";
import app from "@/store/app"
import admin from "@/store/admin"
export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        categories,
        notification,
        popup,
        subscribes,
        breakpoint,
        location,
        homeContent,
        favorite,
        doctor,
        auth,
        app,
        admin
    }
})

<template>
    <div class="full-screen">
        <div class="form">
            <div class="close-parent">
                <font-awesome-icon
                        icon="close"
                        @click="this.$store.dispatch('changeDelete', false)"
                        color="#BDC3C9"
                ></font-awesome-icon>
            </div>
            <div class="icon">
                <question-icon/>
            </div>
            <div class="title">Вы действительно хотите удалить анкету</div>
            <div class="text-main" v-if="!mobile">Ваш аккаунт на сайте останется даже после удаления анкеты</div>
            <div class="buttons">
                <button-helper color="#ffffff" @click="deleteDoctor" background="#02B061">
                    Да, удалить
                </button-helper>
                <span class="text" @click="this.$store.dispatch('changeDelete', false)"> Отмена </span>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import QuestionIcon from "@/components/icons/RecommendQuestion.vue";

export default {
    name: 'DeletePopup',
    components: {ButtonHelper, QuestionIcon},
    computed: {
        user() {
            return this.$store.getters.getUser
        },
        deleteUser() {
            return this.$store.getters.getDeleteUser
        },
        mobile() {
            return this.$store.getters.getMobile
        },
    },
    methods: {
        async deleteDoctor() {
            let user = this.deleteUser ? this.deleteUser : this.user

            await this.$store.dispatch('deleteDoctor', user.id);
            this.$store.dispatch('changeDelete', false);
            this.$router.push({name: 'home'})

        }
    }
}
</script>

<style scoped>
.full-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(238, 240, 244, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

.form {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    width: 483px;
    height: 270px;
    background: #fff;
    box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
    border-radius: 20px;
    right: 0;
    bottom: 0;
}

.close-parent {
    width: 11px;
    height: 11px;
    float: right;
    margin: 15px;
    cursor: pointer;
}

.title {
    display: flex;
    justify-content: center;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #373a43;
    margin-top: 10px;
}

.icon {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}

.buttons {
    text-align: center;
    margin: 45px 0;
}

.buttons button {
    padding: 17px 24px;
}

.text {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #24ad6f;
    margin-left: 20px;
}

.text-main {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #656873;
    margin-top: 10px;
    text-align: center;
}

@media screen and (max-width: 792px) {
    .form {
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .form {
        width: 80%;
        height: 250px;
        padding: 0 10px;
    }

    .title {
        font-size: 18px;
    }

    .buttons {
        margin: 25px 0;
    }
}
</style>

<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.11133H13.4445"
      :stroke="stroke"
      :fill="fill"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.77734 4.11133L2.55512 13.4447C2.55512 13.8572 2.71901 14.2529 3.01073 14.5446C3.30246 14.8363 3.69812 15.0002 4.11068 15.0002H10.3329C10.7455 15.0002 11.1411 14.8363 11.4328 14.5446C11.7246 14.2529 11.8885 13.8572 11.8885 13.4447L12.6662 4.11133"
      :stroke="stroke"
      :fill="fill"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.88867 4.11111V1.77778C4.88867 1.5715 4.97062 1.37367 5.11648 1.22781C5.26234 1.08194 5.46017 1 5.66645 1H8.77756C8.98384 1 9.18167 1.08194 9.32754 1.22781C9.4734 1.37367 9.55534 1.5715 9.55534 1.77778V4.11111"
      :stroke="stroke"
      :fill="fill"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'GreenTrashIcon',
    props: {
      stroke: {
          type: String,
          default: '#02B061'
      },
      fill: {
          type: String,
          default: 'none'
      }
    }
}
</script>

<style scoped></style>

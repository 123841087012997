<template>
  <div class="info-parent">
    <div class="info">
        <div class="info-icon">
            <svg width="18" height="18" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 0L12.9952 7.5H0.00480938L6.5 0Z" fill="#373A43" />
            </svg>
        </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoHelpper'
}
</script>

<style scoped>
.info-parent {
  width: 140px;
}
.info-icon {
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0 !important;
}
.info {
  position: relative;
  height: 60px;
  background: #373a43;
  opacity: 0.7;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding-top: 10px;
  margin-top: 0 !important;
}
</style>

<template>
  <div class="main mobile-only" :style="!login ? 'padding-bottom: 0' : ''">
    <div class="links">
      <div>Сервис</div>
      <div>Пользователям</div>
      <div>Правила сервиса</div>
      <div>Справка</div>
    </div>
    <div class="contacts">
      <div><span class="text-gray">Телефон:</span> 8 (495) 28-43-21</div>
      <div><span class="text-gray">E-mail:</span> info@doc-connect.ru</div>
    </div>
    <div class="icons">
      <div class="circle">
          <vk-icon/>
      </div>
      <div class="circle">
          <telegram-icon/>
      </div>
      <div class="circle">
          <ok-icon/>
      </div>
      <div class="circle">
          <youtube-icon/>
      </div>
    </div>
    <div class="footer text-gray">© 2022, ООО «Связи врачей»</div>
  </div>
</template>

<script>
import VkIcon from "@/components/icons/VkIcon.vue";
import TelegramIcon from "@/components/icons/TelegramFooterIcon.vue";
import OkIcon from "@/components/icons/OkIcon.vue";
import YoutubeIcon from "@/components/icons/YoutubeIcon.vue";

export default {
  name: 'FooterMobile',
    components: {YoutubeIcon, OkIcon, TelegramIcon, VkIcon},

    computed: {
        login() {
            return !!localStorage.userToken
        },
    }

}
</script>

<style scoped>
.main {
  margin-top: auto;
  padding-top: 70px;
  padding-bottom: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #373a43;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
}
.links {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 300;
  font-size: 13px;
  line-height: 36px;
  text-align: center;
}
.contacts {
  margin-top: 28px;
}
.icons {
  margin: 35px 0 38px;
  display: flex;
  gap: 21px;
  width: 120px;
  justify-content: center;
}
.text-gray {
  color: #656873;
}
.circle {
  min-width: 36px;
  height: 36px;
  display: grid;
  place-items: center;
  line-height: 36px;
  border: 1px solid #d3d8e2;
  border-radius: 50%;
  text-align: center;
}
</style>

<template>
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7L7 1L13 7" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: false,
            default: '#373A43'
        }
    },

    name: 'BigArrowIcon'
}
</script>

<style scoped></style>

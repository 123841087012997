<template>
  <div class="full-screen">
    <div class="form" :style="errorHeight">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('changeRegisterPopup', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div class="title">Регистрация врача</div>
      <div class="input-flex">
        <div class="inputs">
            <div class="input">
                <input-layout
                    placeholder="Email"
                    :input-value="user.email"
                    @update:input-value="user.email = $event"
                    :complete="user?.email?.length > 0"
                />
            </div>
            <div class="input">
                <input-layout
                    placeholder="Фамилия"
                    :input-value="user.last_name"
                    @update:input-value="user.last_name = $event"
                    :complete="user?.last_name?.length > 3"
                />
            </div>
          <div class="input">
            <input-layout
                placeholder="Имя"
                :input-value="user.first_name"
                @update:input-value="user.first_name = $event"
                :complete="user?.first_name?.length > 3"
            />
          </div>
            <div class="input">
                <input-layout
                    placeholder="Отчество"
                    :input-value="user.father_name"
                    @update:input-value="user.father_name = $event"
                    :complete="user?.father_name?.length > 3"
                />
            </div>
        </div>

        <hr class="mobile-only line"/>

        <div class="inputs">
            <div class="input">
                <input-layout-password
                    placeholder="Пароль"
                    :input-value="user.password"
                    @update:input-value="user.password = $event"
                    :complete="user.password && user.password.length > 5"
                    :error="user.password && user.password.length < 6 && user.password.length > 0"
                    :show-difficult="true"
                />
            </div>
            <div class="input">
                <input-layout-password
                    placeholder="Повторите пароль"
                    :input-value="user.comfirm"
                    @update:input-value="user.comfirm = $event"
                    :complete="user.comfirm && user.comfirm.length > 5 && user.comfirm === user.password"
                    :error="user.comfirm && user.password.length < 6 && user.password.length > 0 || user.comfirm !== user.password"
                />
            </div>

            <div class="input">
                <input-layout-phone
                    placeholder="Личный номер телефона"
                    :input-value="user.phone"
                    @update:input-value="user.phone = $event"
                    :complete="user.phone"
                />
            </div>
          <div class="phone-info">
            <info-icon icon="circle-info"></info-icon>
            <span
              >Телефон не будет виден пациентам и будет использоваться только для отправки
              уведомлений</span
            >
          </div>
            <div class="input">
                <input-layout-phone
                    placeholder="Номер телефона для пациентов"
                    :input-value="user.phone_soc"
                    @update:input-value="user.phone_soc = $event"
                    :complete="user.phone_soc"
                />
            </div>
          <div class="phone-info">
            <info-icon icon="circle-info"></info-icon>
            <span>По данному номеру пациенты смогут связаться с вами в выбранных мессенджерах</span>
          </div>
          <div class="check" @click="userPhoneCheck">
            <div class="checkbox" :style="checkedStyle">
              <font-awesome-icon v-if="checked" icon="check" color="white"></font-awesome-icon>
            </div>
            <div class="check-text">Номера совпадают</div>
          </div>
        </div>
      </div>

      <hr class="mobile-only line" />

      <div class="title title-2-mobile">
        Укажите социальные сети, где пациент сможет с вами связаться
      </div>
      <div class="small-title desktop-only">И выберите предпочитаемый способ связи</div>
      <div class="input-flex soc-flex">
        <div class="inputs mar-btn-0-mobile mar-top-10-mobile">
            <div class="icon-div width-90-mobile">
                <input
                    type="radio"
                    class="mar-top-21-mobile"
                    @click="whatsppCheck"
                    :checked="whatsapp ? true : false"
                    v-model="whatsapp"
                />
                <span class="checkmark"></span>
                <div class="input-soc">
                    <input-layout-phone
                        icon-name="whatsapp"
                        placeholder="Whatsapp"
                        :input-value="user.whatsapp"
                        @update:input-value="user.whatsapp = $event"
                        :complete=" whatsapp && user?.whatsapp?.toString().length > 8"
                        :is-disable="whatsapp ? false : true"
                    />
                </div>
            </div>

            <div class="icon-div width-90-mobile">
                <input
                    type="radio"
                    class="mar-top-21-mobile"
                    @click="telegramCheck"
                    :checked="telegram ? true : false"
                    v-model="telegram"
                />
                <span class="checkmark"></span>
                <div class="input-soc">
                    <input-layout
                        icon-name="telegram"
                        placeholder="Telegram"
                        :input-value="user.telegram"
                        @update:input-value="user.telegram = $event"
                        :complete="telegram && user?.telegram?.toString().length > 3"
                        :is-disable="telegram ? false : true"
                    />
                </div>
            </div>
        </div>
        <div class="inputs">
            <div class="icon-div width-90-mobile">
                <input
                    type="radio"
                    class="mar-top-21-mobile"
                    @click="instagram ? (instagram = false) : 'on'"
                    :checked="instagram ? true : false"
                    v-model="instagram"
                />
                <span class="checkmark"></span>
                <div class="input-soc">
                    <input-layout
                        icon-name="instagram"
                        placeholder="Instagram"
                        :input-value="user.instagram"
                        @update:input-value="user.instagram = $event"
                        :complete="instagram && user?.instagram?.toString().length > 3"
                        :is-disable="instagram ? false : true"
                    />
                </div>
            </div>
        </div>
      </div>
      <div class="erroe" v-if="errorMessage !== ''">
        <font-awesome-icon icon="circle-info" color="#D83058"></font-awesome-icon>
        {{ errorMessage }}
      </div>
      <div class="buttons">
        <button-helper color="#ffffff" @click="register" background="#02B061"
          >Зарегистрироваться</button-helper
        >
      </div>
    </div>
  </div>
</template>

<script>
import buttonHelper from '@/components/helppers/DefaultButton.vue'
import user from '@/store/user'
import {IMaskDirective} from "vue-imask";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import InputLayoutPassword from "@/components/layout/inputs/InputLayoutPassword.vue";
import InputLayoutPhone from "@/components/layout/inputs/InputLayoutPhone.vue";

export default {
  name: 'RegisterDoctorPopup',
  components: {
      InputLayoutPhone,
      InputLayoutPassword,
      InputLayout,
    buttonHelper
  },
  data() {
    return {
      user: {},
      checked: false,
      whatsapp: false,
      telegram: false,
      instagram: false,
      errorMessage: '',
      pass: false,
      confirm: false,
        phoneMask: {
            mask: "+{7} (000) 000-00-00",
            lazy: true,
        },
    }
  },
  methods: {
    passHandle() {
      this.pass = true
      setTimeout(() => {
        document.addEventListener('click', this.removePass)
      })
    },
    confirmHandle() {
      this.confirm = true
      setTimeout(() => {
        document.addEventListener('click', this.removeConfirm)
      })
    },
    removePass(e) {
      if (!e.target.closest('#pass') && this.pass) {
        this.pass = false
        document.removeEventListener('click', this.removePass)
      }
    },
    removeConfirm(e) {
      if (!e.target.closest('#confirm') && this.confirm) {
        this.confirm = false
        document.removeEventListener('click', this.removeConfirm)
      }
    },
    telegramCheck() {
      this.telegram = this.telegram ? false : 'on'
      user.telegram = user.phone_soc
    },
    whatsppCheck() {
      this.whatsapp = this.whatsapp ? false : 'on'
      user.whatsapp = user.phone_soc
    },
    userPhoneCheck() {
      this.checked = !this.checked
      user.phone_soc = user.phone
      if(!this.user.phone && this.checked && this.user.phone_soc){
        this.user.phone = this.user.phone_soc
      }else if(!this.user.phone_soc && this.checked && this.user.phone){
        this.user.phone_soc = this.user.phone
      }
    },
    async register() {
      if (!this.user.email || this.user.email.length < 4) {
        this.errorMessage = 'Вы не выбрали email'
      } else if (!this.user.first_name || this?.user.first_name.length < 4) {
        this.errorMessage = 'Вы не написали свое имя'
      } else if (!this.user.last_name || this?.user.last_name.length < 4) {
        this.errorMessage = 'Вы не написали свое фамилия'
      }else if (!this.user.father_name || this?.user.father_name.length < 4) {
        this.errorMessage = 'Вы не написали свое отчество'
      }else if (!this.user.password || this?.user.password.length < 6) {
        this.errorMessage = 'Пароль должен быть не менее 6 символов'
      } else if (!this.user.comfirm || this?.user.password != this.user.comfirm) {
        this.errorMessage = 'Пароли не совпадают'
      } else if (user.whatsapp === null && user.telegram === null && user.intagram === null) {
        this.errorMessage = 'Вы не выбрали предпочитаемый способ связи'
      } else {
        this.user.password_confirmed = this.user.comfirm
          await this.$store.dispatch('registerDoctor', this.user)
              .then((response) => {
                  localStorage.userToken = response.data.token
                  this.$store.dispatch('setToken', response.data.token)
              })
              .then(() => {
                  this.$store.dispatch('changeRegisterPopup', false);
                  this.$store.dispatch('changeLoginPopup', false)
                  this.$router.push({name: 'dashboard', params: {id: 5}})
              }).catch((err) => {
                  console.log(err);
                  this.$store.dispatch('setServerError', err.response.data.errors)
              })
      }
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    checkedStyle() {
      return this.checked ? 'background: #02B061;border-color:transparent' : ''
    },
    errorHeight() {
      return this.errorMessage !== '' ? 'height: 870px;' : ''
    },
    left_whatsapp_color() {
      return this.whatsapp ? '#02B061' : '#C0C4CD'
    },
    left_telegram_color() {
      return this.telegram ? '#02B061' : '#C0C4CD'
    },
    left_instagram_color() {
      return this.instagram ? '#02B061' : '#C0C4CD'
    }
  },
    directives: {
        imask: IMaskDirective
    }
}
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 810px;
  max-height: 806px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(189, 189, 189, 0.7);
  border-radius: 20px;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.form::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
}

.form::-webkit-scrollbar-track {
    background: rgba(203, 208, 217, 0.5);
    border-radius: 50px;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 52px;
}
.title-2-mobile {
  margin-top: 76px;
}

.input-flex {
  display: flex;
  gap: 30px;
  margin-top: 25px;
  justify-content: center;
}

.soc-flex {
  margin-top: 15px;
  margin-bottom: 39px;
}

.inputs {
  width: 330px;
}

.input {
  margin-top: 24px;
}

/* Firefox */
.input[type='number'] {
  -moz-appearance: textfield;
}
.input-soc::-webkit-outer-spin-button,
.input-soc::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-soc[type='number'] {
  -moz-appearance: textfield;
}
.input-soc {
  width: 300px;
  margin-top: 20px;
  margin-left: 15px;
}

.input:focus {
  outline: none;
  background: none;
  border: 1px solid #02b061;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.input.verified {
  background: #eaf2ef;
}

.input-soc:focus {
  outline: none;
  background: none;
  border: 1px solid #02b061;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}

.input-soc.verified {
  background: #eaf2ef;
}

.phone-info {
  margin-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #7f818a;
  display: flex;
  gap: 11px;
}

.phone-info span {
  width: calc(100% - 25px);
}

.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #c2c7d0;
  border-radius: 5px;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
}

.check {
  margin-top: 33px;
  cursor: pointer;
  display: flex;
}

.check-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #373a43;
  margin-left: 10px;
}
.small-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #7f818a;
  text-align: center;
  margin-top: 6px;
}

.buttons {
  width: 330px;
  margin: 10px auto;
  text-align: center;
}
.buttons button {
  width: 197px;
  height: 50px;
}

.icon-div {
  display: flex;
  position: relative;
}

.icon-left {
  position: absolute;
  top: 35px;
  left: 45px;
}

.icon-right-soc {
  position: absolute;
  top: 35px;
  right: 15px;
}
.icon-right {
  position: absolute;
  top: 39px;
  right: 15px;
}

.icon-pass {
  position: absolute;
  top: 39px;
  right: 45px;
}
.erroe {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #d83058;
  text-align: center;
  background: #f9edf0;
  border-radius: 10px;
  max-width: 690px;
  height: 61px;
  margin: auto;
  line-height: 61px;
}

.checkmark {
  position: absolute;
  top: 35px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #c2c7d0;
  background: none;
  border-radius: 50%;
  cursor: pointer;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.icon-div input[type='radio'] {
  width: 16px;
  height: 16px;
  padding: 0;
  opacity: 0;
  margin-top: 35px;
  z-index: 10;
  cursor: pointer;
}

.icon-div input:checked ~ .checkmark {
  border: 1px solid #02b061;
}

.icon-div input:checked ~ .checkmark:after {
  display: block;
}

.icon-div .checkmark:after {
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #02b061;
}

@media screen and (max-width: 769px) {
  .checkmark {
    top: 29px;
  }
  .buttons button {
    width: unset;
    height: unset;
  }
  .form {
    margin-top: 1vh;
    width: 100%;
    max-height: 95%;
    overflow-y: scroll;
    max-width: 410px;
    top: unset;
    bottom: 20px;
  }
  .close-parent {
    margin-bottom: 0px;
  }
  .input-flex {
    flex-direction: column;
      gap: 0;
  }
  .title {
    font-size: 16px;
    line-height: 21px;
    margin: 28px auto 14px auto;
    width: 90%;
  }
  .input {
    margin: 19px auto 0px auto;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    width: 90%;
    max-width: 400px;
    border-radius: 5px;
  }
  .input-soc {
    height: 34px;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    border-radius: 5px;
    max-width: 400px;
  }

  .inputs {
    margin-top: 0px;
    margin-bottom: 31px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon-div {
    width: 100%;
    max-width: 400px;
  }
  .line {
    color: #dbe0eb;
    opacity: 1;
    margin: 2px auto 7px auto;
    width: 90%;
  }
  .phone-info {
    display: flex;
    gap: 5px;
    width: 90%;
    margin-top: 15px;
  }
  .check {
    width: 90%;
    margin-top: 30px;
    margin-bottom: 4px;
  }
  .icon-pass {
    position: absolute;
    top: 30px;
    right: 32px;
  }
  .title-2-mobile {
    margin-bottom: 0px !important;
    margin-top: 34px !important;
  }
  .buttons {
    width: 300px;
    margin: 4px auto 37px auto;
    text-align: center;
  }
  .mar-btn-0-mobile {
    margin-bottom: 0 !important;
  }
  .mar-top-10-mobile {
    margin-top: 10px !important;
  }
  .icon-left {
    top: 29px;
    left: 40px;
  }
  .mar-top-21-mobile {
    margin-top: 29px !important;
  }
  .width-90-mobile {
    width: 90% !important;
  }
  .icon-right {
    top: 28px;
    right: 22px;
  }
  .icon-pass {
    top: 28px;
    right: 50px;
  }
}
</style>

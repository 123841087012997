<template>
    <div class="category-route" v-if="pageName">
        <div class="category-route__text">
            <div class="category-route__url">
                <router-link
                    style="text-decoration: none; color: #9195a4"
                    :to="{ name: 'home'}"
                >
                    Главная
                </router-link>
                <ellipse-icon class="category-route__url-dot"/>
                {{ pageName }}
            </div>
            <div class="category-route__title">
                {{ pageName }} в {{ userCity }}
                <span class="small">{{ specLength }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import EllipseIcon from "@/components/icons/EllipseIcon.vue";
import { cityIn } from 'lvovich';

export default {

    name: 'TopCategoryRoute',

    components: {
        EllipseIcon,
    },

    props: {
        specialities: {
            required: true
        }
    },

    data() {
        return {
            city: 'Москва',
        }
    },

    methods: {
        getUserCity() {
            this.city = this.$store.getters.getUserCity
        }
    },

    computed: {
        specId() {
            return this.$route.query.spec
        },
        specLength() {
            return this.specialities[this.specId - 1].doctors.length
        },
        pageName() {
            let arr = this.specialities.filter((item) => item.id == this.specId)
            if(arr[0]){
                let name = arr[0].name;
                if (name.charAt(name.length - 1).toLowerCase() === "т") {
                    return name + "ы";
                }else if(name.charAt(name.length - 1).toLowerCase() === "и"){
                    return name;
                } else {
                    return name + "и";
                }
            }
            return null;
            // return arr[0] ? arr[0].name : null
        },
        user() {
            return this.$store.getters.getUser
        },
        userCity() {
            let user = this.$store.getters.getUser
            let city = this.city
            if (user?.user_detales) {
                city = user?.user_detales?.city ? user?.user_detales?.city : 'Москва'
            } else {
                this.getUserCity();
                city = this.city;
            }

            return cityIn(city);
        },
        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        }
    }
}
</script>

<style scoped lang="scss">

.category-route {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    &__text {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        @media screen and (max-width: 769px) {
            align-items: center;
        }
    }

    &__url {
        display: flex;
        align-items: center;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        color: #9195A4;
        overflow-x: scroll;

        white-space: nowrap;
    }

    &__url::-webkit-scrollbar {
        display: none;
    }

    &__url-dot {
        margin: 0 10px;
    }

    &__title {
        font-family: 'Raleway';
        font-weight: 400;
        font-size: 30px;
        line-height: 35px;
        color: #373A43;
    }
}

.small {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #9195a4;
}

.notification-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #02b061;
    width: 17px;
    height: 17px;
    align-self: flex-start;
}

.notification {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
    color: #ffffff;
}

</style>

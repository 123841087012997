<template>
  <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.94922 6.08789V10.0879L11.9492 12.0879" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 9.08795C1.22409 6.88801 2.25026 4.84757 3.88273 3.35592C5.5152 1.86428 7.63969 1.02584 9.85087 1.00059C12.0621 0.975332 14.2051 1.76502 15.8713 3.21899C17.5374 4.67296 18.6099 6.68943 18.8842 8.88368C19.1584 11.0779 18.6153 13.2964 17.3583 15.1157C16.1014 16.9351 14.2186 18.228 12.0693 18.7478C9.91991 19.2676 7.6544 18.9779 5.70499 17.934C3.75558 16.8901 2.25872 15.165 1.5 13.0879M1 18.0879V13.0879H6" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "StoryIcon",

    props: {
        color: {
            type: String,
            required: false,
            default: '#9FD6BD'
        }
    },
}
</script>

<style scoped>

</style>

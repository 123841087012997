<template>
        <div class="input-layout"
             :class="{
                'input-layout__focus': focus,
                'input-layout__complete': complete && !error,
                'input-layout__error': error,
             }"
             @focusin="focus = true"
             @focusout="focus = false"
        >
            <div class="input-layout__left">

                <whatsapp-icon v-if="iconName === 'whatsapp'" :color="iconColor"/>
                <instagram-icon v-if="iconName === 'instagram'" :color="iconColor"/>
                <telegram-icon v-if="iconName === 'telegram'" :color="iconColor"/>

                <!--DefaultInput-->
                <input v-if="type === 'text'" type="text" :placeholder="placeholder" class="input-layout__field"
                       :value="inputValue"
                       @change="changeInputValue"
                       style="" :disabled="isDisable">

                <!--NumberInput-->
                <input v-if="type === 'number'" v-imask="numberMask" :placeholder="placeholder" class="input-layout__field"
                       :value="inputValue"
                       @change="changeInputValue"
                       style="">

                <!--DropdownInput-->
                <input v-if="type === 'dropdown'" type="text" :disabled="true" :value="inputValue" :placeholder="placeholder" class="input-layout__field"
                       style="">

            </div>

            <div class="input-layout__icons">
                <circle-check-icon v-if="complete && !error" class="input-layout__check-icon"/>
                <circle-error-icon v-else-if="error" class="input-layout__check-icon"/>
            </div>
            <div v-if="isSelect">
                <search-list-arrow-down
                    v-if="!showSelect"
                    style="margin-left: 9px"
                />
                <arrow-up v-else style="margin-left: 9px"/>
            </div>

        </div>
</template>

<script>

import {IMaskDirective} from 'vue-imask';
import CircleCheckIcon from "@/components/icons/CircleCheckIcon.vue";
import CircleErrorIcon from "@/components/icons/CircleErrorIcon.vue";
import WhatsappIcon from "@/components/icons/WhatsappIcon.vue";
import TelegramIcon from "@/components/icons/TelegramFooterIcon.vue";
import InstagramIcon from "@/components/icons/InstagramDoctorPageIcon.vue";
import SearchListArrowDown from "@/components/icons/SearchListArrowDown.vue";
import ArrowUp from "@/components/icons/ArrowUp.vue";

export default {
    name: 'InputLayout',
    components: {CircleErrorIcon, CircleCheckIcon, WhatsappIcon,TelegramIcon,InstagramIcon, SearchListArrowDown,ArrowUp},

    emits: ['update:inputValue'],

    props: {
        showSelect: {
            type: Boolean,
            default: false,
        },
        isSelect: {
            type: Boolean,
            default: false,
        },
        iconName: String,
        isDisable:{
            type: Boolean,
            default: false
        },
        inputValue: {
            required: true,
            default: ''
        },
        placeholder: {
            required: true,
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
            required: false,
            validator: (value) => {
                return ['text', 'number', 'dropdown'].includes(value);
            },
        },
        maxValue: {
            type: String,
            default: '000',
            required: false
        },
        complete: {
            required: false,
        },
        error: {
            required: false
        }
    },

    data() {
        return {
            focus: false,
            numberMask: {
                mask: this.maxValue,
                lazy: true,
            },
        }
    },

    computed: {
        iconColor() {
            if (this.complete) {
                return '#02B061'
            } else if (this.error) {
                return '#F93232'
            } else {
                return '#C0C4CD'
            }
        },
    },

    methods: {
        changeInputValue(event) {
            this.$emit('update:inputValue', event.target.value)
        },
    },

    directives: {
        imask: IMaskDirective
    },

}
</script>

<style scoped lang="scss">

.input-layout {
    background: #EDEFF3;
    border-radius: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #9195a4;
    padding: 0 15px 0 18px;
    border: 1px solid #EDEFF3;
    cursor: text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    column-gap: 5px;
    width: 100%;
    height: 46px;

    &__icons {
        cursor: pointer;
    }

    &__field {
        outline: none;
        border: none;
        background: transparent !important;
        padding: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #373A43;
        width: 100%;
        height: 46px;

        &:focus {
            outline: none;
            border: none;
        }
    }

    &:hover {
        border: 1px solid #DBE0EB;
    }

    &::placeholder {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #A4ACBD;
    }

    &__focus {
        border: 1px solid #02B061 !important;
    }

    &__complete {
        border: 1px solid #EAF2EF;
        background: #EAF2EF;
    }

    &__error {
        border: 1px solid #F93232 !important;
        background: #F9EDED;
    }

    &__left {
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
    }

    &__check-icon {

    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

</style>

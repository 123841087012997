<template>
  <div class="full-screen">
    <div class="form">
      <div class="close-parent">
        <font-awesome-icon
          icon="close"
          @click="this.$store.dispatch('changeLoginPopup', false)"
          color="#BDC3C9"
        ></font-awesome-icon>
      </div>
      <div v-if="type === 'user'" class="title">Авторизация пациента</div>
      <div v-else class="title">Авторизация доктора</div>
      <div class="inputes">
          <input-layout placeholder="Email" :input-value="user.email" @update:input-value="user.email = $event"/>
          <input-layout-password placeholder="Пароль" :input-value="user.password" @update:input-value="user.password = $event"/>
      </div>
      <div class="buttons">
        <button-helper class="login-button" color="#ffffff" @click="login" background="#02B061"
          >Войти</button-helper>
        <span @click="forget" class="forgot-password">
            Забыли пароль?
        </span>
      </div>
      <div class="register">
        <div class="register-title">Вы еще не зарегистрировались?</div>
        <div class="register-link" @click="registerFunc">Регистрация</div>
      </div>
      <div class="socilal">
        <div class="social-title">Или войдите на сайт через</div>
        <div class="social-icons">
          <div class="google">
            <google-icon/>
          </div>
          <div class="yandex">
            <yandex-icon/>
          </div>
          <div class="vk">
            <vk-circle-icon/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import validators from '@/config/validators'
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import InputLayoutPassword from "@/components/layout/inputs/InputLayoutPassword.vue";
import GoogleIcon from "@/components/icons/GoogleIcon.vue";
import YandexIcon from "@/components/icons/YandexIcon.vue";
import VkCircleIcon from "@/components/icons/VkCircleIcon.vue";

export default {
  name: 'LoginPopup',
  components: {VkCircleIcon, YandexIcon, GoogleIcon, InputLayoutPassword, InputLayout, ButtonHelper },

  data() {
    return {
      user: {},
      errors: []
    }
  },

  computed: {
    token() {
      return this.$store.getters.getToken
    },
    serverError() {
      return this.$store.getters.getServerError
    },
      type() {
         return this.$store.getters.getRegisterType;
      }
  },
  methods: {
      forget(){
          this.$store.dispatch('changeLoginPopup', false)
          this.$store.dispatch('changeForgetPopup', true)
      },
    registerFunc() {
      this.$store.dispatch('changeLoginPopup', false)
      this.$store.dispatch('changeRegisterPopup', true)
    },
    async login() {
      this.errors = validators.login(this.user)
      if (!this.errors.length) {
        await this.$store.dispatch('login', {'email': this.user.email, 'password': this.user.password, 'type': this.type})
        if (this.token) {
          localStorage.userToken = this.token
          this.$store.dispatch('changeLoginPopup', false)
          this.$router.push({ name: 'dashboard', params: { id: 1 } })
        }
      }
    }
  }
}
</script>

<style scoped>
.login-button {
  font-size: 12px;
  line-height: 14px;
  border: none !important;
  padding: 18px 28.5px;
}
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(238, 240, 244, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
.form {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 483px;
  height: 583px;
  background: #fff;
  box-shadow: 0 4px 100px hsl(0deg 0% 74% / 25%);
  border-radius: 20px;
  right: 0;
  bottom: 0;
}

.close-parent {
  width: 11px;
  height: 11px;
  float: right;
  margin: 15px;
  cursor: pointer;
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #373a43;
  margin-top: 40px;
}
.inputes {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 330px;
  margin: 50px auto 20px auto;
}

.input {
  width: 330px;
  height: 46px;
  margin-top: 30px;
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding-left: 20px;
  color: #373A43;
  outline: none;
}

.input:focus {
  outline: none;
}

.buttons {
  width: 330px;
  margin: 20px auto;
  text-align: center;
}

.forgot-password {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #656873;
  margin-left: 40px;
}

.register {
  margin-top: 40px;
}

.register-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #373a43;
  margin-bottom: 7px;
}

.register-link {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
  color: #24ad6f;
}

.socilal {
  margin-top: 40px;
  text-align: center;
}

.social-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #656873;
}

.google {
  width: 36px;
  height: 36px;
  background: #4086f4;
  margin: 5px;
  font-size: 11px;
  border-radius: 50%;
  line-height: 36px;
  cursor: pointer;
}

.vk {
  width: 36px;
  height: 36px;
  font-size: 11px;
  background: #0078ff;
  border-radius: 50%;
  margin: 5px;
  line-height: 36px;
  cursor: pointer;
}

.yandex {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 11px;
  background: #fc3f1d;
  margin: 5px;
  line-height: 36px;
  cursor: pointer;
}

.social-icons {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}
@media screen and (max-width: 769px) {
  .form {
    width: 100%;
    max-width: 400px;
    height: 500px;
    max-height: 90vh;
  }
  .login-button {
    padding: 0px 28.5px;
    height: 40px;
  }
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 32px;
  }
  .close-parent {
    margin-bottom: 0px;
  }
  .inputes {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 11px auto 20px auto;
  }

  .input {
    max-width: 95%;
    margin-top: 15px;
    height: 34px;
    border-radius: 5px;
  }
  .buttons {
    margin-top: 26px;
  }
  .register {
    margin-top: 49px;
  }
  .google svg {
    height: 30px;
    width: 30px;
  }
  .yandex svg {
    height: 30px;
    width: 30px;
  }
  .vk svg {
    height: 30px;
    width: 30px;
  }
  .google {
    height: 30px;
    width: 30px;
    display: flex;
  }
  .yandex {
    height: 30px;
    width: 30px;
    display: flex;
  }
  .vk {
    height: 30px;
    width: 30px;
    display: flex;
  }
}
</style>

<template>
  <div class="doctor-dashboard-settings" v-if="user && user.user_detales">

      <div class="doctor-settings-items">
          <div class="form-group">
              <label for="exampleInputEmail1" class="label">Имя</label>
              <div class="form-input">
                  <input-layout
                      :input-value="user.first_name"
                      placeholder="Имя"
                      :complete="user.first_name"
                      @update:input-value="user.first_name = $event"
                  />
              </div>
          </div>
          <div class="form-group">
              <label for="exampleInputEmail1" class="label">Фамилия</label>
              <div class="form-input">
                  <input-layout
                      :input-value="user.last_name"
                      placeholder="Фамилия"
                      :complete="user.last_name"
                      @update:input-value="user.last_name = $event"
                  />
              </div>
          </div>
          <div class="form-group">
              <label for="exampleInputEmail1" class="label">Отчество</label>
              <div class="form-input">
                  <input-layout
                      :input-value="user.father_name"
                      placeholder="Отчество"
                      :complete="user.father_name"
                      @update:input-value="user.father_name = $event"
                  />
              </div>
          </div>

          <div class="form-group">
              <label for="" class="label">E-mail</label>
              <div class="form-input">
                  <input-layout
                      :input-value="user.email"
                      placeholder="E-mail"
                      :complete="user.email"
                      @update:input-value="user.email = $event"
                      :is-disable="true"
                  />
              </div>
          </div>

          <div class="form-group">
              <label for="" class="label">Пол</label>
              <div class="form-input">
                  <input-layout
                      :input-value="user.user_detales.gender"
                      placeholder="Пол"
                      :complete="user.user_detales.gender"
                      type="dropdown"
                      @click="chooseGender = !chooseGender"
                  />
              </div>

              <drop-down style="height: 100px;" v-if="chooseGender" :items="['Мужской', 'Женский']" @closeDrop="chooseGender = false" @dropChosen="user.user_detales.gender = $event; chooseGender = !chooseGender"/>
          </div>



          <div class="form-group">
              <label for="" class="label">Возраст</label>
              <div class="form-input">
                  <input-layout
                      :input-value="user.user_detales.age"
                      placeholder="Укажите возраст"
                      :complete="user.user_detales.age"
                      type="number"
                      @update:input-value="user.user_detales.age = $event"
                  />
              </div>
          </div>



          <div class="form-group">
              <label for="" class="label">Город</label>
              <div class="form-input">
                  <input-layout
                      :input-value="user.user_detales.city"
                      :placeholder="user.user_detales.city ? user.user_detales.city : 'Укажите город'"
                      :complete="user.user_detales.city"
                      type="dropdown"
                      @click="showSelectCity = true"
                  />
              </div>
          </div>


          <div class="form-group">
              <label for="" class="label">Телефон</label>
              <div class="form-input">
                  <input-layout-phone
                      :input-value="user.phone"
                      placeholder="Укажите ваш телефон"
                      :complete="user.phone"
                      @update:input-value="user.phone = $event"
                  />
              </div>
              <div class="info-phone">
                  <info-icon/>
                  Телефон не будет виден пациентам и будет использоваться только для отправки уведомлений
              </div>
          </div>
      </div>

        <div class="form-group">
          <label for="exampleInputEmail1" class="label"/>
          <button-helper
              color="#ffffff"
              background="#02B061"
              style="height: 50px;width: 156px;"
              @click="update"
          >
            <check-button-docs color="#ffffff" style="margin-right: 5px"/>
            Сохранить
          </button-helper>
        </div>
        <SelectCity v-if="showSelectCity" @changeSortCity="selectCity($event)" @closeModal="showSelectCity = false"/>
  </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import CheckButtonDocs from '@/components/icons/CheckButtonDocs.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import DropDown from "@/components/layout/Dropdown.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import InputLayoutPhone from "@/components/layout/inputs/InputLayoutPhone.vue";
import SelectCity from "@/components/layout/SelectCity.vue";

export default {
  name: 'DoctorDashboardSettings',
  components: {InputLayoutPhone, InputLayout, DropDown, InfoIcon, CheckButtonDocs, ButtonHelper, SelectCity },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    name() {
      if (this.user.first_name && this.user.last_name && this.user.patronymic) {
        return this.user.first_name + ' ' + this.user.last_name + ' ' + this.user.patronymic
      } else if (this.user.first_name && this.user.last_name) {
        return this.user.first_name + ' ' + this.user.last_name
      } else if (this.user.first_name && this.user.patronymic) {
        return this.user.first_name + ' ' + this.user.patronymic
      } else {
        return this.user.first_name
      }
    },
  },
  data() {
    return {
      pass: '',
      error: [],
      chooseGender: false,
      showSelectCity: false,
    }
  },
  methods: {
    selectCity($event){
      this.user.user_detales.city = $event;
      this.showSelectCity = false;
    },
    async update() {
      let user = {
        id: this.user.id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        father_name: this.user.father_name,
        login: this.user.login,
        age: this.user.user_detales.age,
        // pass: this.pass,
        gender: this.user.user_detales.gender,
        phone: this.user.phone,
        city: this.user.user_detales.city
        // email: this.user.email,
      }
      if (!this.error.length) {
        await this.$store.dispatch('updateUserDetailes', user)
      } else {
        console.log(this.error)
      }
    }
  },

}
</script>

<style scoped lang="scss">
.doctor-dashboard-settings {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    width: 100%;

    @media screen and (max-width: 969px) {
        align-items: center;
    }
}

.doctor-settings-items {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 60px;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 1260px) {
        flex-direction: column;
    }
}

.form-control {
  background: #edeff3;
  border-radius: 10px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #9195a4;
  height: 46px;
  width: 450px;

  @media screen and (max-width: 1260px) {
      width: 100%;
  }
}
.form-control:focus {
  outline: none;
  background: #eaf2ef;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #373a43;
}
.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 9px;
  color: #373a43;
}

.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.form-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 450px;

    @media screen and (max-width: 1260px) {
        width: 100%;
    }
}

.check-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.icon-r {
  right: -40px !important;
}
.info-phone {
  margin-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #7f818a;
  padding-right: 20px;
  max-width: 450px;

    @media screen and (max-width: 1260px) {
        max-width: 100%;
    }
}
</style>

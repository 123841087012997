<template>
  <div class="container header-3">
    <div class="row header-with-search mar-mobile-20" style="position: relative;">
      <div class="doctors">
        <div class="row head-text-doctor">Связи врачей</div>
        <div class="head-text-search" style="margin-left: -12px" :class="{'change-content-left': admin}">

            <div v-if="!showChangeTitle">
                {{ homeContent.first_block?.annotation_header }}
            </div>

            <change-home-content-helper
                v-if="admin"
                :edit-button="showChangeTitle"
                :first-input="homeContent.header_title"
                @update:firstInput="homeContent.header_title = $event"
                @changeEditButton="showChangeTitle = $event"
                @updateHomeContent="updateHomeContent"
            />
        </div>

      </div>
      <div class="location mar-mobile-20">
        <div class="location-button" @click="location = !location">
          <location-icon />
          <span class="geo">{{ userCity }}</span>
        </div>
        <location-question v-if="location" @close="location = !location" />
      </div>

      <div class="cabinet mar-mobile-20" style="text-align: right">
        <div class="user-header-cabinet" v-if="authUser">
            <div class="login-button" @click="openUserDrop">
                <span class="user-login">{{ user?.email }}</span>
                <header-name-arrow-down class="user-login-icon" :class="{user_drop}"/>
            </div>
            <user-menu-popup v-if="user_drop" @closeAuth="logout"/>
        </div>

        <div class="login-button" v-else>
          <default-button
            :outline="true"
            color="#24AD6F"
            class="mobile-btn-34"
            background="#EDEFF3"
            @click="openAuth"
            >Личный кабинет</default-button
          >
          <div class="auth" v-if="loginDrop">
            <div class="auth-close-parent">
              <font-awesome-icon
                icon="close"
                @click="closePopUp"
                color="#A4ACBD"
              ></font-awesome-icon>
            </div>
            <div class="auth-choose choose-underline">
              <div class="auth-name">Пациент</div>
              <div class="auth-description">Осуществляйте поиск среди рекомендованных врачей</div>
              <div class="auth-log">
                <default-button
                  :outline="true"
                  color="#24AD6F"
                  @click="openPopUp('user')"
                  background="#ffffff"
                  >Войти</default-button
                >
              </div>
            </div>
            <div class="auth-choose">
              <div class="auth-name">Врач</div>
              <div class="auth-description">
                Создайте свою анкету, расскажите о себе и своих услугах, делитесь рекомендациями о
                других врачах
              </div>
              <div class="auth-log">
                <default-button
                  :outline="true"
                  @click="openPopUp('doctor')"
                  color="#24AD6F"
                  background="#ffffff"
                  >Войти</default-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationQuestion from '@/components/helppers/LocationQuestion'
import DefaultButton from '@/components/helppers/DefaultButton'
import ChangeHomeContentHelper from "@/components/helppers/ChangeHomeContentHelper.vue";
import HeaderNameArrowDown from "@/components/icons/HeaderNameArrowDown.vue";
import UserMenuPopup from "@/components/layout/UserMenuPopup.vue";

export default {
  name: 'HomeHeader',
  components: {
      ChangeHomeContentHelper,
    DefaultButton,
    LocationQuestion,
    HeaderNameArrowDown,
    UserMenuPopup,
  },
  props: {
    loginDrop: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      specName: 'Выберите Доктора ',
      special: '',
      drop: false,
      location: false,
      city: 'Москва',
        showChangeTitle: false,
        user_drop: false,
      authUser: false
    }
  },
  computed: {
    specialities() {
      return this.$store.getters.getAllSpecialities
    },
    user() {
        return this.$store.getters.getUser;
    },
      homeContent() {
          return this.$store.getters.getHomeContent
      },
    userCity(){
        let user = this.user
        let city = this.city
        if(user?.user_detales){
            city = user?.user_detales?.city?user?.user_detales?.city:'Москва'
        }else{
            this.getUserCity();
            city = this.city;
        }
        return city
    },
      admin() {
          return this.showEditHomeContent && (this.user.role_id === 1 || this.user.role_id === 2)
      },
      showEditHomeContent() {
          return this.$store.getters.getShowEditHomeContent
      },
  },
  methods: {
    openUserDrop() {
            if (!this.user_drop) {
                setTimeout(() => {
                    document.addEventListener('click', this.closeUserDrop)
                })
            } else {
                setTimeout(() => {
                    document.removeEventListener('click', this.closeUserDrop)
                }, 100)
            }
            this.user_drop = !this.user_drop
    },
    closeUserDrop(e){
            if (!e.target.closest('.auth') && this.user_drop) {
                this.user_drop = false
                setTimeout(() => {
                    document.removeEventListener('click', this.closeUserDrop)
                }, 100)
            }
    },
    logout() {
      this.user_drop = !this.user_drop;
      this.authUser = false;
    },
    openPopUp(type) {
      this.closePopUp()
      this.$store.dispatch('changeLoginPopup', true)
      this.$store.dispatch('changeRegisterType', type)
    },
    openAuth() {
      if (!this.loginDrop) {
        this.$emit('newLogin', true)
        setTimeout(() => {
          document.addEventListener('click', this.closeAuth)
        })
      }
    },
    closeAuth(e) {
      if (!e.target.closest('.auth') && this.loginDrop) {
        this.closePopUp()
      }
    },
    closePopUp() {
      this.$emit('newLogin', false)
      document.removeEventListener('click', this.closeAuth)
    },
    getUserCity(){
      this.$store.dispatch('getUserCity');
      this.city = this.$store.getters.getUserCity ? this.$store.getters.getUserCity : 'Москва';
    },
      updateHomeContent() {
          this.$store.dispatch('updateHomeContent', this.homeContent)
      }

  },
  mounted(){
    this.authUser = !!localStorage.userToken;
  }
}
</script>

<style scoped>
.login-button .user-login-icon {
    transition: .2s;
    transform: rotateX(0);
}
.user-login {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #373A43;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch;
}
.container {
  margin: 5px auto;
}

.change-content-left {
    position: relative;
    border: 1px dashed #C0C4CD;
    border-radius: 10px;
    padding: 5px 5px 5px 25px;
}

.header-3 {
  max-width: 1597px !important;
}

.doctors {
  margin-right: 249px;
  width: auto;
}
.location {
  display: flex;
  width: auto;
  position: relative;
}

.location-button {
  display: flex;
  align-items: center;
  width: auto;
  gap: 11px;
  cursor: pointer;
  position: relative;
}
.cabinet {
  margin-left: auto;
  margin-right: -12px;
  width: auto;
}
.cabinet .login-button .mobile-btn-34 {
  width: 152px;
  height: 46px;
}
.head-text-doctor {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 3px;
  color: #373a43;
}
.head-text-search {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #7f818a;
}
.search-div {
  margin-top: 10px;
}
.search-div span {
  color: #373a43;
  margin-left: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
}
.login-button {
  position: relative;
  cursor: pointer;
}
.auth {
  position: absolute;
  width: 405px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
  border-radius: 20px;
  left: -251px;
  top: 50px;
  z-index: 99999;
  padding: 20px;
}

.auth-close-parent {
  text-align: right;
  position: absolute;
  right: 14px;
  top: 15px;
  cursor: pointer;
}
.auth-choose {
  text-align: left;
  padding: 20px;
}
.auth-name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #373a43;
}

.auth-description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #656873;
  margin-top: 5px;
}

.auth-log {
  margin-top: 15px;
}

.auth-log button {
  padding: 0;
  width: 67px;
  height: 40px;
}
.choose-underline {
  padding-top: 0 !important;
  border-bottom: 1px solid #dbe0eb;
  margin-top: 15px;
}
.geo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  padding-bottom: 2px;
  color: #373a43;
  border-bottom: 1px solid #dbe0eb;
}
@media screen and (max-width: 1330px) {
  .doctors{
    margin-right: 59px;
  }
  .head-text-doctor {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
  }
  .head-text-search {
    font-size: 12px;
    line-height: 14px;
  }
  .location-button {
    gap: 7px;
  }

  .geo {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 2px;
  }
  .header-with-search {
    justify-content: space-between;
  }
  .cabinet .login-button .mobile-btn-34 {
    height: 36px;
    width: 135px;
    font-size: 12px;
    padding: 8px 15px;
  }
}
@media screen and (max-width: 769px) {
  .auth {
    left: 1vw;
    width: 98vw;
  }
  .auth-log button {
    height: 30px;
  }
  .location {
    gap: 9px;
  }
  .head-text-doctor,
  .head-text-search {
    justify-content: center;
  }
  .mobile-center {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .location {
    margin-left: auto;
  }
  .mar-mobile-20 {
    margin-top: 20px;
  }
  .search-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-btn-34 {
    height: 34px !important;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    width: fit-content !important;
  }
  .cabinet {
    margin-right: 0;
    width: fit-content !important;
    margin-right: auto !important;
  }
  .doctors {
    margin-right: 0;
    width: 100%;
      margin-top: -21px;
  }
  .login-button {
    position: static !important;
  }
}
</style>

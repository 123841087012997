<template>
  <svg width="3" height="3" viewBox="0 0 2 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1" cy="1" r="1" fill="#9195A4" />
  </svg>
</template>

<script>
export default {
  name: 'EllipseIcon'
}
</script>

<style scoped></style>

<template>
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.0625 7.5H1.8125C1.36377 7.5 1 7.86377 1 8.3125V13.1875C1 13.6362 1.36377 14 1.8125 14H5.0625C5.51123 14 5.875 13.6362 5.875 13.1875V8.3125C5.875 7.86377 5.51123 7.5 5.0625 7.5Z" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.8125 1H11.5625C11.1138 1 10.75 1.36377 10.75 1.8125V13.1875C10.75 13.6362 11.1138 14 11.5625 14H14.8125C15.2612 14 15.625 13.6362 15.625 13.1875V1.8125C15.625 1.36377 15.2612 1 14.8125 1Z" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.8125 14H13.1875" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.9375 4.25H6.6875C6.23877 4.25 5.875 4.61377 5.875 5.0625V13.1875C5.875 13.6362 6.23877 14 6.6875 14H9.9375C10.3862 14 10.75 13.6362 10.75 13.1875V5.0625C10.75 4.61377 10.3862 4.25 9.9375 4.25Z" stroke="#A4ACBD" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AdminMobileStat"
}
</script>

<style scoped>

</style>

<template>
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.84375 9.00043L7.46222 10.7782L10.6992 7.22266"
      stroke="#02B061"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.27273 1C10.163 2.83696 12.63 3.79339 15.1512 3.66667C15.5183 5.03824 15.6306 6.47686 15.4815 7.89705C15.3324 9.31724 14.9249 10.69 14.2833 11.9338C13.6416 13.1776 12.7789 14.267 11.7463 15.1373C10.7138 16.0076 9.53254 16.6411 8.27273 17C7.01292 16.6411 5.83165 16.0076 4.79911 15.1373C3.76658 14.267 2.90385 13.1776 2.2622 11.9338C1.62054 10.69 1.21305 9.31724 1.06394 7.89705C0.914833 6.47686 1.02715 5.03824 1.39421 3.66667C3.91541 3.79339 6.38249 2.83696 8.27273 1"
      stroke="#02B061"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ShieldDoctorPageIcon'
}
</script>

<style scoped></style>

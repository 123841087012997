import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import instance from "@/utils/axios";
import './assets/font/font.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import {
  faWhatsapp,
  faTelegram,
  faInstagram,
  faVk,
  faOdnoklassniki,
  faGoogle,
  faYandex
} from '@fortawesome/free-brands-svg-icons'
import {
  faMapMarker,
  faShare,
  faSearch,
  faThLarge,
  faThList,
  faTurnUp,
  faTurnDown,
  faCommentDots,
  faStethoscope,
  faMapLocationDot,
  faShield,
  faInfo,
  faQuestion,
  faBell,
  faGear,
  faShareNodes,
  faPlay,
  faThumbsUp,
  faThumbsDown,
  faPlus,
  faTrash,
  faPerson,
  faHandSparkles,
  faBarChart,
  faRightLeft,
  faEdit,
  faHand,
  faArrowTrendUp,
  faArrowTrendDown,
  faFileLines,
  faCheck,
  faClose,
  faChevronRight,
  faChevronLeft,
  faPeopleGroup,
  faClockRotateLeft,
  faLocationDot,
  faList,
  faListDots,
  faCircleCheck,
  faCircleInfo,
  faEye,
  faEyeSlash,
  faCircleQuestion,
  faMagnifyingGlass,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons'
import icons from '@/components/icons/icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faMapMarker,
  faSearch,
  faThLarge,
  faThList,
  faHeart,
  faTurnUp,
  faTurnDown,
  faCommentDots,
  faStethoscope,
  faMapLocationDot,
  faShield,
  faWhatsapp,
  faTelegram,
  faInstagram,
  faInfo,
  faQuestion,
  faBell,
  faGear,
  faShareNodes,
  faPlay,
  faThumbsUp,
  faThumbsDown,
  faPlus,
  faTrash,
  faPerson,
  faHandSparkles,
  faBarChart,
  faRightLeft,
  faEdit,
  faHand,
  faArrowTrendUp,
  faArrowTrendDown,
  faFileLines,
  faCheck,
  faClose,
  faChevronRight,
  faChevronLeft,
  faPeopleGroup,
  faClockRotateLeft,
  faLocationDot,
  faVk,
  faOdnoklassniki,
  faList,
  faListDots,
  faGoogle,
  faYandex,
  faCircleCheck,
  faCircleInfo,
  faEye,
  faEyeSlash,
  faCircleQuestion,
  faMagnifyingGlass,
  faCaretDown,
  faShare
)

const app = createApp(App)

app.config.globalProperties.$axios = instance;
store.$axios = app.config.globalProperties.$axios
app
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(store)
  .use(router)
  .mount('#app')

icons.forEach((component) => {
  app.component(component.name, component)
})

<template>
    <div class="full-screen">
        <div class="form">
            <div class="close-parent">
                <font-awesome-icon
                    icon="close"
                    @click="this.$store.dispatch('changeRegisterPopup', false)"
                    color="#BDC3C9"
                ></font-awesome-icon>
            </div>
            <div class="title">Регистрация пациента</div>
            <div class="inputes">
                <input-layout placeholder="Email" :input-value="user.email" @update:input-value="user.email = $event"/>
                <input-layout-password
                    placeholder="Пароль"
                    :input-value="user.password"
                    @update:input-value="user.password = $event"
                    :complete="user.password && user.password.length > 5"
                    :error="user.password && user.password.length < 6 && user.password.length > 0"
                    :show-difficult="true"
                />
                <input-layout-password
                    placeholder="Повторите пароль"
                    :input-value="user.comfirm"
                    @update:input-value="user.comfirm = $event"
                    :complete="user.comfirm && user.comfirm.length > 5 && user.comfirm === user.password"
                    :error="user.comfirm && user.password.length < 6 && user.password.length > 0 || user.comfirm !== user.password"
                />
            </div>
            <div class="erroe" v-if="errorMessage !== ''">
                <font-awesome-icon icon="circle-info" color="#D83058"/>
                {{ errorMessage }}
            </div>
            <div class="buttons">
                <button-helper @click="register" color="#ffffff" background="#02B061">
                    Зарегистрироваться
                </button-helper>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonHelper from '@/components/helppers/DefaultButton.vue'
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import InputLayoutPassword from "@/components/layout/inputs/InputLayoutPassword.vue";

export default {
    name: 'RegisterPopup',
    components: {InputLayoutPassword, InputLayout, ButtonHelper},
    data() {
        return {
            user: {},
            errorMessage: ''
        }
    },
    methods: {
        async register() {
            if (!this.user.email || this.user.email.length < 4) {
                this.errorMessage = 'Вы не выбрали email'
            } else if (!this.user.password || this?.user.password.length < 6) {
                this.errorMessage = 'Пароль должен быть не менее 6 символов'
            } else if (!this.user.comfirm || this?.user.password != this.user.comfirm) {
                this.errorMessage = 'Пароли не совпадают'
            } else {
                this.user.password_confirmed = this.user.comfirm
                await this.$store.dispatch('registerUser', this.user)
                    .then((response) => {
                        localStorage.userToken = response.data.token
                        this.$store.dispatch('setToken', response.data.token)
                    })
                    .then(() => {
                        this.$store.dispatch('changeRegisterPopup', false);
                        this.$store.dispatch('changeLoginPopup', false)
                        this.$router.push({name: 'dashboard', params: {id: 3}})
                    }).catch((err) => {
                        console.log(err);
                        this.$store.dispatch('setServerError', err.response.data.errors)
                    })


            }
        }
    }
}
</script>

<style scoped>
.full-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(238, 240, 244, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

.form {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    width: 450px;
    height: 466px;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(189, 189, 189, 0.7);
    border-radius: 20px;
    right: 0;
    bottom: 0;
}

.close-parent {
    width: 11px;
    height: 11px;
    float: right;
    margin: 15px;
    cursor: pointer;
}

.title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #373a43;
    margin-top: 40px;
}

.inputes {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 330px;
    margin: 50px auto 20px auto;
}

.input {
    width: 330px;
    height: 46px;
    margin-top: 30px;
    background: #edeff3;
    border-radius: 10px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    padding-left: 5px;
    color: #7f818a;
    outline: none;
}

.input:focus {
    outline: none;
}

.buttons {
    width: 330px;
    margin: 20px auto;
    text-align: center;
}

.erroe {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #d83058;
    text-align: center;
    background: #f9edf0;
    border-radius: 10px;
    width: 350px;
    height: 61px;
    margin: auto;
    line-height: 61px;
}

@media screen and (max-width: 769px) {
    .form {
        width: 100%;
        max-width: 400px;
        height: max-content;
        top: unset;
        bottom: 20px;
    }

    .close-parent {
        margin-bottom: 0px;
    }

    .inputes {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px auto 20px auto;
    }

    .input {
        max-width: 95%;
        margin-top: 15px;
        padding-left: 18px;
        height: 34px;
        border-radius: 5px;
    }

    .title {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-top: 29px;
        margin-bottom: 13px;
    }

    .buttons {
        margin-top: 31px;
    }
}
</style>

const getDefaultState = () => {
  return {
    loginPopup: false,
    registerPopup: false,
    selectCityPopup: false,
    forgetPopup: false,
    connectPopup: false,
    registertype: '',
    subscribePopup: false,
    subscribeType: '',
    subscribeInfo: {},
    verified: {},
    social: false,
    talant: false,
    showEducation: false,
    education: null,
    course: null,
    showCourse: false,
    delete: false,
    job: null,
    showJob: false,
    info: false,
    deleteUser:false,
    blockUser:false,
    createSub:false,
    special: false,
    editRecommendation: false,
    editRecommendationType: 0,
  }
}
export default {
  state: {
    loginPopup: false,
    registerPopup: false,
    selectCityPopup: false,
    forgetPopup: false,
    connectPopup: false,
    registertype: '',
    subscribePopup: false,
    subscribeType: '',
    subscribeInfo: {},
    verified: {},
    social: false,
    talant: false,
    education: null,
    showEducation: false,
    course: null,
    showCourse: false,
    delete: false,
    job: null,
    showJob: false,
    recommendationUnAuth: false,
    info: false,
    deleteUser:false,
    blockUser:false,
    createSub:false,
    special: false,
    editRecommendation: false,
    editRecommendationInfo: {},
    editRecommendationType: 0,
  },

  getters: {
    getRecommendationNotAuthPopup(state){
      return state.recommendationUnAuth
    },
    getloginPopup(state) {
      return state.loginPopup
    },
    getRegisterPopup(state) {
      return state.registerPopup
    },
    getSelectCityPopup(state) {
      return state.selectCityPopup
    },
    getForgetPopup(state) {
      return state.forgetPopup
    },
    getConnectPopup(state) {
      return state.connectPopup
    },
    getRegisterType(state) {
      return state.registerType
    },
    getsubscribePopup(state) {
      return state.subscribePopup
    },
    getSubscribeType(state) {
      return state.subscribeType
    },
    getSubscribeInfo(state) {
      return state.subscribeInfo
    },
    getVerified(state) {
      return state.verified
    },
    getSocial(state) {
      return state.social
    },
    getTalant(state) {
      return state.talant
    },
    getShowEducation(state) {
      return state.showEducation
    },
    getEducation(state) {
      return state.education
    },
    getCourse(state) {
      return state.course
    },
    getSpecial(state) {
      return state.special;
    },
    getShowCourse(state) {
      return state.showCourse
    },
    getDelete(state) {
      return state.delete
    },
    getJob(state) {
      return state.job
    },
   
    getShowJob(state) {
      return state.showJob
    },
    getInfo(state) {
      return state.info
    },

    getDeleteUser(state) {
      return state.deleteUser
    },

    getBlockUser(state){
      return state.blockUser
    },
    getCraeteSub(state){
      return state.createSub
    },
    getEditRecommendation(state){
      return state.editRecommendation
    },

    getEditRecommendationInfo(state){
      return state.editRecommendationInfo
    },

    getEditRecommendationType(state){
      return state.editRecommendationType
    },
  },

  mutations: {
    resetPersonal(state) {
      state.education = null
      state.course = null
      state.job = null
    },
    setRecommendationNotAuthPopup(state, payload){
      state.recommendationUnAuth = payload;
    },
    setLoginPopup(state, loginPopup) {
      state.loginPopup = loginPopup
    },

    setRegisterPopup(state, registerPopup) {
      state.registerPopup = registerPopup
    },

    setSelectCityPopup(state, selectCityPopup) {
      state.selectCityPopup = selectCityPopup
    },

    setForgetPopup(state, forgetPopup) {
      state.forgetPopup = forgetPopup
    },

    setConnectPopup(state, connectPopup) {
      state.connectPopup = connectPopup
    },

    setRegisterType(state, registerType) {
      state.registerType = registerType
    },

    setsubscribePopup(state, subscribe) {
      state.subscribePopup = subscribe
    },

    setSubscribeType(state, subscribeType) {
      state.subscribeType = subscribeType
    },
    setSubscribeInfo(state, subscribeInfo) {
      state.subscribeInfo = subscribeInfo
    },
    setVerified(state, verified) {
      state.verified = verified
    },

    setSocial(state, social) {
      state.social = social
    },

    setTalant(state, talant) {
      state.talant = talant
    },

    setShowEducation(state, showEducation) {
      state.showEducation = showEducation
    },

    setEducation(state, education) {
      state.education = education
    },

    setSpecial(state, special) {
      state.special = special;
    },

    setCourse(state, course) {
      state.course = course
    },
    setShowCourse(state, showCourse) {
      state.showCourse = showCourse
    },

    setDelete(state, del) {
      state.delete = del
    },

    setJob(state, job) {
      state.job = job
    },
    
    setShowJob(state, showJob) {
      state.showJob = showJob
    },

    setInfo(state, info) {
      state.info = info
    },

    setDeleteUser(state, info) {
      state.deleteUser = info
    },

    setBlockUser(state,info){
      state.blockUser = info
    },

    setCreateSub(state,info){
      state.createSub = info
    },

    setEditRecommendation(state,info){
      state.editRecommendation = info
    },

    setEditRecommendationInfo(state, doctor){
      state.editRecommendationInfo = doctor
    },

    setRecommendationDoctorType(state, type){
      state.editRecommendationType = type
    },
  },

  actions: {
    changeLoginPopup({ commit }, loginPopup) {
      commit('setLoginPopup', loginPopup)
    },

    changeRegisterPopup({ commit }, registerPopup) {
      if(registerPopup){
        document.documentElement.classList.add('lock')
      }else{
        document.documentElement.classList.remove('lock')
      }
      commit('setRegisterPopup', registerPopup)
    },

    changeSelectCityPopup({ commit }, selectCityPopup) {
      commit('setSelectCityPopup', selectCityPopup)
    },

    changeForgetPopup({ commit }, forgetPopup) {
      commit('setForgetPopup', forgetPopup)
    },

    changeConnectPopup({ commit }, connectPopup) {
      commit('setConnectPopup', connectPopup)
    },

    changeRegisterType({ commit }, registerType) {
      commit('setRegisterType', registerType)
    },

    changeSubscribPopup({ commit }, subscribe) {
      commit('setsubscribePopup', subscribe)
    },

    changeSubscribType({ commit }, subscribeType) {
      commit('setSubscribeType', subscribeType)
    },
    changeSubscribInfo({ commit }, subscribeInfo) {
      commit('setSubscribeInfo', subscribeInfo)
    },
    changeVerified({ commit }, verified) {
      commit('setVerified', verified)
    },
    changeSocial({ commit }, social) {
      commit('setSocial', social)
    },
    changeTalant({ commit }, talant) {
      commit('setTalant', talant)
    },
    changeSpecial({ commit }, special) {
      commit('setSpecial', special)
    },
    changeEducation({ commit }, education) {
      commit('setEducation', education)
    },

    showEducation({ commit }, showEducation) {
      commit('setShowEducation', showEducation)
    },
    changeCourse({ commit }, course) {
      commit('setCourse', course)
    },
    showCourse({ commit }, showCourse) {
      commit('setShowCourse', showCourse)
    },
    changeDelete({ commit }, del) {
      commit('setDelete', del)
    },
    changeJob({ commit }, job) {
      commit('setJob', job)
    },

    showJob({ commit }, showJob) {
      commit('setShowJob', showJob)
    },

    changeInfo({ commit }, info) {
      commit('setInfo', info)
    },
    closePopUp({ state }) {
      document.documentElement.classList.remove('lock')
      Object.assign(state, getDefaultState())
    },

    changeDeleteUser({commit},info){
      commit('setDeleteUser', info)
    },

    changeBlockUser({commit},info){
      commit('setBlockUser', info)
    },

    changeCreateSub({commit},info){
      commit('setCreateSub',info)
    },

    resetPersonal({commit},info){
      commit('resetPersonal',info)
    },

    editRecommendationDoctor({commit},info){
      commit('setEditRecommendation', info)
    },
    editRecommendationDoctorInfo({commit}, doctor){
      commit('setEditRecommendationInfo', doctor)
    },
    editRecommendationDoctorType({commit}, type){
      commit('setRecommendationDoctorType', type)
    },
  }
}

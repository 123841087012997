<template>
    <div class="user-layout"
         :class="{'no-container': !isContainer, 'admin-layout': login && admin, 'home-admin-layout': isHomeAdminLayout}">

        <user-header v-if="isHeader"/>

        <admin-left-menu v-if="!mobile && login && admin"/>
        <mobile-fixed-menu :role="user?.role_id" v-if="mobile && login && isMenu" :menu="menu"/>

        <slot> <!--CONTENT--> </slot>

        <user-footer v-if="!mobile && isFooter"/>
        <user-footer-mobile v-else-if="isFooter"/>

    </div>

</template>

<script>

import UserFooter from "@/components/layout/UserFooter.vue";
import UserFooterMobile from "@/components/layout/UserFooterMobile.vue";
import UserHeader from "@/components/layout/UserHeader.vue";
import MobileFixedMenu from "@/components/layout/MobileFixedMenu.vue";
import config from "@/config/config";
import AdminLeftMenu from "@/components/layout/AdminLeftMenu.vue";

export default {
    name: 'UserLayout',

    components: {
        AdminLeftMenu,
        MobileFixedMenu,
        UserHeader,
        UserFooterMobile,
        UserFooter
    },

    props: {
        isFooter: {
            type: Boolean,
            required: false,
            default: true
        },

        isHeader: {
            type: Boolean,
            required: false,
            default: true
        },

        isMenu: {
            type: Boolean,
            required: false,
            default: true
        },

        isContainer: {
            type: Boolean,
            required: false,
            default: true
        },

        isHomeAdminLayout: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {

        user() {
            return this.$store.getters.getUser
        },

        admin() {
            return this.user.role_id === 1 || this.user.role_id === 2
        },

        login() {
            return !!localStorage.userToken
        },

        menu() {
            switch (this.user.role_id) {
                case 1 || 2:
                    return config.ADMIN_MANU_ITEMS
                case 3:
                    return config.DOCTOR_MANU_ITEM
                default:
                    return config.USER_MANU_ITEM
            }
        },

        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        }
    }
}
</script>

<style scoped lang="scss">

.user-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1637px;
    padding: 0 20px;
    margin: 0 auto;
    min-height: 100vh;

    @media (max-width: 769px) {
        align-items: center;
        text-align: center;
    }
}

.no-container {
    max-width: 100% !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
}

.admin-layout {
    max-width: 1697px;
    padding: 0 20px 0 80px;


    @media screen and (max-width: 1080px) {
        max-width: 1687px;
        padding: 0 20px 0 70px;
    }

    @media screen and (max-width: 769px) {
        max-width: 1637px;
        padding: 0 20px;
    }
}

.home-admin-layout {
    @media screen and (max-width: 1700px) {
        padding-left: 60px !important;
    }
    @media screen and (max-width: 1080px) {
        padding-left: 50px !important;
    }
    @media screen and (max-width: 769px) {
        padding-left: 0 !important;
    }
}

</style>

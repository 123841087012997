<template>
  <div class="admin-left-menu desktop-only">
    <div v-for="page in pages" :key="page.id">
        <router-link v-if="page.icon" :to="{ name: 'dashboard', params: { id: page.id } }">
          <div v-if="page.id == 1">
            <admin-active-icon style="position: absolute;left: 0;" v-if="pageId == 1"/>
            <stat-icon color="#ffffff" v-if="pageId == 1"/>
            <stat-icon color="#9FD6BD" v-else/>
          </div>
          <div v-else-if="page.id == 2">
            <admin-active-icon style="position: absolute;left: 0;" v-if="pageId == 2"/>
            <catalog-icon color="#ffffff" v-if="pageId == 2"/>
            <catalog-icon color="#9FD6BD" v-else/>
          </div>
          <div v-else-if="page.id == 3">
            <admin-active-icon style="position: absolute;left: 0;" v-if="pageId == 3"/>
            <like-icon color="#ffffff" v-if="pageId == 3"/>
            <like-icon color="#9FD6BD" v-else/>
          </div>
          <div v-else-if="page.id == 4">
            <admin-active-icon style="position: absolute;left: 0;" v-if="pageId == 4"/>
            <people-icon color="#ffffff" v-if="pageId == 4"/>
            <people-icon color="#9FD6BD" v-else/>
          </div>
          <div v-else-if="page.id == 5">
            <admin-active-icon style="position: absolute;left: 0;" v-if="pageId == 5"/>
            <notification-icon color="#ffffff" v-if="pageId == 5"/>
            <notification-icon color="#9FD6BD" v-else/>
          </div>
          <div v-else-if="page.id == 6">
            <admin-active-icon style="position: absolute;left: 0;" v-if="pageId == 6"/>
            <story-icon color="#ffffff" v-if="pageId == 6"/>
            <story-icon color="#9FD6BD" v-else/>
          </div>
          <div v-else-if="page.id == 7">
            <admin-active-icon style="position: absolute;left: 0;" v-if="pageId == 7"/>
            <settings-icon color="#ffffff" v-if="pageId == 7"/>
            <settings-icon color="#9FD6BD" v-else/>
          </div>
          <div v-else-if="page.id == 8">
            <admin-active-icon style="position: absolute;left: 0" v-if="pageId == 8"/>
            <change-icon color="#ffffff" v-if="pageId == 8"/>
            <change-icon color="#9FD6BD" v-else/>
          </div>
        </router-link>
    </div>
  </div>
</template>

<script>

import config from '@/config/config'
import AdminActiveIcon from "@/components/icons/AdminActiveIcon.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import StoryIcon from "@/components/icons/StoryIcon.vue";
import NotificationIcon from "@/components/icons/NotificationIcon.vue";
import PeopleIcon from "@/components/icons/PeopleIcon.vue";
import LikeIcon from "@/components/icons/LikeIcon.vue";
import StatIcon from "@/components/icons/StatIcon.vue";
import CatalogIcon from "@/components/icons/CatalogIcon.vue";
import ChangeIcon from "@/components/icons/ChangeIcon.vue";

export default {
  name: 'AdminLeftMenu',

  components: {
      ChangeIcon,
    StatIcon,
    CatalogIcon,
    LikeIcon,
    PeopleIcon,
    NotificationIcon,
    StoryIcon,
    SettingsIcon,
    AdminActiveIcon,
  },
  computed:   {
    pages() {
      return config.ADMIN_MANU_ITEMS
    },
    pageId() {
      return this.$route.params.id
    },
      showEditHomeContent() {
          return this.$store.getters.getShowEditHomeContent
      }
  },

    methods: {
        changeShowEditHomeContent(item) {
            this.$router.push({name: 'home'})
            this.$store.commit('setShowEditHomeContent', item)
        }
    }
}
</script>

<style scoped lang="scss">
.admin-left-menu {
  width: 60px;
  height: 100%;
  background-color: #02b061;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
  padding: 30px 0;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 20px;
  z-index: 120;

  @media screen and (max-width: 1080px) {
      width: 50px;
  }
}

</style>

<template>
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.49181 9.926C8.83631 9.926 9.92624 8.83607 9.92624 7.49156C9.92624 6.14706 8.83631 5.05713 7.49181 5.05713C6.14731 5.05713 5.05737 6.14706 5.05737 7.49156C5.05737 8.83607 6.14731 9.926 7.49181 9.926Z"
      stroke="#02B061"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0824 12.0821L8.63926 15.5252C8.33494 15.8292 7.92239 16 7.49224 16C7.06208 16 6.64953 15.8292 6.34521 15.5252L2.9013 12.0821C1.99343 11.1742 1.37518 10.0175 1.12472 8.7582C0.874256 7.49891 1.00283 6.19364 1.4942 5.00744C1.98556 3.82123 2.81763 2.80736 3.8852 2.09405C4.95277 1.38073 6.20788 1 7.49183 1C8.77578 1 10.0309 1.38073 11.0985 2.09405C12.166 2.80736 12.9981 3.82123 13.4895 5.00744C13.9808 6.19364 14.1094 7.49891 13.8589 8.7582C13.6085 10.0175 12.9902 11.1742 12.0824 12.0821V12.0821Z"
      stroke="#02B061"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LocationIcon'
}
</script>

<style scoped></style>

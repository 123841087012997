<template>
  <div class="admin-verified">
    <div class="row row-underline">
      <div class="col-md-1 col-underline" @click="archive = false">
        Последние
        <span :class="!archive ? 'paint' : ''"></span>
      </div>
      <div class="col-md-1 col-underline"  @click="archive = true">
        Архив
        <span :class="archive ? 'paint' : ''"></span>
      </div>
    </div>
    <div class="last" v-if="archive == false">
      <verified-doctor @id-selected="handleIdSelected" v-for="user in users" :user="user" :key="user.id"/>
    </div>
    <verifide-archive v-else :type="true" :users="archiveUsers"/>
  </div>
</template>

<script>
import VerifiedDoctor from '@/components/admin/VerifiedDoctor.vue'
import VerifideArchive from '@/components/admin/VerifideArchive.vue'

export default {
  name: 'AdminVerifided',
  components: { VerifideArchive, VerifiedDoctor },
  data() {
    return {
      archive: false,
    }
  },
  methods:{

    handleIdSelected(data) {
      const userIndex = this.users.findIndex(user => user.id === data.id);
      const user = this.users.filter(user => user.id === data.id)[0];
      user.verified_at = data.verified_at;
      if (userIndex !== -1 && user) {
        if(data.verified){
          user.verified = 1;
        }else{
          user.verified = 0;
        }
        this.users.splice(userIndex, 1);
        this.archiveUsers.push(user);
      }
    }
  },
    computed:{
        users(){
            return this.$store.getters.getDoctorsNotVerified
        },
        archiveUsers(){
            return this.$store.getters.getDoctorsArchive
        }
    },
  mounted(){
    this.$store.dispatch('actionGetDoctorsNotVerified');
    this.$store.dispatch('actionGetDoctorsArchive');
  }
}
</script>

<style scoped>
.last {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.archive {
    display: flex;
    flex-direction: column;
    row-gap: 90px;
}

.header-3{
  max-width: 1597px !important;
  padding: 0 !important;
}
.row-underline {
  border-bottom: 1px solid #dbe0eb;
  margin: 10px 10px 22px 10px;
}
.col-underline {
  line-height: 50px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #373a43;
  cursor: pointer;
  width: auto;
  padding: 0 !important;
  margin-right: 52px;
}
.col-underline:last-child {
  margin-right: 0;
}
.paint {
    display: block;
    width: 100%;
    height: 3px;
    background: #02b061;
  /*border-bottom: 3px solid #02b061;*/
    border-radius: 20px 20px 0px 0px;
}

</style>

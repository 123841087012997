<template>
  <form @submit.prevent="login">
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input
        type="email"
        class="form-control"
        id="exampleInputEmail1"
        v-model="email"
        aria-describedby="emailHelp"
        placeholder="Enter email"
      />
      <small id="emailHelp" class="form-text text-muted"
        >We'll never share your email with anyone else.</small
      >
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input
        type="password"
        class="form-control"
        id="exampleInputPassword1"
        v-model="password"
        placeholder="Password"
      />
    </div>
    <div v-if="errors.length">
      <div v-for="error in errors" :key="error">
        <div>{{ error }}</div>
      </div>
    </div>
    <div v-if="serverError">{{ serverError }}</div>

    <button type="submit" class="btn btn-primary">Login</button>
  </form>
  <div class="container">
    <router-link to="/register">Register</router-link>
  </div>
</template>

<script>
import validators from '@/config/validators'

export default {
  name: 'login-page',
  data() {
    return {
      email: '',
      password: '',
      errors: []
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    },
    serverError() {
      return this.$store.getters.getServerError
    }
  },

  methods: {
    async login() {
      this.errors = validators.login({
        email: this.email,
        password: this.password
      })
      if (!this.errors.length) {
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password
        })
        if (this.token) {
          localStorage.userToken = this.token
          this.$router.push({ name: 'dashboard', params: { id: 1 } })
        }
      }
    }
  },
  mounted() {
    if (localStorage.userToken) {
      this.$router.push({ name: 'dashboard', params: { id: 1 } })
    }
  }
}
</script>

<style scoped></style>

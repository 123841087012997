<template>
    <div class="admin-statistics">
        <div class="admin-statistics-table">
            <table :style="{'margin-bottom' : doctors.length > 8 ? '' : '355px'}" style="height: 300; overflow-y: auto;">
                <tr>
                    <th class="top-left">
                        <span class="th-span" @click="sortByName = !sortByName">
                            ФИО
                            <arrow-down :class="{'arrow-icon-active': sortByName}"/>
                        </span>
                    </th>
                    <th style="position: relative">
                        <span class="th-span" @click="showSort = !showSort">
                            Специальность
                            <arrow-down :class="{'arrow-icon-active': showSort}"/>
                        </span>
                        <div class="sort-popup" v-if="showSort">
                            <div class="sort-popup-item" @click="selectedSpec = []">
                                Все
                            </div>
                            <div :style="{'background': selectedSpec.includes(item.name) ? '#edeff3' : 'none'}" class="sort-popup-item" v-for="item in specialities" :key="item.id" @click="selectSpec(item.name)">
                                {{ item.name }}
                            </div>
                        </div>
                    </th>
                    <th>
                        <span class="th-span" @click="showSortCity = true;">
                            Город: {{ sortCity ? sortCity : 'Все' }}
                            <arrow-down :class="{'arrow-icon-active': sortCity}"/>
                        </span>
                    </th>
                    <th>
                        <span class="th-span" @click="sortByAuthority = !sortByAuthority">
                            Авторитет
                            <arrow-down :class="{'arrow-icon-active': sortByAuthority}"/>
                        </span>
                    </th>
                </tr>
                <tr v-for="(doctor, index) in doctors" :key="doctor.id">
                    <td v-if="doctors.length" style="cursor: pointer; display: flex; align-items: center"
                        :class="index == doctors.length - 1 ? 'bottom-left' : ''" @click="goToDoctorPage(doctor)">
                        <img
                            class="img"
                            :src="`${main_url}/img/doctors/${doctor.user_detales.image}`"
                            alt=""
                        />
                        {{ doctor.last_name }} {{ doctor.first_name }} {{ doctor.father_name }}
                    </td>
                    <td v-if="doctors.length"><span v-for="spec in doctor.specialities" :key="spec.id">{{ spec.specie_name }}, </span></td>
                    <td v-if="doctors.length">{{ doctor.user_detales.city }}</td>
                    <td v-if="doctors.length">
                        {{ doctor.authority }}
                        <font-awesome-icon
                            icon="arrow-trend-up"
                            v-if="doctor.authority > 0"
                            color="#02B061"
                        />
                        <font-awesome-icon icon="arrow-trend-down" v-if="doctor.authority < 0" color="#E14469"/>
                    </td>
                </tr>
            </table>
        </div>

        <pagination-helper v-if="doctors.length" @updateCurrentPage="updateCurrentPage" :currentPage="currentPage" :page-count="pageCount"/>
        <SelectCity v-if="showSortCity" @changeSortCity="filterByCity($event)" @closeModal="showSortCity = false"/>
    </div>
</template>

<script>
import config from '@/config/config'
import PaginationHelper from '@/components/helppers/PaginationHelper.vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {arrow} from '@popperjs/core'
import ArrowDown from "@/components/icons/HeaderNameArrowDown.vue";
import SelectCity from "@/components/layout/SelectCity.vue";


export default {
    name: 'AdminStatistics',
    components: {
        FontAwesomeIcon,
        PaginationHelper,
        ArrowDown,
        SelectCity
    },
    data() {
        return {
            currentPage: 1,
            showSort:false,
            specialities: [],
            selectedSpec: [],
            sortByName: null,
            sortByAuthority: null,
            showSortCity: false,
            sortCity: null,
            
        }
    },
    computed: {
        arrow() {
            return arrow
        },
        doctors() {
            let doctors = this.$store.getters.getStatsDoctors;
            doctors.filter((doctor) => {
                return doctor.authority = doctor.my_recommendations.length - doctor.un_recommendations_count;
            })   

            if (this.selectedSpec.length) {
              doctors = this.selectedFilter(doctors);
            }

            if(this.sortByName){
                this.sort(doctors, 'full_name');
            }else if(this.sortByName === false){
                this.sortReverse(doctors, 'full_name');
            }

            if(this.sortByAuthority){
                this.sort(doctors, 'authority');
            }else if(this.sortByAuthority === false){
                this.sortReverse(doctors, 'authority');
            }

            if (this.sortCity && this.sortCity !== null) {
                doctors = doctors.filter(doctor => {
                return doctor.user_detales.city === this.sortCity
              });
            }
            
            return doctors
        },
        pageCount() {
            return Math.ceil(this.$store.getters.getStatsPageCount / 24)
        },
        main_url() {
            return config.URL
        },
    },
    watch: {
        async currentPage(newVal) {
            await this.$store.dispatch('getStatsDoctors', newVal)
        }
    },
    methods: {
        selectedFilter(doctors){
            return doctors.filter((doctor) => {
                return doctor.specialities.some(spec => this.selectedSpec.includes(spec.specie_name))
              })
        },
        updateCurrentPage(newVal) {
            this.currentPage = newVal
        },
        goToDoctorPage(doctor) {
            this.$router.push({name: 'doctor-page', params: { id: doctor.id }, query: {spec: doctor.specialities[0].id}})
        },
        selectSpec(spec){
            if(this.selectedSpec.includes(spec)){
                let index = this.selectedSpec.indexOf(spec)
                if(index !== -1){
                    this.selectedSpec.splice(index, 1)
                }
            }else{ 
                this.selectedSpec.push(spec)
            }
        },
        sort(doctors, key){
            return doctors.sort((a, b) => {
                    let nameA = a[key];
                    let nameB = b[key];
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                });
        },
        sortReverse(doctors, key){
            return doctors.sort((a, b) => {
                  let nameA = a[key];
                  let nameB = b[key];
                  if (nameA > nameB) {
                    return -1;
                  }
                  if (nameA < nameB) {
                    return 1;
                  }
                  return 0;
                });
        },
        filterByCity($event){
            this.sortCity = $event;
            this.showSortCity = false;
        }
    },

    async mounted() {
        await this.$store.dispatch('getStatsDoctors', 1)
        await this.$store.dispatch('getOnlySpecialities');
        this.specialities = this.$store.getters.getOnlySpecialities;
    }
}
</script>

<style scoped>
.admin-statistics {
    margin-right: -20px;

}

.admin-statistics-table {
    overflow-x: auto;
    padding: 2px 20px 15px 2px;
}

table {
    border: none;
    outline: 2px solid #dbe0eb;
    border-radius: 20px;
    margin: auto;
}

td, th {
    border: none;
    outline: 1px solid #dbe0eb;
    text-align: left;
    padding: 13px 23px;
    width: 340px;
    line-height: 20px;
    white-space: nowrap;
}

th {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #373a43;
}

.th-span {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 5px;
}

.th-span svg {
    cursor: pointer;
}

td {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #373a43;
}

.img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
}

.top-left {
    border-top-left-radius: 20px !important;
}

.top-right {
    border-top-right-radius: 20px;
}

.bottom-left {
    border-bottom-left-radius: 20px;
}

.bottom-right {
    border-bottom-right-radius: 20px;
}

.sort-popup {
    z-index: 100;
    position: absolute;
    width: 235px;
    background: #ffffff;
    border: 1px solid #dbe0eb;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.3);
    border-radius: 10px;
    top: 60px;
    max-height: 355px;
    overflow-y: auto;
}

.sort-popup::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
}

.sort-popup::-webkit-scrollbar-track {
    background: rgba(203, 208, 217, 0.5);
    border-radius: 50px;
}

.sort-popup-item {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 40px;
    color: #373a43;
    cursor: pointer;
    text-align: left;
    padding-left: 20px;
}
.arrow-icon-active {
    transition: .2s;
    transform: rotateX(-180deg);
}
.sort-popup-item:hover {
    background: #edeff3 !important;
    border-radius: 5px !important;
}
</style>

<template>
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 5L5 9L13 1"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: false,
            default: '#02B061'
        }
    },

  name: 'CheckButtonDocs'
}
</script>

<style scoped></style>

<template>
  <button class="default-button" :class="{ outline, icon }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'DefaultButton',
  props: {
    outline: {
      default: false
    },
    icon: {
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.default-button {
  border-radius: 50px;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  background: #02b061;
  border: none;
  color: #ffffff;

  &:hover {
    background: #059f59;
  }

  &:active {
    background: #089253;
  }

  &.outline {
    border: 1px solid #02b061;
    background: none;
    color: #02b061;

    &:hover {
      background: #02b061;
      color: #ffffff;
    }

    &:active {
      background: #089253;
      color: #ffffff;
    }
  }

  &.icon {
    border: 1px solid #d3d8e2;
    color: #7f818a;

    svg {
      stroke: #a4acbd;
    }

    &:hover {
      border: 1px solid #02b061;
      color: #24ad6f;

      svg {
        stroke: #02b061;
      }
    }

    &:active {
      border: 1px solid #049754;
      color: #038147;

      svg {
        stroke: #02b061;
      }
    }
  }
}
</style>

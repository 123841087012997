<template>
    <div class="change-contents">
        <admin-change-home-content @show-prompt="showPromptHandler"/>
        <admin-change-doctor-invite-content @show-prompt="showPromptHandler"/>
        <admin-change-doctor-dashboard-content @show-prompt="showPromptHandler"/>
    </div>
    <prompt-text-popup v-if="showPrompt" @close-prompt="showPrompt = false" text="Изменения сохранены"/>
</template>

<script>

import AdminChangeHomeContent from "@/components/admin/AdminChangeHomeContent.vue";
import PromptTextPopup from "@/components/helppers/PromptTextPopup.vue";
import AdminChangeDoctorInviteContent from "@/components/admin/AdminChangeDoctorInviteContent.vue";
import AdminChangeDoctorDashboardContent from "@/components/admin/AdminChangeDoctorDashboardContent.vue";

export default {
    name: 'AdminChangeContent',

    components: {
        AdminChangeDoctorDashboardContent,
        AdminChangeDoctorInviteContent, PromptTextPopup, AdminChangeHomeContent},

    data() {
        return {
            showPrompt: false
        }
    },

    methods: {
        showPromptHandler() {
            this.showPrompt = true
            setTimeout(() => {
                this.showPrompt = false
            }, 3000)
        },
    },

    mounted() {
        this.$store.dispatch('getHomeContent')
        this.$store.dispatch('getDoctorInviteContent')
        this.$store.dispatch('getDoctorDashboardContent')
    }

}
</script>

<style scoped lang="scss">

.change-contents {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

</style>

<template>
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6444 17.8201C14.7882 17.8201 16.5262 16.0822 16.5262 13.9384C16.5262 11.7945 14.7882 10.0566 12.6444 10.0566C10.5006 10.0566 8.7627 11.7945 8.7627 13.9384C8.7627 16.0822 10.5006 17.8201 12.6444 17.8201Z" stroke="#C0C4CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.6452 2.29712H10.0574C9.7142 2.29712 9.38509 2.43344 9.14243 2.6761C8.89978 2.91875 8.76346 3.24786 8.76346 3.59103C8.76346 4.27736 8.49081 4.93558 8.0055 5.42089C7.52019 5.9062 6.86197 6.17885 6.17564 6.17885H3.58782C2.90149 6.17885 2.24326 6.45149 1.75795 6.9368C1.27264 7.42211 1 8.08033 1 8.76667V20.4119C1 21.0982 1.27264 21.7564 1.75795 22.2417C2.24326 22.727 2.90149 22.9997 3.58782 22.9997H21.7026C22.3889 22.9997 23.0471 22.727 23.5324 22.2417C24.0177 21.7564 24.2904 21.0982 24.2904 20.4119V11.3545" stroke="#C0C4CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.5259 4.88391H24.2893" stroke="#C0C4CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.4087 1V8.76346" stroke="#C0C4CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
    name: 'PhotoIcon'
}
</script>


export default {
    state: {
        user: {},
        token: '',
        serverError: false,
        allDoctors: [],
        doctorDetalies: {},
        userDetalies: {},
        subscribesDoctors: [],
        pageCount: 0,
        docSubscribes: [],
        subDoctors: [],
        unSubDoctors: [],
        doctors: [],
        educations: [],
        courses: [],
        jobs: [],
        talents: [],
        specials: [],
        userCity: '',
        neural: {},
        neuralParams: {},
        userCollections: {}
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setToken(state, token) {
            state.token = token
        },
        setServerError(state, serverError) {
            state.serverError = serverError
        },

        setAllDoctors(state, doctors) {
            state.allDoctors = doctors
        },
        setSubscribesDoctors(state, doctors) {
            state.subscribesDoctors = doctors
        },

        setDoctorDetalies(state, detalies) {
            state.doctorDetalies = detalies
        },
        setUserDetalies(state, detalies) {
            state.userDetalies = detalies
        },

        setPageCount(state, pageCount) {
            state.pageCount = pageCount
        },

        setdocSubscribes(state, subscribe) {
            state.docSubscribes = subscribe
        },
        setSubDoctors(state, subscribe) {
            state.subDoctors = subscribe
        },
        setUnSubDoctors(state, subscribe) {
            state.unSubDoctors = subscribe
        },

        setDoctors(state, doctors) {
            state.doctors = doctors
        },

        setEducations(state, educations) {
            state.educations = educations
        },

        setCourses(state, courses) {
            state.courses = courses
        },

        setJobs(state, jobs) {
            state.jobs = jobs
        },

        setTalents(state, talents) {
            state.talents = talents
        },
        setSpecials(state, specials) {
            state.specials = specials
        },

        setUserCity(state, city) {
            state.userCity = city;
        },

        setNeural(state, neural) {
            let data = {
                nodes: [],
                links: []
            }

            data.nodes.push({
                id: neural.id,
                fullName: neural.full_name,
                specialization: neural.specialities.map(item => item.specie_name),
                level: 1,
                image:neural.user_detales.image,
                my_recommendations_count: neural.my_recommendations_count,
                recommended_count: neural.recommendations_count
            })

            function sortData(recommendation,level){
                const alreadyExistsNode = data.nodes.some((item) =>
                    item.id === recommendation.recommendation_id
                );
                const alreadyExistsLink = data.links.some((item) =>
                    item.source === recommendation.doctor_id && item.target === recommendation.recommendation_id
                );

                if (!alreadyExistsNode) {
                    data.nodes.push({
                        id: recommendation.recommendation_id,
                        fullName: recommendation.doctor.full_name,
                        specialization: recommendation.doctor.specialities.map(item => item.specie_name),
                        level,
                        image:recommendation.doctor.user_detales.image,
                        my_recommendations_count: recommendation.doctor.my_recommendations_count,
                        recommended_count: recommendation.doctor.recommendations_count
                    })
                }
                if (!alreadyExistsLink) {
                    data.links.push({
                        source: recommendation.doctor_id,
                        target: recommendation.recommendation_id,
                        text: `Врач, которому доверяет ${recommendation.doctor.last_name} ${recommendation.doctor.first_name.slice(0, 1)}. ${recommendation.doctor.father_name.slice(0, 1)}`
                    })
                }
            }


            neural.recommendations.forEach(recommendation => {
                sortData(recommendation,2)

                recommendation.doctor.recommendations.forEach(item => {
                    sortData(item,3)
                })

            })

            state.neural = data;
        },

        setNeuralParams(state, params) {
            localStorage.setItem('neuralParams',JSON.stringify(params))
            state.neuralParams = params
        },
        setUserCollections(state, userCollections){
            state.userCollections = userCollections
        }
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getToken(state) {
            return state.token
        },
        getServerError(state) {
            return state.serverError
        },

        getAllDoctores(state) {
            return state.allDoctors
        },

        getSubscribesDoctors(state) {
            return state.subscribesDoctors
        },

        getDoctorDetalies(state) {
            return state.doctorDetalies
        },
        getUserDetalies(state) {
            return state.userDetalies
        },

        getPageCount(state) {
            return state.pageCount
        },

        getdocSubscribes(state) {
            return state.docSubscribes
        },

        getunsubscribesDoc(state) {
            return state.unSubDoctors
        },

        getSubDoctors(state) {
            return state.subDoctors
        },
        getDoctors(state) {
            return state.doctors
        },

        getEducations(state) {
            return state.educations
        },

        getCourses(state) {
            return state.courses
        },

        getJobs(state) {
            return state.jobs
        },

        getTalents(state) {
            return state.talents
        },

        getSpecials(state) {
            return state.specials
        },

        getUserCity(state) {
            return state.userCity
        },

        getNeural(state) {
            return state.neural
        },
        getUserCollections(state){
            return state.userCollections
        }
    },
    actions: {
        async login({commit}, user) {
            await this.$axios
                .post(`/api/login`, user)
                .then((response) => {
                    commit('setToken', response.data.token)
                })
                .catch((err) => {
                    commit('setServerError', err.response.data.errors)
                })
        },
        async logout({commit}) {
            await this.$axios.post(`/api/logout`)
                .then(() => {
                    commit('setToken', null)
                    localStorage.removeItem("userToken")
                    localStorage.removeItem("neuralParams")
                    window.location.reload()
                })
                .catch((err) => {
                    commit('setServerError', err.response.data.errors)
                })
        },

        async getUserCity({commit}) {
            await this.$axios.get(`/api/get-user-city`).then((response) => {
                if (response.data.city) {
                    commit('setUserCity', response.data.city)
                }
            })
        },

        async updateUserDetailes({commit}, user) {
            await this.$axios.post(`/api/updateUserDetalies`, user)
                .then((response) => {
                    commit('setUser', response.data)
                })
                .catch((err) => {
                    commit('setServerError', err.response.data.errors)
                })
        },
        async register({commit}, user) {
            await this.$axios.post(`/api/registerDoctor`, user)
                .then((response) => {
                    commit('setToken', response.data.user)
                })
                .catch((err) => {
                    commit('setServerError', err.response.data.errors)
                })
        },

        setToken({commit}, token) {
            commit('setToken', token)
        },

        setServerError({commit}, errors) {
            commit('setServerError', errors)
        },

        async getUserByToken({commit}) {
            await this.$axios.get(`/api/getUserByToken`).then((response) => {
                commit('setUser', response.data)
            })
        },

        async getAllDoctors({commit}, page) {
            await this.$axios
                .get(
                    `/api/getAllDoctors?id=${page.page}&species_id=${page.spec}&name=${page.name}`
                )
                .then((response) => {
                    commit('setAllDoctors', response.data)
                })
        },
        async deleteSocial(_,params) {
            await this.$axios.delete(`/api/deleteSocial/${params.socialName}`);
        },
        async addSocial(_,params) {
            await this.$axios.post(`/api/addSocial` ,params);
        },

        async getDoctorsAll({commit}) {
            await this.$axios
                .get(
                    `/api/getDoctorsBySub`
                )
                .then((response) => {
                    commit('setDoctors', response.data)
                })
        },
        async getDoctors({commit}, page) {
            await this.$axios
                .get(
                    `/api/getDoctors?species_id=${page.spec}&users=${typeof page.users === 'string' ? page.users : JSON.stringify(page.users)}`
                )
                .then((response) => {
                    commit('setAllDoctors', response.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },

        async getNeural({commit, state}) {
            await this.$axios
                .post(
                    `/api/getNeural`, state.neuralParams
                )
                .then((response) => {
                    commit('setNeural', response.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },

        async getLastDoctors({commit}) {
            await this.$axios.get(`/api/getLastDoctors`).then((response) => {
                commit('setAllDoctors', response.data)
            })
        },
        async getDoctorsBySubscribe({commit}, page) {
            await this.$axios
                .get(
                    `/api/getDoctorsByRecommendation?id=${page.page}&species_id=${page.spec_id}&user_id=${page.user_id}`
                )
                .then((response) => {
                    commit('setSubscribesDoctors', response.data)
                })
        },
        async getDoctorsSubscribe({commit}, page) {
            await this.$axios
                .get(`/api/getDoctorsRecommendations?ids=${JSON.stringify(page.page)}`)
                .then((response) => {
                    commit('setdocSubscribes', response.data)
                })
        },
        async resetDoctorsSubscribe({commit}) {
            commit('setdocSubscribes', [])
        },
        async getSubscribeDoctors({commit}, page) {
            await this.$axios
                .get(`/api/getDoctorsRecommendations?ids=${JSON.stringify(page.page)}`)
                .then((response) => {
                    commit('setSubDoctors', response.data)
                })
        },
        async resetSubscribeDoctors({commit}) {
            commit('setSubDoctors', [])
        },

        async getUnSubscribeDoctors({commit}, page) {
            await this.$axios
                .get(`/api/getDoctorsRecommendations?ids=${JSON.stringify(page.page)}`)
                .then((response) => {
                    commit('setUnSubDoctors', response.data)
                })
        },

        async getDoctorDetalies({commit}, id) {
            await this.$axios.get(`/api/getUserDetailes?id=${id}`).then((response) => {
                commit('setDoctorDetalies', response.data)
            })
        },

        async getUserDetalies({commit}, id) {
            await this.$axios.get(`/api/getUserDetailes?id=${id}`).then((response) => {
                commit('setUserDetalies', response.data)
            })
        },

        async getPageCount({commit}, page) {
            await this.$axios
                .get(`/api/getPageCount?species_id=${page.spec_id}&name=${page.name}`)
                .then((response) => {
                    commit('setPageCount', response.data)
                })
        },

        async resetPassword(_, email) {
            await this.$axios.post(`/api/reset-password`, email)
        },

        async getUserEducations({commit}) {
            await this.$axios.get(`/api/educations`).then((response) => {
                commit('setEducations', response.data)
            })
        },

        async addEducation({commit}, education) {
            await this.$axios.post(`/api/addEducation`, education).then((response) => {
                commit('setEducations', response.data)
            })
        },
        async editEducation({commit}, education) {
            await this.$axios.post(`/api/editEducation/${education.id}`, education).then((response) => {
                commit('setEducations', response.data)
            })
        },

        async deleteEducation({commit}, educId) {
            await this.$axios.delete(`/api/deleteEducation/${educId}`).then((response) => {
                commit('setEducations', response.data)
            })
        },

        async getUserCourses({commit}) {
            await this.$axios.get(`/api/courses`).then((response) => {
                commit('setCourses', response.data)
            })
        },

        async addCourse({commit}, course) {
            await this.$axios.post(`/api/addCourse`, course).then((response) => {
                commit('setCourses', response.data)
            })
        },

        async editCourse({commit}, course) {
            await this.$axios.post(`/api/editCourse/${course.id}`, course).then((response) => {
                commit('setCourses', response.data)
            })
        },

        async deleteCourse({commit}, courseId) {
            await this.$axios.delete(`/api/deleteCourse/${courseId}`).then((response) => {
                commit('setCourses', response.data)
            })
        },

        async getUserJobs({commit}) {
            await this.$axios.get(`/api/jobs`).then((response) => {
                commit('setJobs', response.data)
            })
        },

        async addJob({commit}, jobs) {
            await this.$axios.post(`/api/addJob`, jobs).then((response) => {
                commit('setJobs', response.data)
            })
        },

        async editJob({commit}, job) {
            await this.$axios.post(`/api/editJob/${job.id}`, job).then((response) => {
                commit('setJobs', response.data)
            })
        },

        async deleteJob({commit}, jobId) {
            await this.$axios.delete(`/api/deleteJob/${jobId}`).then((response) => {
                commit('setJobs', response.data)
            })
        },

        async getUserTalents({commit}) {
            await this.$axios.get(`/api/talents`).then((response) => {
                commit('setTalents', response.data)
            })
        },

        async addUserTalents(_,params) {
            await this.$axios.post(`/api/addTalent`,params)
        },

        async deleteTalent({commit}, talentId) {
            await this.$axios.delete(`/api/deleteTalent/${talentId}`).then((response) => {
                commit('setTalents', response.data)
            })
        },

        async addSpecial({commit}, special) {
            await this.$axios.post(`/api/addSpecial`, special).then((response) =>{
                commit('setSpecials', response.data)
            })
        },

        async getUserSpecials({commit}) {
            await this.$axios.get(`/api/userSpecials`).then((response) => {
                commit('setSpecials', response.data)
            })
        },

        async deleteSpecial({commit}, specialId) {
            await this.$axios.delete(`/api/deleteUserSpecial/${specialId}`).then((response) => {
                commit('setSpecials', response.data)
            })
        },

        async getUserCollections({commit}, userId) {
            await this.$axios.get(`/api/user-collections/${userId}`).then((response) => {
                commit('setUserCollections', response.data)
            })
        },
    },
}

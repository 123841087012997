<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9324 0.377174C16.7056 0.188565 16.432 0.064954 16.1406 0.0194947C15.8493 -0.0259645 15.551 0.00842934 15.2776 0.119016L1.0078 5.88028C0.702633 6.00584 0.442865 6.22124 0.262975 6.49788C0.083085 6.77453 -0.00841856 7.09934 0.000609116 7.4292C0.0096368 7.75907 0.118769 8.07839 0.31352 8.34478C0.508272 8.61117 0.779433 8.81204 1.09101 8.92071L3.97748 9.9246L5.58633 15.245C5.6082 15.316 5.63993 15.3837 5.6806 15.4459C5.68677 15.4555 5.69547 15.4626 5.70203 15.4719C5.74902 15.5374 5.8058 15.5954 5.87039 15.6437C5.88876 15.6577 5.90634 15.6708 5.92598 15.6832C6.00157 15.7334 6.08529 15.7701 6.1734 15.7916L6.18283 15.7924L6.18818 15.7947C6.24115 15.8055 6.29508 15.811 6.34914 15.811C6.35434 15.811 6.35896 15.8086 6.36411 15.8085C6.4457 15.807 6.52657 15.793 6.60386 15.7668C6.62184 15.7607 6.63725 15.7504 6.65469 15.743C6.71232 15.7192 6.76693 15.6885 6.81736 15.6518C6.85775 15.6178 6.89815 15.5838 6.93857 15.5498L9.09021 13.1742L12.2994 15.6602C12.5819 15.8801 12.9297 15.9997 13.2877 16C13.6629 15.9995 14.0265 15.8701 14.3176 15.6334C14.6087 15.3966 14.8095 15.067 14.8865 14.6998L17.4844 1.94638C17.5433 1.65934 17.523 1.3617 17.4258 1.08529C17.3286 0.808885 17.158 0.564117 16.9324 0.377174ZM6.66488 10.1781C6.55447 10.2881 6.47899 10.4282 6.44793 10.5809L6.20147 11.7785L5.57715 9.71371L8.81417 8.02805L6.66488 10.1781ZM13.2753 14.4012L9.48291 11.4634C9.32425 11.3408 9.12522 11.2825 8.92548 11.3001C8.72574 11.3177 8.53996 11.4099 8.40515 11.5583L7.71605 12.3189L7.95958 11.1353L13.5995 5.49534C13.7342 5.3609 13.8162 5.18269 13.8307 4.993C13.8453 4.8033 13.7914 4.61466 13.6789 4.46125C13.5664 4.30783 13.4027 4.19979 13.2174 4.15669C13.032 4.11359 12.8374 4.13828 12.6687 4.22629L4.57448 8.44064L1.6089 7.3554L15.9246 1.62832L13.2753 14.4012Z"
      fill="#C0C4CD"
    />
  </svg>
</template>

<script>
export default {
  name: 'TelegramDoctorPageIcon'
}
</script>

<style scoped></style>

<template>
    <div class="super-admin-dashboard page-title">
        <admin-statistics v-if="pageId == 1"/>
        <admin-verifided v-if="pageId == 2"/>
        <doctor-subscribes
            v-if="pageId == 3"
            type="1"
            :page="true"
            :subscribes="getDoctorDetalies.subscribes"
        />
        <div class="admin-invite-container" v-if="pageId == 4">
            <admin-invite
                v-for="invite in invitations"
                :invite="invite"
                :key="invite.id"
            />
        </div>
        <doctor-notification v-if="pageId == 5"/>
        <admin-history v-if="pageId == 6"/>
        <admin-settings v-if="pageId == 7"/>
        <admin-change-content v-if="pageId == 8"/>
        <admin-send-invite v-if="pageId == 9"/>
    </div>
</template>

<script>
import config from '@/config/config'
import AdminStatistics from '@/components/admin/AdminStatistics.vue'
import AdminVerifided from '@/components/admin/AdminVerifided.vue'
import DoctorSubscribes from '@/components/doctor/DoctorSubscribes.vue'
import AdminInvite from '@/components/admin/AdminInvite.vue'
import DoctorNotification from '@/components/doctor/DoctorNotification.vue'
import AdminHistory from '@/components/admin/AdminHistory.vue'
import AdminSettings from "@/components/admin/AdminSettings.vue";
import AdminSendInvite from "@/components/admin/AdminSendInvite.vue";
import AdminChangeContent from "@/components/admin/AdminChangeContent.vue";

export default {
    name: 'SuperAdminDashboard',
    components: {
        AdminChangeContent,
        AdminSettings,
        AdminHistory,
        AdminInvite,
        AdminVerifided,
        AdminStatistics,
        DoctorSubscribes,
        DoctorNotification,
        AdminSendInvite
    },
    computed: {
        config() {
            return config
        },
        pageId() {
            return this.$route.params.id
        },
        pageName() {
            let arr = config.ADMIN_MANU_ITEMS.filter((item) => item.id == this.pageId)
            return arr[0].title
        },
        user() {
            return this.$store.getters.getUser
        },
        getDoctorDetalies() {
            return this.$store.getters.getDoctorDetalies
        },
        mobile() {
            return this.$store.getters.getMobile
        },
        invitations(){
            console.log(this.$store.getters.getInvitations);
            return this.$store.getters.getInvitations
        }
    },
    data() {
        return {
            inviteSend: false
        }
    },
    async mounted() {
        if (this.user) {
            await this.$store.dispatch('getUserByToken')
            await this.$store.dispatch('getDoctorDetalies', this.user.id)
            await this.$store.dispatch('getNotifications')
            await this.$store.dispatch('getInvitations', this.user.id)
        }
    }
}
</script>

<style scoped>

.super-admin-dashboard {
    width: 100%;
}

.admin-invite-container {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}

.page-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: #373a43;
}

.right-row {
    text-align: right;
}
</style>

<template>
    <div class="user-header-desktop desktop-only">
        <div class="user-header-title">

            <router-link :to="{name:'home'}" style="text-decoration: none;">
                <div class="user-header-text-doctor">Связи врачей</div>
                <div class="user-header-text-search">{{ annotationHeader }}</div>
            </router-link>

        </div>

        <div class="user-header-location">
            <div class="location-button" @click="location = !location">
                <location-icon/>
                <span class="geo">{{ userCity }}</span>
            </div>
            <location-question v-if="location" @close="location = !location"/>
        </div>

        <div class="user-header-search" v-if="$router.name !== 'home'">

            <div class="search-input">

                <div class="search-doctor" @click="openDrop" :class="{'full-border-radius': !login}">
                    <header-search-icon class="search-icon"/>

                    <input
                        type="text"
                        class="int"
                        v-model="search"
                        @input="searchByName"
                        placeholder="Специальность или ФИО"
                        :disabled="false"
                    >

                    <header-arrow-down/>
                </div>

                <div class="search-favorites" @click="openRecomend" v-if="login">
                    <div class="vl"/>

                    <input type="text"
                           v-if="!recomentedSearch.length"
                           class="int"
                           placeholder="Мое избранное"
                           :disabled="true"
                    >

                    <div class="rec-images" v-else>
                        <img v-for="item in recImages"
                             class="rec-img"
                             :class="{'no-img': item.doctor.user_detales.image === 'no_doctor_photo.svg'}"
                             :src="`${image}/${item.doctor.user_detales.image}`"
                             :key="item.doctor.id"
                        >
                    </div>

                    <header-arrow-down/>
                </div>

                <div class="drop-menu" v-if="drop">
                    <div class="drop-scroll">
                        <div class="drop-item" v-for="spec in specialities" :key="spec.id"
                             @click="selectSpec(spec)">
                            {{ spec.name }}
                            <span class="doc-count">
                                {{ spec.doctors.length }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="recomended-search" v-if="recomentedDrop">
                    <div class="recomended-title">
                        Поиск на основе избранного
                    </div>
                    <div class="recomended-info">
                        Вы можете осуществить поиск на основе одного или нескольких врачей
                    </div>
                    <div v-for="item in favorites" @click="recomentedSearch.push(item.doctor.id)"
                         class="recomended-content" :key="item.doctor.id">
                        <div class="recomended-img">
                            <img class="recomented-image"
                                 v-if="item.doctor.user_detales.image"
                                 :class="{'no-img': item.doctor.user_detales.image === 'no_doctor_photo.svg'}"
                                 :src="`${image}/${item.doctor.user_detales.image}`"
                            >
                        </div>
                        <div class="recomended-name">
                            {{ item.doctor.full_name }}
                        </div>
                        <div class="recomented-icon">
                            <delete-icon @click.stop="removRec(item.doctor.id)"
                                         v-if="recomentedSearch.indexOf(item.doctor.id) > -1"></delete-icon>
                        </div>
                    </div>
                    <div class="recomented-footer" v-if="favorites.length">
                        <div class="recomrented-cencel" @click="recomentedSearch=[]">
                            Очистить
                        </div>
                        <div class="recomented-enter">
                            <button-helper color="#ffffff" background="#02B061" @click="recomentedDrop=false"
                                           style="width: 117px;height: 50px;padding: 0 !important;">
                                Применить
                            </button-helper>
                        </div>
                    </div>
                </div>
            </div>

            <button-helper
                color="#FFFFFF"
                @click="searchFunc"
                class="search-button"
            >
                Поиск
            </button-helper>
        </div>


        <div class="user-header-cabinet" v-if="login">
            <div class="login-button" @click="openUserDrop">
                <span v-if="user.role_id !== 3" class="user-login">{{ user?.email }}</span>
                <div  v-else class="user-login-circle-bg-desktop">
                    <img
                        alt=""
                        class="user-img"
                        :class="{'no-img-desc': user.user_detales.image === 'no_doctor_photo.svg'}"
                        :src="`${image}/${user.user_detales.image}`"
                    >
                </div>
            </div>
                <header-name-arrow-down class="user-login-icon" :class="{user_drop}"/>
            <user-menu-popup v-if="user_drop" @closeAuth="user_drop = !user_drop"/>
        </div>

        <div class="user-header-login" v-else>
            <button-helper
                color="#24AD6F"
                background="#FFFFFF"
                style="width: 152px; height: 46px"
                @click="login_drop = !login_drop"
            >
                Личный кабинет
            </button-helper>

            <choose-login-popup @closePopup="login_drop = false" v-if="login_drop"/>
        </div>

    </div>

    <div class="user-header-mobile mobile-only" :class="{'stickyHeader': isSticky}">
        <div class="search-input-mobile" @click="openDrop">

            <div style="display: flex; column-gap: 6px; flex-wrap: nowrap; width: 100%">
                <header-search-icon @click.stop="searchFunc"/>
                <input type="text" class="int" v-model="search"  @input="searchByName" placeholder="Специальность или ФИО">
            </div>

            <header-arrow-down/>
            <div class="drop-menu-mobile" v-if="drop">
                <div class="drop-scroll-mobile">
                    <div class="drop-item-mobile" v-for="spec in specialities" :key="spec.id"
                         @click="selectSpec(spec, true)">
                        {{ spec.name }}
                        <span class="doc-count">
                            {{ spec.doctors.length }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="login-button-circle" v-if="login">
            <div class="user-login-circle-bg" @click="user_drop = !user_drop" :class="{'user-login-circle-bg__gray': user.role_id == 3}">
                <div v-if="user.role_id !== 3" class="user-login-circle">
                    {{ firstLetter(user?.email) }}
                </div>
                <img
                    v-else
                    alt=""
                    class="user-img-mobile"
                    :class="{'no-img-mobile': user.user_detales.image === 'no_doctor_photo.svg'}"
                    :src="`${image}/${user.user_detales.image}`"
                >
            </div>
            <header-name-arrow-down/>
            <user-menu-popup v-if="user_drop" @closeAuth="user_drop = !user_drop"/>
        </div>

        <div class="user-header-login" v-else>
            <span class="login-btn-mobile" @click="login_drop = !login_drop">
                Авторизация
            </span>

            <choose-login-popup @closePopup="login_drop = false" v-if="login_drop"/>
        </div>
    </div>

    <div class="sub-header mobile-only" v-if="isSticky"/>
</template>

<script >
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";
import config from "@/config/config"
import HeaderNameArrowDown from "@/components/icons/HeaderNameArrowDown.vue";
import HeaderSearchIcon from "@/components/icons/HeaderSearchIcon.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import LocationQuestion from "@/components/helppers/LocationQuestion";
import LocationIcon from "@/components/icons/LocationIcon.vue";
import UserMenuPopup from "@/components/layout/UserMenuPopup.vue";
import ChooseLoginPopup from "@/components/layout/ChooseLoginPopup.vue";
import {onMounted, ref} from "vue";
import HeaderArrowDown from "@/components/icons/HeaderArrowDown.vue";

export default {
    name: "UserHeader",
    components: {
        HeaderArrowDown,
        ChooseLoginPopup,
        UserMenuPopup,
        LocationIcon,
        DeleteIcon,
        HeaderSearchIcon,
        HeaderNameArrowDown,
        ButtonHelper,
        LocationQuestion
    },

    setup() {
        const isSticky = ref(false);

        const handleScroll = () => {
            isSticky.value = window.pageYOffset > 0;
        };

        onMounted(() => {
            window.addEventListener('scroll', handleScroll);
        });

        return {
            isSticky,
        };
    },

    data() {
        return {
            specName: "Выберите Доктора ",
            special: "",
            drop: false,
            location: false,
            user_drop: false,
            login_drop: false,
            recomentedDrop: false,
            search: '',
            recomentedSearch: [],
            city: 'Москва',
            byName:false
        }
    },
    computed: {
        specialities() {
            return this.$store.getters.getAllSpecialities
        },
        annotationHeader() {
            return this.$store.getters.getAnnotationHeader
        },
        token() {
            return localStorage.userToken
        },
        user() {
            return this.$store.getters.getUser;
        },
        favorites() {
            return this.$store.getters.getFavorites
        },
        image() {
            return `${config.URL}/img/doctors`
        },
        recImages() {
            let rec = this.recomentedSearch;
            return this.favorites.filter(item => {
                if (rec.indexOf(item.doctor.id) > -1) {
                    return item.doctor;
                }
            })
        },
        userCity(){
            let user = this.$store.getters.getUser
            let city = this.city
            if(user?.user_detales){
                city = user?.user_detales?.city?user?.user_detales?.city:'Москва'
            }else{
                city = this.$store.getters.getUserCity ? this.$store.getters.getUserCity : 'Москва';
            }
            return city
        },
        mobile() {
            return this.$store.getters.getMobile //mobile breakpoint
        },
        login() {
            return !!localStorage.userToken
        },
    },
    methods: {
        searchByName(){
            document.removeEventListener('click', this.closeDrops)
            this.drop = false
            this.byName = true
            this.special = ''

        },
        openUserDrop() {
            if (!this.user_drop) {
                setTimeout(() => {
                    document.addEventListener('click', this.closeUserDrop)
                })
            } else {
                setTimeout(() => {
                    document.removeEventListener('click', this.closeUserDrop)
                }, 100)
            }
            this.user_drop = !this.user_drop
        },
        closeUserDrop(e){
            if (!e.target.closest('.auth') && this.user_drop) {
                this.user_drop = false
                setTimeout(() => {
                    document.removeEventListener('click', this.closeUserDrop)
                }, 100)
            }
        },
        openRecomend() {
            if (!this.recomentedDrop) {
                setTimeout(() => {
                    document.addEventListener('click', this.closeDrops)
                })
            } else {
                setTimeout(() => {
                    document.removeEventListener('click', this.closeDrops)
                }, 100)
            }
            this.recomentedDrop = !this.recomentedDrop
            this.drop = false
        },
        openDrop() {
            if (!this.drop) {
                setTimeout(() => {
                    document.addEventListener('click', this.closeDrops)
                })
            } else {
                setTimeout(() => {
                    document.removeEventListener('click', this.closeDrops)
                }, 100)
            }
            this.drop = !this.drop
            this.recomentedDrop = false
        },
        selectSpec(spec, isSearch) {
            this.drop = false
            this.special = spec.id
            this.specName = spec.name
            this.search = spec.name
            this.location = false
            this.byName = false

            if (isSearch) {
                this.searchFunc()
            }
        },
        closeDrops(e) {
            if (!e.target.closest('.search-input') && (this.drop || this.recomentedDrop)) {
                this.drop = false
                this.recomentedDrop = false
                setTimeout(() => {
                    document.removeEventListener('click', this.closeDrops)
                }, 100)
            }
        },
        logout() {
            this.$store.dispatch('logout');
            this.$router.push({name: "home"})
        },
        removRec(id) {
            const index = this.recomentedSearch.indexOf(id);
            if (index > -1) {
                this.recomentedSearch.splice(index, 1);
            }
        },
        firstLetter(login) {
            if (login)
                login = login[0].toUpperCase()

            return login
        },
        async searchFunc() {
            if (this.specName == "") {
                await this.$store.dispatch('getAllDoctors', {page: 1, name: this.search});
                await this.$store.dispatch('getPageCount', {name: this.$route.search});
                this.$router.push(`/category?name=${this.search}`)
            } else {
                if (this.recomentedSearch.length && !this.byName) {
                    const id = this.special ? this.special :''
                    this.$store.commit("setNeuralParams", {species_id: id, doctor: this.recomentedSearch[0]})
                    this.$router.push({name:'search-page'})
                    if(this.$route.name === 'search-page'){
                        await this.$store.dispatch('getNeural');
                    }
                } else {
                    await this.$store.dispatch('getAllDoctors', {page: 1, spec: this.special,name:this.search});
                    await this.$store.dispatch('getPageCount', {spec_id: this.special});
                    this.$router.push(`/category?spec=${this.special}&name=${this.search}`)
                }

            }
        },
    },
    async mounted() {
        this.$store.dispatch('getUserCity');
        this.$store.dispatch('getAnnotationHeader')

        if(localStorage.getItem('neuralParams') && this.$route.name === 'search-page'){
           let data = JSON.parse(localStorage.getItem('neuralParams'))
            this.special = data.species_id
            this.recomentedSearch.push(data.doctor)
        }
        if (this.user?.subscribes?.length) {
            let ids = this.user.subscribes.map(item => item.id)
            await this.$store.dispatch('getSubscribeDoctors', {page: ids});
        }
        if(this.$route.name === 'category-page'){
            const urlParams = new URLSearchParams(window.location.search);
            const spec = urlParams.get('spec');
            const name = urlParams.get('name');
            this.special = spec
            this.search = name
            this.searchFunc()
        }
    },
}
</script>

<style scoped lang="scss">

.user-header-desktop {
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.user-header-mobile {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    column-gap: 20px;
    background: #ffffff;

    @media screen and (max-width: 320px) {
        column-gap: 10px;
    }
}

.stickyHeader {
    position: fixed;
    top: 0;
    padding: 10px 20px;
    margin: 0;
    box-shadow: 0px 4px 50px rgba(189, 189, 189, 0.7);
    z-index: 100;
}

.sub-header {
    height: 34px;
    margin-top: 10px;
    margin-bottom: 40px;
}

.user-img{
    width: 46px;
    height: 46px;
}

.no-img-desc {
    width: 20px !important;
    height: 22px !important;
}

.no-img-mobile {
    width: 14px !important;
    height: 16px !important;
}

.user-img-mobile {
    width: 30px;
    height: 30px;
}

.user-header-title {

}

.user-header-location {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 10px;
}

.header-3 {
    max-width: 1597px !important;
    margin-top: 21px;
}

.header-row {
    display: flex;
    align-items: center;
}

.head-location {
    position: relative;
}

.location-button {
    cursor: pointer;
}

.user-header-location .geo {
    color: #373A43;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    font-size: 13px;
    margin-bottom: 2px;
    margin-left: 11px;
    border-bottom: 1px solid #DBE0EB;
}

.user-header-search {
    display: flex;
    position: relative;
}

.user-header-cabinet {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.user-header-login {
    position: relative;
}

.user-header-text-doctor {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 3px;
    font-size: 18px;
    color: #373A43;
    text-decoration: none;
}

.recomended-search {
    position: absolute;
    width: 640px;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    z-index: 9;
    top: 75px;
    padding: 29px 36px;
}

.user-header-text-search {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #7F818A;
    text-decoration: none;
}

.search-div {
    margin-top: 10px;
    position: relative;
}

.search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.recomended-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #373A43;
}

.recomended-info {
    margin-top: 13px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #656873;
}

.recomended-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #373A43;

}

.recomended-content {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 578px;
    height: 68px;
    border: 1px solid #DBE0EB;
    border-radius: 10px;
    margin-top: 19px;
}

.rec-img {
    width: 30px;
    height: 30px;
}

.no-img {
    width: 14px !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.rec-images {
    display: flex;
}

.recomended-img {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #E9EBF0;
    border-radius: 50%;
    margin: 19px 25px;
}

.recomented-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;

}

.recomented-icon {
    margin-left: auto;
    text-align: right;
    padding-right: 16px;
    padding-top: 8px;
}

.recomented-footer {
    margin-top: 30px;
    display: flex;
}

.recomrented-cencel {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #24AD6F;
}

.recomented-enter {
    width: 100%;
    text-align: right;
}

.int {
    border: none;
    background-color: #EDEFF3;
    outline: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #7F818A;
    text-overflow: ellipsis;
    cursor: text !important;
}

.int:focus {
    outline: none;
}

.vl {
    position: absolute;
    left: 0;
    border-left: 1px solid #DBE0EB;
    height: 70%;
}

.search-div span {
    color: #373A43;
    margin-left: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
}

.search-input {
    display: flex;
    align-items: center;
    max-width: 538px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #373A43;
    position: relative;
}

.search-doctor {
    padding: 15px 10px 15px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px 0 0 50px;
    background-color: #EDEFF3;
    position: relative;
    max-height: 46px;
    column-gap: 3px;
    width: 100%;

    > input {
        margin-left: 30px;
    }
}

.search-favorites {
    padding: 15px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 50px 50px 0;
    background-color: #EDEFF3;
    max-height: 46px;
    column-gap: 3px;
    position: relative;
}

.full-border-radius {
    border-radius: 50px;
}

.search-button {
    width: 88px;
    height: 46px;
    margin-left: 14px;
    background: #02B061;

    &:hover {
        background: #059f59;
    }
}

.login-button {
    display: flex;
    align-items: center;
}

.arrow-down {
    margin-top: 10px;
    float: right;
    border: 5px solid rgba(164, 172, 189, 0.01);
    border-top-color: #A4ACBD;
    width: 0;
}

.arrow-down-right {
    margin-top: 10px;
    float: right;
    border: 5px solid rgba(164, 172, 189, 0.01);
    border-top-color: #5B5F6C;
    width: 0;
}

.drop-menu {
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    z-index: 999;
    position: absolute;
    width: 330px;
    height: 351px;
    overflow: hidden;
    top: 81px;
    left: 0;
    padding: 14px 10px 17px 6px;
}

.drop-scroll {
    overflow: auto !important;
    width: 315px;
    height: 320px;
    margin: auto;
}

.drop-scroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
}

.drop-scroll::-webkit-scrollbar-track {
    background: rgba(203, 208, 217, 0.5);
    border-radius: 50px;
}

.drop-scroll::-webkit-scrollbar-thumb {
    background-color: #d6d9e0;
    border-radius: 50px;
    border: 5px solid #d6d9e0;
}

.drop-item {
    height: 37px;
    width: 310px;
    line-height: 37px;
    margin: auto;
    cursor: pointer;
    z-index: 9999;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 40px;
    color: #373A43;
    padding-left: 23px;
    margin: auto;

}

.drop-item:hover {
    background: #EDEFF3;
    border-radius: 5px;
}

.doc-count {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    color: #7F818A;
}

.location {
    position: absolute;
    width: 300px;
    text-align: center;
    height: 125px;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    left: 0;
    top: 50px;
}

.location-choose {
    margin-top: 15px;
}

.location-title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #373A43;

}

.location-no {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #24AD6F;
    margin-left: 30px;
    cursor: pointer;
}

.login-button {
    position: relative;
}

.auth {
    position: absolute;
    width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    right: 12px;
    top: 37px;
    z-index: 99999;
    padding: 34px 28px 28px;
    text-align: left;
}

.auth-close-parent {
    text-align: right;
    margin: 5px 10px;
    cursor: pointer;
}

.auth-choose {
    text-align: left;
    padding: 20px;
}

.auth-name {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #373A43;
}

.auth-description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #656873;
    margin-top: 5px;
}

.auth-log {
    margin-top: 15px;
}

.login-btn-mobile {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #24ad6f;
}

.choose-underline {
    padding-top: 0 !important;
    border-bottom: 1px solid #DBE0EB;;
}

.login-button .user-login-icon {
    transition: .2s;
    transform: rotateX(0);
}

.login-button .user_drop {
    transform: rotateX(180deg);
}

.user-login {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #373A43;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch;
}

.name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #9195A4;
}

.login-name {
    margin-top: 4px;
    margin-bottom: 20px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #373A43;
}

.user-menu a {
    display: flex;
    gap: 15px;
    align-items: center;
}

.user-menu {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 46px;
    color: #373A43;
}

.auth .logout-button {
    margin-top: 23px;
}

.search-input-mobile {
    cursor: pointer;
    display: flex;
    width: 100%;
    column-gap: 3px;
    align-items: center;
    justify-content: space-between;
    height: 34px;
    line-height: 46px;
    background-color: #EDEFF3;
    border-radius: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #373A43;
    padding: 10px 15px;
}

.login-button-circle {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 5px;
}

.user-login-circle-bg-desktop {
    min-width: 46px;
    min-height: 46px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #E9EBF0;
    overflow: hidden;
}

.user-login-circle-bg {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #02B061;
    margin-right: 5px;
    overflow: hidden;

    &__gray {
        background-color: #E9EBF0;
    }
}

.user-login-circle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    color: #FFFFFF;
}

.drop-menu-mobile {
    background: #FFFFFF;
    box-shadow: 0 4px 40px rgba(138, 138, 138, 0.45);
    border-radius: 20px;
    z-index: 999;
    position: fixed;
    width: 100vw;
    overflow-y: hidden;
    height: max-content;
    top: 10%;
    left: 0;
    right: 0;
}

.drop-scroll-mobile {
    overflow: auto !important;
    padding-top: 10px;
    height: 400px;
}

.drop-scroll-mobile::-webkit-scrollbar {
    width: 5px;
}

.drop-scroll-mobile::-webkit-scrollbar-track {
    background: #CBD0D9;
}

.drop-scroll-mobile::-webkit-scrollbar-thumb {
    background-color: #D6D9E0;
    border-radius: 20px;
    border: 5px solid #D6D9E0;
}

.drop-item-mobile {
    height: 37px;
    width: 321px;
    line-height: 37px;
    cursor: pointer;
    z-index: 9999;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 40px;
    color: #373A43;
    padding-left: 10px;
    margin: auto;
}

@media screen and (max-width: 1280px) {

    .user-header-text-search {
        font-size: 10px;
    }

    .user-header-text-doctor {
        font-size: 16px;
    }
}

@media screen and (max-width: 1080px) {
    .head-text-search {
        font-size: 9px;
    }

    .user-header-location {
        display: none;
    }

    .head-text-doctor {
        font-size: 15px;
    }

    .search-favorites {
        display: none;
    }

    .search-doctor {
        border-radius: 50px;

        > input {
            margin-left: 0;
        }
    }

    .search-button-icon {
        display: block;
    }

    .search-icon {
        display: none;
    }
}

@media screen and (max-width: 768px) {

    .header-row {
        padding: 0 20px;
    }

}

</style>

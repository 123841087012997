<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#4086F4" />
    <path
      d="M13.2685 15.1722C13.7926 14.1285 14.5967 13.251 15.5909 12.638C16.5851 12.025 17.7302 11.7006 18.8981 11.7009C20.596 11.7009 22.0223 12.3246 23.1128 13.3421L21.3066 15.1489C20.6533 14.5246 19.823 14.2064 18.8981 14.2064C17.257 14.2064 15.8678 15.3152 15.3733 16.8039C15.2473 17.1819 15.1755 17.5851 15.1755 18.0009C15.1755 18.4167 15.2473 18.8199 15.3733 19.1979C15.8685 20.6872 17.257 21.7954 18.8981 21.7954C19.7455 21.7954 20.4668 21.5718 21.0313 21.1938C21.3586 20.9783 21.6387 20.6987 21.8549 20.3719C22.071 20.0451 22.2186 19.6779 22.2888 19.2924H18.8981V16.8556H24.8315C24.9058 17.2676 24.9461 17.6973 24.9461 18.1439C24.9461 20.0629 24.2594 21.6782 23.0675 22.7744C22.0255 23.7371 20.5991 24.3009 18.8981 24.3009C18.0707 24.3013 17.2513 24.1385 16.4868 23.822C15.7223 23.5056 15.0277 23.0415 14.4426 22.4564C13.8576 21.8714 13.3935 21.1767 13.077 20.4122C12.7605 19.6477 12.5978 18.8283 12.5981 18.0009C12.5981 16.9841 12.8413 16.0227 13.2685 15.1722Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'GoogleIcon'
}
</script>

<style scoped></style>

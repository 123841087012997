<template>
    <div class="change-contents__item" v-if="homeContent">
        <div class="change-contents__title" @click="showChangeContent = !showChangeContent">
            Главная страница

            <big-arrow-icon style="transition: .2s; min-width: 14px" :class="{'rotate-icon': !showChangeContent}"/>
        </div>

        <div class="change-contents__desc" v-if="!showChangeContent">
            Блоки главной страницы
        </div>

        <div class="change-contents__main" v-if="showChangeContent">
            <div class="change-contents__main-item">
                <div class="change-contents__main-title">
                    Первый блок
                    <div style="position: relative">
                        <question-icon
                            style="cursor: pointer"
                            @click="showPrompt1 = !showPrompt1"
                            :color="hoverQuestion1 ? '#02B061' : '#C0C4CD'"
                            @mouseenter="hoverQuestion1 = true"
                            @mouseleave="hoverQuestion1 = false"
                        />
                        <change-content-prompt v-if="showPrompt1" img="home_first_block.jpg"/>
                    </div>
                </div>
                <div class="change-contents__inputs">
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Аннотация в шапке
                        </div>
                        <input-layout
                            placeholder="Аннотация в шапке"
                            :input-value="homeContent.first_block.annotation_header"
                            @update:input-value="homeContent.first_block.annotation_header = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок зеленый цвет
                        </div>
                        <input-layout
                            placeholder="Заголовок зеленый цвет"
                            :input-value="homeContent.first_block.title1"
                            @update:input-value="homeContent.first_block.title1 = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок черный цвет
                        </div>
                        <input-layout
                            placeholder="Заголовок черный цвет"
                            :input-value="homeContent.first_block.title2"
                            @update:input-value="homeContent.first_block.title2 = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Аннотация под заголовком
                        </div>
                        <input-layout
                            placeholder="Аннотация под заголовком"
                            :input-value="homeContent.first_block.annotation_title"
                            @update:input-value="homeContent.first_block.annotation_title = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Описание
                        </div>
                        <input-layout
                            placeholder="Описание"
                            :input-value="homeContent.first_block.desc"
                            @update:input-value="homeContent.first_block.desc = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Текст под описанием
                        </div>
                        <input-layout
                            placeholder="Текст под описанием"
                            :input-value="homeContent.first_block.text_under_desc"
                            @update:input-value="homeContent.first_block.text_under_desc = $event"
                        />
                    </div>
                </div>
            </div>

            <div class="change-contents__separator"/>

            <div class="change-contents__main-item">
                <div class="change-contents__main-title">
                    Второй блок
                    <div style="position: relative">
                        <question-icon
                            style="cursor: pointer"
                            @click="showPrompt2 = !showPrompt2"
                            :color="hoverQuestion2 ? '#02B061' : '#C0C4CD'"
                            @mouseenter="hoverQuestion2 = true"
                            @mouseleave="hoverQuestion2 = false"
                        />
                        <change-content-prompt v-if="showPrompt2" img="home_second_block.jpg"/>
                    </div>
                </div>
                <div class="change-contents__inputs">
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок зеленый цвет
                        </div>
                        <input-layout
                            placeholder="Заголовок зеленый цвет"
                            :input-value="homeContent.second_block.title1"
                            @update:input-value="homeContent.second_block.title1 = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок черный цвет
                        </div>
                        <input-layout
                            placeholder="Заголовок черный цвет"
                            :input-value="homeContent.second_block.title2"
                            @update:input-value="homeContent.second_block.title2 = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Описание
                        </div>
                        <input-layout
                            placeholder="Описание"
                            :input-value="homeContent.second_block.desc"
                            @update:input-value="homeContent.second_block.desc = $event"
                        />
                    </div>
                </div>
            </div>

            <div class="change-contents__separator"/>

            <div class="change-contents__main-item">
                <div class="change-contents__main-title">
                    Четвертый блок
                    <div style="position: relative">
                        <question-icon
                            style="cursor: pointer"
                            @click="showPrompt3 = !showPrompt3"
                            :color="hoverQuestion3 ? '#02B061' : '#C0C4CD'"
                            @mouseenter="hoverQuestion3 = true"
                            @mouseleave="hoverQuestion3 = false"
                        />
                        <change-content-prompt v-if="showPrompt3" img="home_fourth_block.jpg"/>
                    </div>
                </div>
                <div class="change-contents__inputs">
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок зеленый цвет
                        </div>
                        <input-layout
                            placeholder="Заголовок зеленый цвет"
                            :input-value="homeContent.fourth_block.title1"
                            @update:input-value="homeContent.fourth_block.title1 = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок черный цвет
                        </div>
                        <input-layout
                            placeholder="Заголовок черный цвет"
                            :input-value="homeContent.fourth_block.title2"
                            @update:input-value="homeContent.fourth_block.title2 = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Первое преимущество
                        </div>
                        <input-layout
                            placeholder="Описание"
                            :input-value="homeContent.fourth_block.first_benefit"
                            @update:input-value="homeContent.fourth_block.first_benefit = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Второе преимущество
                        </div>
                        <input-layout
                            placeholder="Описание"
                            :input-value="homeContent.fourth_block.second_benefit"
                            @update:input-value="homeContent.fourth_block.second_benefit = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Третье преимущество
                        </div>
                        <input-layout
                            placeholder="Описание"
                            :input-value="homeContent.fourth_block.third_benefit"
                            @update:input-value="homeContent.fourth_block.third_benefit = $event"
                        />
                    </div>
                </div>
            </div>

            <div class="change-contents__separator"/>

            <div class="change-contents__main-item">
                <div class="change-contents__main-title">
                    Пятый блок
                    <div style="position: relative">
                        <question-icon
                            style="cursor: pointer"
                            @click="showPrompt4 = !showPrompt4"
                            :color="hoverQuestion4 ? '#02B061' : '#C0C4CD'"
                            @mouseenter="hoverQuestion4 = true"
                            @mouseleave="hoverQuestion4 = false"
                        />
                        <change-content-prompt v-if="showPrompt4" img="home_fifth_block.jpg"/>
                    </div>
                </div>
                <div class="change-contents__inputs">
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок зеленый цвет
                        </div>
                        <input-layout
                            placeholder="Заголовок зеленый цвет"
                            :input-value="homeContent.fifth_block.title1"
                            @update:input-value="homeContent.fifth_block.title1 = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Заголовок черный цвет
                        </div>
                        <input-layout
                            placeholder="Заголовок черный цвет"
                            :input-value="homeContent.fifth_block.title2"
                            @update:input-value="homeContent.fifth_block.title2 = $event"
                        />
                    </div>
                    <div class="change-contents__input">
                        <div class="change-contents__input-title">
                            Аннотация
                        </div>
                        <input-layout
                            placeholder="Описание"
                            :input-value="homeContent.fifth_block.annotation"
                            @update:input-value="homeContent.fifth_block.annotation = $event"
                        />
                    </div>
                </div>
                <div class="change-contents__btns">
                    <button-helper class="change-contents__btn" color="#FFFFFF" background="#02B061"
                                   @click="updateHomeContent">
                        <check-button-docs color="#FFFFFF"/>
                        Сохранить
                    </button-helper>
                    <button-helper class="change-contents__btn" color="#02B061" background="#FFFFFF" @click="preview">
                        <open-eye-icon color="#02B061"/>
                        Предпросмотр
                    </button-helper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import BigArrowIcon from "@/components/icons/BigArrowIcon.vue";
import QuestionIcon from "@/components/icons/QuestionIcon.vue";
import InputLayout from "@/components/layout/inputs/InputLayout.vue";
import ButtonHelper from "@/components/helppers/ButtonHelper.vue";
import CheckButtonDocs from "@/components/icons/CheckButtonDocs.vue";
import OpenEyeIcon from "@/components/icons/OpenEyeIcon.vue";
import ChangeContentPrompt from "@/components/helppers/changeContentPrompt.vue";

export default {
    name: 'AdminChangeHomeContent',

    components: {
        ChangeContentPrompt,
        OpenEyeIcon, CheckButtonDocs, ButtonHelper, InputLayout, QuestionIcon, BigArrowIcon},

    emits: ['showPrompt'],

    data() {
        return {
            showChangeContent: false,
            showPrompt1: false,
            hoverQuestion1: false,
            showPrompt2: false,
            hoverQuestion2: false,
            showPrompt3: false,
            hoverQuestion3: false,
            showPrompt4: false,
            hoverQuestion4: false
        }
    },

    computed: {
        homeContent() {
            return this.$store.getters.getHomeContent
        },
    },

    methods: {
        async updateHomeContent() {
            await this.$store.dispatch('updateHomeContent', this.homeContent)
            this.$store.dispatch('getAnnotationHeader')
            this.$emit('showPrompt')
        },
        async preview() {
            this.$router.push({name: 'home'})
        }
    },

}
</script>

<style scoped lang="scss">

.change-contents {

    &__item {
        border: 1px solid #D3D8E2;
        border-radius: 20px;
        padding: 45px 70px;
        overflow: hidden;

        @media screen and (max-width: 769px) {
            padding: 25px;
        }
    }

    &__title {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
        color: #373A43;
        column-gap: 10px;

        @media screen and (max-width: 769px) {
            justify-content: center;
            font-size: 23px;
        }
    }

    &__desc {
        margin-top: 8px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #7F818A;
    }

    &__main {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        row-gap: 60px;
    }

    &__main-item {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
    }

    &__main-title {
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: #373A43;
    }

    &__inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 50px;
    }

    &__input {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        max-width: 380px;
        width: 100%;

        @media screen and (max-width: 769px) {
            max-width: 100%;
        }
    }

    &__input-title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #373A43;
    }

    &__btns {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        @media screen and (max-width: 769px) {
            flex-direction: column-reverse;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 12px;
    }

    &__separator {
        border: 1px solid #DBE0EB;
        width: 100%;
        height: 1px;
    }

}

.rotate-icon {
    transition: .2s;
    transform: rotateX(-180deg);
}

</style>

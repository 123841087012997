<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="17.5" stroke="#D3D8E2" />
    <path
      d="M13 15.4443H22.7778"
      stroke="#A4ACBD"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6115 15.4443L14.2226 22.7777C14.2226 23.1018 14.3513 23.4127 14.5805 23.6419C14.8098 23.8711 15.1206 23.9999 15.4448 23.9999H20.3337C20.6578 23.9999 20.9687 23.8711 21.1979 23.6419C21.4271 23.4127 21.5559 23.1018 21.5559 22.7777L22.167 15.4443"
      stroke="#A4ACBD"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0574 15.4444V13.6111C16.0574 13.449 16.1218 13.2936 16.2364 13.179C16.351 13.0644 16.5064 13 16.6685 13H19.1129C19.275 13 19.4304 13.0644 19.5451 13.179C19.6597 13.2936 19.724 13.449 19.724 13.6111V15.4444"
      stroke="#A4ACBD"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DeleteIcon'
}
</script>

<style scoped></style>

export default {
  state: {
    mobile: false
  },

  getters: {
    getMobile(state) {
      return state.mobile
    }
  },

  mutations: {
    setMobile(state) {
      state.mobile = window.innerWidth < 769
    }
  }
}

<template>
    <user-layout>
        <D3Graph/>
    </user-layout>
</template>

<script>
import UserLayout from "@/components/layout/UserLayout.vue";
import D3Graph from "@/components/graph/D3Graph";

export default {
    name: "UserSearch",
    components: {D3Graph, UserLayout},
}
</script>

<style scoped>


</style>
